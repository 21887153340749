import {useMemo, useEffect, useState} from 'react';
import Table from '../../../../../../components/Table';

import {EmployeesHTTP} from '../../../../providers/employees';

const TimelinePage = ({userId = ''}) => {
  const [timeline, setTimeline] = useState({
    loading: true,
    data: [],
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({row}) =>
          `${row.original.date.month}/${row.original.date.day}/${row.original.date.year}`,
      },
      {
        Header: 'Hourly Rate',
        accessor: '_id.hourprice.$numberDecimal',
        Cell: ({row}) => `$ ${row.original._id.hourprice.$numberDecimal}`,
      },
    ],
    [],
  );

  useEffect(() => {
    async function fetchTimeline() {
      const {data} = await EmployeesHTTP.getTimeline(userId);
      setTimeline({
        loading: false,
        data,
      });
    }

    fetchTimeline();
  }, [userId]);

  return <Table columns={columns} data={timeline.data} />;
};

export default TimelinePage;
