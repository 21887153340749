import {useCallback, useEffect, useState, useMemo} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {useEmployee} from '../../../hooks/useEmployee';

import {currencyFormat} from '../../../../../utils/currency';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Avatar from '../../../../../components/Avatar';
import Button from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton';
import Search from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import Modal from '../../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../../components/LoadingBox';
import Switch from '../../../../../components/Switch';

import EmployeeForm from './components/Form';

const EmployeesPage = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {canCreateEmployee} = useAuth();
  const {employees, getEmployees, createEmployee, updateEmployee} =
    useEmployee();

  const [activeTab, setActiveTab] = useState('active');
  const [openModal, setOpenModal] = useState(false);
  const [openLimitModal, setOpenLimitModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getEmployees({status: 'all'});
  }, []);

  const handleCreate = useCallback(async (payload) => {
    try {
      setCreateLoading(true);
      await createEmployee(payload);
      await getEmployees({status: 'all'});
      setOpenModal(false);
      toast.success('Employee created successfully!');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.field) {
        setErrors(err.response.data);
      }
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const handleUpdateStatus = useCallback(async ({status, id}) => {
    try {
      await updateEmployee(id, {status: status ? 'active' : 'inactive'});
      toast.success('Done! status updated successfully.');
      await getEmployees({status: 'all'});
      // await updateEmployeesList(id, {status: status ? 'active' : 'inactive'});
    } catch (err) {
      if (err.response && err.response.data && err.response.data.field) {
        setErrors(err.response.data);
      }
    }
  }, []);

  const handleCreateEmployee = () => {
    if (!canCreateEmployee) {
      setOpenLimitModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const tabItems = useMemo(
    () => [
      {
        value: 'all',
        label: `${formatMessage({id: 'all'})} (${employees.all.length})`,
      },
      {
        value: 'active',
        label: `${formatMessage({id: 'active'})} (${employees.active.length})`,
      },
      {
        value: 'inactive',
        label: `${formatMessage({id: 'inactive'})} (${
          employees.inactive.length
        })`,
      },
    ],
    [employees, formatMessage],
  );

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Avatar
              id={row.original._id}
              photo={row.original.photo || null}
              name={row.original.name}
              size={10}
            />
            <div className="ml-2">
              <p>{row.original.name}</p>
              {row.original.skill_id && (
                <p className="text-xs text-gray-500">
                  {row.original.skill_id.name}
                </p>
              )}
            </div>
          </div>
        ),
      },
      {
        Header: `${formatMessage({id: 'phone'})}`,
        accessor: 'phone',
      },
      {
        Header: `${formatMessage({id: 'department'})}`,
        accessor: 'department_id.name',
      },
      {
        Header: `${formatMessage({id: 'hourly value'})}`,
        accessor: ({hourprice}) =>
          hourprice ? currencyFormat(hourprice.$numberDecimal) : '$0.00',
      },
      // {
      //   Header: 'Last Punch',
      //   accessor: 'last_punch',
      // },
      {
        Header: `${formatMessage({id: 'status'})}`,
        accessor: ({status, _id}) => (
          <Switch
            label={status}
            checked={status === 'active'}
            onChange={(val) => handleUpdateStatus({id: _id, status: val})}
          />
        ),
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updated_at}) =>
          formatDate(new Date(updated_at), 'MM/dd/yyyy hh:mm:ss'),
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() =>
              navigate(`/employee/${row.original._id}`, {replace: true})
            }
          />
        ),
      },
    ],
    [employees, formatMessage],
  );

  const employeesSearch =
    search.length > 0
      ? employees[activeTab].filter((emp) =>
          emp.name.toLowerCase().includes(search.toLowerCase()),
        )
      : employees[activeTab];

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'user.employees'})}
        breadcrumbs={[
          {
            id: 'users',
            label: formatMessage({id: 'menu.users'}),
          },
          {
            id: 'employees',
            label: formatMessage({id: 'user.employees'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => handleCreateEmployee()}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'employees.new-button'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={employees.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <Search onChange={(e) => setSearch(e.target.value)} value={search} />
        }>
        <Table columns={columns} data={employeesSearch} />
      </SectionWrapper>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="New Employee">
        <EmployeeForm
          onSubmit={handleCreate}
          onCancel={() => setOpenModal(false)}
          submitErrors={errors}
        />
      </Modal>

      <Modal
        open={openLimitModal}
        onClose={() => setOpenLimitModal(false)}
        maxWidth="max-w-2xl"
        title="New Employee">
        <div className="p-6">
          <h2 className="text-3xl font-bold">Ops...</h2>
          <p className="mt-2 mb-4">
            {formatMessage({id: 'user.employees-limit-modal-description'})}
          </p>
          <Button onClick={() => navigate(`/plans`, {replace: true})}>
            {formatMessage({id: 'user.employees-see-plans'})}
          </Button>
        </div>
      </Modal>

      {createLoading && <LoadingBox info="creating new employee..." />}
    </>
  );
};

export default EmployeesPage;
