/* eslint-disable react/no-unescaped-entities */
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {DashboardHTTP} from '../../../../providers/dashboard';

import Loading from '../../../../../../components/Loading';
import Card from '../../../../../../components/Card';
import Button from '../../../../../../components/Button';
import {WomanDeviceVector} from '../../../../../../components/_marketing/WomanDeviceVector';
import {ManDeviceVector} from '../../../../../../components/_marketing/ManDeviceVector';

const PunchesInfoCard = () => {
  const navigate = useNavigate();

  const [punches, setPunches] = useState({
    loading: true,
    data: {
      punchesMissedOut: 0,
      punchesWithoutJobLocation: 0,
    },
  });

  useEffect(() => {
    async function fetchPunches() {
      const {data} = await DashboardHTTP.getCompanyPunches();
      setPunches({
        loading: false,
        data,
      });
    }

    fetchPunches();
  }, []);

  const hasMissedOutPunches = punches.data.punchesMissedOut > 0;
  const hasWithoutJobLocationPunches =
    punches.data.punchesWithoutJobLocation > 0;

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mb-6">
      {hasWithoutJobLocationPunches && (
        <Card className="bg-yellow-100">
          {punches.loading ? (
            <Loading />
          ) : (
            <div className="flex justify-between items-center">
              <div className="hidden lg:block" style={{minWidth: 220}}>
                <WomanDeviceVector />
              </div>
              <div className="flex flex-col flex-grow items-start">
                <h2 className="text-2xl font-bold text-yellow-800">
                  You have {punches.data.punchesWithoutJobLocation} punches
                  without a job location
                </h2>
                <p className="mt-2 mb-4 text-sm text-yellow-900">
                  For better accuracy we recommend that you create a JOB
                  LOCATION and our system will allocate them automatically for
                  future new punches! After you create the job location, please
                  assign the above{' '}
                  <span className="font-bold">
                    {punches.data.punchesWithoutJobLocation}
                  </span>{' '}
                  punches to the corresponding job location that has already
                  been created.
                </p>

                <Button
                  onClick={() =>
                    navigate('/reports/punches-whitout-job-location')
                  }>
                  See punches
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}
      {hasMissedOutPunches && (
        <Card className="bg-yellow-100">
          {punches.loading ? (
            <Loading />
          ) : (
            <div className="flex justify-between items-center">
              <div className="hidden lg:block" style={{minWidth: 220}}>
                <ManDeviceVector />
              </div>
              <div className="flex flex-col flex-grow items-start">
                <h2 className="text-2xl font-bold text-yellow-800">
                  You have {punches.data.punchesMissedOut} punches MISSED OUT
                </h2>
                <p className="mt-2 mb-4 text-sm text-yellow-900">
                  Missed Out happens when employee exceed the limit of it's
                  department Max Shift. For further assistence we recommend you
                  to check your timecard and contact your team. Remember when an
                  employee reach Max Shift, he or she looses the entire day of
                  work. You can change it's Max Shift whenever you want.
                </p>
                <Button onClick={() => navigate('/reports/punches-missed-out')}>
                  See punches
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default PunchesInfoCard;
