/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import Cards from 'react-credit-cards';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {StripeHTTP} from '../../../../providers/stripe';
import {useAuth} from '../../../../../auth/hooks/useAuth';

import Badge from '../../../../../../components/Badge';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Loading from '../../../../../../components/Loading';
import ConfirmationModal from '../../../../../../components/_modals/ConfirmationModal';

import Form from './Form';

const PaymentMethods = () => {
  const {user} = useAuth();
  const {formatMessage} = useIntl();

  const [paymentMethods, setPaymentMethods] = useState({
    loading: true,
    data: [],
    openNew: false,
    selected: null,
    update: {
      open: false,
      loading: false,
    },
    remove: {
      open: false,
      loading: false,
    },
  });

  const getPaymentMethods = async () => {
    if (!user || !user.company) return;

    if (!paymentMethods.loading)
      setPaymentMethods((prev) => ({...prev, loading: true}));
    const {data} = await StripeHTTP.getPaymentMethods({
      stripeCustomer: user.company.stripe_customer,
    });
    setPaymentMethods((prev) => ({
      ...prev,
      loading: false,
      data: data && data.data ? data.data : [],
    }));
  };

  const handleCloseNew = () => {
    getPaymentMethods();
    setPaymentMethods((prev) => ({...prev, openNew: false}));
  };

  const handleUpdateDefault = async () => {
    try {
      if (!paymentMethods.selected) return;
      setPaymentMethods((prev) => ({
        ...prev,
        update: {...prev.update, loading: true},
      }));
      await StripeHTTP.updatePaymentMethod(paymentMethods.selected.id, {
        customer: user.company.stripe_customer,
        is_default: true,
      });
      setPaymentMethods((prev) => ({
        ...prev,
        update: {open: false, loading: false},
      }));
    } catch (_) {
      setPaymentMethods((prev) => ({
        ...prev,
        update: {open: false, loading: false},
      }));
    }
  };

  const handleRemovePm = async () => {
    try {
      if (!paymentMethods.selected) return;
      setPaymentMethods((prev) => ({
        ...prev,
        remove: {...prev.remove, loading: true},
      }));
      await StripeHTTP.removePaymentMethod(
        paymentMethods.selected.id,
        user.company.stripe_customer,
      );
      getPaymentMethods();
      setPaymentMethods((prev) => ({
        ...prev,
        remove: {open: false, loading: false},
      }));
    } catch (_) {
      setPaymentMethods((prev) => ({
        ...prev,
        remove: {open: false, loading: false},
      }));
      throw new Error('remove-payment-method');
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <>
      <Card padding="p-0" className="mb-6 pb-6 overflow-hidden">
        <div className="flex items-center justify-between w-full p-6">
          <h2 className="text-lg font-medium text-gray-800">
            {formatMessage({id: 'settings.pay'})}
          </h2>

          <Button
            onClick={() =>
              setPaymentMethods((prev) => ({...prev, openNew: true}))
            }>
            {formatMessage({id: 'settings.pay-add-new-method'})}
          </Button>
        </div>
        {paymentMethods.loading ? (
          <div className="m-4">
            <Loading />
          </div>
        ) : (
          <div className="flex gap-4 p-4 flex-wrap">
            {paymentMethods.data.map((pm) => (
              <div
                key={`pm-card-${pm.id}`}
                className="relative flex flex-col items-start gap-2 w-2/6">
                <div className="relative">
                  {pm.default_source && (
                    <Badge extraClassName="absolute left-2 top-2 z-40 border-2 border-white">
                      {formatMessage({id: 'settings.pay-default'})}
                    </Badge>
                  )}
                  <Cards
                    cvc=""
                    expiry={`${pm.card.exp_month < 10 ? '0' : ''}${
                      pm.card.exp_month
                    }/${pm.card.exp_year}`}
                    name={pm.billing_details.name}
                    number={`**** **** **** ${pm.card.last4}`}
                    issuer={pm.card.display_brand}
                    preview
                    focused={false}
                    style={{margin: 0}}
                  />
                </div>

                <div className="flex gap-4">
                  {/* <Button>Edit</Button> */}
                  {!pm.default_source && (
                    <>
                      <Button
                        variant="primary"
                        onClick={() =>
                          setPaymentMethods((prev) => ({
                            ...prev,
                            selected: pm,
                            update: {...prev.update, open: true},
                          }))
                        }>
                        {formatMessage({id: 'settings.pay-set-default'})}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() =>
                          setPaymentMethods((prev) => ({
                            ...prev,
                            selected: pm,
                            remove: {...prev.remove, open: true},
                          }))
                        }>
                        {formatMessage({id: 'remove'})}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
      <Form open={paymentMethods.openNew} onClose={() => handleCloseNew()} />
      <ConfirmationModal
        open={paymentMethods.update.open}
        onClose={() =>
          setPaymentMethods((prev) => ({
            ...prev,
            update: {loading: false, open: false},
          }))
        }
        onSubmit={handleUpdateDefault}
        title={formatMessage({id: 'settings.pay-modal-title'})}
        description={formatMessage({id: 'settings.pay-desc'})}
        successMsg={formatMessage({id: 'settings.pay-default-pay'})}
      />
      <ConfirmationModal
        open={paymentMethods.remove.open}
        onClose={() =>
          setPaymentMethods((prev) => ({
            ...prev,
            remove: {loading: false, open: false},
          }))
        }
        onSubmit={handleRemovePm}
        title={formatMessage({id: 'settings.pay-remove-pay'})}
      />
    </>
  );
};

export default PaymentMethods;
