import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

const normalButtonClasses =
  'text-gray-600 hover:text-yellow-600 hover:bg-yellow-50';
const openedButtonClasses = 'text-yellow-600 bg-yellow-50';

const SubMenu = ({
  label,
  items,
  itemIcon: ItemIcon,
  activeItem,
  onOpenTutorial = () => {},
}) => {
  const {formatMessage} = useIntl();

  return (
    <Menu as="div" className="relative z-20">
      {({open}) => (
        <>
          <Menu.Button
            className={`${
              open || activeItem ? openedButtonClasses : normalButtonClasses
            } px-3 py-3 rounded-md text-sm font-medium flex items-center focus:outline-none`}>
            <>
              <ItemIcon className="w-4 mr-2" aria-hidden="true" />
              {formatMessage({id: label})}{' '}
              {open ? (
                <ChevronUpIcon className="w-4 ml-2" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="w-4 ml-2" aria-hidden="true" />
              )}
            </>
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              static
              className="origin-top-left absolute left-0 w-52 rounded-md shadow-lg py-0 bg-white focus:outline-none">
              {items.map(({label: subLabel, route, icon: SubIcon, videoLink}) =>
                route === '#tutorial-modal' ? (
                  <Menu.Item key={`subitem-${route}`}>
                    <button
                      type="button"
                      onClick={() => onOpenTutorial(videoLink)}
                      className="flex items-center p-4 w-full text-sm text-gray-700 border-l-2 border-transparent bg-transparent hover:bg-yellow-50 hover:text-yellow-700 hover:border-yellow-200">
                      <SubIcon className="w-4 mr-2" aria-hidden="true" />
                      {formatMessage({id: subLabel})}
                    </button>
                  </Menu.Item>
                ) : (
                  <Menu.Item key={`subitem-${route}`}>
                    <Link
                      to={route}
                      className="flex items-center p-4 text-sm text-gray-700 border-l-2 border-transparent hover:bg-yellow-50 hover:text-yellow-700 hover:border-yellow-200">
                      <>
                        <SubIcon className="w-4 mr-2" aria-hidden="true" />
                        {formatMessage({id: subLabel})}
                      </>
                    </Link>
                  </Menu.Item>
                ),
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SubMenu;
