/* eslint-disable react/button-has-type */
const IconButton = ({
  type = 'button',
  size = 'w-10 h-10',
  colors = 'bg-transparent hover:bg-yellow-50',
  icon: Icon,
  iconClassName = 'text-yellow-500 w-6 h-6',
  ...rest
}) => (
  <button
    type={type}
    className={`flex items-center justify-center rounded-full ${size} ${colors} transition-colors duration-200 transform`}
    {...rest}>
    <Icon className={iconClassName} aria-hidden="true" />
  </button>
);

export default IconButton;
