import {useEffect} from 'react';

import {useJobLocation} from '../../../../hooks/useJobLocation';

import Modal from '../../../../../../components/_modals/Modal';
import Map from '../../../../../../components/Map';

const ViewPunchMapModal = ({
  open = false,
  onClose = () => {},
  punch = {},
  location = {},
}) => {
  const {jobs, getJobs} = useJobLocation();

  useEffect(() => {
    getJobs();
  }, []);

  if (jobs.loading && !location) return <h1>loading...</h1>;

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={250}
      open={open}
      onClose={onClose}
      title="View Address">
      {punch && location && location.address && (
        <>
          <p className="p-4 text-sm" style={{marginTop: -20}}>
            {location.address}
          </p>
          <Map
            circles={jobs.data}
            points={[punch]}
            initialZoom={14}
            initialData={{
              coordinates: location.coordinates,
              radius: 0,
              address: location.address,
              city: location.city,
            }}
          />
        </>
      )}
    </Modal>
  );
};

export default ViewPunchMapModal;
