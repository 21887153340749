import {useParams} from 'react-router-dom';

const InvoicePdf = () => {
  const {id} = useParams();

  return (
    <iframe
      title="Preview"
      src={`https://workpodium.com/preview.php?token=${id}`}
      width="100%"
      height="100%"
    />
  );
};

export default InvoicePdf;
