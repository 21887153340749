import BaseModal from 'react-modal';
import {XMarkIcon} from '@heroicons/react/24/outline';

import IconButton from '../../IconButton';

BaseModal.setAppElement('#root');

const modalContainerClass =
  'relative inline-block align-bottom bg-white text-left shadow-xl transform transition-all w-full h-screen sm:my-8 sm:align-middle md:h-auto md:rounded-lg';

const Modal = ({
  open,
  onClose,
  maxWidth = 'max-w-4xl',
  minHeight = 200,
  extraClasses = '',
  contentExtraClasses = '',
  title = '',
  children,
}) => (
  <BaseModal
    isOpen={open}
    onRequestClose={onClose}
    overlayClassName="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex items-end justify-center min-h-screen p-0 md:pt-4 md:px-4 md:pb-20 text-center sm:block sm:p-0"
    className={`${modalContainerClass} ${extraClasses} md:${maxWidth}`}>
    <div className={`flex flex-col ${contentExtraClasses}`} style={{minHeight}}>
      <div
        className={`flex ${
          title ? 'justify-between' : 'justify-end'
        } w-full p-2`}>
        {title && <h2 className="font-bold text-lg p-2">{title}</h2>}
        <IconButton icon={XMarkIcon} onClick={onClose} />
      </div>

      {children}
    </div>
  </BaseModal>
);

export default Modal;
