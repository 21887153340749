import {useState, useEffect} from 'react';
import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import {DateRange} from 'react-date-range';
import {endOfMonth, subMonths, format as formatDate} from 'date-fns';
import {useIntl} from 'react-intl';

import {useEmployee} from '../../../hooks/useEmployee';
import {ReportsHTTP} from '../../../providers/reports';

import Button from '../../../../../components/Button';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import SearchSelect from '../../../../../components/_form/SearchSelect';

const now = new Date();

const EmployeeEarningsReports = () => {
  const {formatMessage} = useIntl();
  const {getEmployees, employees} = useEmployee();

  const [loading, setLoading] = useState(false);
  const [selectedEmployee, setSelectEmployee] = useState('');
  const [period, setPeriod] = useState({
    startDate: subMonths(now, 12),
    endDate: endOfMonth(now),
    key: 'selection',
  });

  const createdPDF = async () => {
    if (!selectedEmployee) {
      toast.error(formatMessage({id: 'toast.select-employee'}));
      return;
    }

    try {
      setLoading(true);
      const startAt = formatDate(period.startDate, 'yyyy-MM-dd');
      const endAt = formatDate(period.endDate, 'yyyy-MM-dd');
      await ReportsHTTP.employeeEarningsdPDF({
        userId: selectedEmployee,
        start: startAt,
        end: endAt,
      });
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'reports.employee-earnings'})}
        breadcrumbs={[
          {
            id: 'reports',
            label: formatMessage({id: 'menu.reports'}),
          },
          {
            id: 'employee-earnings-pdf',
            label: formatMessage({id: 'reports.employee-earnings'}),
          },
        ]}
      />

      <SectionWrapper
        toolbarTitle={formatMessage({id: 'reports.select-employee'})}>
        <div className="flex flex-col items-start gap-4 w-full p-4 bg-white">
          <div style={{width: 350}}>
            {!employees.loading && (
              <SearchSelect
                placeholder={formatMessage({id: 'toast.select-employee'})}
                options={employees.all.map((e) => ({
                  value: e._id,
                  label: `${e.name} ${
                    e.status === 'inactive' ? '(inactive)' : ''
                  }`,
                }))}
                onChange={(selected) => setSelectEmployee(selected.value)}
              />
            )}
          </div>

          <div className="mt-1">
            <h3>{formatMessage({id: 'select-period'})}</h3>
            <div className="flex datepicker-tmg">
              <DateRange
                ranges={[period]}
                onChange={(values) => setPeriod(values.selection)}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
              />
            </div>
          </div>

          <Button
            extraClassName="shadow-sm"
            disabled={loading}
            onClick={() => createdPDF()}>
            <>
              <ArrowDownTrayIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              {loading
                ? formatMessage({id: 'creating-pdf'})
                : formatMessage({id: 'download-pdf'})}
            </>
          </Button>
        </div>
      </SectionWrapper>
    </>
  );
};

export default EmployeeEarningsReports;
