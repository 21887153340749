/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import {useState, useCallback, useEffect, memo} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  InfoWindow,
} from '@react-google-maps/api';

import PunchMarker from './PunchMarker';
import {Container} from './styles';

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};
const divStyle = {
  background: `white`,
  padding: 5,
};

const libraries = ['places'];

function MapRadius({
  mapHeight = 500,
  initialData = {
    coordinates: [],
    radius: 500,
    address: null,
    city: '',
  },
  circles = [],
  points = [],
  initialZoom = 10,
}) {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD9bppSLroFMyFMQQ3mF5FufeFZrQXLrgA',
    libraries,
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  const [zoom, setZoom] = useState(8);
  const [circleRadius, setCircleRadius] = useState(null);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [, setLoadEl] = useState({
    markers: [],
    circles: [],
  });

  const onLoad = useCallback((mapLoaded) => {
    const bounds = new window.google.maps.LatLngBounds();
    mapLoaded.fitBounds(bounds);
    setMap(mapLoaded);
  }, []);

  const onLoadMarker = (marker) => {
    // console.log('marker: ', marker);
    setLoadEl((prev) => ({...prev, markers: marker}));
  };

  const onLoadCircle = useCallback((ref) => {
    setCircleRadius(ref);
    setLoadEl((prev) => ({...prev, circles: ref}));
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map && initialData.address) {
      setTimeout(() => {
        setCenter({
          lat: initialData.coordinates[1],
          lng: initialData.coordinates[0],
        });
      }, 400);

      setTimeout(() => setZoom(initialZoom), 500);
    }
  }, [initialData, map]);

  if (!isLoaded) return <h1>loading...</h1>;

  return (
    <Container $height={mapHeight}>
      <GoogleMap
        id="google-map-wrapper"
        mapContainerStyle={{width: '100%', height: mapHeight}}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        {circles.map((circle) => (
          <Circle
            key={circle._id}
            onLoad={onLoadCircle}
            radius={parseFloat(circle.location.radius.$numberDecimal)}
            center={{
              lat: circle.location.coordinates[1],
              lng: circle.location.coordinates[0],
            }}
            options={options}
            onClick={() => {
              setSelectedCircle(circle);
            }}
          />
        ))}

        {points.map((point) => (
          <PunchMarker key={`full-${point._id}`} punch={point} />
        ))}

        {selectedCircle && (
          <InfoWindow
            onLoad={onLoadMarker}
            onCloseClick={() => setSelectedCircle(null)}
            position={{
              lat: selectedCircle.location.coordinates[1],
              lng: selectedCircle.location.coordinates[0],
            }}>
            <div style={divStyle}>
              <strong>Job Location</strong>
              <h2
                style={{
                  marginTop: 15,
                  marginBottom: 5,
                  fontSize: 16,
                  fontWeight: 'bold',
                }}>
                {selectedCircle.name}
              </h2>
              <p>{selectedCircle.location.address}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Container>
  );
}

export default memo(MapRadius);
