import styled from 'styled-components';

const variants = {
  primary: 'bg-yellow-300 text-yellow-800',
  secondary: 'bg-gray-100 text-gray-800',
  success: 'bg-green-100 text-green-800',
  danger: 'bg-yellow-100 text-yellow-800',
  error: 'bg-red-100 text-red-800',
  info: 'bg-blue-100 text-blue-800',
};

const Wrapper = styled.span`
  & > * {
    display: inline;
    margin-bottom: 2px;
  }
`;

const Badge = ({
  variant = 'primary',
  children,
  extraClassName = '',
  ...rest
}) => (
  <Wrapper
    className={`inline-block max-w-full truncate px-2 leading-5 text-xs font-semibold rounded-full ${variants[variant]} ${extraClassName}`}
    {...rest}>
    {children}
  </Wrapper>
);

export default Badge;
