import {forwardRef} from 'react';

const Input = forwardRef(
  (
    {
      label = '',
      id,
      error,
      errorMessage = 'This field is required',
      helperText = '',
      type = 'text',
      className = 'mb-2',
      inputExtraClassName = '',
      ...rest
    },
    ref,
  ) => (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <input
        id={id}
        className={`focus:ring-yellow-200 focus:border-yellow-100 block w-full shadow-sm sm:text-sm rounded-md placeholder-gray-400 h-10 ${
          error ? 'border-red-300 ' : 'border-gray-300 '
        } ${inputExtraClassName}`}
        type={type}
        ref={ref}
        {...rest}
      />
      {error && (
        <p className="text-xs text-red-500 mt-2">
          {errorMessage || 'This field is required'}
        </p>
      )}
      {!error && helperText && (
        <p className="text-xs text-gray-500 mt-2">{helperText}</p>
      )}
    </div>
  ),
);

export default Input;
