import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {usStates} from '../../../../../../../utils/states';

import Input from '../../../../../../../components/_form/Input';
import InputPassword from '../../../../../../../components/_form/InputPassword';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import {FieldsetTitle} from '../../../../../../../components/_form/FieldsetTitle';
import {
  validateWhiteSpaces,
  validateUppercase,
} from '../../../../../../../utils/utils';

const EmployeeForm = ({
  onSubmit,
  initialData = {},
  onCancel = () => {},
  type = 'create',
  loading = false,
  submitErrors = null,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: initialData,
  });

  const [seePassword, setSeePassword] = useState(false);

  useEffect(() => {
    if (submitErrors && submitErrors.field) {
      setError(submitErrors.field, {
        type: 'manual',
        message: submitErrors.msg || '',
      });
    }
  }, [submitErrors]);

  useEffect(() => clearErrors(), []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Name *"
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <Controller
            rules={{
              required: true,
              pattern: {
                value: /^[^_]*$/i,
                message: 'Provide a valid phone number',
              },
            }}
            name="phone"
            control={control}
            render={({field}) => (
              <MaskedInput
                {...field}
                defaultValue={field.value}
                mask="(999) 999-9999"
                maskPlaceholder="_"
                label="Phone *"
                alwaysShowMask={false}
                removeMask={false}
                error={errors.phone}
                errorMessage={errors.phone ? errors.phone.message : ''}
              />
            )}
          />
          <Input
            label="Email"
            id="email"
            type="email"
            error={errors.email}
            {...register('email')}
          />
          <Controller
            name="language"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label="Language"
                id="select-language"
                defaultValue="english"
                options={[
                  {value: 'english', label: 'English'},
                  {value: 'portuguese', label: 'Portuguese'},
                  {value: 'spanish', label: 'Spanish'},
                ]}
              />
            )}
          />
        </div>

        <FieldsetTitle>Address</FieldsetTitle>

        <div className="grid grid-cols-4 gap-4">
          <Input
            label="Street"
            id="street"
            type="text"
            error={errors.street}
            {...register('street')}
          />
          <Input
            label="City"
            id="city"
            type="text"
            error={errors.city}
            {...register('city')}
          />
          <Input
            label="Zip Code"
            id="zip_code"
            type="text"
            error={errors.zip_code}
            {...register('zip_code')}
          />
          <Controller
            name="state"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label="State"
                id="select-state"
                options={usStates}
              />
            )}
          />
        </div>

        <FieldsetTitle>Account Settings</FieldsetTitle>

        <div className="grid grid-cols-3 gap-4">
          <Controller
            name="role"
            control={control}
            rules={{
              required: true,
            }}
            render={({field}) => (
              <Select
                {...field}
                label="Role *"
                id="select-role"
                error={errors.role}
                options={[
                  {value: 'sales', label: 'Sales'},
                  {value: 'sales_assistant', label: 'Sales Assistant'},
                ]}
              />
            )}
          />
          <Input
            label="Username *"
            id="username"
            type="text"
            error={errors.username}
            errorMessage={errors.username ? errors.username.message : ''}
            {...register('username', {
              required: true,
              validate: {
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
                uppercaseChar: (val) =>
                  validateUppercase(val) || 'Remove all uppercase letters!',
              },
            })}
          />
          <InputPassword
            label="Password *"
            id="password"
            error={errors.password}
            onShowPassword={() => setSeePassword((prev) => !prev)}
            seePassword={seePassword}
            {...register('password', {
              required: type === 'create',
              min: 3,
              validate: {
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
              },
            })}
          />
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit" loading={loading}>
          Save
        </Button>
        {type === 'create' && (
          <Button
            variant="secondary"
            extraClassName="mr-2"
            onClick={() => onCancel()}>
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default EmployeeForm;
