import {useState} from 'react';

import Plan from './Plan';
import Register from './Register';

const RegisterBlackFriday = () => {
  const [state, setState] = useState({
    step: 1,
    plan: 'yearly',
  });

  if (state.step === 1)
    return (
      <Plan
        plan={state.plan}
        onChangePlan={(plan) => setState({step: 1, plan})}
        onSelectPlan={() => {
          setState((prev) => ({...prev, step: 2}));
          window.scrollTo(0, 0);
        }}
      />
    );

  return (
    <Register
      plan={state.plan}
      onBack={() => setState((prev) => ({...prev, step: 1}))}
    />
  );
};

export default RegisterBlackFriday;
