/* eslint-disable jsx-a11y/label-has-associated-control */
import {useState} from 'react';
import {toast} from 'react-toastify';
import {format as formatDate} from 'date-fns-tz';
import {useIntl} from 'react-intl';

import {JobLocationHTTP} from '../../../../../providers/jobs';
import {currencyFormat} from '../../../../../../../utils/currency';
import {clearCurrencyValue} from '../../../../../../../utils/utils';

import Button from '../../../../../../../components/Button';
import TextArea from '../../../../../../../components/_form/TextArea';
import CurrencyInput from '../../../../../../../components/_form/MaskedInput/Currency';

const BudgetTimeline = ({jobLocation, onSuccess = () => {}}) => {
  const [action, setAction] = useState('increase');
  const [amount, setAmount] = useState('0.00');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const {formatMessage} = useIntl();

  const handleUpdateBudget = async () => {
    try {
      setLoading(true);
      const payload = {
        action,
        amount: clearCurrencyValue(amount),
        message,
      };
      await JobLocationHTTP.updateBudget(jobLocation._id, payload);
      onSuccess();
      setAmount('0.00');
      setMessage('');
      setAction('increase');
      toast.success('Budget updated successfully!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="p-4 flex flex-wrap w-full">
        <div className="lg:w-2/5 md:w-1/3">
          <h2 className="text-2xl font-bold text-gray-700">
            {formatMessage({id: 'joblocation.current-budget'})}{' '}
            <span className="text-yellow-500">
              {currencyFormat(jobLocation.budget.$numberDecimal)}
            </span>
          </h2>

          <div className="flex pt-4 pb-2">
            <div className="pr-5">
              <div className="flex items-center">
                <input
                  id="update-action"
                  className="focus:ring-yellow-400 h-4 w-4 text-yellow-500 border-gray-300"
                  name="update-action"
                  type="radio"
                  value="increase"
                  checked={action === 'increase'}
                  onChange={() => setAction('increase')}
                />
                <label
                  htmlFor="update-action"
                  className="ml-3 block text-sm font-medium text-gray-700">
                  {formatMessage({id: 'increase'})}
                </label>
              </div>
              <div className="flex items-center mt-1">
                <input
                  id="update-action"
                  className="focus:ring-yellow-400 h-4 w-4 text-yellow-500 border-gray-300"
                  name="update-action"
                  type="radio"
                  value="decrease"
                  checked={action === 'decrease'}
                  onChange={() => setAction('decrease')}
                />
                <label
                  htmlFor="update-action"
                  className="ml-3 block text-sm font-medium text-gray-700">
                  {formatMessage({id: 'decrease'})}
                </label>
              </div>
            </div>

            <CurrencyInput
              label={formatMessage({id: 'joblocation.change-budget-value'})}
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e)}
            />
          </div>

          <div className="pr-10 mb-6">
            <TextArea
              label={formatMessage({id: 'message'})}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <Button onClick={() => handleUpdateBudget()} loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>

        <div className="lg:w-3/5 md:w-2/3 md:pr-10 md:py-6">
          {jobLocation.logs.map((log) => {
            const updatedData = JSON.parse(log.new_values);
            const {field} = updatedData;
            const newValue =
              field === 'budget'
                ? currencyFormat(updatedData.new_value.$numberDecimal)
                : updatedData.new_value;
            const oldValue =
              field === 'budget'
                ? currencyFormat(updatedData.old_value.$numberDecimal)
                : updatedData.old_value;
            return (
              <div key={log._id} className="flex relative pb-8">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <p className="text-sm text-gray-400">
                    Updated at{' '}
                    {formatDate(
                      new Date(log.created_at),
                      'MM/dd/yyyy hh:mm:ss',
                      {timeZone: 'America/New_York'},
                    )}{' '}
                    by {log.user_id.name}
                  </p>
                  <h2 className="font-medium text-lg text-gray-900 mb-1 tracking-wider">
                    Field {field} was uptated from {oldValue} to {newValue}
                  </h2>
                  <p className="text-gray-700">{log.message}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BudgetTimeline;
