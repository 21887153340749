/* eslint-disable no-nested-ternary */
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid';
import range from 'lodash/range';

const Pagination = ({
  totalPages = 5,
  perPage = 50,
  currentPage = 1,
  totalRecords = 50,
  onChangePage = () => {},
}) => {
  const activeClasses =
    'z-10 bg-yellow-50 border-yellow-500 text-yellow-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
  const normalClasses =
    'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium';

  const isFirstPage = Boolean(currentPage === 1);
  const isLastPage = Boolean(currentPage === totalPages);
  const isLastPages = Boolean(totalPages - currentPage < 5);

  const startAt = isFirstPage
    ? 1
    : isLastPages
    ? currentPage - (5 - (totalPages - currentPage))
    : currentPage - 1;
  const endAt = isLastPage ? totalPages : currentPage + 3;

  const pages = range(
    startAt < 1 ? 1 : startAt,
    endAt > totalPages ? totalPages : endAt,
  );

  const initRecord = isFirstPage ? 1 : perPage * currentPage + 1;
  const lastRecord = isFirstPage ? perPage : initRecord + perPage - 1;

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {!isFirstPage && (
          <button
            type="button"
            onClick={() => onChangePage(currentPage - 1)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Previous
          </button>
        )}
        {!isLastPage && (
          <button
            type="button"
            onClick={() => onChangePage(currentPage + 1)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Next
          </button>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{initRecord}</span> to{' '}
            <span className="font-medium">{lastRecord}</span> of{' '}
            <span className="font-medium">{totalRecords}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination">
            {!isFirstPage && (
              <button
                type="button"
                onClick={() => onChangePage(currentPage - 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {pages.map((p) => (
              <button
                key={`page-${p}`}
                type="button"
                onClick={() => onChangePage(p)}
                aria-current="page"
                className={p === currentPage ? activeClasses : normalClasses}>
                {p}
              </button>
            ))}
            {!isLastPage && !isLastPages && (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
            )}
            <button
              type="button"
              onClick={() => onChangePage(totalPages)}
              aria-current="page"
              className={isLastPage ? activeClasses : normalClasses}>
              {totalPages}
            </button>

            {!isLastPage && (
              <button
                type="button"
                onClick={() => onChangePage(currentPage + 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
