/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import api from '../../../../services/api';
import MaskedInput from '../../../../components/_form/MaskedInput';
import Input from '../../../../components/_form/Input';
import Button from '../../../../components/Button';
import {validateWhiteSpaces} from '../../../../utils/utils';

import logo from '../../../../assets/images/logos-inline-color.png';

const ThirdStep = ({state, setState}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setState((prev) => ({...prev, loading: true}));

      const password = data.password.replace(/\s/g, '');

      await api.post('forgot-password/update-password', {
        token: state.code,
        user_id: state.user,
        password,
      });
      toast.success('Done! password updated successfully.');
      navigate('/');
    } catch (error) {
      setState((prev) => ({...prev, loading: false}));
      toast.error('The password is invalid.');
    }
  };

  return (
    <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Password *"
        id="password"
        type="password"
        error={errors.password}
        errorMessage={errors.password && errors.password.message}
        {...register('password', {
          required: true,
          minLength: {
            value: 4,
            message: 'needs to be at least 4 characters',
          },
          validate: {
            whitespace: (val) =>
              validateWhiteSpaces(val) || 'Remove all white spaces!',
          },
        })}
      />

      <div className="mt-6">
        <Button
          loading={state.loading}
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
          Save new password
        </Button>
      </div>
    </form>
  );
};

const SecondStep = ({state, setState}) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setState((prev) => ({...prev, loading: true}));
      const code = data.code.replace(/\s/g, '');

      await api.post('forgot-password/verify-token', {
        token: code,
        user_id: state.user,
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        code,
        step: 'third',
      }));
    } catch (error) {
      setState((prev) => ({...prev, loading: false}));
      toast.error('The code was incorrect.');
    }
  };

  return (
    <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        rules={{
          required: true,
        }}
        name="code"
        control={control}
        render={({field}) => (
          <MaskedInput
            {...field}
            label="Code *"
            mask="999-999"
            maskPlaceholder="XXX-XXX"
            alwaysShowMask={false}
            removeMask
            error={errors.code}
            errorMessage={errors.code ? errors.code.message : ''}
          />
        )}
      />

      <div className="mt-6">
        <Button
          loading={state.loading}
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
          Verify and send code
        </Button>
      </div>
    </form>
  );
};

const FirstStep = ({state, setState}) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setState((prev) => ({...prev, loading: true}));
      const phone = data.phone.replace(/[() -]/g, '');

      let currentUserId = null;
      const responseData = await api.get(`forgot-password/user?phone=${phone}`);
      if (responseData && responseData.data) {
        currentUserId = responseData.data[0]._id;
      }
      await api.post('forgot-password/create-token', {
        user: currentUserId,
        method: 'phone',
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        phone,
        user: currentUserId,
        step: 'second',
      }));
    } catch (error) {
      setState((prev) => ({...prev, loading: false}));
      toast.error('Your phone number was incorrect or no account was found.');
    }
  };

  return (
    <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        rules={{
          required: true,
          minLength: 14,
          pattern: {
            value: /^[^_]*$/i,
            message: 'Provide a valid phone number',
          },
        }}
        name="phone"
        control={control}
        render={({field}) => (
          <MaskedInput
            {...field}
            label="Phone *"
            mask="(999) 999-9999"
            maskPlaceholder="(999) 999-9999"
            alwaysShowMask={false}
            removeMask={false}
            error={errors.phone}
            errorMessage={errors.phone ? errors.phone.message : ''}
          />
        )}
      />

      <div className="mt-6">
        <Button
          loading={state.loading}
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
          Verify and send code
        </Button>
      </div>
    </form>
  );
};

const stepTexts = {
  first:
    'Enter your mobile number and we will send the code to reset your password.',
  second: 'Enter the code sent to your cell phone.',
  third: 'Enter the new password.',
};
const ResetPassword = () => {
  const [state, setState] = useState({
    loading: false,
    phone: '',
    user: '',
    code: '',
    step: 'first',
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="WorkPodium - Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Forgot password
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {stepTexts[state.step]}
          </p>
        </div>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            {state.step === 'first' && (
              <FirstStep state={state} setState={setState} />
            )}
            {state.step === 'second' && (
              <SecondStep state={state} setState={setState} />
            )}
            {state.step === 'third' && (
              <ThirdStep state={state} setState={setState} />
            )}

            <p className="mt-8 text-xs font-light text-center text-gray-400">
              {' '}
              Have an account?{' '}
              <Link
                to="/"
                className="font-medium text-yellow-500 hover:text-yellow-600">
                Login now
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
