/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import Button from '../../../../../../../components/Button';
import AutocompleteMaps from '../../../../../../../components/AutocompleteMaps';

const UpdateLocation = ({
  location = {},
  onUpdate = () => {},
  loading = false,
}) => {
  const [selectedPlace, setSelectedPlace] = useState(() => {
    const {address, coordinates, radius, city} = location;
    return {
      address,
      coordinates,
      radius: radius.$numberDecimal,
      city,
    };
  });
  const {formatMessage} = useIntl();
  const handleUpdate = () => {
    if (
      !selectedPlace ||
      !selectedPlace.address ||
      !selectedPlace.coordinates ||
      !selectedPlace.coordinates[0]
    ) {
      toast.error('Provide a valid address!');
      return;
    }

    onUpdate({location: selectedPlace});
  };

  useEffect(() => {
    if (!selectedPlace.address) {
      const {address, coordinates, radius, city} = location;
      setSelectedPlace({
        address,
        coordinates,
        radius: radius.$numberDecimal,
        city,
      });
    }
  }, [location]);

  return (
    <div>
      <h2 className="p-4 text-sm font-bold text-gray-900">
        {formatMessage({id: 'joblocation.current-address'})} {location?.address}
      </h2>
      <div className="col-span-2 bg-white rounded-lg shadow overflow-hidden p-4">
        {selectedPlace.address && selectedPlace.coordinates && (
          <AutocompleteMaps
            onChange={(value) => {
              if (value && value.address) {
                setSelectedPlace(value);
              }
            }}
            initialData={selectedPlace}
          />
        )}
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button onClick={() => handleUpdate()} loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
      </div>
    </div>
  );
};

export default UpdateLocation;
