import {useState} from 'react';
import {endOfMonth, subMonths, format as formatDate} from 'date-fns';
import {DateRangePicker} from 'react-date-range';

import Button from '../../Button';
import Modal from '../Modal';

const DateRangeModal = ({open, onSelect = () => {}, onClose = () => {}}) => {
  const now = new Date();

  const [period, setPeriod] = useState({
    startDate: subMonths(now, 12),
    endDate: endOfMonth(now),
    key: 'selection',
  });

  return (
    <Modal open={open} onClose={() => onClose()}>
      <DateRangePicker
        ranges={[period]}
        onChange={(values) => setPeriod(values.selection)}
        showSelectionPreview
        moveRangeOnFirstSelection={false}
        months={2}
        direction="horizontal"
      />

      <div className="form-footer">
        <Button
          onClick={() =>
            onSelect({
              startDate: formatDate(period.startDate, 'MM-dd-yyyy'),
              endDate: formatDate(period.endDate, 'MM-dd-yyyy'),
            })
          }>
          Confirm
        </Button>
        <Button
          onClick={() => onClose()}
          variant="secondary"
          extraClassName="mr-2">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DateRangeModal;
