import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';

import {usStates} from '../../../../../../../utils/states';

import Input from '../../../../../../../components/_form/Input';
import InputPassword from '../../../../../../../components/_form/InputPassword';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import {FieldsetTitle} from '../../../../../../../components/_form/FieldsetTitle';
import {
  validateWhiteSpaces,
  validateUppercase,
} from '../../../../../../../utils/utils';

const BossForm = ({
  onSubmit,
  onCancel = () => {},
  initialData = {},
  submitErrors = null,
  loading = false,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: initialData,
  });
  const {formatMessage} = useIntl();

  const [seePassword, setSeePassword] = useState(false);

  useEffect(() => {
    if (submitErrors && submitErrors.field) {
      setError(submitErrors.field, {
        type: 'manual',
        message: submitErrors.msg || '',
      });
    }
  }, [submitErrors]);

  useEffect(() => clearErrors(), []);

  const isEdit = Boolean(Object.keys(initialData).length > 0);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={formatMessage({id: 'name-required'})}
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <Controller
            rules={{
              required: true,
            }}
            name="phone"
            control={control}
            render={({field}) => (
              <MaskedInput
                {...field}
                defaultValue={field.value}
                mask="(999) 999-9999"
                maskPlaceholder="_"
                label={formatMessage({id: 'phone-required'})}
                alwaysShowMask={false}
                removeMask={false}
                error={errors.phone}
                errorMessage={errors.phone ? errors.phone.message : ''}
              />
            )}
          />
          <Input
            label={formatMessage({id: 'email'})}
            id="email"
            type="email"
            error={errors.email}
            {...register('email')}
          />
          <Controller
            name="language"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label={formatMessage({id: 'language'})}
                id="select-language"
                defaultValue="english"
                options={[
                  {value: 'english', label: formatMessage({id: 'english'})},
                  {
                    value: 'portuguese',
                    label: formatMessage({id: 'portuguese'}),
                  },
                  {value: 'spanish', label: formatMessage({id: 'spanish'})},
                ]}
              />
            )}
          />
        </div>

        <FieldsetTitle>{formatMessage({id: 'adress'})}</FieldsetTitle>

        <div className="grid grid-cols-4 gap-4">
          <Input
            label={formatMessage({id: 'street'})}
            id="street"
            type="text"
            error={errors.street}
            {...register('street')}
          />
          <Input
            label={formatMessage({id: 'city'})}
            id="city"
            type="text"
            error={errors.city}
            {...register('city')}
          />
          <Input
            label={formatMessage({id: 'zip-code'})}
            id="zip_code"
            type="text"
            error={errors.zip_code}
            {...register('zip_code')}
          />
          <Controller
            name="state"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label={formatMessage({id: 'state'})}
                id="select-state"
                options={usStates}
              />
            )}
          />
        </div>

        <FieldsetTitle>{formatMessage({id: 'account-settings'})}</FieldsetTitle>

        <div className="grid grid-cols-3 gap-4">
          <Input
            label={formatMessage({id: 'username-required'})}
            id="username"
            type="text"
            error={errors.username}
            errorMessage={errors.username ? errors.username.message : ''}
            {...register('username', {
              required: true,
              validate: {
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
                uppercaseChar: (val) =>
                  validateUppercase(val) || 'Remove all uppercase letters!',
              },
            })}
          />
          <InputPassword
            label={formatMessage({id: 'password-required'})}
            id="password"
            onShowPassword={() => setSeePassword((prev) => !prev)}
            seePassword={seePassword}
            error={errors.password}
            {...register('password', {
              required: !isEdit,
              min: 3,
              validate: {
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
              },
            })}
          />
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit" loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
        <Button
          variant="secondary"
          extraClassName="mr-2"
          disabled={loading}
          onClick={() => onCancel()}>
          {formatMessage({id: 'cancel'})}
        </Button>
      </div>
    </form>
  );
};

export default BossForm;
