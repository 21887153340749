import {useState} from 'react';
import {Player} from '@lottiefiles/react-lottie-player';
import {useNavigate} from 'react-router-dom';

import Button from '../../../../components/Button';

const DoneStep = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    localStorage.removeItem('@TrackMyGuys:flow');
    setTimeout(() => navigate('/dashboard'), 500);
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 max-w-2xl mt-8">
      <div className="px-8 py-4" style={{maxWidth: '80%'}}>
        <Player
          autoplay
          loop
          speed={0.65}
          src="https://assets10.lottiefiles.com/packages/lf20_y2hxPc.json"
          style={{height: '300px', width: '300px'}}
        />
      </div>
      <h1 className="text-4xl font-extralight text-center">
        Yeah! <span className="font-bold">Well done</span>.
      </h1>
      <p className="mt-2 text-sm font-normal text-gray-700 text-center mb-8">
        Now you have the basics to have a better experience on our platform. Now
        you will get to know our dashboard, where you can manage your entire
        company, view reports and much more. Any questions we will be ready to
        help you, just call on the chat. Thank you for choosing track my guys
        and enjoy your trial!
      </p>

      <Button onClick={() => handleNext()} loading={loading}>
        Go and Enjoy my trial
      </Button>
    </div>
  );
};

export default DoneStep;
