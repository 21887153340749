/* eslint-disable react/button-has-type */
import Loading from '../Loading';

const variants = {
  primary:
    'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md lg:text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500',
  secondary:
    'w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-gray-500 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-sm',
  error:
    'w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm',
};

const Button = ({
  variant = 'primary',
  extraClassName = '',
  loading = false,
  disabled = false,
  type = 'button',
  loadingProps = {color: '#fff'},
  children,
  ...rest
}) => (
  <button
    type={type}
    disabled={disabled}
    className={`${
      variants[variant] || variants.primary
    } ${extraClassName} transition-colors duration-200 transform ${
      disabled ? 'opacity-70' : ''
    }`}
    {...rest}>
    {loading ? <Loading {...loadingProps} /> : children}
  </button>
);

export default Button;
