import {useContextSelector} from 'use-context-selector';
import {DashboardContext} from '../context/DashboardContext';

export function useDashboard() {
  const period = useContextSelector(DashboardContext, (data) => data.period);
  const setPeriod = useContextSelector(
    DashboardContext,
    (data) => data.setPeriod,
  );

  return {
    period,
    setPeriod,
  };
}
