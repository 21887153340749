/* eslint-disable no-unused-vars */
import {useCallback, useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {usStates} from '../../../../../../../utils/states';
import {StripeHTTP} from '../../../../../providers/stripe';
import {useAuth} from '../../../../../../auth/hooks/useAuth';

import Input from '../../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import Modal from '../../../../../../../components/_modals/Modal';

const PaymentMethods = ({open = false, onClose = () => {}}) => {
  const {user} = useAuth();
  const {formatMessage} = useIntl();
  const company = user && user.company;

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: company
      ? {
          address_zip: company.zip_code,
          address_line1: company.street,
          address_city: company.city,
          address_state: company.state,
        }
      : {
          address_zip: '',
          address_line1: '',
          address_city: '',
          address_state: '',
        },
  });

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    reset({});
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const exp_month = values.expiry.substring(0, 2);
        const exp_year = values.expiry.slice(2);
        const payload = {
          name: values.name,
          cvc: values.cvc,
          number: values.card_number,
          exp_month,
          exp_year,
          customer: company.stripe_customer,
          address: {
            country: 'US',
            postal_code: values.address_zip,
            state: values.address_state.toString().toUpperCase(),
            city: values.address_city,
            line1: values.address_line1,
            line2: values.address_line2 || '',
          },
          is_default: true,
        };

        await StripeHTTP.createPaymentMethod(payload);
        toast.success(formatMessage({id: 'toast.pay-method-added'}));
        handleClose();
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.param
        ) {
          let field = error.response.data.data.param;

          if (
            error.response.data.data.param === 'exp_year' ||
            error.response.data.dataparam === 'exp_month'
          ) {
            field = 'expiry';
          }
          if (error.response.data.data.param === 'number') {
            field = 'card_number';
          }

          setError(field, {
            type: 'manual',
            message: error.response.data.msg,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [company],
  );

  useEffect(() => {
    clearErrors();
  }, []);

  return (
    <Modal
      maxWidth="max-w-2xl"
      open={open}
      title={formatMessage({id: 'settings.new-pay-modal'})}
      onClose={() => handleClose()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-grow p-6">
          <div className="grid grid-cols-2 gap-4">
            <Input
              label={formatMessage({id: 'cardholder-name-required'})}
              id="name"
              type="text"
              error={errors.name}
              {...register('name', {required: true})}
            />
            <Controller
              name="card_number"
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  defaultValue={field.value}
                  mask="9999 9999 9999 9999"
                  maskPlaceholder="_"
                  type="tel"
                  label={formatMessage({id: 'card-number-required'})}
                  alwaysShowMask={false}
                  error={errors.card_number}
                  errorMessage={
                    errors.card_number ? errors.card_number.message : ''
                  }
                />
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-x-4">
            <Controller
              rules={{
                required: true,
                minLength: 6,
              }}
              name="expiry"
              control={control}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  defaultValue={field.value}
                  mask="99/9999"
                  maskPlaceholder="MM/YYYY"
                  label={formatMessage({id: 'valid-thru-required'})}
                  id="expiry"
                  type="tel"
                  alwaysShowMask={false}
                  error={errors.expiry}
                  errorMessage={
                    errors.expiry ? formatMessage({id: 'erro.valid-date'}) : ''
                  }
                />
              )}
            />
            <Controller
              rules={{
                required: true,
                minLength: 3,
              }}
              name="cvc"
              control={control}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  defaultValue={field.value}
                  mask="9999"
                  maskPlaceholder="_"
                  type="tel"
                  label={formatMessage({id: 'security-code-required'})}
                  alwaysShowMask={false}
                  error={errors.cvc}
                  errorMessage={errors.cvc ? errors.cvc.message : ''}
                />
              )}
            />
          </div>

          <div className="grid grid-cols-4 gap-x-4 mt-4">
            <div className="col-span-2">
              <Input
                label={formatMessage({id: 'address-line-1-required'})}
                id="address_line1"
                type="text"
                error={errors.address_line1}
                {...register('address_line1', {required: true})}
              />
            </div>
            <div className="col-span-2">
              <Input
                label={formatMessage({id: 'address-line-2'})}
                id="address_line2"
                type="text"
                error={errors.address_line2}
                {...register('address_line2', {required: false})}
              />
            </div>
            <Input
              label={formatMessage({id: 'city-required'})}
              id="address_city"
              type="text"
              error={errors.address_city}
              {...register('address_city', {required: true})}
            />
            <Input
              label={formatMessage({id: 'zip-code-required'})}
              id="address_zip"
              type="text"
              error={errors.address_zip}
              {...register('address_zip', {required: true})}
            />
            <div className="col-span-2">
              <Controller
                rules={{
                  required: true,
                }}
                name="address_state"
                control={control}
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({id: 'state-required'})}
                    id="select-state"
                    options={usStates}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-footer">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PaymentMethods;
