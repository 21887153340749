/* eslint-disable jsx-a11y/control-has-associated-label */
import {useEffect, useMemo, useState, useCallback} from 'react';
import {
  SignalSlashIcon,
  MapPinIcon,
  ReceiptRefundIcon,
  ChatBubbleLeftIcon,
  PencilSquareIcon,
  TrashIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {currencyFormat} from '../../../../../utils/currency';
import {useQuery} from '../../../../../hooks/useQuery';
import {useAuth} from '../../../../auth/hooks/useAuth';
import {useEmployee} from '../../../hooks/useEmployee';
import {TimecardHTTP} from '../../../providers/timercard';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import {FormatDate} from '../../../../../components/FormatDate';
import Button from '../../../../../components/Button';
import DropdownButton from '../../../../../components/DropdownButton';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import Select from '../../../../../components/_form/Select';
import EmptyData from '../../../../../components/EmptyData';
import SearchSelect from '../../../../../components/_form/SearchSelect';

import CreatePunchModal from '../components/CreatePunchModal';
import ViewReceiptsModal from '../components/ViewReceiptsModal';
import BossCommentModal from '../components/BossCommentModal';
import UpdateJobLocationModal from '../components/UpdateJobLocationModal';
import UpdatePunchModal from '../components/UpdatePunchModal';
import DeletePunchModal from '../components/DeletePunchModal';
import ViewPunchMapModal from '../components/ViewPunchMapModal';

const TimecardView = () => {
  const {formatMessage} = useIntl();
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQuery(location.search);

  const {payPeriod} = useAuth();
  const {employees, getEmployees} = useEmployee();

  const [period, setPeriod] = useState(() => {
    const selectedPeriod = payPeriod.find(
      (p) =>
        p.period.start === queryParams.get('start') &&
        p.period.end === queryParams.get('end'),
    );

    return {
      start: selectedPeriod
        ? selectedPeriod.period.start
        : payPeriod[0].period.start,
      end: selectedPeriod ? selectedPeriod.period.end : payPeriod[0].period.end,
      value: selectedPeriod ? selectedPeriod.value : payPeriod[0].value,
    };
  });
  const [selectedEmployee, setSelectedEmployee] = useState(id);
  const [timecard, setTimecard] = useState({
    loading: true,
    data: {punches: []},
  });
  const [openNewPunchModal, setOpenNewPunchModal] = useState(false);
  const [actionsModal, setActionsModal] = useState({
    type: '',
    open: false,
    punchId: '',
    params: {}, // params to
    punch: {},
  });

  const handleUpdateUrl = (employee, selectedPeriod) => {
    navigate(
      `/timecard/${employee}?start=${selectedPeriod.start}&end=${selectedPeriod.end}`,
    );
  };

  const handleSelectEmployee = (value) => {
    setSelectedEmployee(value);
    handleUpdateUrl(value, period);
  };

  const handleSelectPayPeriod = (value) => {
    const selected = payPeriod.find((p) => p.value === value);
    setPeriod({
      start: selected.period.start,
      end: selected.period.end,
      value: selected.value,
    });
    // setPeriod(selected);
    handleUpdateUrl(selectedEmployee, selected.period);
  };

  const handleCloseActionsModal = () => {
    setActionsModal({
      type: '',
      open: false,
      punchId: '',
      params: {},
      punch: null,
    });
  };

  const fetchTimecard = async () => {
    setTimecard({loading: true, data: {punches: []}});
    const {data} = await TimecardHTTP.get({
      user_id: selectedEmployee,
      start: period.start,
      end: period.end,
    });
    setTimecard({loading: false, data});
  };

  const checkIfOffline = (punch) => {
    if (punch.start_offline) return 'start';
    if (punch.end_offline) return 'end';
    if (punch.offline) return 'start';

    return 'online';
  };

  const renderJobLocationInfo = (work_name) => {
    if (!work_name) {
      return (
        <Badge variant="error" extraClassName="my-2">
          {formatMessage({id: 'timecard.without-job-location'})}
        </Badge>
      );
    }

    return (
      <Badge variant="info" extraClassName="my-2">
        <MapPinIcon className="w-4" /> {work_name}
      </Badge>
    );
  };

  const employeesOptions = useMemo(
    () => employees.active.map((e) => ({label: e.name, value: e._id})),
    [employees],
  );

  useEffect(() => {
    if (employees.all.length === 0) getEmployees({});
  }, []);

  useEffect(() => {
    fetchTimecard();
  }, [period, selectedEmployee]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'day'})}`,
        accessor: 'department_name',
        Cell: ({row}) => {
          const hasReceipt = row.original.receipts;
          const bossUpdated = row.original.boss_editing;
          const bossCreated = row.original.boss_created;
          const managerCreated = row.original.manager_created;
          const adminCreated = row.original.admin_created;

          return (
            <div className="flex flex-col items-start">
              <div className="flex mb-2">
                {bossUpdated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'update-by'})}:{' '}
                    {row.original.last_boss_edit_name}
                  </Badge>
                )}
                {bossCreated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'created-by-boss'})}
                  </Badge>
                )}
                {managerCreated && (
                  <Badge variant="danger">
                    {formatMessage({id: 'created-by-manager'})}
                  </Badge>
                )}
                {adminCreated && (
                  <Badge variant="danger">
                    {formatMessage({id: 'created-by-admin'})}
                  </Badge>
                )}
              </div>

              <p className="font-bold">
                {row.original.start ? (
                  <FormatDate value={row.original.start.time} pattern="EEEE" />
                ) : (
                  formatMessage({id: 'timecard.puch-with-issue'})
                )}
              </p>
              <p className="text-xs text-gray-600">
                {row.original.department_name}
              </p>

              {hasReceipt && (
                <Badge variant="primary" extraClassName="mt-2">
                  <ReceiptRefundIcon className="w-3" />
                  {formatMessage({id: 'receipt'})}
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'in'})}`,
        accessor: 'start',
        Cell: ({row}) => {
          const {original} = row;
          const {start, work_name} = original;
          return (
            <div style={{maxWidth: '30vw'}}>
              {start ? (
                <div className="flex flex-col items-start">
                  {checkIfOffline(original) === 'start' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" /> Offline
                    </Badge>
                  )}
                  <button
                    type="button"
                    className="max-w-full break-all whitespace-normal text-left text-yellow-600 font-bold text-xs 2xl:text-sm hover:text-yellow-700"
                    title={start.location.address}
                    onClick={() =>
                      setActionsModal({
                        type: 'view-map',
                        open: true,
                        punch: row.original,
                        params: {
                          coordinates: row.original.start.location.coordinates,
                          address: row.original.start.location.address,
                          city: row.original.start.location.city,
                        },
                      })
                    }>
                    {start.location.address}
                  </button>
                  <p className="text-xs 2xl:text-sm">
                    <FormatDate value={start.time} />
                  </p>

                  {renderJobLocationInfo(work_name)}
                </div>
              ) : (
                <p className="text-xs 2xl:text-sm">
                  {formatMessage({id: 'timecard.puch-with-issue'})}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'out'})}`,
        accessor: 'in',
        Cell: ({row}) => {
          const {original} = row;
          const {end, missed_out} = original;
          return (
            <div style={{maxWidth: '30vw'}}>
              {end ? (
                <div className="flex flex-col items-start">
                  {checkIfOffline(original) === 'end' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" /> Offline
                    </Badge>
                  )}
                  {!missed_out && (
                    <button
                      type="button"
                      className="max-w-full break-all whitespace-normal text-left text-yellow-600 font-bold text-xs 2xl:text-sm hover:text-yellow-700"
                      title={end?.location?.address}
                      onClick={() =>
                        setActionsModal({
                          type: 'view-map',
                          open: true,
                          punch: row.original,
                          params: {
                            coordinates:
                              row.original.end?.location?.coordinates,
                            address: row.original.end?.location?.address,
                            city: row.original.end?.location?.city,
                          },
                        })
                      }>
                      {end?.location?.address}
                    </button>
                  )}
                  {!missed_out && end.time && (
                    <p className="text-xs 2xl:text-sm">
                      <FormatDate value={end.time} />
                    </p>
                  )}
                  {missed_out && (
                    <Badge variant="error">
                      {formatMessage({id: 'timecard.missed-out'})}
                    </Badge>
                  )}
                </div>
              ) : (
                <>
                  {original.in && (
                    <Badge variant="success">{formatMessage({id: 'in'})}</Badge>
                  )}
                  {missed_out && (
                    <Badge variant="error">
                      {formatMessage({id: 'timecard.missed-out'})}
                    </Badge>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'hours'})}`,
        accessor: 'hours',
        Cell: ({row}) => {
          const {original} = row;

          if (original.in)
            return <Badge variant="success">{formatMessage({id: 'in'})}</Badge>;
          if (original.missed_out)
            return (
              <Badge variant="error">
                {formatMessage({id: 'timecard.missed-out'})}
              </Badge>
            );

          return (
            <div className="flex flex-col items-start">
              <p className="text-gray-600 font-semibold text-xs 2xl:text-sm">
                {formatMessage({id: 'work'})} : {original.hours}
              </p>
              {original.lunch_deduction &&
                original.hours_with_deduction !== original.hours && (
                  <>
                    <p className="text-gray-500 text-xs 2xl:text-sm mt-2">
                      {formatMessage({id: 'lunch-deduction'})}: -{' '}
                      {original.lunch_deduction_time}
                    </p>
                    <p className="text-gray-600 font-bold text-xs 2xl:text-sm">
                      {formatMessage({id: 'after-deduction'})}:{' '}
                      {original.hours_with_deduction}
                    </p>
                  </>
                )}
              {original.overtime.active &&
                original.hours_with_overtime &&
                original.amount_overtime &&
                original.amount_overtime.$numberDecimal > 0 && (
                  <>
                    <p className="text-gray-600 font-bold text-xs 2xl:text-sm mt-2">
                      {formatMessage({id: 'regular-hours'})}:{' '}
                      {original.regular_hours}
                    </p>
                    <p className="text-gray-500 text-xs 2xl:text-sm">
                      {formatMessage({id: 'regular-amount'})}:{' '}
                      {currencyFormat(original.regular_amount.$numberDecimal)}
                    </p>
                    <p className="text-gray-600 font-bold text-xs 2xl:text-sm mt-2">
                      {formatMessage({id: 'overtime-hours'})}:{' '}
                      {original.hours_with_overtime}
                    </p>
                    <p className="text-gray-500 text-xs 2xl:text-sm">
                      {formatMessage({id: 'overtime-amount'})}:{' '}
                      {currencyFormat(original.amount_overtime.$numberDecimal)}
                    </p>
                  </>
                )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: 'total',
        Cell: ({row}) => {
          const hasReceipt = row.original.receipts;

          if (row.original.in) return <Badge variant="success">IN</Badge>;
          if (row.original.missed_out)
            return (
              <Badge variant="error">
                {formatMessage({id: 'timecard.missed-out'})}
              </Badge>
            );

          return (
            <div>
              <p className="text-xs 2xl:text-sm">
                {currencyFormat(row.original?.total?.$numberDecimal)}
              </p>
              {hasReceipt && (
                <p className="text-xs text-gray-500">
                  {formatMessage({id: 'receipts-amount'})}:{' '}
                  {currencyFormat(row.original.receipts)}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <DropdownButton
            label={formatMessage({id: 'edit'})}
            options={[
              {
                id: 'receipts',
                label: formatMessage({id: 'receipts'}),
                icon: ReceiptRefundIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'receipts',
                    open: true,
                    punchId: row.original._id,
                    params: {},
                  }),
              },
              {
                id: 'boss-comment',
                label: formatMessage({id: 'boss-comment'}),
                icon: ChatBubbleLeftIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'bossComments',
                    open: true,
                    punchId: row.original._id,
                    params: {
                      boss_comment: row.original.boss_comment
                        ? row.original.boss_comment
                        : '',
                    },
                  }),
              },
              {
                id: 'update-job-location',
                label: formatMessage({id: 'assign-job-location'}),
                icon: MapPinIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'jobLocation',
                    open: true,
                    punchId: row.original._id,
                    params: {
                      work_id: row.original.work_id
                        ? row.original.work_id
                        : null,
                    },
                  }),
              },
              {
                id: 'edit-punch',
                label: formatMessage({id: 'edit-hours'}),
                icon: PencilSquareIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'punch',
                    open: true,
                    punchId: row.original._id,
                    params: row.original,
                  }),
              },
              {
                id: 'delete-punch',
                label: formatMessage({id: 'delete-punch'}),
                icon: TrashIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'delete',
                    open: true,
                    punchId: row.original._id,
                    params: {},
                  }),
              },
            ].filter((item) =>
              row.original.in
                ? !['edit-punch', 'delete-punch'].includes(item.id)
                : true,
            )}
          />
        ),
      },
    ],
    [formatMessage],
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({row, hasBg}) => (
      <>
        <td
          className={`px-6 py-4 text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={3}>
          <Badge variant="secondary">
            {formatMessage({id: 'employee-comment'})}
          </Badge>
          <pre className="w-full max-w-lg whitespace-normal text-xs leading-3 mt-2">
            <code>
              {row.original.comments
                ? row.original.comments
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
        <td
          className={`px-6 py-4 text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={3}>
          <Badge variant="secondary">
            {formatMessage({id: 'boss-comment'})}
          </Badge>
          <pre className="w-full max-w-lg whitespace-normal text-xs leading-3 mt-2 text-red-500">
            <code>
              {row.original.boss_comment && row.original.boss_comment.comment
                ? row.original.boss_comment.comment
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
      </>
    ),
    [formatMessage],
  );

  const renderFooterComponent = useCallback(
    (footerData = {}) => (
      <tfoot className="bg-gray-100">
        <tr>
          <td
            className="px-4 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
            colSpan={4}
          />
          <td
            className="px-4 py-4 text-left text-xs font-medium text-gray-600"
            colSpan={2}>
            <p className="text-sm">
              {formatMessage({id: 'hours-amount'})}: {footerData.totalWorked}
            </p>
            <p className="font-bold text-xs 2xl:text-sm">
              {formatMessage({id: 'gross-pay'})}:{' '}
              {currencyFormat(footerData.grossPay)}
            </p>
            <p className="pt-4 text-xs 2xl:text-sm">
              {formatMessage({id: 'receipts-amount'})}:{' '}
              {currencyFormat(footerData.receiptsAmount)}
            </p>
            <p className="text-xs 2xl:text-sm">
              {formatMessage({id: 'amount'})}:{' '}
              {currencyFormat(footerData.globalAmount)}
            </p>
          </td>
        </tr>
      </tfoot>
    ),
    [formatMessage],
  );

  const isLoading = employees.loading || timecard.loading;
  const selectedEmployeeData = employees.active.find((e) => e._id === id);

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'timecard'})}
        breadcrumbs={[
          {
            id: 'timecard',
            label: formatMessage({id: 'timecard'}),
          },
          {
            id: 'timecard-employee',
            label: selectedEmployeeData ? selectedEmployeeData.name : '',
          },
        ]}
        rightContent={
          !isLoading && (
            <Button onClick={() => setOpenNewPunchModal(true)}>
              <PlusCircleIcon className="w-5 mr-2" />
              {formatMessage({id: 'timecard.new-punch'})}
            </Button>
          )
        }
      />

      <SectionWrapper
        isLoading={isLoading}
        toolbar={
          <>
            {!isLoading && (
              <SearchSelect
                label={formatMessage({id: 'employee'})}
                placeholder="Employee"
                options={employeesOptions}
                onChange={(e) => handleSelectEmployee(e.value)}
                defaultValue={[
                  {
                    label: selectedEmployeeData.name,
                    value: selectedEmployeeData._id,
                  },
                ]}
                className="w-72"
                isClearable={false}
              />
            )}
            <Select
              label={formatMessage({id: 'pay-period'})}
              options={payPeriod}
              onChange={(e) => handleSelectPayPeriod(e)}
              defaultValue={period.value}
              className="ml-4 w-80"
            />
          </>
        }>
        {timecard.data.punches.length === 0 ? (
          <EmptyData
            description={formatMessage({id: 'timecard.nothing-found'})}
          />
        ) : (
          <Table
            columns={columns}
            data={timecard.data.punches}
            hasOver={false}
            renderRowSubComponent={renderRowSubComponent}
            showComment
            renderFooterComponent={() => renderFooterComponent(timecard.data)}
          />
        )}
      </SectionWrapper>

      <CreatePunchModal
        open={openNewPunchModal}
        period={period}
        userId={id}
        onClose={() => setOpenNewPunchModal(false)}
        onSuccess={() => {
          fetchTimecard();
          setOpenNewPunchModal(false);
        }}
      />

      <ViewReceiptsModal
        open={actionsModal.open && actionsModal.type === 'receipts'}
        punchId={actionsModal.punchId}
        onClose={() => handleCloseActionsModal()}
      />

      <BossCommentModal
        open={actionsModal.open && actionsModal.type === 'bossComments'}
        punchId={actionsModal.punchId}
        bossComment={
          actionsModal.params &&
          Object.prototype.hasOwnProperty.call(
            actionsModal.params,
            'boss_comment',
          )
            ? actionsModal.params.boss_comment.comment
            : ''
        }
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchTimecard();
          handleCloseActionsModal();
        }}
      />

      <UpdateJobLocationModal
        open={actionsModal.open && actionsModal.type === 'jobLocation'}
        punchId={actionsModal.punchId}
        selectedWorkId={
          Object.prototype.hasOwnProperty.call(actionsModal.params, 'work_id')
            ? actionsModal.params.work_id
            : null
        }
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchTimecard();
          handleCloseActionsModal();
        }}
      />

      {actionsModal.open && actionsModal.type === 'punch' && (
        <UpdatePunchModal
          open={actionsModal.open && actionsModal.type === 'punch'}
          punchId={actionsModal.punchId}
          punch={actionsModal.params}
          period={period}
          onClose={() => handleCloseActionsModal()}
          onSuccess={() => {
            fetchTimecard();
            handleCloseActionsModal();
          }}
        />
      )}

      <DeletePunchModal
        open={actionsModal.open && actionsModal.type === 'delete'}
        punchId={actionsModal.punchId}
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchTimecard();
          handleCloseActionsModal();
        }}
      />

      <ViewPunchMapModal
        open={actionsModal.open && actionsModal.type === 'view-map'}
        onClose={() => handleCloseActionsModal()}
        punch={actionsModal.punch}
        location={actionsModal.params}
      />
    </>
  );
};

export default TimecardView;
