import {
  RectangleGroupIcon,
  BuildingOfficeIcon,
  CheckBadgeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

export default [
  {
    route: '/admin/dashboard',
    routerGroup: ['dashboard'],
    label: 'Dashboard',
    icon: RectangleGroupIcon,
  },
  {
    route: '/admin/users',
    routerGroup: ['user', 'users'],
    label: 'Users',
    icon: UsersIcon,
  },
  {
    route: '/admin/companies',
    routerGroup: ['company', 'companies'],
    label: 'Companies',
    icon: BuildingOfficeIcon,
  },
  {
    route: '/admin/sales-companies',
    label: 'Sales Companies',
    icon: CheckBadgeIcon,
  },
  {
    route: '/admin/trades',
    label: 'Trades',
    icon: CheckBadgeIcon,
  },
];

export const salesMenu = [
  {
    route: '/sales/dashboard',
    routerGroup: ['dashboard'],
    label: 'Dashboard',
    icon: RectangleGroupIcon,
  },
  {
    route: '/sales/users',
    routerGroup: ['user', 'users'],
    label: 'Users',
    icon: BuildingOfficeIcon,
  },
  {
    route: '/sales/companies',
    routerGroup: ['company', 'companies'],
    label: 'Companies',
    icon: BuildingOfficeIcon,
  },
];
