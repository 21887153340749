import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';

import Input from '../../../../../../../components/_form/Input';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';

const CategoryForm = ({
  loading,
  onSubmit,
  onCancel = () => {},
  initialData = {},
}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({defaultValues: initialData});
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-1 gap-4">
          <Input
            label={formatMessage({id: 'name-required'})}
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Controller
            name="status"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label={formatMessage({id: 'status'})}
                id="status"
                options={[
                  {value: 'active', label: formatMessage({id: 'active'})},
                  {value: 'inactive', label: formatMessage({id: 'inactive'})},
                ]}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4" />
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit" loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
        <Button
          variant="secondary"
          extraClassName="mr-2"
          onClick={() => onCancel()}>
          {formatMessage({id: 'cancel'})}
        </Button>
      </div>
    </form>
  );
};

export default CategoryForm;
