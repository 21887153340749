import Dashboard from '../pages/Dashboard';

import Users from '../pages/Users/Home';
import UserProfile from '../pages/Users/Profile';

import Companies from '../pages/Companies/Home';
import CompanyProfile from '../pages/Companies/Profile';
import SalesCompanies from '../pages/Companies/Sales';
import SalesUsers from '../pages/Sales/Users';

import Trades from '../pages/Trades/Home';
import TradeItems from '../pages/Trades/Items';

export default [
  {
    path: '/admin/dashboard',
    element: Dashboard,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/users',
    element: Users,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/user/:id',
    element: UserProfile,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/companies',
    element: Companies,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/company/:id',
    element: CompanyProfile,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/sales-companies',
    element: SalesCompanies,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/trades',
    element: Trades,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/admin/trade/:id',
    element: TradeItems,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/sales/dashboard',
    element: Dashboard,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/sales/users',
    element: SalesUsers,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/sales/companies',
    element: Companies,
    exact: false,
    isPrivate: true,
  },
];
