import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {JobLocationHTTP} from '../../../../providers/jobs';
import {PunchHTTP} from '../../../../providers/punch';

import Modal from '../../../../../../components/_modals/Modal';
import Loading from '../../../../../../components/Loading';
import Button from '../../../../../../components/Button';
import SearchSelect from '../../../../../../components/_form/SearchSelect';

const UpdateJobLocationModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  punchId = '',
  selectedWorkId = '',
}) => {
  const {formatMessage} = useIntl();
  const [selected, setSelected] = useState(null);

  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      if (!selected || !selected.value) {
        toast.error(formatMessage({id: 'toast.select-job-location-first'}));
        return;
      }
      setLoading(true);

      await PunchHTTP.update(punchId, {work_id: selected.value});

      toast.success(formatMessage({id: 'toast.job-location-upd-success'}));
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchJobs() {
      setJobs({loading: true, data: []});
      const {data} = await JobLocationHTTP.get({status: 'active'});
      setJobs({
        loading: false,
        data: data.map((j) => ({value: j._id, label: j.name})),
      });

      if (selectedWorkId) {
        const selectedData = data.find((j) => j._id === selectedWorkId);
        if (selectedData) {
          setSelected({
            value: selectedData._id,
            label: selectedData.name,
          });
        }
      } else if (selected) {
        setSelected(null);
      }
    }

    if (open) fetchJobs();
  }, [open, selectedWorkId]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={220}
      open={open}
      onClose={onClose}
      title={formatMessage({id: 'timecard.assign-job-location'})}>
      <div className="flex flex-col justify-between h-full flex-1">
        <div className="form-content">
          {jobs.loading ? (
            <div className="m-4">
              <Loading />
            </div>
          ) : (
            <SearchSelect
              placeholder={formatMessage({id: 'timecard.select-job-location'})}
              options={jobs.data}
              onChange={(val) => setSelected(val)}
              defaultValue={selected}
            />
          )}
        </div>

        <div className="form-footer">
          <Button type="button" onClick={onSubmit} loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onClose()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateJobLocationModal;
