import api, {stripeApi, apiTs} from '../../../services/api';

export const StripeHTTP = {
  newCard: (payload = {}) => api.post('/stripe/payment', payload),
  getInvoices: () => api.get('/stripe/invoices'),
  getInvoicesItems: () => api.get('/stripe/invoices/items'),
  getTransactions: () => api.get('/stripe/transactions'),
  getPaymentMethods: ({stripeCustomer = ''}) =>
    stripeApi.get('/payment-methods', {params: {customer: stripeCustomer}}),
  createPaymentMethod: (payload = {}) =>
    stripeApi.post('/payment-methods', payload),
  updatePaymentMethod: (cardId = '', payload = {}) =>
    stripeApi.put(`/payment-methods/${cardId}`, payload),
  removePaymentMethod: (cardId = '', customer = '') =>
    stripeApi.delete(`/payment-methods/${cardId}?customer=${customer}`),
  createCheckoutSession: (payload = {}) =>
    stripeApi.post('/checkout-session', payload),
  createPublicCheckoutSession: (params = {}) =>
    apiTs.get('/checkout-session', {params}),
};
