import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: ${({$height}) => $height + 5}px;

  #google-map-wrapper {
    div:nth-child(2) {
      margin-top: 0px;
    }
  }
`;
