import {useContextSelector} from 'use-context-selector';
import {JobLocationContext} from '../context/JobLocationContext';

export function useJobLocation() {
  const jobs = useContextSelector(JobLocationContext, (data) => data.jobs);
  const job = useContextSelector(JobLocationContext, (data) => data.job);
  const sync = useContextSelector(JobLocationContext, (data) => data.sync);

  const getJobs = useContextSelector(
    JobLocationContext,
    (data) => data.getJobs,
  );
  const getJob = useContextSelector(JobLocationContext, (data) => data.getJob);
  const createJob = useContextSelector(
    JobLocationContext,
    (data) => data.createJob,
  );
  const updateJob = useContextSelector(
    JobLocationContext,
    (data) => data.updateJob,
  );
  const updateBudget = useContextSelector(
    JobLocationContext,
    (data) => data.updateBudget,
  );
  const syncPunches = useContextSelector(
    JobLocationContext,
    (data) => data.syncPunches,
  );

  return {
    jobs,
    job,
    sync,
    getJobs,
    getJob,
    createJob,
    updateJob,
    updateBudget,
    syncPunches,
  };
}
