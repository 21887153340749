import Loading from '../Loading';

export const LoadingBox = ({info = 'Loading...'}) => (
  <div
    className="fixed inset-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-20"
    style={{zIndex: 9998}}>
    <div className="flex flex-col justify-center items-center bg-white max-w-2xl py-4 px-6 shadow-md rounded-lg overflow-hidden">
      <Loading />
      <p className="mt-2 text-sm text-gray-700">{info}</p>
    </div>
  </div>
);
