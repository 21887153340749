import InvoicePdf from '../pages/InvoicePdf';

export default [
  {
    path: '/preview/pdf/:id',
    element: InvoicePdf,
    exact: false,
    isPrivate: false,
  },
];
