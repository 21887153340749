import {forwardRef} from 'react';
import CurrencyInputBase from 'react-currency-input';

const CurrencyInput = forwardRef(
  ({label = '', id, error, className = 'mb-2', ...rest}, ref) => (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <CurrencyInputBase
        id={id}
        className="mt-1 focus:ring-yellow-200 focus:border-yellow-100 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-400"
        ref={ref}
        {...rest}
      />
      {error && (
        <p className="text-xs text-red-500 mt-2">This field is required</p>
      )}
    </div>
  ),
);

export default CurrencyInput;
