import Home from '../pages/Home';
import SignupPt from '../pages/SignupPt';

export default [
  {
    path: '/onboarding',
    element: Home,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/finalizar-cadastro',
    element: SignupPt,
    exact: false,
    isPrivate: true,
  },
];
