import {format as formatDate} from 'date-fns';

export const formatDateTZ = (
  value = '',
  pattern = 'MM/dd/yyyy hh:mm a',
  unix = false,
) => {
  const dt = unix ? new Date(value * 1000) : new Date(value);

  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );

  return formatDate(dtDateOnly, pattern);
};

export function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}
