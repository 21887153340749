/* eslint-disable react/no-unescaped-entities */
import {useState, useEffect} from 'react';
import {Calendar} from 'react-date-range';
import {format, subWeeks, subDays, addWeeks, nextSunday} from 'date-fns';

import api from '../../../../services/api';

import {ManDeviceVector} from '../../../../components/_marketing/ManDeviceVector';
import Button from '../../../../components/Button';
import Select from '../../../../components/_form/Select';
import Loading from '../../../../components/Loading';

const formatOptions = [
  {
    label: 'Weekly',
    value: 1,
  },
  {
    label: 'Bi-Weekly',
    value: 2,
  },
  {
    label: 'Monthly',
    value: 31,
  },
];

const PayPeriod = ({nextStep = () => {}}) => {
  const maxDate = addWeeks(new Date(), 1);
  const startAt = nextSunday(subWeeks(new Date(), 2));

  const [paymentFormat, setPaymentFormat] = useState(1);
  const [date, setDate] = useState(startAt);
  const [payPeriod, setPayPeriod] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!paymentFormat || !date) return;

      setSubmitLoading(true);

      await api.put('/my-company', {
        pay_period: paymentFormat,
        new_period_start_at: format(date, 'yyyy-MM-dd'),
        new_company: false,
      });

      localStorage.setItem('@TrackMyGuys:flow', 'job-location');
      nextStep();
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    async function getPayPeriod() {
      setLoading(true);
      const {data} = await api.get('/pay-period', {
        params: {
          pay_period: paymentFormat,
          new_period_start_at: format(date, 'yyyy-MM-dd'),
        },
      });

      setPayPeriod(
        data.length > 6
          ? [data[0], {label: '...', value: 'just_dots'}, ...data.slice(-5)]
          : data,
      );

      setTimeout(() => setLoading(false), 350);
    }

    if (paymentFormat && date) {
      getPayPeriod();
    }
  }, [paymentFormat, date]);

  useEffect(() => {
    if (paymentFormat) {
      if (paymentFormat === 31) {
        setDate((prevDate) => subDays(prevDate, 31));
      } else {
        setDate((prevDate) => subWeeks(prevDate, paymentFormat));
      }
    }
  }, [paymentFormat]);

  const isLoadingDates = Boolean(!date || !paymentFormat);

  return (
    <>
      <div className="flex items-center justify-center px-8 py-4 max-w-4xl">
        <div className="px-8 py-4 hidden lg:block" style={{maxWidth: 300}}>
          <ManDeviceVector />
        </div>
        <div>
          <h1 className="text-4xl font-extralight">
            Payment <span className="font-bold">settings</span>.
          </h1>
          <p className="mt-2 text-sm font-normal text-gray-700">
            Now let's adjust your company's payment settings. We will use this
            information to build your reports and timecard.
          </p>
        </div>
      </div>

      {isLoadingDates ? (
        <Loading />
      ) : (
        <div className="flex flex-col w-full xl:flex-row border-t border-gray-100 pt-6 overflow-x-hidden">
          <div className="pr-6" style={{minWidth: '27em'}}>
            <div style={{maxWidth: 250}}>
              <Select
                defaultValue={1}
                label="You pay your employees per: "
                id="select-department"
                options={formatOptions}
                onChange={(e) => setPaymentFormat(e)}
              />
            </div>

            <div className="flex flex-col mt-6">
              <p className="block text-sm font-medium text-gray-700">
                Select when your period start at the track my guys
              </p>
              <div className="flex datepicker-tmg">
                <Calendar
                  minDate={new Date('2017-12-31')}
                  maxDate={maxDate}
                  focusedRange={[0, 7]}
                  onChange={(item) => setDate(item)}
                  date={date}
                />
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="flex rounded-lg bg-yellow-100 p-4 mb-6 mt-6 lg:mt-0">
              <p className="text-sm font-medium text-yellow-800">
                if you pay your employees every Saturday, you must state that
                you pay by the week and that your period starts on a Sunday. So
                our system will set up the periods starting on Sundays and
                ending on Saturdays (payment day).
              </p>
            </div>

            <p className="block text-sm font-medium text-gray-700">
              See an short example of how your pay period will be built
            </p>
            {loading ? (
              <Loading />
            ) : (
              <ul
                className="flex flex-col mt-4 mb-8 bg-gray-50 rounded-lg overflow-hidden  border border-gray-200"
                style={{maxWidth: 250}}>
                {payPeriod.map((i) => (
                  <li key={i.value}>
                    <p className="text-sm text-center text-gray-800 py-2 px-4 border-b border-gray-200">
                      {i.label}
                    </p>
                  </li>
                ))}
              </ul>
            )}
            <Button
              onClick={() => handleSubmit()}
              disabled={loading}
              loading={submitLoading}>
              save and next
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PayPeriod;
