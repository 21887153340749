import {useState, useEffect, useRef} from 'react';
import {Calendar} from 'react-date-range';
import {CalendarIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';

import MaskedInput from '../MaskedInput';

const monthFirst = /[0-1]/;
const monthSecond = /[0-9]/;
const dayFirst = /[0-3]/;
const daySecond = /[0-9]/;
const year = /[0-9]/;
const mask = [
  monthFirst,
  monthSecond,
  '/',
  dayFirst,
  daySecond,
  '/',
  year,
  year,
  year,
  year,
];

const InputDatePicker = ({
  label = '',
  onChange = () => {},
  value = '',
  id = 'datepicker-input',
  error = false,
  maxDate,
  ...rest
}) => {
  const elRef = useRef(null);

  const [touched, setTouched] = useState(false);
  const [date, setDate] = useState(value ? new Date(value) : new Date());
  const [openCalendar, setOpenCalendar] = useState({
    open: false,
    position: {},
  });
  const [inputValue, setInputValue] = useState(value ?? null);

  const handleOpenCalendar = () => {
    setOpenCalendar((prev) => ({...prev, open: !prev.open}));
  };

  const handleOnChange = (val) => {
    setTouched(true);
    setInputValue(val);
  };

  const handleOnChangeCalendar = (val) => {
    setTouched(true);
    setDate(val);
    setInputValue(formatDate(val, 'MM/dd/yyyy'));
    handleOpenCalendar();
  };

  useEffect(() => {
    if (elRef && elRef.current) {
      setOpenCalendar((prev) => ({
        ...prev,
        position: {
          top: elRef.current.offsetHeight,
          left: 0,
        },
      }));
    }
  }, [elRef]);

  useEffect(() => {
    if (touched) {
      onChange(inputValue);
    }
  }, [inputValue]);

  return (
    <div ref={elRef} className="relative">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div className="flex items-center justify-start">
        <MaskedInput
          removeMask={false}
          mask={mask}
          maskPlaceholder="MM/DD/YYYY"
          label={null}
          alwaysShowMask={false}
          onChange={(val) => handleOnChange(val)}
          defaultValue={inputValue}
          id={id}
          error={error}
          inputClassName="flex flex-grow"
          inputExtraClassName="h-10 rounded-r-none"
          {...rest}
        />

        <button
          type="button"
          onClick={handleOpenCalendar}
          className="group flex justify-center items-center h-10 w-11 border border-l-0 focus:ring-yellow-200 focus:border-yellow-100 shadow-sm sm:text-sm rounded-r-lg border-gray-300">
          <CalendarIcon
            className="w-6 h-6 text-gray-400 group-focus:text-yellow-400 hover:text-yellow-400"
            aria-hidden="true"
          />
        </button>
      </div>
      {openCalendar.open && (
        <div
          className="absolute z-20 flex bg-white rounded-lg shadow-lg overflow-hidden border border-gray-100"
          style={
            openCalendar.position && openCalendar.position.top
              ? openCalendar.position
              : {}
          }>
          <Calendar
            onChange={(item) => handleOnChangeCalendar(item)}
            date={date}
            showMonthAndYearPickers
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};

export default InputDatePicker;
