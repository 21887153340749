import {DataFilesVector} from '../_marketing/DataFilesVector';

const EmptyData = ({
  title = 'No data found',
  description = 'nothing was found for the query performed',
}) => (
  <div className="flex flex-col items-center justify-center p-8">
    <div style={{maxWidth: 260}}>
      <DataFilesVector />
    </div>
    <h2 className="mt-6 text-2xl font-bold text-yellow-500">{title}</h2>
    <p className="text-gray-600 text-sm">{description}</p>
  </div>
);

export default EmptyData;
