import api from '../../../services/api';

export const JobLocationHTTP = {
  get: (params = {}) => api.get('/jobs', {params}),
  getById: (id = '') => api.get(`/jobs/${id}`),
  create: (payload = {}) => api.post('/jobs', payload),
  update: (id = '', payload = {}) => api.put(`/jobs/${id}`, payload),
  updateBudget: (id = '', payload = {}) =>
    api.put(`/jobs/budget/${id}`, payload),
  syncPunches: (id = '') => api.get(`/jobs/sync-job/${id}`),
};
