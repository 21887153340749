import React, {useCallback, useState, useMemo} from 'react';
import {createContext} from 'use-context-selector';
import api, {apiTs} from '../../../services/api';

export const CompaniesContext = createContext({});

export function CompaniesProvider({children}) {
  const [companies, setCompanies] = useState({
    loading: true,
    pagination: {
      perPage: 20,
      currentPage: 1,
      totalPages: 1,
      totalDocs: 1,
    },
    data: [],
  });
  const [company, setCompany] = useState({
    loading: true,
    data: {},
  });

  const getCompanies = useCallback(async (params) => {
    setCompanies((prev) => ({...prev, loading: true}));
    const {data} = await apiTs.get('/companies', {params});
    setCompanies({
      loading: false,
      pagination: {
        perPage: data.limit,
        currentPage: data.page,
        totalPages: data.totalPages,
        totalDocs: data.totalDocs,
      },
      data: data.docs,
    });
  }, []);

  const getCompany = useCallback(async (id) => {
    setCompany((prev) => ({...prev, loading: true}));
    const {data} = await api.get(`/companies/${id}`);
    setCompany({
      loading: false,
      data,
    });
  }, []);

  const updateCompany = useCallback(
    async (id, payload) => api.put(`/companies/${id}`, payload),
    [],
  );

  const removeCompanyFromList = useCallback(
    (id) => {
      const newList = companies.data.filter((c) => c._id !== id);
      setCompanies((prev) => ({
        loading: false,
        pagination: {
          ...prev.pagination,
          totalDocs: prev.pagination.totalDocs - 1,
        },
        data: newList,
      }));
    },
    [companies],
  );

  const values = useMemo(
    () => ({
      companies,
      company,
      getCompanies,
      getCompany,
      updateCompany,
      removeCompanyFromList,
    }),
    [
      companies,
      company,
      getCompanies,
      getCompany,
      updateCompany,
      removeCompanyFromList,
    ],
  );

  return (
    <CompaniesContext.Provider value={values}>
      {children}
    </CompaniesContext.Provider>
  );
}
