import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {useForm} from 'react-hook-form';

import {useEffect} from 'react';
import {useFetch} from '../../../../../../../../hooks/useFetch';
import {
  TradesHTTP,
  InvoiceTradesHTTP,
} from '../../../../../../providers/trades';

import Modal from '../../../../../../../../components/_modals/Modal';
import Button from '../../../../../../../../components/Button';
import Input from '../../../../../../../../components/_form/Input';

const AddTrade = ({
  invoiceId,
  open,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {formatMessage} = useIntl();
  const {data, loading, refetch} = useFetch(
    TradesHTTP.get,
    'get-trades-by-invoice-id',
    {invoiceId},
  );
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSelectTrade = async (trade) => {
    try {
      const sendData = {
        invoiceId,
        tradeId: trade.id,
        name: trade.name,
      };
      const res = await InvoiceTradesHTTP.createTrade(sendData);
      toast.success(
        formatMessage({id: 'bookpodium.estimates.trades.added.success'}),
      );
      onSuccess(res.data);
    } catch (_err) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    }
  };

  const handleSubmitForm = async (values) => {
    try {
      const createdTrade = await TradesHTTP.createTrade(values);
      onSelectTrade(createdTrade.data);
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    }
  };

  useEffect(() => {
    if (open) refetch();
  }, [open]);

  return (
    <Modal
      maxWidth="max-w-3xl"
      open={open}
      onClose={onClose}
      title="Add new trade">
      <div className="flex flex-col justify-end w-full px-4">
        <h2 className="p-4 m-0 font-bold w-full text-center">
          Create a new trade or select from our list
        </h2>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Input
            label={formatMessage({id: 'name-required'})}
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
          <Button
            type="submit"
            loading={false}
            onClick={() => refetch(null, true)}>
            {formatMessage({id: 'bookpodium.estimates.trades.create-button'})}
          </Button>
        </form>
      </div>

      {loading && <p>loading...</p>}
      {!loading && data && (
        <div className="flex flex-col py-4">
          <h2 className="p-4 m-0 font-bold w-full text-center">
            Trade options
          </h2>
          <ul
            className="flex flex-col w-full overflow-y-scroll"
            style={{maxHeight: 400}}>
            {data.map((item) => (
              <li
                key={`trades-item-${item.id}`}
                className="flex w-full p-4 border-t">
                <button
                  type="button"
                  className="flex w-full"
                  onClick={() => onSelectTrade(item)}>
                  <span className="flex flex-col flex-grow justify-start items-start text-sm">
                    {item.name}
                  </span>
                  <ChevronRightIcon className="text-yellow-500 w-6 h-6" />
                </button>
              </li>
            ))}
          </ul>
          {!loading && data.length === 0 && (
            <p className="px-4">No trades available, create a new one.</p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AddTrade;
