import {useEffect, useState} from 'react';
import {PlusCircleIcon} from '@heroicons/react/24/outline';

import {DashboardHTTP} from '../../../../../providers/dashboard';

import Loading from '../../../../../../../components/Loading';
import Modal from '../../../../../../../components/_modals/Modal';

const AddJobModal = ({open, onClose}) => {
  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
  });
  const [addLoading, setAddLoading] = useState(false);

  const handleAddJob = async (jobId = '') => {
    try {
      setAddLoading(true);
      await DashboardHTTP.updateJobs({work_id: jobId});
      onClose();
    } finally {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    async function getJobs() {
      const {data} = await DashboardHTTP.getJobLocation();
      setJobs({
        loading: false,
        data,
      });
    }
    getJobs();
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col p-4">
        <h2 className="text-2xl font-bold text-gray-700">Job Location</h2>
        <p className="text-gray-500">
          Select a job location from the list (only activies jobs)
        </p>
      </div>

      {jobs.loading ? (
        <div className="m-4">
          <Loading />
        </div>
      ) : (
        <ul className="flex flex-col p-4 bg-gray-50">
          {jobs.data.map((item) => (
            <li className="flex w-full my-1" key={item._id}>
              <button
                disabled={addLoading}
                onClick={() => handleAddJob(item._id)}
                className="flex justify-between items-center w-full bg-white rounded-lg p-4"
                type="button">
                <div className="flex flex-col flex-grow items-start text-left overflow-hidden">
                  <h3>{item.name}</h3>
                  <p className="text-xs text-gray-500 truncate">
                    {item.location.address}
                  </p>
                </div>
                <PlusCircleIcon className="w-8 h-8 text-yellow-500 ml-2" />
              </button>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default AddJobModal;
