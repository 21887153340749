/* eslint-disable camelcase */
import {useEffect, useState, useMemo, useCallback} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import debounce from 'lodash/debounce';

import {useIntl} from 'react-intl';
import {SkillsHTTP} from '../../providers/skills';
import {useAuth} from '../../../auth/hooks/useAuth';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import {FormatDate} from '../../../../components/FormatDate';
import Button from '../../../../components/Button';
import Badge from '../../../../components/Badge';
import IconButton from '../../../../components/IconButton';
import Search from '../../../../components/Search';
import Table from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Modal from '../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../components/LoadingBox';

import SkillsForm from './components/Form';

const Skills = () => {
  const {formatMessage} = useIntl();
  const {currentCompany} = useAuth();
  const [activeTab, setActiveTab] = useState('active');
  const [formType, setFormType] = useState({
    loading: false,
    type: 'create',
    selected: {},
    modal: false,
  });
  const [skills, setSkills] = useState({
    loading: true,
    data: {},
  });
  const [globalModal, setGlobalModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  const tabItems = [
    {
      value: 'active',
      label: formatMessage({id: 'active'}),
    },
    {
      value: 'inactive',
      label: formatMessage({id: 'inactive'}),
    },
  ];

  function handleEdit(data) {
    if (data.global) {
      setGlobalModal(true);
      return;
    }

    setFormType((prev) => ({
      ...prev,
      type: 'edit',
      selected: data,
      modal: true,
    }));
  }

  async function fetchSkills() {
    setSkills({
      loading: true,
      data: {},
    });
    const {data} = await SkillsHTTP.get({
      status: activeTab,
      page: currentPage,
      ...(search ? {name: search} : {}),
    });
    setSkills({
      loading: false,
      data,
    });
  }

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setFormType((prev) => ({...prev, loading: true}));
        const payload = {
          name: values.name,
          status: values.status,
          global: false,
        };

        if (formType.type === 'create') {
          if (currentCompany) {
            payload.company_id = currentCompany;
          }
          await SkillsHTTP.create(payload);
          toast.success('Skill created successfully!');
        } else {
          await SkillsHTTP.update(formType.selected._id, payload);
          toast.success('Skill edited successfully!');
        }
        fetchSkills();
      } finally {
        setFormType({
          loading: false,
          type: 'create',
          selected: {},
          modal: false,
        });
      }
    },
    [formType, currentCompany],
  );

  useEffect(() => {
    fetchSkills();
  }, [activeTab, search]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
      },
      {
        Header: `${formatMessage({id: 'created-by'})}`,
        accessor: ({global}) => (
          <Badge variant={global ? 'info' : 'success'}>
            {global ? 'created by the system' : 'created by your company'}
          </Badge>
        ),
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updated_at}) => <FormatDate value={updated_at} />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => handleEdit(row.original)}
          />
        ),
      },
    ],
    [formatMessage],
  );

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title="Skills"
        breadcrumbs={[
          {
            id: 'company',
            label: formatMessage({id: 'menu.company'}),
          },
          {
            id: 'skills',
            label: formatMessage({id: 'menu.skills'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => setFormType((prev) => ({...prev, modal: true}))}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'company.new-skill-button'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={skills.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={skills.data.docs} />

        <Pagination
          totalPages={skills.data.totalPages}
          currentPage={currentPage}
          totalRecords={skills.data.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      <Modal
        maxWidth="max-w-2xl"
        open={formType.modal}
        onClose={() =>
          setFormType((prev) => ({...prev, modal: false, selected: {}}))
        }
        title={formatMessage({id: 'menu.skills'})}>
        <SkillsForm
          loading={formType.loading}
          initialData={formType.selected}
          onSubmit={(value) => handleSubmit(value)}
          onCancel={() =>
            setFormType((prev) => ({...prev, modal: false, selected: {}}))
          }
        />
      </Modal>

      <Modal
        maxWidth="max-w-2xl"
        open={globalModal}
        onClose={() => setGlobalModal(false)}>
        <p className="bg-yellow-50 p-4 text-yellow-800 text-lg font-bold">
          {formatMessage({id: 'company.alert-1'})}
        </p>
        <p className="p-4 text-gray-500 font-medium">
          {formatMessage({id: 'company.alert-2'})}
        </p>
      </Modal>

      {formType.loading && <LoadingBox info="Saving skill..." />}
    </>
  );
};

export default Skills;
