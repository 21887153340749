import {useEffect, useState, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import debounce from 'lodash/debounce';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import {apiAdonis} from '../../../../../services/api';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import Table from '../../../../../components/Table';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Search from '../../../../../components/Search';
import Button from '../../../../../components/Button';
import ConfirmationModal from '../../../../../components/_modals/ConfirmationModal';
import Input from '../../../../../components/_form/Input';

import ItemFormModal from './components/ItemFormModal';

const TradesPage = () => {
  const {id} = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({});

  const [loading, setLoading] = useState(false);
  const [trade, setTrade] = useState({data: {}, loading: true});
  const [search, setSearch] = useState('');
  const [items, setItems] = useState({data: [], loading: true});
  const [formModal, setFormModal] = useState({
    open: false,
    item: null,
    itemId: null,
  });

  const getTrade = async () => {
    const {data} = await apiAdonis.get(`/trades/${id}`);
    setValue('name', data.name);
    setTrade({data, loading: false});
  };

  const getItems = async () => {
    if (!items.loading) setItems({data: [], loading: true});
    const {data} = await apiAdonis.get(
      search && search.length > 0
        ? `/trade-items?tradeId=${id}&name=${search}`
        : `/trade-items?tradeId=${id}`,
    );
    setItems({data, loading: false});
  };

  const handleCloseFormModal = () => {
    setFormModal({open: false, item: null, itemId: null});
  };

  const handleDelete = async () => {
    try {
      await apiAdonis.delete(`/trade-items/${formModal.itemId}`);
      await getItems();
      handleCloseFormModal();
    } catch {
      throw new Error();
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        name: data.name,
      };
      await apiAdonis.put(`/trades/${id}`, payload);
      setTrade((prev) => ({...prev, name: data.name, loading: false}));

      toast.success('Trade updated successfully!');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => getItems(), 350);
  }, [search]);

  useEffect(() => {
    getTrade();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({row}) => (
          <>
            <Button
              onClick={() =>
                setFormModal({open: true, item: row.original, itemId: null})
              }>
              Edit
            </Button>
            <Button
              variant="secondary"
              extraClassName="ml-2"
              onClick={() =>
                setFormModal({open: true, item: null, itemId: row.original.id})
              }>
              Delete
            </Button>
          </>
        ),
      },
    ],
    [],
  );

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      {!trade.loading && (
        <div className="bg-white shadow-md md:rounded-t-lg pt-6 mb-12">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content">
              <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
                <Input
                  label="Name *"
                  id="name"
                  error={errors.name}
                  errorMessage={errors.name?.message}
                  {...register('name', {required: true})}
                />
              </div>

              <Button type="submit" loading={loading}>
                Save
              </Button>
            </div>
          </form>
        </div>
      )}
      <PageHeader
        title="Items"
        breadcrumbs={[
          {
            id: 'trade',
            label: 'Trade',
          },
          {
            id: 'items',
            label: 'Items',
          },
        ]}
        rightContent={
          <Button
            onClick={() => setFormModal({open: true, item: null, itemId: null})}
            extraClassName="mr-2">
            Add Item
          </Button>
        }
      />

      <SectionWrapper
        isLoading={trade.loading || items.loading}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={items.data} />
      </SectionWrapper>

      <ItemFormModal
        tradeId={id}
        item={formModal.item}
        open={formModal.open && !formModal.itemId}
        onClose={() => handleCloseFormModal()}
        onSuccess={() => {
          getItems();
          handleCloseFormModal();
        }}
      />

      <ConfirmationModal
        open={formModal.open && formModal.itemId}
        onClose={() => handleCloseFormModal()}
        onSubmit={handleDelete}
        title="Delete item"
      />
    </>
  );
};

export default TradesPage;
