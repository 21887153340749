/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {useState, useCallback, useEffect, memo} from 'react';
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';

import Modal from '../Modal';

import {Container} from './styles';

const libraries = ['places'];

const PreviewMapModal = ({
  open = false,
  onClose = () => {},
  mapHeight = 500,
  coordinates = null,
}) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD9bppSLroFMyFMQQ3mF5FufeFZrQXLrgA',
    libraries,
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  const [zoom, setZoom] = useState(8);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (open && coordinates) {
      setTimeout(() => setCenter(coordinates), 250);
      setTimeout(() => setZoom(16), 550);
    }
  }, [coordinates]);

  if (!isLoaded) return <h1>loading...</h1>;

  return (
    <Modal maxWidth="max-w-4xl" minHeight={320} open={open} onClose={onClose}>
      <Container $height={mapHeight}>
        <GoogleMap
          id="google-map-wrapper"
          mapContainerStyle={{width: '100%', height: mapHeight}}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}>
          <Marker position={center} />
        </GoogleMap>
      </Container>
    </Modal>
  );
};

export default memo(PreviewMapModal);
