import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../../auth/hooks/useAuth';
import {usStates} from '../../../../../../utils/states';
import {CompaniesHTTP} from '../../../../providers/companies';

import Card from '../../../../../../components/Card';
import Input from '../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../components/_form/MaskedInput';
import Select from '../../../../../../components/_form/Select';
import Button from '../../../../../../components/Button';
import {FieldsetTitle} from '../../../../../../components/_form/FieldsetTitle';

const CompanyDetails = () => {
  const {formatMessage} = useIntl();
  const {user} = useAuth();
  const {name, phone, email, street, city, zip_code, state} = user.company;

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm({
    defaultValues: {name, phone, email, street, city, zip_code, state},
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await CompaniesHTTP.updateMyCompany(values);
      toast.success('Company updated successfully!');
    } catch (error) {
      if (error.response.data.error) {
        setError('email', {
          type: 'manual',
          message: error.response.data.error,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => clearErrors(), []);

  return (
    <Card padding="p-0" className="overflow-hidden">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="grid grid-cols-2 gap-4">
            <Input
              label={formatMessage({id: 'name-required'})}
              id="name"
              type="text"
              error={errors.name}
              {...register('name', {required: true})}
            />
          </div>

          <div className="grid grid-cols-3 gap-4">
            <Controller
              rules={{
                required: true,
              }}
              name="phone"
              control={control}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  defaultValue={field.value}
                  mask="(999) 999-9999"
                  maskPlaceholder="_"
                  label={formatMessage({id: 'phone-required'})}
                  alwaysShowMask={false}
                  error={errors.phone}
                  errorMessage={errors.phone ? errors.phone.message : ''}
                />
              )}
            />
            <Input
              label={formatMessage({id: 'email-required'})}
              id="email"
              type="email"
              error={errors.email}
              errorMessage={
                errors.email ? errors.email.message : 'This field is required'
              }
              {...register('email', {required: true})}
            />
          </div>

          <FieldsetTitle>{formatMessage({id: 'address'})}</FieldsetTitle>

          <div className="grid grid-cols-4 gap-4">
            <Input
              label={formatMessage({id: 'street'})}
              id="street"
              type="text"
              error={errors.street}
              {...register('street')}
            />
            <Input
              label={formatMessage({id: 'city'})}
              id="city"
              type="text"
              error={errors.city}
              {...register('city')}
            />
            <Input
              label={formatMessage({id: 'zip-code'})}
              id="zip_code"
              type="text"
              error={errors.zip_code}
              {...register('zip_code')}
            />
            <Controller
              name="state"
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  label={formatMessage({id: 'state'})}
                  id="select-state"
                  options={usStates}
                />
              )}
            />
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default CompanyDetails;
