import {useState} from 'react';
import {useIntl} from 'react-intl';
import {
  CalendarIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  FaceFrownIcon,
  ArrowsUpDownIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

import PageHeader from '../../../../components/_layouts/PageHeader';
import CompanyDetails from './components/CompanyDetails';
import PayPeriod from './components/PayPeriod';
import PlanAndBilling from './components/PlanAndBilling';
import PaymentMethods from './components/PaymentMethods';
import Transactions from './components/Transactions';
import CloseAccount from './components/CloseAccount';

const Settings = () => {
  const {formatMessage} = useIntl();

  const tabs = [
    {
      id: 1,
      title: formatMessage({id: 'company-details'}),
      component: CompanyDetails,
      icon: BuildingOfficeIcon,
    },
    {
      id: 2,
      title: formatMessage({id: 'pay-period'}),
      component: PayPeriod,
      icon: CalendarIcon,
    },
    {
      id: 3,
      title: formatMessage({id: 'plan'}),
      component: PlanAndBilling,
      icon: StarIcon,
    },
    {
      id: 4,
      title: formatMessage({id: 'payment-methods'}),
      component: PaymentMethods,
      icon: CreditCardIcon,
    },
    {
      id: 5,
      title: formatMessage({id: 'transactions'}),
      component: Transactions,
      icon: ArrowsUpDownIcon,
    },
    {
      id: 6,
      title: formatMessage({id: 'close-account'}),
      component: CloseAccount,
      icon: FaceFrownIcon,
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const {component: Component} = activeTab;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'header.settings'})}
        breadcrumbs={[
          {
            id: 'company',
            label: formatMessage({id: 'company'}),
          },
          {
            id: 'settings',
            label: formatMessage({id: 'header.settings'}),
          },
        ]}
      />
      <div className="grid grid-cols-4 gap-4">
        <div>
          <ul className="flex flex-col w-full m-0 p-0 mt-4">
            {tabs.map((tab) => {
              const {id, title, icon: Icon} = tab;
              const isActive = id === activeTab.id;
              return (
                <li key={id} className="flex mb-2 w-full">
                  <button
                    className={`flex items-center w-full p-2 border-0 font-medium rounded-md ${
                      isActive
                        ? 'bg-white text-yellow-500'
                        : 'bg-transparent text-gray-700 hover:text-yellow-500'
                    }`}
                    onClick={() => setActiveTab(tab)}
                    type="button">
                    <Icon
                      className={`w-6 mr-2 ${
                        isActive
                          ? 'text-yellow-500'
                          : 'text-gray-400 hover:text-yellow-500'
                      }`}
                    />{' '}
                    {title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-span-3">
          <Component />
        </div>
      </div>
    </>
  );
};

export default Settings;
