import {useState} from 'react';
import {Marker, Polyline, InfoWindow} from '@react-google-maps/api';

import {FormatDate} from '../../FormatDate';
import markerIn from '../../../assets/images/marker-green.png';
import markerOut from '../../../assets/images/marker-red.png';

const optionsPath = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

// eslint-disable-next-line no-unused-vars
const onLoadMarker = (marker) => {
  // console.log('marker pin el: ', marker);
};

const PunchMarker = ({punch}) => {
  const [selected, setSelected] = useState(null);

  const onClick = (data, status = false) =>
    setSelected({
      ...data,
      in: status,
      user: punch.user_name || punch.employee_name,
    });

  return (
    <>
      <Marker
        key={punch._id}
        onLoad={onLoadMarker}
        clickable
        onClick={() => onClick(punch.start, true)}
        icon={markerIn}
        position={{
          lat: punch.start.location.coordinates[1],
          lng: punch.start.location.coordinates[0],
        }}
      />
      {punch.end && !punch.missed_out && (
        <>
          <Polyline
            onLoad={onLoadMarker}
            path={[
              {
                lat: punch.start.location.coordinates[1],
                lng: punch.start.location.coordinates[0],
              },
              {
                lat: punch.end.location.coordinates[1],
                lng: punch.end.location.coordinates[0],
              },
            ]}
            options={optionsPath}
          />
          <Marker
            key={punch.end.location._id}
            onLoad={onLoadMarker}
            clickable
            onClick={() => onClick(punch.end)}
            icon={markerOut}
            position={{
              lat: punch.end.location.coordinates[1],
              lng: punch.end.location.coordinates[0],
            }}
          />
        </>
      )}

      {selected && (
        <InfoWindow
          onLoad={onLoadMarker}
          onCloseClick={() => setSelected(null)}
          position={{
            lat: selected.location.coordinates[1],
            lng: selected.location.coordinates[0],
          }}>
          <div style={{padding: 5}}>
            <h2 className="text-lg">{selected.user}</h2>
            <strong>{selected.in ? 'IN' : 'OUT'}</strong>
            <h3
              style={{
                marginTop: 15,
                marginBottom: 5,
                fontSize: 16,
                fontWeight: 'bold',
              }}>
              <FormatDate value={selected.time} />
            </h3>
            <p>{selected.location.address}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default PunchMarker;
