import {useEffect, useState, useMemo} from 'react';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import debounce from 'lodash/debounce';

import {apiAdonis} from '../../../../../services/api';

import IconButton from '../../../../../components/IconButton';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import Table from '../../../../../components/Table';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Search from '../../../../../components/Search';
import Button from '../../../../../components/Button';

import TradeFormModal from '../components/TradeFormModal';

const TradesPage = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [trades, setTrades] = useState({data: [], loading: true});
  const [openFormModal, setOpenFormModal] = useState(false);

  const getTrades = async () => {
    if (!trades.loading) setTrades({data: [], loading: true});
    const {data} = await apiAdonis.get(
      search && search.length > 0 ? `/trades?name=${search}` : '/trades',
    );
    setTrades({data, loading: false});
  };

  useEffect(() => {
    setTimeout(() => getTrades(), 350);
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: ({row}) =>
          formatDate(new Date(row.original.updatedAt), 'MM/dd/yyyy hh:mm:ss'),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => navigate(`/admin/trade/${row.original.id}`)}
          />
        ),
      },
    ],
    [],
  );

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title="Trades"
        breadcrumbs={[
          {
            id: 'trades',
            label: 'Trades',
          },
        ]}
        rightContent={
          <Button onClick={() => setOpenFormModal(true)} extraClassName="mr-2">
            New trade
          </Button>
        }
      />

      <SectionWrapper
        isLoading={trades.loading}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={trades.data} />
      </SectionWrapper>

      <TradeFormModal
        open={openFormModal}
        onClose={() => setOpenFormModal(false)}
        onSuccess={() => {
          getTrades();
          setOpenFormModal(false);
        }}
      />
    </>
  );
};

export default TradesPage;
