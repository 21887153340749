/* eslint-disable jsx-a11y/label-has-associated-control */
const Checkbox = ({id = '', label = '', description = '', ...rest}) => (
  <div className="flex items-start">
    <div className="flex items-center h-5">
      <input
        id={id}
        type="checkbox"
        className="focus:ring-yellow-400 h-4 w-4 text-yellow-500 border-gray-300 rounded"
        {...rest}
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={id} className="font-medium text-gray-700">
        {label}
      </label>
      {description && <p className="text-gray-500">{description}</p>}
    </div>
  </div>
);

export default Checkbox;
