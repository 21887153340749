import {useEffect, useState, useMemo} from 'react';
import {
  PlusIcon,
  ChevronRightIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import {useIntl} from 'react-intl';

import {ExtrasHTTP} from '../../../providers/extras';
import {useJobLocation} from '../../../hooks/useJobLocation';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import IconButton from '../../../../../components/IconButton';
import {currencyFormat} from '../../../../../utils/currency';
import Button from '../../../../../components/Button';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import {FormatDate} from '../../../../../components/FormatDate';
import Pagination from '../../../../../components/Pagination';
import SearchSelect from '../../../../../components/_form/SearchSelect';
import Search from '../../../../../components/Search';
import ConfirmationModal from '../../../../../components/_modals/ConfirmationModal';
import ManHoursForm from './components/Form';

const AddManHours = () => {
  const {formatMessage} = useIntl();
  const {jobs, getJobs} = useJobLocation();

  const [manhours, setManHours] = useState({
    loading: true,
    data: {},
  });

  const [formModal, setFormModal] = useState({
    modal: false,
    loading: false,
    selected: {},
    type: 'create',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({work: ''});
  const [search, setSearch] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });

  const fetchManHours = async (doLoading = true) => {
    if (doLoading && !manhours.loading) setManHours({loading: true, data: []});

    const {data} = await ExtrasHTTP.getManHours({
      page: currentPage,
      ...filters,
      ...(search ? {name: search} : {}),
    });

    setManHours({
      loading: false,
      data,
    });
  };

  const handleDelete = async () => {
    try {
      await ExtrasHTTP.deleteManHours(deleteModal.id);
      await fetchManHours(false);
      setDeleteModal({open: false, id: null});
    } catch {
      throw new Error();
    }
  };

  function handleEdit(data) {
    setFormModal((prev) => ({
      ...prev,
      type: 'edit',
      selected: data,
      modal: true,
    }));
  }

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: (row) => row.name ?? 'no name',
      },
      {
        Header: `${formatMessage({id: 'job-location'})}`,
        // eslint-disable-next-line react/destructuring-assignment
        accessor: (row) => <Badge variant="info">{row.work_id?.name}</Badge>,
      },
      {
        Header: `${formatMessage({id: 'hourly-rate-hours'})}`,
        accessor: 'hours',
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: 'amount.$numberDecimal',
        Cell: ({value}) => currencyFormat(value),
      },
      {
        Header: `${formatMessage({id: 'date'})}`,
        accessor: 'created_at',
        Cell: ({value}) => <FormatDate value={value} pattern="P" />,
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex gap-2">
            <IconButton
              icon={TrashIcon}
              onClick={() =>
                setDeleteModal({open: true, id: row.original?._id})
              }
            />
            <IconButton
              icon={ChevronRightIcon}
              onClick={() => handleEdit(row.original)}
            />
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  useEffect(() => {
    getJobs({status: 'all'});
  }, []);

  useEffect(() => {
    fetchManHours();
  }, [currentPage, filters, search]);

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'extras.man-hours'})}
        breadcrumbs={[
          {
            id: 'extras',
            label: formatMessage({id: 'extras'}),
          },
          {
            id: 'man-hours',
            label: formatMessage({id: 'menu.extras.guys'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() =>
              setFormModal((prev) => ({...prev, type: 'crete', modal: true}))
            }>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'extra.new-man-hours'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={manhours.loading}
        toolbar={
          <>
            <div style={{width: 350}}>
              <SearchSelect
                placeholder={formatMessage({id: 'filter.job-location'})}
                options={jobs.data.map((j) => ({
                  value: j._id,
                  label: j.name,
                }))}
                onChange={(selected) => setFilters({work: selected?.value})}
              />
            </div>
            <Search
              placeholder={formatMessage({id: 'search.name'})}
              onChange={(e) => searchDebounce(e.target.value)}
            />
          </>
        }>
        <Table
          loading={manhours.loading}
          columns={columns}
          data={manhours.data?.docs}
        />

        <Pagination
          totalPages={manhours.data.totalPages}
          currentPage={currentPage}
          totalRecords={manhours.data.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      {formModal.modal && (
        <ManHoursForm
          jobs={jobs.data.map((j) => ({
            value: j._id,
            label: j.name,
          }))}
          initialData={
            formModal.type === 'edit'
              ? {
                  ...formModal.selected,
                  hour_rate: formModal.selected?.hour_rate?.$numberDecimal,
                  amount: formModal.selected?.amount?.$numberDecimal,
                  period: new Date(formModal.selected?.period),
                  work_id: formModal.selected?.work_id
                    ? {
                        label: formModal.selected?.work_id?.name,
                        value: formModal.selected?.work_id?._id,
                      }
                    : null,
                }
              : {}
          }
          id={formModal.selected?._id}
          open={formModal.modal}
          onSuccess={() => {
            fetchManHours();
            setFormModal((prev) => ({...prev, modal: false, selected: {}}));
          }}
          onClose={() =>
            setFormModal((prev) => ({...prev, modal: false, selected: {}}))
          }
        />
      )}

      <ConfirmationModal
        open={deleteModal.open}
        onClose={() => setDeleteModal({open: false, id: null})}
        onSubmit={handleDelete}
        title={formatMessage({id: 'extra.confirm-delete-bill'})}
      />
    </>
  );
};

export default AddManHours;
