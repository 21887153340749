import {useMemo} from 'react';
import {
  MapPinIcon,
  ClockIcon,
  SignalSlashIcon,
} from '@heroicons/react/24/outline';

import Table from '../../../../../../components/Table';
import {FormatDate} from '../../../../../../components/FormatDate';

const PunchesPage = ({punches = []}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Department',
        accessor: 'department_name',
      },
      {
        Header: 'Work',
        accessor: 'work_id.name',
      },
      {
        Header: 'Start',
        accessor: 'start.time',
        Cell: ({row}) => (
          <>
            {row.original.start_offline && (
              <SignalSlashIcon
                className="h-6 w-6 text-yellow-600 mr-1"
                aria-hidden="true"
              />
            )}
            <p className="flex items-center mb-2">
              <MapPinIcon
                className="h-6 w-6 text-yellow-500 mr-1"
                aria-hidden="true"
              />
              {row.original.start.location.address}
            </p>
            <p className="flex items-center">
              <ClockIcon
                className="h-6 w-6 text-yellow-500 mr-1"
                aria-hidden="true"
              />
              <FormatDate value={row.original.start.time} />
            </p>
          </>
        ),
      },
      {
        Header: 'End',
        accessor: 'in',
        Cell: ({row}) => (
          <>
            {row.original.end_offline && (
              <SignalSlashIcon
                className="h-6 w-6 text-yellow-600 mr-1"
                aria-hidden="true"
              />
            )}
            {row.original.missed_out && <p>MISSED OUT</p>}
            {!row.original.missed_out &&
            !row.original.in &&
            row.original.end ? (
              <>
                <p className="flex items-center mb-2">
                  <MapPinIcon
                    className="h-6 w-6 text-yellow-500 mr-1"
                    aria-hidden="true"
                  />
                  {row.original.end.location.address}
                </p>
                <p className="flex items-center">
                  <ClockIcon
                    className="h-6 w-6 text-yellow-500 mr-1"
                    aria-hidden="true"
                  />
                  <FormatDate value={row.original.end.time} />
                </p>
              </>
            ) : (
              'IN'
            )}
          </>
        ),
      },
      {
        Header: 'Worked Hours',
        accessor: 'hours_with_deduction',
      },
      {
        Header: 'Gross Pay',
        accessor: 'total.$numberDecimal',
        Cell: ({row}) =>
          `$ ${
            row.original.total ? row.original.total.$numberDecimal : '0.00'
          }`,
      },
    ],
    [],
  );

  return <Table columns={columns} data={punches} />;
};

export default PunchesPage;
