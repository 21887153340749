import {useCallback, useState, useMemo} from 'react';
import {createContext} from 'use-context-selector';
// import {toast} from 'react-toastify';
import api from '../../../services/api';

export const EmployeeContext = createContext({});

export function EmployeeProvider({children}) {
  const [employees, setEmployees] = useState({
    loading: false,
    active: [],
    inactive: [],
    all: [],
  });
  const [employee, setEmployee] = useState({
    loading: true,
    data: {},
  });

  const getEmployees = useCallback(async (params) => {
    setEmployees((prev) => ({...prev, loading: true}));
    const {data} = await api.get('/employees', {params});
    setEmployees({
      loading: false,
      active: data.filter((i) => i.status === 'active'),
      inactive: data.filter((i) => i.status === 'inactive'),
      all: data,
    });
  }, []);

  const getEmployee = useCallback(async (id) => {
    setEmployee((prev) => ({...prev, loading: true}));
    const {data} = await api.get(`/employees/${id}`);
    setEmployee({
      loading: false,
      data,
    });
  }, []);

  const createEmployee = useCallback(
    async (payload) => api.post('/employees', payload),
    [],
  );

  const updateEmployee = useCallback(
    async (id, payload) => api.put(`/employees/${id}`, payload),
    [],
  );

  const updateEmployeesList = useCallback(
    async (id, payload) => {
      const selectedEmployee = employees.all.find((e) => e._id === id);
      if (!selectedEmployee) return;

      const listPosition = employees.all.findIndex((e) => e._id === id);
      const newList = employees.all;
      newList[listPosition] = {...selectedEmployee, ...payload};

      setEmployees({
        loading: false,
        active: newList.filter((i) => i.status === 'active'),
        inactive: newList.filter((i) => i.status === 'inactive'),
        all: newList,
      });
    },
    [employees],
  );

  const values = useMemo(
    () => ({
      employees,
      employee,
      getEmployees,
      getEmployee,
      createEmployee,
      updateEmployee,
      updateEmployeesList,
    }),
    [
      employees,
      employee,
      getEmployees,
      getEmployee,
      createEmployee,
      updateEmployee,
      updateEmployeesList,
    ],
  );

  return (
    <EmployeeContext.Provider value={values}>
      {children}
    </EmployeeContext.Provider>
  );
}
