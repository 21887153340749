/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import {useState, useCallback, useEffect, memo} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Circle,
} from '@react-google-maps/api';
import {Container} from './styles';

const inputStyle = {
  textOverflow: `ellipses`,
  position: 'absolute',
  width: '90%',
  top: 10,
  left: 10,
  right: 10,
};

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: true,
  visible: true,
  zIndex: 1,
};

const libraries = ['places'];

function AutocompleteMaps({
  onChange = () => {},
  mapHeight = 500,
  initialData = {
    coordinates: [],
    radius: 500,
    address: null,
    city: '',
  },
}) {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD9bppSLroFMyFMQQ3mF5FufeFZrQXLrgA',
    libraries,
  });

  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [circleRadius, setCircleRadius] = useState(null);
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  const [zoom, setZoom] = useState(8);
  const [address, setAddress] = useState(null);
  const [radius, setRadius] = useState(350);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onLoadSearchBox = useCallback((ref) => {
    setSearchBox(ref);
  }, []);

  const onLoadCircle = useCallback((ref) => {
    setCircleRadius(ref);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const onPlaceChanged = useCallback(() => {
    const place = searchBox.getPlace();

    setAddress((prev) => ({
      ...prev,
      address: place?.formatted_address,
      city: place?.vicinity,
      coordinates: [
        place?.geometry?.location?.lng(),
        place?.geometry?.location?.lat(),
      ],
      radius: 500,
    }));

    setCenter({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    });

    setTimeout(() => setZoom(16), 500);
  }, [searchBox]);

  const onRadiusChanged = useCallback(() => {
    setAddress((prev) => ({
      ...prev,
      radius: circleRadius ? circleRadius.radius : 500,
    }));
  }, [circleRadius]);

  useEffect(() => {
    if (address) {
      onChange(address);
    }
  }, [address]);

  useEffect(() => {
    if (initialData.address) {
      setCenter({
        lat: initialData.coordinates[1],
        lng: initialData.coordinates[0],
      });

      setTimeout(() => setAddress(initialData), 150);

      setTimeout(() => setRadius(parseFloat(initialData.radius)), 350);

      setTimeout(() => setZoom(16), 500);
    }
  }, [initialData]);

  if (!isLoaded) return <h1>loading...</h1>;

  return (
    <Container $height={mapHeight}>
      <GoogleMap
        id="google-map-wrapper"
        mapContainerStyle={{width: '100%', height: mapHeight}}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        <Autocomplete onLoad={onLoadSearchBox} onPlaceChanged={onPlaceChanged}>
          <input
            type="text"
            placeholder="Type the address"
            style={inputStyle}
            defaultValue={initialData.address ? initialData.address : ''}
            className="mt-1 focus:ring-yellow-200 focus:border-yellow-100 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-400"
          />
        </Autocomplete>
        <Circle
          onLoad={onLoadCircle}
          onRadiusChanged={onRadiusChanged}
          radius={radius}
          center={center}
          options={options}
        />
      </GoogleMap>
    </Container>
  );
}

export default memo(AutocompleteMaps);
