/* eslint-disable react/no-unescaped-entities */
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const ErrorScreen = ({error, resetError}) => {
  const [secondRender, setSecondRender] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (error && secondRender) resetError();

    if (!secondRender) setTimeout(() => setSecondRender(true), 1000);
  }, [location]);

  // eslint-disable-next-line no-return-assign

  return (
    <div className="mx-auto max-w-xl mt-5">
      <h1 className="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-5xl md:text-6xl">
        Oops...
      </h1>
      <p className="ml-16 mt-5 text-md leading-6 text-gray-600">
        looks like something went wrong. But don't worry, our support has
        already been informed and is taking care of it.
      </p>
    </div>
  );
};

export default ErrorScreen;
