import api from '../../../services/api';

export const PunchHTTP = {
  get: (params = {}) => api.get('/punches', {params}),
  create: (payload = {}) => api.post('/punches/create-boss', payload),
  update: (punchId = '', payload = {}) =>
    api.put(`/punches/${punchId}`, payload),
  updateHours: (punchId = '', payload = {}) =>
    api.put(`/punches/update-boss/${punchId}`, payload),
  destroy: (punchId = '') => api.delete(`/punches/${punchId}`),

  updateBossComment: (punchId = '', payload = '') =>
    api.put(`/boss-comments/${punchId}`, payload),
};
