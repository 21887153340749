import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Calendar} from 'react-date-range';
import {toast} from 'react-toastify';

import {InvoicesHTTP} from '../../../../../providers/invoices';

import Card from '../../../../../../../components/Card';
import Input from '../../../../../../../components/_form/Input';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import TextArea from '../../../../../../../components/_form/TextArea';

const Form = ({id, state, fetchEstimate}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: state.estimate?.data,
  });

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date(state.estimate.data.invoiceDate));

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true);
      const payload = {
        ...state.estimate.data,
        ...values,
        invoiceDate: date,
      };
      await InvoicesHTTP.update(id, payload);
      fetchEstimate(null, true);
      toast.success(formatMessage({id: 'toast.updated-sucess'}));
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card padding="p-0 pt-4 mb-8">
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex gap-4 bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex flex-col flex-grow gap-4">
            {!state.customers.loading && (
              <Controller
                name="customerId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({id: 'customer'})}
                    id="select-customerId"
                    options={state.customers.data}
                    error={errors.customerId}
                  />
                )}
              />
            )}

            <Input
              label={formatMessage({id: 'work-name-required'})}
              id="workName"
              type="text"
              error={errors.workName}
              {...register('workName', {required: true})}
            />

            <TextArea
              label={formatMessage({id: 'notes'})}
              id="notes"
              type="text"
              error={errors.notes}
              {...register('notes', {required: false})}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div style={{width: 360}}>
              <p className="block text-sm font-medium text-gray-700">
                {formatMessage({id: 'invoice-date-required'})}
              </p>
              <div style={{marginTop: -10}}>
                <Calendar
                  onChange={(item) => setDate(item)}
                  date={date}
                  showMonthAndYearPickers={false}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default Form;
