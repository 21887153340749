/* eslint-disable react/no-unescaped-entities */
import {useState, useEffect} from 'react';
import {Calendar} from 'react-date-range';
import {format, subWeeks, subDays, addWeeks} from 'date-fns';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import api from '../../../../../../services/api';
import {useAuth} from '../../../../../auth/hooks/useAuth';
import {resetTimezone} from '../../../../../../utils/utils';
import {CompaniesHTTP} from '../../../../providers/companies';

import Card from '../../../../../../components/Card';
import Button from '../../../../../../components/Button';
import Select from '../../../../../../components/_form/Select';
import Loading from '../../../../../../components/Loading';

const PayPeriod = () => {
  const {formatMessage} = useIntl();

  const formatOptions = [
    {
      label: formatMessage({id: 'weekly'}),
      value: 1,
    },
    {
      label: formatMessage({id: 'bi-weekly'}),
      value: 2,
    },
    {
      label: formatMessage({id: 'monthly'}),
      value: 31,
    },
  ];

  const {user} = useAuth();
  const {company} = user;

  const maxDate = addWeeks(new Date(), 1);
  const startAt = resetTimezone(company.new_period_start_at);

  const [paymentFormat, setPaymentFormat] = useState(company.pay_period);
  const [date, setDate] = useState(startAt);
  const [payPeriod, setPayPeriod] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!paymentFormat || !date) return;

      setSubmitLoading(true);

      await CompaniesHTTP.updateMyCompany({
        pay_period: paymentFormat,
        new_period_start_at: format(date, 'yyyy-MM-dd'),
      });
      toast.success('Pay period updated successfully!');
    } finally {
      setSubmitLoading(false);
    }
  };

  const handlePayPeriodChange = (e) => {
    setPaymentFormat(e);

    if (e === 31) {
      setDate((prevDate) => subDays(prevDate, 31));
    } else {
      setDate((prevDate) => subWeeks(prevDate, e));
    }
  };

  useEffect(() => {
    async function getPayPeriod() {
      setLoading(true);
      const {data} = await api.get('/pay-period', {
        params: {
          pay_period: paymentFormat,
          new_period_start_at: format(date, 'yyyy-MM-dd'),
        },
      });

      setPayPeriod(
        data.length > 6
          ? [data[0], {label: '...', value: 'just_dots'}, ...data.slice(-5)]
          : data,
      );

      setTimeout(() => setLoading(false), 350);
    }

    if (paymentFormat && date) {
      getPayPeriod();
    }
  }, [paymentFormat, date]);

  const isLoadingDates = Boolean(!date || !paymentFormat);

  return (
    <Card padding="p-0" className="overflow-hidden">
      {isLoadingDates ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col w-full xl:flex-row p-6">
            <div className="pr-6" style={{minWidth: '27em'}}>
              <div style={{maxWidth: 250}}>
                <Select
                  defaultValue={company.pay_period}
                  label={formatMessage({id: 'settings.pay-employees'})}
                  id="select-department"
                  options={formatOptions}
                  onChange={(e) => handlePayPeriodChange(e)}
                />
              </div>

              <div className="flex flex-col mt-6">
                <p className="block text-sm font-medium text-gray-700">
                  {formatMessage({id: 'settings.select-period'})}
                </p>
                <div className="flex datepicker-tmg">
                  <Calendar
                    minDate={new Date('2017-12-31')}
                    maxDate={maxDate}
                    onChange={(item) => setDate(item)}
                    date={date}
                  />
                </div>
              </div>
            </div>
            <div className="flex-grow">
              <div className="flex rounded-lg bg-yellow-100 p-4 mb-6">
                <p className="text-sm font-medium text-yellow-800">
                  {formatMessage({id: 'settings.info'})}
                </p>
              </div>

              <p className="block text-sm font-medium text-gray-700">
                {formatMessage({id: 'settings.see-example'})}
              </p>
              {loading ? (
                <Loading />
              ) : (
                <ul
                  className="flex flex-col mt-4 mb-8 bg-gray-50 rounded-lg overflow-hidden  border border-gray-200"
                  style={{maxWidth: 250}}>
                  {payPeriod.map((i) => (
                    <li key={i.value}>
                      <p className="text-sm text-center text-gray-800 py-2 px-4 border-b border-gray-200">
                        {i.label}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button
              type="button"
              onClick={() => handleSubmit()}
              loading={submitLoading}>
              {formatMessage({id: 'save'})}
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default PayPeriod;
