import React, {useCallback, useState, useLayoutEffect, useMemo} from 'react';
import {createContext} from 'use-context-selector';
import CryptoJS from 'crypto-js';
import {toast} from 'react-toastify';
import * as Sentry from '@sentry/react';
import {clarity} from 'react-microsoft-clarity';

import api, {stripeApi, apiTs, apiAdonis} from '../../../services/api';

const encryptKey = 'tmg2021reactwebapp';

export const AuthContext = createContext({});

export function AuthProvider({children}) {
  const [authData, setAuthData] = useState(() => {
    const token = localStorage.getItem('@TrackMyGuys:token');
    const currentCompany = localStorage.getItem('@TrackMyGuys:cckey');
    const currentCompanyName = localStorage.getItem('@TrackMyGuys:cnc');

    if (token) {
      const tokenBytes = CryptoJS.AES.decrypt(token, encryptKey);

      api.defaults.headers['x-access-token'] = tokenBytes.toString(
        CryptoJS.enc.Utf8,
      );
      stripeApi.defaults.headers['x-access-token'] = tokenBytes.toString(
        CryptoJS.enc.Utf8,
      );
      apiTs.defaults.headers['x-access-token'] = tokenBytes.toString(
        CryptoJS.enc.Utf8,
      );
      apiAdonis.defaults.headers['x-access-token'] = tokenBytes.toString(
        CryptoJS.enc.Utf8,
      );

      if (currentCompany) {
        api.defaults.headers['manager-company'] = currentCompany;
        apiTs.defaults.headers['manager-company'] = currentCompany;
        apiAdonis.defaults.headers['manager-company'] = currentCompany;
      }

      return {
        token,
        user: null,
        payPeriod: [],
        loading: true,
        currentCompany: currentCompany || null,
        currentCompanyName: currentCompanyName || null,
        currentCompanyUuid: null,
      };
    }

    return {};
  });

  const signIn = useCallback(async ({username, password}) => {
    try {
      const {data} = await api.post('/auth/login-web', {
        username,
        password,
      });

      localStorage.setItem(
        '@TrackMyGuys:token',
        CryptoJS.AES.encrypt(data.token, encryptKey).toString(),
      );

      api.defaults.headers['x-access-token'] = data.token;
      apiTs.defaults.headers['x-access-token'] = data.token;
      apiAdonis.defaults.headers['x-access-token'] = data.token;
      stripeApi.defaults.headers['x-access-token'] = data.token;

      setAuthData({
        token: data.token,
        currentCompany: null,
        currentCompanyName: data.company.name,
        currentCompanyUuid: data.company.uuid,
        payPeriod: [],
        plan: data.plan,
        user: {
          ...data.user,
          company: {...data.company, plan_id: data.plan},
        },
      });

      toast.success('Login successfully!');
    } catch (error) {
      toast.error(
        'The login or password did not match our records. Please try again!',
      );
    }
  }, []);

  const signUp = useCallback(async (payload) => {
    try {
      // await api.post('/subscription', payload);

      localStorage.setItem('@TrackMyGuys:flow', 'new-account');

      await signIn({username: payload.username, password: payload.password});
      toast.success('Account created successfully!');
    } catch (error) {
      toast.error('Something wrong. Please try again!');
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setAuthData({});
  }, []);

  async function fetchMe() {
    try {
      const {data} = await api.get('/me');

      const user = {
        ...data.user,
        company: data.company,
      };
      if (clarity.hasStarted()) {
        clarity.identify(data.user?.company_id, {
          userProperty: 'companyId',
        });
        clarity.setTag('company', data.company?.name);
        clarity.setTag('userId', data.user?._id);
        clarity.setTag('userName', data.user?.name);
      }
      Sentry.setUser({
        id: data.user?._id,
        username: data.user?.username,
        name: data.user?.name,
        email: data.user?.email,
        companyId: data.user?.company_id,
        company: data.company?.name,
      });

      const hasPlan = !!data.plan;
      const canCreateEmployee = hasPlan
        ? data.plan.max_employees > data.employees_qty
        : true;
      const canCreateBoss =
        hasPlan && data.user?.company_id !== '5c13d5670919e005a4b6fe3a'
          ? data.plan.boss > data.boss_qty
          : true;
      setAuthData((prev) => ({
        ...prev,
        loading: false,
        currentCompanyName: data.company.name,
        currentCompanyUuid: data.company.uuid,
        user,
        canCreateEmployee,
        employeesQty: data.employees_qty,
        canCreateBoss,
        bossQty: data.boss_qty,
        payPeriod: data.pay_period,
        plan: data.plan ?? null,
      }));
    } catch (error) {
      signOut();
    }
  }

  const setCurrentCompany = useCallback(
    async (companyId, companyName) => {
      if (!companyId || companyId === null) {
        localStorage.removeItem('@TrackMyGuys:cckey');
        localStorage.removeItem('@TrackMyGuys:cnc');
      } else {
        localStorage.setItem('@TrackMyGuys:cckey', companyId);
        localStorage.setItem('@TrackMyGuys:cnc', companyName);
        api.defaults.headers['manager-company'] = companyId;
        apiTs.defaults.headers['manager-company'] = companyId;
        apiAdonis.defaults.headers['manager-company'] = companyId;
      }
    },
    [authData],
  );

  useLayoutEffect(() => {
    if (authData.token) fetchMe();
  }, [authData.token]);

  const values = useMemo(
    () => ({
      signIn,
      signUp,
      signOut,
      setCurrentCompany,
      user: authData.user,
      isAdmin:
        authData.user && authData.user.role && authData.user.role === 'admin',
      payPeriod: authData.payPeriod,
      canCreateEmployee: authData.canCreateEmployee,
      employeesQty: authData.employeesQty,
      canCreateBoss: authData.canCreateBoss,
      bossQty: authData.bossQty,
      currentCompany: authData.currentCompany,
      currentCompanyName: authData.currentCompanyName,
      currentCompanyUuid: authData.currentCompanyUuid,
      timezone: 'America/New_York', // authData.user.company.timezone ||
    }),
    [signIn, signUp, signOut, setCurrentCompany, authData],
  );

  if (authData.loading) return <h1>loading...</h1>;

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
