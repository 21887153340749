import styled from 'styled-components';

export const TableWrapper = styled.div`
  overflow-x: auto;
`;

export const TableWrapperScroll = styled.div`
  position: relative;

  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: auto;

  table {
    height: 100%;
  }

  thead,
  tfoot {
    position: sticky;
  }

  thead {
    top: 0;
  }
  tfoot {
    bottom: 0;
  }
`;
