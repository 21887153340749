import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {useJobLocation} from '../../../hooks/useJobLocation';
import {isValidDate, clearCurrencyValue} from '../../../../../utils/utils';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import Input from '../../../../../components/_form/Input';
import {LoadingBox} from '../../../../../components/LoadingBox';
import MaskedInput from '../../../../../components/_form/MaskedInput';
import CurrencyInput from '../../../../../components/_form/MaskedInput/Currency';
import Button from '../../../../../components/Button';
import {FieldsetTitle} from '../../../../../components/_form/FieldsetTitle';
import AutocompleteMaps from '../../../../../components/AutocompleteMaps';

const Create = () => {
  const {formatMessage} = useIntl();
  const {currentCompany, user} = useAuth();
  const {createJob, syncPunches} = useJobLocation();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const hasPlace = Object.keys(selectedPlace).length > 0;
      const hasCoordinates =
        selectedPlace &&
        selectedPlace.coordinates &&
        selectedPlace.coordinates[0] &&
        selectedPlace.coordinates[1];
      if (!hasPlace || !hasCoordinates) {
        toast.error('Provider a location!');
        return;
      }

      if (!isValidDate(new Date(data.started_at))) {
        toast.error('Provider a valid start date!');
        return;
      }

      const response = await createJob({
        ...data,
        location: selectedPlace,
        budget: clearCurrencyValue(data.budget),
        started_at: new Date(data.started_at).toDateString(),
        company_id: currentCompany || user.company_id,
        status: 'active',
      });

      toast.success('Job location created successfully!');
      syncPunches(response.data._id);
      navigate('/job-location');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'job-location'})}
        breadcrumbs={[
          {
            id: 'job-location',
            label: formatMessage({id: 'job-location'}),
          },
          {
            id: 'job-location-new',
            label: formatMessage({id: 'create'}),
          },
        ]}
      />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <form
          className="bg-white rounded-lg shadow overflow-hidden"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="grid grid-cols-1 gap-4">
              <Input
                label={formatMessage({id: 'job-name-required'})}
                id="name"
                type="text"
                error={errors.name}
                {...register('name', {required: true})}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Controller
                rules={{
                  required: true,
                }}
                name="budget"
                control={control}
                render={({field}) => (
                  <CurrencyInput
                    {...field}
                    label={formatMessage({id: 'initial-budget-required'})}
                    error={errors.budget}
                  />
                )}
              />
              <Controller
                rules={{
                  required: true,
                }}
                name="started_at"
                control={control}
                render={({field}) => (
                  <MaskedInput
                    {...field}
                    removeMask={false}
                    mask="99/99/9999"
                    maskPlaceholder="MM/DD/YYYY"
                    label={formatMessage({id: 'started-at-required'})}
                    error={errors.started_at}
                    alwaysShowMask={false}
                  />
                )}
              />
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button type="submit" loading={loading}>
              {formatMessage({id: 'save'})}
            </Button>
            <Button
              variant="secondary"
              onClick={() => navigate('/job-location')}>
              {formatMessage({id: 'cancel'})}
            </Button>
          </div>
        </form>
        <div className="col-span-2 bg-white rounded-lg shadow overflow-hidden p-4">
          <FieldsetTitle>{formatMessage({id: 'location'})}</FieldsetTitle>

          <AutocompleteMaps onChange={(address) => setSelectedPlace(address)} />
        </div>
      </div>

      {loading && <LoadingBox info="creating new job location..." />}
    </>
  );
};

export default Create;
