const Tabs = ({actived, onChange, items}) => (
  <ul className="flex flex-col items-center md:flex-row">
    {items.map((item) => (
      <li key={item.value}>
        <button
          type="button"
          onClick={() => onChange(item.value)}
          className={`flex border-b-2 py-4 px-6 text-sm ${
            actived === item.value
              ? 'border-yellow-500 text-yellow-700'
              : 'border-transparent text-gray-500'
          } hover:border-yellow-500 hover:text-yellow-700 transition-colors duration-200 transform focus:outline-none focus:ring-transparent`}>
          {item.label}
        </button>
      </li>
    ))}
  </ul>
);

export default Tabs;
