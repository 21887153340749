import {
  UsersIcon,
  BuildingOfficeIcon,
  ClockIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';

import PageHeader from '../../../../../components/_layouts/PageHeader';

const ProfilePage = () => (
  <>
    <PageHeader
      title="Dashboard"
      breadcrumbs={[
        {
          id: '/',
          label: 'Dashboard',
        },
      ]}
    />
    <div className="grid grid-cols-1 gap-6 md:grid-cols-4 mb-6">
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <UsersIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        <div className="ml-4">
          <h3 className="text-gray-500">Employees</h3>
          <p className="text-2xl font-bold">11</p>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <BuildingOfficeIcon
          className="h-6 w-6 text-yellow-500"
          aria-hidden="true"
        />
        <div className="ml-4">
          <h3 className="text-gray-500">Punches</h3>
          <p className="text-2xl font-bold">12</p>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <ClockIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        <div className="ml-4">
          <h3 className="text-gray-500">Hours Tracked</h3>
          <p className="text-2xl font-bold">13</p>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <CurrencyDollarIcon
          className="h-6 w-6 text-yellow-500"
          aria-hidden="true"
        />
        <div className="ml-4">
          <h3 className="text-gray-500">Gross Pay</h3>
          <p className="text-2xl font-bold">$14</p>
        </div>
      </div>
    </div>
  </>
);

export default ProfilePage;
