import {DashboardProvider} from '../../context/DashboardContext';

import Home from './Home';

const Dashboard = () => (
  <DashboardProvider>
    <Home />
  </DashboardProvider>
);

export default Dashboard;
