/* eslint-disable no-console */
import {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {ArrowUpTrayIcon, CheckCircleIcon} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {useFile} from '../../hooks/useFile';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: 4,
  border: '1px dashed #c5c5c5',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#333333',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const getSize = (size) => {
  const megas = Math.round(size / 1000 / 1000);

  if (megas < 1) return `${Math.round(size / 1000)} kb`;

  return `${megas} mb`;
};

function DragAndDropUpload({
  label = 'File',
  model = '',
  // accept = 'image/*, .pdf, .doc, .docx, .txt, .xml, .xps, .xls, .xlsx',
  // accept = {
  //   'image/*': ['.png', '.jpeg', '.jpg', '.gif', '.JPEG', '.JPG'],
  //   'application/msword': ['.doc'],
  // },
  maxFiles = 1,
  maxSize = 10077433,
  onUpload = () => {},
  error = false,
}) {
  const {formatMessage} = useIntl();
  const {upload, uploading} = useFile();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted: async (files) => {
      try {
        const response = await upload(files[0], model);
        onUpload(response.file);
      } catch (err) {
        console.log('upload erro', err);
      }
    },
    // accept,
    maxFiles,
    maxSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className="mt-4 mb-4">
      <p className="block text-sm font-medium text-gray-700 mb-1">{label}</p>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p className="text-sm text-gray-400">
          {formatMessage({id: 'drag-drop-files'})} {getSize(maxSize)})
        </p>
        {error && (
          <p className="text-xs text-red-500 mt-2">
            {formatMessage({id: 'select-valid-file'})}
          </p>
        )}
      </div>
      <ul className="list-disc text-sm space-y-2 mt-2">
        {acceptedFiles.map((file) => (
          <li key={file.path} className="flex text-gray-400">
            {file.path} - {getSize(file.size)}{' '}
            {uploading ? (
              <span className="flex text-xs">
                <ArrowUpTrayIcon
                  className="ml-2 mr-1 h-5 w-5 text-oragen-500"
                  aria-hidden="true"
                />{' '}
                uploading...
              </span>
            ) : (
              <CheckCircleIcon
                className="ml-2 h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DragAndDropUpload;
