import React from 'react';

const useFetchInitialState = {
  loading: true,
  error: undefined,
  data: undefined,
};

const useFetchReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {...useFetchInitialState, loading: true};
    case 'fetched':
      return {...useFetchInitialState, loading: false, data: action.payload};
    case 'error':
      return {...useFetchInitialState, loading: false, error: action.payload};
    default:
      return state;
  }
};

const ignore = false;

export function useFetch(httpRequest, requestId = '', requestOptions = {}) {
  // const cacheRef = React.useRef({});

  const [state, dispatch] = React.useReducer(
    useFetchReducer,
    useFetchInitialState,
  );

  const fetchData = async (currentOptions = null, _ignoreCache = false) => {
    const options = currentOptions || requestOptions;
    // const cachedResponse = cacheRef.current[requestId];

    // if (cachedResponse && !ignoreCache) {
    //   dispatch({type: 'fetched', payload: cachedResponse});
    //   return;
    // }

    dispatch({type: 'loading'});

    try {
      const res = await httpRequest(options);

      const {data} = res;
      // cacheRef.current[requestId] = data;

      // if (ignore === false) {
      dispatch({type: 'fetched', payload: data});
      // }
    } catch (e) {
      if (ignore === false) {
        dispatch({type: 'error', payload: e});
      }
    }
  };

  React.useEffect(() => {
    if (typeof httpRequest !== 'function') return;

    fetchData(requestOptions);

    // eslint-disable-next-line consistent-return
    // return () => {
    //   ignore = true;
    // };
  }, [requestId]);

  return {...state, refetch: fetchData};
}
