import {forwardRef} from 'react';
import MaskedInput from 'react-input-mask';

import Input from '../Input';

const CustomMaskedInput = forwardRef(
  (
    {
      mask,
      maskPlaceholder,
      label,
      onChange,
      removeMask = true,
      clearInputRegex = /[^0-9]/g,
      alwaysShowMask = false,
      error = false,
      errorMessage = 'This field is required',
      defaultValue,
      inputClassName = 'mb-2',
      inputExtraClassName = '',
      props,
    },
    ref,
  ) => (
    <MaskedInput
      mask={mask}
      alwaysShowMask={alwaysShowMask}
      onChange={(e) => {
        e.persist();
        onChange(
          removeMask
            ? e.target.value.replace(clearInputRegex, '')
            : e.target.value,
        );
      }}
      value={defaultValue || props?.value}
      {...props}>
      <Input
        label={label}
        error={error}
        errorMessage={errorMessage}
        ref={ref}
        placeholder={maskPlaceholder}
        className={inputClassName}
        inputExtraClassName={inputExtraClassName}
      />
    </MaskedInput>
  ),
);

export default CustomMaskedInput;
