import {format as formatDate} from 'date-fns';
import PropTypes from 'prop-types';

export const FormatDate = ({
  value = '',
  pattern = 'MM/dd/yyyy hh:mm a',
  unix = false,
}) => {
  const dt = unix ? new Date(value * 1000) : new Date(value);

  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );

  return <>{formatDate(dtDateOnly, pattern)}</>;
};

FormData.propTypes = {
  /**
   * datetime with the ISOString format
   */
  value: PropTypes.string.isRequired,
  /**
   * format of datetime will be return
   * see the link: https://date-fns.org/v2.23.0/docs/format
   * for more details about the formats
   */
  pattern: PropTypes.string,
  unix: PropTypes.bool,
};
