import {useIntl} from 'react-intl';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {
  XCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {toast} from 'react-toastify';

import {currencyFormat} from '../../../../../../../utils/currency';
import {InvoicesHTTP} from '../../../../../providers/invoices';
import {
  InvoiceTradeItemsHTTP,
  InvoiceTradesHTTP,
} from '../../../../../providers/trades';

import Card from '../../../../../../../components/Card';
import Input from '../../../../../../../components/_form/Input';
import Button from '../../../../../../../components/Button';
import IconButton from '../../../../../../../components/IconButton';
import ConfirmationModal from '../../../../../../../components/_modals/ConfirmationModal';

import AddTrade from '../../components/_trade/AddTrade';
import ItemForm from '../../components/_trade_items/ItemForm';

const confirmationModalSettings = {
  trade: {
    title: 'Trade delete',
    description: 'Do you confirm the exclusion of this trade?',
  },
  item: {
    title: 'Item delete',
    description: 'Do you confirm the exclusion of this item?',
  },
};

const ScopeOfWork = ({id, state, dispatchState, fetchEstimate}) => {
  const {formatMessage} = useIntl();

  const handleSubmitDiscountTax = async (type = 'discount') => {
    try {
      if (type === 'discount') {
        dispatchState({discount: {...state.discount, loading: true}});
      } else {
        dispatchState({tax: {...state.tax, loading: true}});
      }
      const payload = {
        workName: state.estimate?.data?.workName,
        discount: state.estimate?.data?.discount,
        tax: state.estimate?.data?.tax,
      };

      if (type === 'discount') payload.discount = state.discount.value || 0;
      if (type === 'tax') payload.tax = state.tax.value || 0;

      await InvoicesHTTP.update(id, payload);
      fetchEstimate(null, true);
      toast.success(formatMessage({id: 'toast.updated-sucess'}));
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    }
  };

  const handleAddedTrade = (tradeSelected) => {
    fetchEstimate(tradeSelected);
  };

  const handleSuccessItemModal = () => {
    fetchEstimate(state.trade);
    dispatchState({openCreateItem: false});
  };

  const handleOpenItemModal = (item) => {
    dispatchState({invoiceTradeItem: item, openCreateItem: true});
  };

  const handleConfirmationModal = (itemType = 'trade', itemId = null) => {
    dispatchState({
      confirmation: {
        open: true,
        type: itemType,
        id: itemId,
      },
    });
  };

  const handleCloseConfirmationModal = () => {
    dispatchState({
      confirmation: {
        open: false,
        type: 'trade',
        id: null,
      },
    });
  };

  const handleDeleteItem = async () => {
    if (state.confirmation.type === 'item') {
      await InvoiceTradeItemsHTTP.deleteItem({id: state.confirmation.id});
    } else {
      await InvoiceTradesHTTP.deleteTradeFromInvoice({
        id: state.confirmation.id,
      });
    }
    fetchEstimate(state.trade);
    dispatchState({
      confirmation: {
        open: false,
        type: 'trade',
        id: null,
      },
    });
  };

  const hasSelectedTrade = state.trade;

  return (
    <>
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        <Card
          padding="p-0"
          className={`bg-white pt-4 ${
            hasSelectedTrade ? 'col-span-1' : 'col-span-2'
          }`}>
          <div className="flex w-full px-4 justify-between">
            <h3 className="font-bold">Trades/Scope of work</h3>
            <Button
              type="button"
              onClick={() => dispatchState({openAddTradeModal: true})}>
              {formatMessage({id: 'bookpodium.estimates.trades.new-button'})}
            </Button>
          </div>
          <ul className="flex flex-col w-full mt-4">
            {state.estimate?.data?.trades?.map((t) => (
              <li
                key={`trade-${t.id}`}
                className="flex items-center w-full border-b px-2">
                <IconButton
                  icon={XCircleIcon}
                  iconClassName="text-red-500 w-6 h-6"
                  onClick={() => handleConfirmationModal('trade', t.id)}
                />
                <button
                  type="button"
                  className={`flex items-center w-full p-2 hover:bg-gray-50 ${
                    t.id === state.trade?.id ? 'bg-gray-50' : ''
                  }`}
                  onClick={() => dispatchState({trade: t})}>
                  <span className="flex flex-col flex-grow justify-start items-start">
                    {t.name} <br />
                    <span className="text-xs text-gray-700">
                      {t.itemsCount}{' '}
                      {parseInt(t.itemsCount, 10) > 1 ? 'Items' : 'Item'}
                    </span>
                  </span>
                  <span>{currencyFormat(t.tradeAmount)}</span>
                  <ChevronRightIcon className="text-yellow-500 w-6 h-6" />
                </button>
              </li>
            ))}
            <li className="bg-gray-100 p-4">
              <p className="flex justify-between">
                Subtotal:{' '}
                <span>{currencyFormat(state.estimate?.data?.subtotal)}</span>
              </p>
            </li>
            <li className="bg-gray-50 px-4 py-2">
              <div className="flex justify-between text-sm">
                {state.discount.edit ? (
                  <form className="flex items-end">
                    <IconButton
                      icon={XMarkIcon}
                      disabled={state.discount.loading}
                      onClick={() =>
                        dispatchState({
                          discount: {
                            edit: false,
                            value: state.estimate?.data?.discount,
                            loading: false,
                          },
                        })
                      }
                    />
                    <Input
                      autoFocus
                      placeholder="Discount %"
                      label="Discount"
                      type="number"
                      defaultValue={state.discount.value}
                      className="m-0 mr-2"
                      onChange={(ev) =>
                        dispatchState({
                          discount: {
                            edit: true,
                            value: ev.target.value,
                            loading: false,
                          },
                        })
                      }
                    />
                    <Button
                      type="button"
                      loading={state.discount.loading}
                      onClick={() => handleSubmitDiscountTax('discount')}>
                      Save
                    </Button>
                  </form>
                ) : (
                  <>
                    <span className="flex items-center">
                      <IconButton
                        icon={PencilSquareIcon}
                        onClick={() =>
                          dispatchState({
                            discount: {
                              edit: true,
                              value: state.estimate?.data?.discount,
                              loading: false,
                            },
                          })
                        }
                      />
                      Discount({state.estimate?.data?.discount}%):
                    </span>
                    <span>
                      {currencyFormat(state.estimate?.data?.amountDiscount)}
                    </span>
                  </>
                )}
              </div>
            </li>
            <li className="bg-gray-50 px-4 py-2 border-t">
              <div className="flex justify-between text-sm">
                {state.tax.edit ? (
                  <form className="flex items-end">
                    <IconButton
                      icon={XMarkIcon}
                      disabled={state.tax.loading}
                      onClick={() =>
                        dispatchState({
                          tax: {
                            edit: false,
                            value: state.estimate?.data?.tax,
                            loading: false,
                          },
                        })
                      }
                    />
                    <Input
                      autoFocus
                      placeholder="Tax %"
                      label="Tax"
                      type="number"
                      defaultValue={state.tax.value}
                      className="m-0 mr-2"
                      onChange={(ev) =>
                        dispatchState({
                          tax: {
                            edit: true,
                            value: ev.target.value,
                            loading: false,
                          },
                        })
                      }
                    />
                    <Button
                      type="button"
                      loading={state.tax.loading}
                      onClick={() => handleSubmitDiscountTax('tax')}>
                      Save
                    </Button>
                  </form>
                ) : (
                  <>
                    <span className="flex items-center">
                      <IconButton
                        icon={PencilSquareIcon}
                        onClick={() =>
                          dispatchState({
                            tax: {
                              edit: true,
                              value: state.estimate?.data?.tax,
                              loading: false,
                            },
                          })
                        }
                      />
                      Sales Tax({state.estimate?.data?.tax}%):
                    </span>
                    <span>
                      {currencyFormat(state.estimate?.data?.amountTax)}
                    </span>
                  </>
                )}
              </div>
            </li>
            <li className="bg-gray-200 p-4">
              <p className="flex justify-between">
                Grand Total:{' '}
                <span>{currencyFormat(state.estimate?.data?.grandTotal)}</span>
              </p>
            </li>
          </ul>
        </Card>
        {hasSelectedTrade && (
          <Card className="bg-white col-span-1">
            <div className="flex w-full justify-between">
              <h3 className="font-bold">{state.trade.name} items</h3>
              <Button
                type="button"
                onClick={() => dispatchState({openCreateItem: true})}>
                {formatMessage({id: 'bookpodium.estimates.trades.new-button'})}
              </Button>
            </div>

            <ul className="flex flex-col w-full mt-4">
              {state.trade?.items?.map((item) => (
                <li
                  key={`trade-item-${item.id}`}
                  className="flex w-full py-2 border-b">
                  <IconButton
                    icon={XCircleIcon}
                    iconClassName="text-red-500 w-6 h-6"
                    onClick={() => handleConfirmationModal('item', item.id)}
                  />
                  <button
                    type="button"
                    className="flex items-center w-full hover:bg-gray-50"
                    onClick={() => handleOpenItemModal(item)}>
                    <span className="flex flex-col flex-grow justify-start items-start">
                      {item.name}
                    </span>
                    <span className="flex flex-col items-end">
                      {currencyFormat(item.itemAmount)} <br />
                      <span className="text-xs text-gray-700">
                        {item.taxable ? 'taxable' : ''}
                      </span>
                    </span>
                    <ChevronRightIcon className="text-yellow-500 w-6 h-6" />
                  </button>
                </li>
              ))}
            </ul>
          </Card>
        )}
      </div>

      <AddTrade
        invoiceId={id}
        open={state.openAddTradeModal}
        onClose={() => dispatchState({openAddTradeModal: false})}
        onSuccess={(tradeSelected) => handleAddedTrade(tradeSelected)}
      />
      <ItemForm
        open={state.openCreateItem}
        invoiceId={id}
        invoiceTradeId={state.trade?.id}
        invoiceTradeItem={state.invoiceTradeItem}
        onClose={() =>
          dispatchState({openCreateItem: false, invoiceTradeItem: null})
        }
        onSuccess={handleSuccessItemModal}
      />
      <ConfirmationModal
        open={state.confirmation.open}
        title={confirmationModalSettings[state.confirmation.type].title}
        description={
          confirmationModalSettings[state.confirmation.type].description
        }
        onClose={() => handleCloseConfirmationModal()}
        onSubmit={() => handleDeleteItem()}
      />
    </>
  );
};

export default ScopeOfWork;
