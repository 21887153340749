import {useEffect, useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useEmployee} from '../../hooks/useEmployee';
import {DepartmentsHTTP} from '../../providers/departments';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import Search from '../../../../components/Search';
import Table from '../../../../components/Table';
import Select from '../../../../components/_form/Select';
import EmptyData from '../../../../components/EmptyData';

const Timecard = () => {
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const {employees, getEmployees} = useEmployee();

  const [departments, setDepartments] = useState({
    loading: true,
    data: [],
  });
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [search, setSearch] = useState('');
  const handleGoToPage = (row) => {
    navigate(`/timecard/${row._id}`);
  };

  useEffect(() => {
    getEmployees({
      status: 'active',
      department: selectedDepartment === 'all' ? '' : selectedDepartment,
      name: search,
    });
  }, [selectedDepartment, search]);

  useEffect(() => {
    async function fetchDepartments() {
      const {data} = await DepartmentsHTTP.get({status: 'active'});
      setDepartments({
        loading: false,
        data: [
          {
            label: formatMessage({id: 'all'}),
            value: 'all',
          },
          ...data.map((d) => ({
            label: d.name,
            value: d._id,
          })),
        ],
      });
    }
    fetchDepartments();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
      },
      {
        Header: `${formatMessage({id: 'departments'})}`,
        accessor: 'department_id.name',
      },
    ],
    [formatMessage],
  );

  const emptyData = !employees.loading && employees.active.length === 0;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'menu.timecard'})}
        breadcrumbs={[
          {
            id: 'timecard',
            label: formatMessage({id: 'menu.timecard'}),
          },
        ]}
      />

      <SectionWrapper
        isLoading={employees.loading}
        toolbar={
          <>
            <div style={{minWidth: 280}}>
              <Select
                label=""
                id="departments"
                options={departments.data}
                defaultValue={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e)}
                placeholder={formatMessage({id: 'filter.department'})}
              />
            </div>
            <Search onChange={(e) => setSearch(e.target?.value)} />
          </>
        }>
        <Table
          columns={columns}
          data={employees.active}
          onRowClick={handleGoToPage}
        />
        {emptyData && (
          <EmptyData
            title={formatMessage({id: 'timecard.no-employees'})}
            description={formatMessage({
              id: 'timecard.no-employees-description',
            })}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Timecard;
