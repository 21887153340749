import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Calendar} from 'react-date-range';

import {CustomersHTTP} from '../../../../../providers/customers';

import Input from '../../../../../../../components/_form/Input';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';

const CreateForm = ({loading, onSubmit, onCancel = () => {}}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const [date, setDate] = useState(new Date());
  const [customers, setCustomers] = useState({
    loading: true,
    data: [],
    meta: {},
  });

  const handleSubmitForm = (values) => {
    onSubmit({...values, invoiceDate: date});
  };

  const fetchCustomers = async () => {
    setCustomers({
      loading: true,
      data: [],
      meta: {},
    });

    const {data} = await CustomersHTTP.get({perPage: 150});

    setCustomers({
      loading: false,
      data: data.data.map((c) => ({label: c.name, value: c.id})),
      meta: data.meta,
    });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-1 gap-4">
          {!customers.loading && (
            <Controller
              name="customerId"
              control={control}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <Select
                  {...field}
                  label={formatMessage({id: 'customer'})}
                  id="select-customerId"
                  options={customers.data}
                  error={errors.customerId}
                />
              )}
            />
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <Input
            label={formatMessage({id: 'work-name-required'})}
            id="workName"
            type="text"
            error={errors.workName}
            {...register('workName', {required: true})}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 mt-2">
          <div style={{width: 360}}>
            <p className="block text-sm font-medium text-gray-700">
              {formatMessage({id: 'invoice-date-required'})}
            </p>
            <div style={{marginTop: -10}}>
              <Calendar
                onChange={(item) => setDate(item)}
                date={date}
                showMonthAndYearPickers={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit" loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
        <Button
          variant="secondary"
          extraClassName="mr-2"
          onClick={() => onCancel()}>
          {formatMessage({id: 'cancel'})}
        </Button>
      </div>
    </form>
  );
};

export default CreateForm;
