import {useContextSelector} from 'use-context-selector';
import {EmployeeContext} from '../context/EmployeeContext';

export function useEmployee() {
  const employees = useContextSelector(
    EmployeeContext,
    (data) => data.employees,
  );
  const employee = useContextSelector(EmployeeContext, (data) => data.employee);

  const getEmployees = useContextSelector(
    EmployeeContext,
    (data) => data.getEmployees,
  );
  const getEmployee = useContextSelector(
    EmployeeContext,
    (data) => data.getEmployee,
  );
  const createEmployee = useContextSelector(
    EmployeeContext,
    (data) => data.createEmployee,
  );
  const updateEmployee = useContextSelector(
    EmployeeContext,
    (data) => data.updateEmployee,
  );
  const updateEmployeesList = useContextSelector(
    EmployeeContext,
    (data) => data.updateEmployeesList,
  );

  return {
    employees,
    employee,
    getEmployees,
    getEmployee,
    createEmployee,
    updateEmployee,
    updateEmployeesList,
  };
}
