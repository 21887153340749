import {useEffect, useState} from 'react';
import {IdentificationIcon, MapPinIcon} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {WhoIsInHTTP} from '../../providers/whoisin';
import {DepartmentsHTTP} from '../../providers/departments';

import PageHeader from '../../../../components/_layouts/PageHeader';
import Card from '../../../../components/Card';
import Badge from '../../../../components/Badge';
import Loading from '../../../../components/Loading';
import {FormatDate} from '../../../../components/FormatDate';

const renderItem = (item) => (
  <li className="border-b border-gray-200">
    <div className="p-4">
      <div className="flex items-center">
        <Badge variant={item.in ? 'success' : 'error'}>
          <IdentificationIcon className="w-4 mr-1" />
          {item.department_name}
        </Badge>
        {item.work_name && (
          <Badge variant="info" extraClassName="ml-2">
            <MapPinIcon className="w-4 mr-1" /> {item.work_name}
          </Badge>
        )}
      </div>
      <h3 className="font-medium text-gray-700">{item.employee_name}</h3>
      <p className="text-gray-400 text-xs">{item.start.location.address}</p>
      <p className="text-green-500 text-xs">
        <FormatDate
          value={item.in ? item.start.time : item.end.time}
          pattern="p"
        />
      </p>
    </div>
  </li>
);

const getDepartmentCount = (punches = [], departmentId = '') =>
  punches.filter((d) => d.department_id._id === departmentId).length;

const WhoIsIn = () => {
  const {formatMessage} = useIntl();
  const [whoIsIn, setWhoIsIn] = useState({
    loading: true,
    data: {
      all: [],
      in: [],
      out: [],
    },
  });
  const [departments, setDepartments] = useState({
    loading: true,
    data: [],
  });
  const [selectedDepartment, setSelectedDepartment] = useState('all');

  async function fetchDepartments() {
    setDepartments({
      loading: true,
      data: [],
    });
    const {data} = await DepartmentsHTTP.get({
      sort: 'updated_at',
      status: 'active',
    });
    setDepartments({
      loading: false,
      data,
    });
  }

  const fetchWhoisIn = async () => {
    setWhoIsIn({
      loading: true,
      data: {
        all: [],
        in: [],
        out: [],
      },
    });

    const {data} = await WhoIsInHTTP.get();

    setWhoIsIn({loading: false, data});
  };

  useEffect(() => {
    fetchDepartments();
    fetchWhoisIn();
  }, []);

  const inList = whoIsIn.data.in.filter((i) =>
    selectedDepartment === 'all'
      ? i.department_id._id
      : i.department_id._id === selectedDepartment,
  );
  const outList = whoIsIn.data.out.filter((i) =>
    selectedDepartment === 'all'
      ? i.department_id._id
      : i.department_id._id === selectedDepartment,
  );

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'who-is-in'})}
        breadcrumbs={[
          {
            id: 'whoisin',
          },
        ]}
      />

      <div className="grid grid-cols-3 gap-4">
        <div>
          <h2 className="p-2 font-semibold text-gray-700">
            {formatMessage({id: 'filter-by-department'})}
          </h2>
          <Card padding="p-0">
            {departments.loading ? (
              <div className="m-4">
                <Loading />
              </div>
            ) : (
              <ul className="overflow-y-auto" style={{maxHeight: '65vh'}}>
                <li className="border-b border-gray-200">
                  <button
                    className={`p-4 text-sm text-left w-full hover:bg-gray-50 hover:text-yellow-700 ${
                      selectedDepartment === 'all'
                        ? 'bg-gray-50 text-yellow-700'
                        : ''
                    }`}
                    type="button"
                    onClick={() => setSelectedDepartment('all')}>
                    {formatMessage({id: 'all'})} ({whoIsIn.data.all.length})
                  </button>
                </li>
                {departments.data.map((item) => (
                  <li key={item._id} className="border-b border-gray-200">
                    <button
                      className={`p-4 text-sm text-left w-full hover:bg-gray-50 hover:text-yellow-700 ${
                        selectedDepartment === item._id
                          ? 'bg-gray-50 text-yellow-700'
                          : ''
                      }`}
                      type="button"
                      onClick={() => setSelectedDepartment(item._id)}>
                      {item.name} (
                      {getDepartmentCount(whoIsIn.data.all, item._id)})
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </Card>
        </div>

        <div>
          <h2 className="p-2 font-semibold text-gray-700">
            {formatMessage({id: 'who-is-in'})}
          </h2>
          <Card padding="p-0">
            {whoIsIn.loading ? (
              <div className="m-4">
                <Loading />
              </div>
            ) : (
              <>
                {inList.length === 0 && (
                  <div className="flex p-4">
                    {formatMessage({id: 'no-data'})}
                  </div>
                )}
                <ul className="overflow-y-auto" style={{maxHeight: '65vh'}}>
                  {inList.map((item) => renderItem(item))}
                </ul>
              </>
            )}
          </Card>
        </div>

        <div>
          <h2 className="p-2 font-semibold text-gray-700">
            {formatMessage({id: 'who-is-out'})}
          </h2>
          <Card padding="p-0">
            {whoIsIn.loading ? (
              <div className="m-4">
                <Loading />
              </div>
            ) : (
              <>
                {outList.length === 0 && (
                  <div className="flex p-4">
                    {formatMessage({id: 'no-data'})}
                  </div>
                )}
                <ul className="overflow-y-auto" style={{maxHeight: '65vh'}}>
                  {outList.map((item) => renderItem(item))}
                </ul>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};
export default WhoIsIn;
