/* eslint-disable react/no-unescaped-entities */
import {
  CheckBadgeIcon,
  CreditCardIcon,
  CalendarIcon,
} from '@heroicons/react/24/solid';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import {StripeHTTP} from '../../../company/providers/stripe';
import {useAuth} from '../../../auth/hooks/useAuth';
import {currencyFormat} from '../../../../utils/currency';

import Loading from '../../../../components/Loading';
import {FormatDate} from '../../../../components/FormatDate';
import Layout from '../../components/Layout';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {});

const HomeStripe = () => {
  const {signOut, isAdmin, user} = useAuth();
  const {company} = user;

  const fetchClientSecret = async () => {
    if (!company) return '';

    const {data} = await StripeHTTP.createCheckoutSession({
      company_id: company._id,
      customer: company.stripe_customer,
      customer_email: company.email || '',
      // plan: 'plan_EisaKRhXepElrO',
      plan: company.plan_id?.stripe_id || '',
    });
    return data.client_secret;
  };

  return (
    <Layout isAdmin={isAdmin} signOut={() => signOut()}>
      {!company ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col mb-6 px-4 lg:px-0">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Welcome back,{' '}
              <span className="text-yellow-600">{company.name}</span>
            </h2>
            <p>
              You see this page because the status of your account it's
              INACTIVE, for active your account you need add a new credit card.
            </p>
          </div>

          <div className="flex flex-col lg:flex-row">
            <ul className="max-w-sm w-full lg:mr-4 lg:w-2/5">
              <li className="gap-4 bg-white shadow rounded-lg mb-4 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 hidden lg:block">
                <div className="flex items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckBadgeIcon
                      className="h-6 w-6 text-yellow-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title">
                      {company.plan_id?.description}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Current Plan</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white shadow rounded-lg mb-4 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 hidden lg:block">
                <div className="flex items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CreditCardIcon
                      className="h-6 w-6 text-yellow-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title">
                      {currencyFormat(company.plan_id?.price?.$numberDecimal)}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Current Plan Value
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="bg-white shadow rounded-lg mb-4 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CalendarIcon
                      className="h-6 w-6 text-yellow-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title">
                      <FormatDate value={company.updated_at} />
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Inactived at</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div className="flex flex-col flex-grow">
              <div className="bg-white shadow rounded-lg p-4">
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{fetchClientSecret}}>
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default HomeStripe;
