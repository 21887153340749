import {forwardRef} from 'react';
import RSelect from 'react-select';

const SearchSelect = forwardRef(
  (
    {
      placeholder = 'Search',
      options = [],
      isClearable = true,
      isSearchable = true,
      error = false,
      label = '',
      className = '',
      id = '',
      ...rest
    },
    ref,
  ) => (
    <div className={`relative z-5 ${className}`}>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <RSelect
        id={id}
        ref={ref}
        placeholder={placeholder}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#fef2c7',
            primary: '#f59e0b',
          },
        })}
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: error ? 'red' : '#d1d5da',
            borderRadius: '0.375rem',
          }),
          container: (provided) => ({
            ...provided,
            fontSize: 14,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: 14,
          }),
          option: (provided) => ({
            ...provided,
            fontSize: 14,
            cursor: 'pointer',
          }),
          input: (provided) => ({
            ...provided,
            boxShadow: 'unset !important',
            '& input': {
              '--tw-ring-color': 'transparent !important',
            },
          }),
        }}
        {...rest}
      />
    </div>
  ),
);

export default SearchSelect;
