import {useState} from 'react';
import {useIntl} from 'react-intl';
import {useDashboard} from '../../hooks/useDashboard';

import PageHeader from '../../../../components/_layouts/PageHeader';
import Button from '../../../../components/Button';
import DateRangeModal from '../../../../components/_modals/DateRangeModal';

import PunchesInfoCard from './components/PunchesInfoCard';
import MonthlyExpenses from './components/MonthlyExpenses';
import NumbersCard from './components/NumbersCard';
import PunchesCard from './components/PunchesCard';
import JobLocation from './components/JobLocation';

import {Container} from './styles';

const Home = () => {
  const {period, setPeriod} = useDashboard();

  const {formatMessage} = useIntl();

  const [openDateRange, setOpenDateRange] = useState(false);

  const handleSelect = (values) => {
    setPeriod(values);
    setOpenDateRange(false);
  };

  return (
    <>
      <PageHeader
        title="Dashboard"
        breadcrumbs={[
          {
            id: 'dashboard',
            label: 'Dashboard',
          },
        ]}
      />
      <div className="flex px-4">
        <PunchesInfoCard />
      </div>
      <Container editing={false}>
        <div className="flex items-center justify-between m-2 p-2 bg-white rounded-lg border border-yellow-300">
          <h4>{formatMessage({id: 'dashboard.title'})}</h4>
          <div className="relative">
            <div className="flex items-center">
              <p className="px-2 text-sm font-semibold">
                {period.startDate} - {period.endDate}
              </p>
              <Button onClick={() => setOpenDateRange(true)}>
                {formatMessage({id: 'dashboard.select-period'})}
              </Button>
            </div>
          </div>
          <DateRangeModal
            open={openDateRange}
            onClose={() => setOpenDateRange(false)}
            onSelect={(val) => handleSelect(val)}
          />
        </div>

        <div className="card" key="numbers-card">
          <div className="card" key="numbers-card">
            <NumbersCard />
          </div>
          <div className="flex gap-6">
            <div
              className="card w-2/3"
              key="monthly-chart"
              style={{height: 500}}>
              <MonthlyExpenses />
            </div>
            <div
              className="card w-1/3"
              key="punches-card"
              style={{height: 500}}>
              <PunchesCard />
            </div>
          </div>
          <div className="card" key="job-location">
            <JobLocation />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
