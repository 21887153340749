import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';

import {useEmployee} from '../../../company/hooks/useEmployee';

import EmployeeForm from './components/Form';

const CreateEmployee = ({nextStep = () => {}}) => {
  const {createEmployee} = useEmployee();

  const [createLoading, setCreateLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleCreate = useCallback(async (payload) => {
    try {
      setCreateLoading(true);
      await createEmployee(payload);
      toast.success('Employee created successfully!');
      localStorage.setItem('@TrackMyGuys:flow', 'settings');
      nextStep();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.field) {
        setErrors(err.response.data);
      }
    } finally {
      setCreateLoading(false);
    }
  }, []);

  return (
    <div>
      <h1 className="text-4xl font-extralight text-center">
        Creating your <span className="font-bold">first employee</span>
      </h1>
      <p className="mt-2 text-sm font-normal text-gray-700 text-center">
        Now is the time to create your first employee, provide a name, a valid
        phone number, username and password for his access to the app. To help
        you, we will send an text message to the phone number informed with the
        username and link to download the app, but the password for security
        reasons, you must inform it securely.
      </p>

      <EmployeeForm
        loading={createLoading}
        onSubmit={handleCreate}
        submitErrors={errors}
      />
    </div>
  );
};

export default CreateEmployee;
