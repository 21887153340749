import Header from '../Header';
import DownloadApp from '../../DownloadApp';

const DefaultLayout = ({children}) => (
  <>
    <Header />
    <main className="mx-auto px-0 pb-6 md:px-6 lg:px-8 lg:pt-6 lg:pb-14 overflow-x-hidden max-w-full min-h-screen">
      <DownloadApp />
      {children}
    </main>
    <footer className="flex items-center p-6 lg:mt-12 bg-gray-200 text-gray-700">
      <p>© 2024 WorkPodium by TrackMyGuys. All Rights Reserved.</p>
    </footer>
  </>
);

export default DefaultLayout;
