import {useContextSelector} from 'use-context-selector';
import {CompaniesContext} from '../context/CompaniesContext';

export function useCompanies() {
  const companies = useContextSelector(
    CompaniesContext,
    (data) => data.companies,
  );
  const company = useContextSelector(CompaniesContext, (data) => data.company);

  const getCompanies = useContextSelector(
    CompaniesContext,
    (data) => data.getCompanies,
  );

  const getCompany = useContextSelector(
    CompaniesContext,
    (data) => data.getCompany,
  );

  const updateUser = useContextSelector(
    CompaniesContext,
    (data) => data.updateUser,
  );

  const removeCompanyFromList = useContextSelector(
    CompaniesContext,
    (data) => data.removeCompanyFromList,
  );

  return {
    companies,
    company,
    getCompanies,
    getCompany,
    updateUser,
    removeCompanyFromList,
  };
}
