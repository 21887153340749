import api from '../../../services/api';

export const ExtrasHTTP = {
  // employees receipts
  getEmployeesReceipts: (params = {}) => api.get('/receipts', {params}),
  updateEmployeesReceipts: (id = '', payload = {}) =>
    api.put(`/receipts/${id}`, payload),
  deleteEmployeesReceipts: (id = '') => api.delete(`/receipts/${id}`),
  // receipts categories
  getCategories: () => api.get(`/receipt-categories`),
  createCategory: (payload = {}) => api.post('/receipt-categories', payload),
  updateCategory: (id = '', payload) =>
    api.put(`/receipt-categories/${id}`, payload),
  // extra bills
  getExtraBills: (params = {}) => api.get('/expenses', {params}),
  createExtraBills: (payload = {}) => api.post('/expenses', payload),
  updateExtraBills: (id = '', payload = {}) =>
    api.put(`/expenses/${id}`, payload),
  deleteExtraBills: (id = '') => api.delete(`/expenses/${id}`),
  // extra man hours
  getManHours: (params = {}) => api.get('/extra-employees', {params}),
  createManHours: (payload = {}) => api.post('/extra-employees', payload),
  updateManHours: (id = '', payload = {}) =>
    api.put(`/extra-employees/${id}`, payload),
  deleteManHours: (id = '') => api.delete(`/extra-employees/${id}`),
};
