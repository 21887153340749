import logo from '../../../../assets/images/logos-inline.png';

const Layout = ({isAdmin = false, signOut = () => {}, children}) => {
  if (!isAdmin) {
    return (
      <>
        <nav className="bg-gray-800">
          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 md:h-12"
                    src={logo}
                    alt="Work Podium - Track My Guys"
                  />
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => signOut()}
                  type="button"
                  className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
        <main className="mx-auto py-6 px-0 md:px-6 lg:px-8 overflow-x-hidden max-w-full">
          {children}
        </main>
      </>
    );
  }

  return children;
};

export default Layout;
