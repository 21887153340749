import {Switch} from '@headlessui/react';

const CustomSwitch = ({
  label = '',
  checked = false,
  onChange = () => {},
  extraClassName = '',
}) => (
  <Switch.Group>
    <div className={`flex items-center ${extraClassName}`}>
      {label && (
        <Switch.Label className="mr-2 text-sm font-medium text-gray-700">
          {label}
        </Switch.Label>
      )}
      <Switch
        checked={checked}
        onChange={(val) => {
          onChange(val);
        }}
        className={`${
          checked ? 'bg-yellow-500' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400`}>
        <span
          className={`${
            checked ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
      </Switch>
    </div>
  </Switch.Group>
);

export default CustomSwitch;
