import {useState, useEffect, useMemo, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {ReportsHTTP} from '../../../providers/reports';
import {useJobLocation} from '../../../hooks/useJobLocation';
import {useAuth} from '../../../../auth/hooks/useAuth';

import {currencyFormat} from '../../../../../utils/currency';

import Table from '../../../../../components/Table';
import Button from '../../../../../components/Button';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import SearchSelect from '../../../../../components/_form/SearchSelect';
import EmptyData from '../../../../../components/EmptyData';

import MenHours from './components/MenHours';
import {useQuery} from '../../../../../hooks/useQuery';

const reportInitialState = {
  selectedJobLocation: '',
  selectedPeriod: {start: '', end: ''},
  data: {
    list: [],
    globalAmount: 0,
    menHoursAmount: 0,
    receiptsBossTotalAmount: 0,
    receiptsEmployeesTotalAmount: 0,
    receiptsTotalAmount: 0,
    totalWorked: 0,
  },
  byPeriodData: {
    job: {},
    data: {
      amounts: {},
      punches: [],
      extraMen: [],
      receipts: [],
      bossReceipts: [],
    },
  },
  loading: false,
};

const Report = ({activeTab, columns, report, onRowClick}) => {
  if (activeTab === 'summary') {
    return (
      <Table
        hasFooter
        scrollable
        columns={columns}
        data={report.data?.list.filter((item) => item.amount > 0)}
        onRowClick={onRowClick}
      />
    );
  }

  return <MenHours report={report.byPeriodData} />;
};

const JobLocationReports = () => {
  const {formatMessage} = useIntl();
  const location = useLocation();
  const queryParams = useQuery(location.search);
  const {payPeriod} = useAuth();
  const {jobs, getJobs} = useJobLocation();

  const [activeTab, setActiveTab] = useState('summary');
  const [report, setReport] = useState(reportInitialState);
  // const [searchDefaultValue, setSearchDefaultValue] = useState(null);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const tabItems = [
    {
      value: 'summary',
      label: formatMessage({id: 'summary'}),
    },
    {
      value: 'by-period',
      label: formatMessage({id: 'by-period'}),
    },
  ];

  const generatePDF = async (jobLocationId) => {
    if (loadingPDF) return;

    setLoadingPDF(true);
    await ReportsHTTP.downloadJobLocationSummaryPDF(jobLocationId);
    setLoadingPDF(false);
  };

  const fetchByPeriod = async () => {
    setReport((prev) => ({
      ...prev,
      loading: true,
    }));
    const {data} = await ReportsHTTP.getJobLocationByPeriod(
      report.selectedJobLocation,
      {
        start: report.selectedPeriod.start.replaceAll('/', '-'),
        end: report.selectedPeriod.end.replaceAll('/', '-'),
      },
    );
    setReport((prev) => ({
      ...prev,
      byPeriodData: {
        job: jobs.data.find((job) => job._id === report.selectedJobLocation),
        data,
      },
      loading: false,
    }));
  };

  const fetchSummary = async () => {
    setReport((prev) => ({
      ...prev,
      loading: true,
    }));
    const {data} = await ReportsHTTP.getJobLocationSummary(
      report.selectedJobLocation,
    );
    setReport((prev) => ({
      ...prev,
      data: data.data,
      loading: false,
    }));
  };

  const handleSelectPeriod = (period) => {
    const periodSplit = period.split(' - ');
    const selectedPeriod = {
      start: periodSplit[0],
      end: periodSplit[1],
    };
    setReport((prev) => ({
      ...prev,
      selectedPeriod,
    }));
    setTimeout(() => setActiveTab('by-period'), 350);
  };

  const handleRowCLick = (row) => {
    handleSelectPeriod(row.period);
  };

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'period'})}`,
        Footer: 'TOTAL',
        accessor: 'period',
      },
      {
        Header: `${formatMessage({id: 'total-men-hours'})}`,
        Footer: report.data.totalWorked ?? '0:00',
        accessor: 'totalWorked',
      },

      {
        Header: `${formatMessage({id: 'gross-pay'})}`,
        Footer: currencyFormat(report.data.menHoursAmount) ?? '$0.00',
        accessor: ({grossPay}) => currencyFormat(grossPay) ?? '$0.00',
      },

      {
        Header: `${formatMessage({id: 'receipts-added-employees'})}`,
        Footer:
          currencyFormat(report.data.receiptsEmployeesTotalAmount) ?? '$0.00',
        accessor: ({receiptsEmployeesAmount}) =>
          currencyFormat(receiptsEmployeesAmount) ?? '$0.00',
      },

      {
        Header: `${formatMessage({id: 'receipts-added-boss'})}`,
        Footer: currencyFormat(report.data.receiptsBossTotalAmount) ?? '$0.00',
        accessor: ({receiptsBossAmount}) =>
          currencyFormat(receiptsBossAmount) ?? '$0.00',
      },
      {
        Header: `${formatMessage({id: 'receipts-amount'})}`,
        Footer: currencyFormat(report.data.receiptsTotalAmount) ?? '$0.00',
        accessor: ({receiptsAmount}) =>
          currencyFormat(receiptsAmount) ?? '$0.00',
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        Footer: currencyFormat(report.data.globalAmount) ?? '$0.00',
        accessor: ({amount}) => (amount ? currencyFormat(amount) : '$0.00'),
      },
    ],
    [report.data, formatMessage],
  );

  useEffect(() => {
    if (activeTab === 'summary' && report.selectedJobLocation) {
      fetchSummary();
    } else if (
      report.selectedJobLocation &&
      report.selectedPeriod &&
      report.selectedPeriod.start &&
      report.selectedPeriod.end
    ) {
      fetchByPeriod();
    } else {
      // setReport(reportInitialState);
    }
  }, [report.selectedJobLocation, report.selectedPeriod, activeTab]);

  useEffect(() => {
    getJobs();
  }, []);

  useLayoutEffect(() => {
    if (queryParams.get('id')) {
      setReport((prev) => ({
        ...prev,
        selectedJobLocation: queryParams.get('id'),
      }));
    }
  }, []);

  // pego o estado que tem todos os jobs -> jobs.data
  // faço um find atraves do ID que veio na url
  const findSelectJobData = jobs.data.find(
    (job) => job._id === queryParams.get('id'),
  );
  // se o find encontrar algum job
  // jogo os dados desse job em um estado
  // no formato de um OPTION do select {label: '', value: ''}
  const searchDefaultValue = {
    value: findSelectJobData?._id,
    label: findSelectJobData?.name,
  };
  // EXTRAS
  // o SearchSelect é um componente que utiliza essa lib: https://react-select.com/props#statemanager-props
  // ela ja tem prop para valores iniciais "defaultValue"
  // o fluxo anterior pega os dados do job que veio atraves do ID na url
  // joga em um estado e passa para o componente atraves do defaultValue - linha 273

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'job-location'})}
        breadcrumbs={[
          {
            id: 'reports',
            label: formatMessage({id: 'menu.reports'}),
          },
          {
            id: 'joblocation-summary',
            label: formatMessage({id: 'job-location-summary'}),
          },
        ]}
      />
      <SectionWrapper
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        isLoading={report.loading}
        toolbar={
          <div
            className={`w-full flex ${
              activeTab === 'summary' ? 'justify-between' : ''
            }`}>
            <div style={{width: 350}}>
              {!jobs.loading && (
                <SearchSelect
                  placeholder={formatMessage({
                    id: 'timecard.select-job-location',
                  })}
                  options={jobs.data.map((j) => ({
                    value: j._id,
                    label: j.name,
                  }))}
                  defaultValue={[searchDefaultValue]}
                  onChange={(selected) =>
                    setReport((prev) => ({
                      ...prev,
                      selectedJobLocation: selected ? selected.value : '',
                    }))
                  }
                />
              )}
            </div>

            {activeTab === 'by-period' && (
              <div className="ml-5" style={{width: 350}}>
                <SearchSelect
                  placeholder={formatMessage({id: 'dashboard.select-period'})}
                  options={payPeriod}
                  defaultValue={{
                    value: `${report.selectedPeriod?.start}@${report.selectedPeriod?.end}`,
                    label: `${report.selectedPeriod?.start} - ${report.selectedPeriod?.end}`,
                    period: report.selectedPeriod,
                  }}
                  onChange={(selected) =>
                    setReport((prev) => ({
                      ...prev,
                      selectedPeriod: selected
                        ? {
                            start: selected.value.split('@')[0],
                            end: selected.value.split('@')[1],
                          }
                        : {
                            start: '',
                            end: '',
                          },
                    }))
                  }
                />
              </div>
            )}

            {activeTab === 'summary' &&
              report.selectedJobLocation &&
              !report.loading && (
                <Button
                  extraClassName="shadow-sm ml-5"
                  onClick={() => generatePDF(report.selectedJobLocation)}>
                  <>
                    <ArrowDownTrayIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    {loadingPDF
                      ? formatMessage({id: 'downloading'})
                      : formatMessage({id: 'download-pdf'})}
                  </>
                </Button>
              )}
          </div>
        }>
        {!report.selectedJobLocation ? (
          <EmptyData
            title={formatMessage({id: 'timecard.select-job-location'})}
            description={formatMessage({
              id: 'report.select-job-location-summary',
            })}
          />
        ) : (
          <Report
            activeTab={activeTab}
            columns={columns}
            report={report}
            onRowClick={handleRowCLick}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default JobLocationReports;
