import {useLayoutEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {
  ShareIcon,
  EyeIcon,
  DocumentCheckIcon,
  TrashIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';

import {CustomersHTTP} from '../../../../../providers/customers';
import {InvoicesHTTP} from '../../../../../providers/invoices';

import SectionWrapper from '../../../../../../../components/SectionWrapper';
import PageHeader from '../../../../../../../components/_layouts/PageHeader';
import ConfirmationModal from '../../../../../../../components/_modals/ConfirmationModal';
import Modal from '../../../../../../../components/_modals/Modal';

import Form from './Form';
import ScopeOfWork from './ScopeOfWork';

const confirmationModalSettings = {
  convertToInvoice: {
    title: 'bookpodium.estimates.convertToInvoice.title',
    description: 'bookpodium.estimates.convertToInvoice.description',
  },
  delete: {
    title: 'bookpodium.estimates.delete.title',
    description: 'bookpodium.estimates.delete.description',
  },
};

const EditPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const idCripty = window.btoa(id);
  const {formatMessage} = useIntl();

  const [activeTab, setActiveTab] = useState('scope-of-work');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [state, setState] = useState({
    customers: {
      loading: true,
      data: [],
      meta: {},
    },
    estimate: {
      loading: true,
      data: {},
    },
    estimateConfirmation: {
      open: false,
      type: 'convertToInvoice',
    },
    confirmation: {
      open: false,
      type: 'trade',
      id: null,
    },
    discount: {
      edit: false,
      value: 0,
    },
    trade: null,
    invoiceTradeItem: null,
    openAddTradeModal: false,
    openCreateItem: false,
    editTax: false,
  });

  const dispatchState = (newState) => {
    if (!newState) return;
    setState((prev) => ({...prev, ...newState}));
  };

  const fetchCustomers = async () => {
    dispatchState({
      customers: {
        loading: true,
        data: [],
        meta: {},
      },
    });

    const {data} = await CustomersHTTP.get({perPage: 150});

    dispatchState({
      customers: {
        loading: false,
        data: data.data.map((c) => ({label: c.name, value: c.id})),
        meta: data.meta,
      },
    });
  };

  const fetchEstimate = async (tradeSelected = null, noLoading = true) => {
    if (!noLoading) {
      dispatchState({
        estimate: {
          loading: true,
          data: {},
        },
      });
    }

    const {data} = await InvoicesHTTP.getById(id);

    dispatchState({
      estimate: {
        loading: false,
        data,
      },
      trade: tradeSelected
        ? data.trades.find((t) => t.id === tradeSelected.id)
        : null,
      openAddTradeModal: false,
      discount: {
        edit: false,
        value: data.discount,
        loading: false,
      },
      tax: {
        edit: false,
        value: data.tax,
        loading: false,
      },
    });
  };

  const handleCloseConfirmationModal = () => {
    dispatchState({
      estimateConfirmation: {
        open: false,
        type: 'convertToInvoice',
      },
    });
  };

  const handleConfirmActionModal = async () => {
    if (state.estimateConfirmation.type === 'convertToInvoice') {
      const payload = {
        ...state.estimate.data,
        isEstimate: false,
      };
      await InvoicesHTTP.update(id, payload);
      navigate(`/book-podium/invoices/${id}`);
    } else {
      await InvoicesHTTP.delete(id);
      // redirect to estimates list
      navigate(`/book-podium/estimates`);
    }
  };

  useLayoutEffect(() => {
    fetchCustomers();
    fetchEstimate(null, false);
  }, []);

  const tabItems = [
    {
      value: 'scope-of-work',
      label: formatMessage({id: 'bookpodium.scope-of-work'}),
    },
    {
      value: 'estimate-info',
      label: formatMessage({id: 'bookpodium.estimates.edit-info'}),
    },
  ];

  return (
    <>
      <PageHeader
        title={
          state.estimate.loading
            ? 'loading...'
            : `${formatMessage({id: 'bookpodium.estimate'})} #EST00${
                state.estimate.data.code
              }`
        }
        breadcrumbs={[
          {
            id: 'bookpodium',
            label: formatMessage({id: 'menu.bookpodium'}),
          },
          {
            id: 'bookpodium-estimates',
            label: formatMessage({id: 'menu.bookpodium.estimates'}),
          },
        ]}
        rightContent={
          <div className="flex">
            <button
              type="button"
              onClick={() => setOpenShareModal(true)}
              className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-700 border-r-0 rounded-l-md hover:bg-yellow-500 hover:text-white">
              <ShareIcon className="w-4 h-4" /> Share
            </button>
            <button
              type="button"
              onClick={() =>
                window.open(
                  `https://app.workpodium.com/preview/pdf/${idCripty}`,
                  '_blank',
                )
              }
              className="flex items-center gap-2 px-4 py-2 bg-white border border-r-0 border-gray-700 hover:bg-yellow-500 hover:text-white">
              <EyeIcon className="w-4 h-4" /> Preview
            </button>
            <button
              type="button"
              onClick={() =>
                dispatchState({
                  estimateConfirmation: {
                    open: true,
                    type: 'convertToInvoice',
                  },
                })
              }
              className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-700 hover:bg-yellow-500 hover:text-white">
              <DocumentCheckIcon className="w-4 h-4" /> Convert to invoice
            </button>
            <button
              type="button"
              onClick={() =>
                dispatchState({
                  estimateConfirmation: {
                    open: true,
                    type: 'delete',
                  },
                })
              }
              className="flex items-center gap-2 px-4 py-2 bg-red-600 border border-gray-700 border-l-0 rounded-r-md text-white hover:bg-red-700">
              <TrashIcon className="w-4 h-4 text-white" /> Delete
            </button>
          </div>
        }
      />

      <SectionWrapper
        showRoundedEnd={false}
        containerClassName="pt-4"
        isLoading={state.estimate.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}>
        <>
          {activeTab === 'scope-of-work' && (
            <ScopeOfWork
              state={state}
              dispatchState={dispatchState}
              id={id}
              fetchEstimate={fetchEstimate}
            />
          )}
          {activeTab === 'estimate-info' && (
            <Form
              state={state}
              dispatchState={dispatchState}
              id={id}
              fetchEstimate={fetchEstimate}
            />
          )}
        </>
      </SectionWrapper>

      <ConfirmationModal
        open={state.estimateConfirmation.open}
        title={formatMessage({
          id: confirmationModalSettings[state.estimateConfirmation.type].title,
        })}
        description={formatMessage({
          id: confirmationModalSettings[state.estimateConfirmation.type]
            .description,
        })}
        onClose={() => handleCloseConfirmationModal()}
        onSubmit={() => handleConfirmActionModal()}
      />

      <Modal
        maxWidth="max-w-2xl"
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        title="Copy link to share">
        <div className="flex p-4">
          <div className="relative flex items-center justify-between border rounded-md w-full">
            <span className="p-2 text-xs w-11/12 break-words">
              https://app.workpodium.com/preview/pdf/{idCripty}
            </span>
            <button
              type="button"
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://app.workpodium.com/preview/pdf/${idCripty}`,
                )
              }
              className="absolute flex items-center bg-yellow-500 p-2 border-l top-0 right-0 bottom-0">
              <ClipboardDocumentIcon className="w-4 h-4 text-white" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPage;
