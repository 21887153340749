import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {PunchHTTP} from '../../../../providers/punch';

import Modal from '../../../../../../components/_modals/Modal';
import TextArea from '../../../../../../components/_form/TextArea';
import Button from '../../../../../../components/Button';

const BossCommentModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  punchId = '',
  bossComment = '',
}) => {
  const {formatMessage} = useIntl();
  const {control, handleSubmit, reset} = useForm({
    defaultValues: {
      boss_comment: bossComment,
    },
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      await PunchHTTP.updateBossComment(punchId, values);
      toast.success(formatMessage({id: 'toast.commend-success'}));
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({boss_comment: bossComment});
  }, [bossComment]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={250}
      open={open}
      onClose={onClose}
      title={formatMessage({id: 'boss-comment'})}>
      <form
        className="flex flex-col justify-between"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="form-content">
          <Controller
            name="boss_comment"
            control={control}
            render={({field}) => (
              <TextArea
                {...field}
                label={formatMessage({id: 'comment'})}
                id="boss_comment"
                style={{minHeight: 120}}
              />
            )}
          />
        </div>

        <div className="form-footer">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onClose()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BossCommentModal;
