import {useEffect, useState} from 'react';

import {CompaniesHTTP} from '../../../../providers/companies';

import Subscription from './components/Subscription';
import Loading from '../../../../../../components/Loading';

const PlanAndSubscription = () => {
  const [company, setCompany] = useState({loading: true, data: {}});

  const getCompany = async () => {
    if (!company.loading) setCompany({loading: true, data: {}});
    const {data} = await CompaniesHTTP.myCompany();
    setCompany({loading: false, data});
  };

  useEffect(() => {
    getCompany();
  }, []);

  if (company.loading)
    return (
      <div className="m-4">
        <Loading />
      </div>
    );

  return (
    <Subscription company={company} onRefetchCompany={() => getCompany()} />
  );
};

export default PlanAndSubscription;
