import {useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import Modal from '../Modal';
import Button from '../../Button';

const ConfirmationModal = ({
  open = false,
  onClose = () => {},
  onSubmit = () => new Promise(),
  modalTitle = '',
  title,
  description,
  successMsg,
  errorMsg,
}) => {
  const {formatMessage} = useIntl();
  const [loading, setLoading] = useState(false);

  const defaultTitle = formatMessage({id: 'settings.pay-modal-conf-title'});
  const defaultDescription = formatMessage({
    id: 'settings.pay-modal-conf-desc',
  });
  const defaultSuccessMsg = formatMessage({
    id: 'settings.pay-modal-conf-success',
  });
  const defaultErrorMsg = formatMessage({id: 'settings.pay-modal-conf-error'});

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit();
      toast.success(successMsg || defaultSuccessMsg);
    } catch {
      toast.error(errorMsg || defaultErrorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maxWidth="max-w-lg"
      minHeight={195}
      open={open}
      onClose={onClose}
      title={modalTitle}>
      <div>
        <p className="text-medium font-normal text-gray-600 px-4 pb-1">
          {title || defaultTitle}
        </p>
        <p className="text-sm font-bold text-gray-600 px-4 pb-6">
          {description || defaultDescription}
        </p>
        <div className="form-footer">
          <Button type="button" onClick={handleSubmit} loading={loading}>
            {formatMessage({id: 'confirm'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={onClose}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
