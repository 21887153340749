import {useEffect, useState, useMemo} from 'react';
import {PencilSquareIcon, TrashIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import debounce from 'lodash/debounce';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {useCompanies} from '../../../hooks/useCompanies';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Search from '../../../../../components/Search';
import Pagination from '../../../../../components/Pagination';
import DropdownButton from '../../../../../components/DropdownButton';
import Button from '../../../../../components/Button';

import DeleteModal from './components/DeleteModal';
import CompanyFormModal from './components/CompanyFormModal';

const managerCompanyInitialState = {
  action: '',
  openModal: false,
  id: '',
  name: '',
};

const SalesCompaniesPage = () => {
  const {companies, getCompanies, removeCompanyFromList} = useCompanies();
  const {setCurrentCompany} = useAuth();

  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [managerCompany, setManager] = useState(managerCompanyInitialState);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleCreateCompanyWithSuccess = () => {
    getCompanies({
      page: 1,
      name: '',
      status: '',
      sales: true,
    });
    setOpenCreateModal(false);
  };

  useEffect(() => {
    getCompanies({
      page: currentPage,
      name: search,
      sales: true,
      status:
        activeTab === 'all' || activeTab === 'trial' || activeTab === 'closed'
          ? ''
          : activeTab,
      // eslint-disable-next-line no-nested-ternary
      ...(activeTab === 'trial'
        ? {trial: true}
        : activeTab === 'active'
        ? {trial: false}
        : {}),
      ...(activeTab === 'closed' ? {closed: true, status: 'inactive'} : {}),
    });
  }, [currentPage, search, activeTab]);

  const searchDebounce = debounce(setSearch, 500);

  const handleSelectCompany = async (companyId, companyName) => {
    await setCurrentCompany(companyId, companyName);
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (window.location.href = '/sales/companies'), 600);
  };

  const handleUpdateList = async (id) => {
    await removeCompanyFromList(id);
    setManager(managerCompanyInitialState);
  };

  const companyOptionsMenu = (row) => {
    const menu = [];

    menu.push(
      {
        id: 'edit-company',
        label: 'Edit company',
        icon: PencilSquareIcon,
        onClick: () => {},
      },
      {
        id: 'delete-company',
        label: 'Delete Company',
        icon: TrashIcon,
        onClick: () =>
          setManager({
            action: 'delete',
            openModal: true,
            id: row.original._id,
            name: row.original.name,
          }),
      },
    );

    return menu;
  };

  const tabItems = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex flex-col">
            {row.original.name}
            <Badge variant="info" extraClassName="my-2" style={{maxWidth: 220}}>
              ID: {row.original._id}
            </Badge>
            {row.original.uuid && (
              <Badge
                variant="info"
                extraClassName="mb-2"
                style={{maxWidth: 195}}>
                SUPPORT ID: {row.original.uuid ?? ''}
              </Badge>
            )}
          </div>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => (
          <Badge
            variant={row.original.status === 'inactive' ? 'error' : 'success'}
            style={{maxWidth: 165}}>
            {row.original.status}
          </Badge>
        ),
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.created_at), 'MM/dd/yyyy'),
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Button
              onClick={() =>
                handleSelectCompany(row.original._id, row.original.name)
              }
              extraClassName="mr-2">
              Manager
            </Button>
            <DropdownButton
              label="Edit"
              variant="secondary"
              options={companyOptionsMenu(row)}
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <PageHeader
        title="Companies"
        breadcrumbs={[
          {
            id: 'companies',
            label: 'Companies',
          },
        ]}
        rightContent={
          <Button
            onClick={() => setOpenCreateModal(true)}
            extraClassName="mr-2">
            New company
          </Button>
        }
      />

      <SectionWrapper
        isLoading={companies.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={companies.data} />

        <Pagination
          totalPages={companies.pagination.totalPages}
          currentPage={currentPage}
          totalRecords={companies.pagination.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      <CompanyFormModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onSucess={() => handleCreateCompanyWithSuccess()}
      />

      <DeleteModal
        open={managerCompany.openModal}
        onClose={() => setManager(managerCompanyInitialState)}
        onSuccess={() => handleUpdateList(managerCompany.id)}
        companyId={managerCompany.id}
        companyName={managerCompany.name}
      />
    </>
  );
};

export default SalesCompaniesPage;
