/* eslint-disable react/no-unescaped-entities */
import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';

import {useAuth} from '../../../auth/hooks/useAuth';
import {JobLocationHTTP} from '../../../company/providers/jobs';
import {isValidDate, clearCurrencyValue} from '../../../../utils/utils';

import Input from '../../../../components/_form/Input';
import MaskedInput from '../../../../components/_form/MaskedInput';
import CurrencyInput from '../../../../components/_form/MaskedInput/Currency';
import Button from '../../../../components/Button';
import AutocompleteMaps from '../../../../components/AutocompleteMaps';

import {WomanDeviceVector} from '../../../../components/_marketing/WomanDeviceVector';

const JobLocation = ({nextStep}) => {
  const {currentCompany, user} = useAuth();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      if (Object.keys(selectedPlace).length <= 1) {
        toast.error('Provider a location!');
        return;
      }

      if (!isValidDate(new Date(data.started_at))) {
        toast.error('Provider a valid start date!');
        return;
      }

      await JobLocationHTTP.create({
        ...data,
        location: selectedPlace,
        budget: clearCurrencyValue(data.budget),
        started_at: new Date(data.started_at).toDateString(),
        company_id: currentCompany || user.company_id,
        status: 'active',
      });

      localStorage.setItem('@TrackMyGuys:flow', 'done');
      nextStep();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center px-8 py-4 max-w-4xl">
        <div
          className="px-8 py-4 hidden lg:block"
          style={{minWidth: 260, maxWidth: 300}}>
          <WomanDeviceVector />
        </div>
        <div>
          <h1 className="text-4xl font-extralight">
            The best way to organize{' '}
            <span className="font-bold">your expenses</span>.
          </h1>
          <p className="mt-2 text-sm font-normal text-gray-700">
            the job location module helps you to organize your employees'
            punches and their expenses in a specific place of work. Through our
            AI, all checkin and checkout that are within the informed radius
            will be sync with the job location.
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="col-span-2 p-4">
          <p className="text-sm text-gray-800 py-4">
            Enter the address of your job location, remember to adjust the
            radius.
          </p>

          <AutocompleteMaps
            mapHeight={400}
            onChange={(address) => setSelectedPlace(address)}
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              <Input
                label="Job Name *"
                id="name"
                type="text"
                error={errors.name}
                {...register('name', {required: true})}
              />

              <Controller
                rules={{
                  required: true,
                }}
                name="budget"
                control={control}
                render={({field}) => (
                  <CurrencyInput
                    {...field}
                    label="Initial Budget *"
                    error={errors.budget}
                  />
                )}
              />
              <Controller
                rules={{
                  required: true,
                }}
                name="started_at"
                control={control}
                render={({field}) => (
                  <MaskedInput
                    {...field}
                    removeMask={false}
                    mask="99/99/9999"
                    maskPlaceholder="MM/DD/YYYY"
                    label="Started at *"
                    error={errors.started_at}
                    alwaysShowMask={false}
                  />
                )}
              />
            </div>
          </div>

          <div className="px-4 py-3">
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default JobLocation;
