import {CheckIcon} from '@heroicons/react/24/solid';

import logo from '../../../../assets/images/logos-inline.png';

const handleStep = (currentStep, step) => {
  if (currentStep === step) {
    return 'bg-white border-white text-yellow-800';
  }

  if (step < currentStep) {
    return 'border-yellow-800 text-yellow-500 bg-yellow-800';
  }

  return 'border-white text-white';
};

const Steps = ({steps = [], activeStep = 0}) => (
  <div className="relative z-20 flex flex-col items-center justify-center h-full p-8">
    <img className="mx-auto h-12 w-auto" src={logo} alt="WorkPodium - Logo" />
    <ul className="flex flex-col w-2/3 mt-8">
      {steps.map((s, index) => (
        <li key={s.id} className="flex items-center w-full mb-6">
          <span
            style={{minWidth: 48, minHeight: 48}}
            className={`flex items-center justify-center w-12 h-12 rounded-full border font-semibold text-lg ${handleStep(
              activeStep,
              index,
            )}`}>
            {index < activeStep ? (
              <CheckIcon
                className="h-6 w-6 text-yellow-500"
                aria-hidden="true"
              />
            ) : (
              s.id
            )}
          </span>
          <p className="ml-4 text-white text-left">{s.title}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default Steps;
