import {AuthProvider} from '../modules/auth/context/AuthContext';
import {TranslationProvider} from '../modules/translation/context/TranslationContext';

import {AdminProvider} from '../modules/admin/context';

import {EmployeeProvider} from '../modules/company/context/EmployeeContext';
import {JobLocationProvider} from '../modules/company/context/JobLocationContext';

function AppProviders({children}) {
  return (
    <AuthProvider>
      <TranslationProvider>
        <AdminProvider>
          <EmployeeProvider>
            <JobLocationProvider>{children}</JobLocationProvider>
          </EmployeeProvider>
        </AdminProvider>
      </TranslationProvider>
    </AuthProvider>
  );
}

export default AppProviders;
