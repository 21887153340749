import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Calendar} from 'react-date-range';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {ExtrasHTTP} from '../../../../../providers/extras';
import {useJobLocation} from '../../../../../hooks/useJobLocation';

import TextArea from '../../../../../../../components/_form/TextArea';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import Modal from '../../../../../../../components/_modals/Modal';
import DragAndDropUpload from '../../../../../../../components/DragAndDropUpload';
import CurrencyInput from '../../../../../../../components/_form/MaskedInput/Currency';
import {formatDateTZ, isValidDate} from '../../../../../../../utils/date';

const FormEmployeesReceipts = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  id = null,
  initialData = {},
}) => {
  const {formatMessage} = useIntl();
  const {getJobs, jobs} = useJobLocation();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: {
      ...initialData,
      job: initialData.work_id?._id,
      category: initialData.category_id,
    },
  });

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [minDate, setMinDate] = useState(
    initialData && initialData.work_id
      ? new Date(initialData.work_id?.started_at)
      : new Date(),
  );
  const [maxDate, setMaxDate] = useState(new Date());
  const [date, setDate] = useState(
    initialData && initialData.send_in ? initialData.send_in : minDate,
  );
  const [periodMsg, setPeriodMsg] = useState(
    formatMessage({id: 'extras.select-job-location-first'}),
  );
  const selectedJobId = watch(
    'job',
    initialData ? initialData.work_id?._id : null,
  );

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      let period = new Date(
        `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`,
      );
      if (!period || !isValidDate(period)) {
        period = `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`;
      }
      const payload = {
        amount: values.amount,
        category_id: values.category,
        work_id: values.job,
        description: values.description,
        file: values.file,
        send_in: period,
      };
      if (id && initialData) {
        await ExtrasHTTP.updateEmployeesReceipts(id, payload);
        toast.success(formatMessage({id: 'toast.updated-sucess'}));
      }

      reset();
      onSuccess();
    } catch {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const selectedJob = jobs.data.find((j) => j._id === selectedJobId);

    if (selectedJob) {
      setMinDate(new Date(selectedJob.started_at));
      if (selectedJob.status === 'done') {
        setMaxDate(new Date(selectedJob.done_at));
        setPeriodMsg(
          `This job location started on ${formatDateTZ(
            selectedJob.started_at,
            'MM/dd/yyyy',
          )} and ended on ${formatDateTZ(
            selectedJob.done_at,
            'MM/dd/yyyy',
          )}, so you can only select a date between this period.`,
        );
      } else {
        setPeriodMsg(
          `this job location started on ${formatDateTZ(
            selectedJob.started_at,
            'MM/dd/yyyy',
          )}, so you can only select a date after this period.`,
        );
      }
    }
  }, [selectedJobId]);

  useEffect(() => {
    register('file', {required: true});
  }, []);

  useEffect(() => {
    const getCategorias = async () => {
      const {data} = await ExtrasHTTP.getCategories();
      setCategories(data.map((c) => ({value: c._id, label: c.name})));
    };

    getCategorias();
    getJobs();
  }, []);

  useEffect(() => {
    if (initialData && id && !jobs.loading) {
      reset({
        ...initialData,
        job: initialData.work_id?._id,
        category: initialData.category_id,
      });
    }
  }, [initialData, id, jobs]);

  return (
    <Modal
      maxWidth="max-w-3xl"
      open={open}
      onClose={() => onClose()}
      title={
        initialData && id
          ? formatMessage({id: 'extra.edit-receipt'})
          : formatMessage({id: 'extra.new-receipt'})
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pt-1 pb-4 sm:p-6 sm:pb-4 sm:pt-1">
          <div className="grid grid-cols-1 gap-4">
            <DragAndDropUpload
              model="expenses"
              onUpload={(file) => setValue('file', file)}
              error={errors.file}
            />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div style={{width: 360}}>
              <p className="block text-sm font-medium text-gray-700">
                {formatMessage({id: 'date'})}
              </p>
              <div style={{marginTop: -10}}>
                <Calendar
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(item) => setDate(item)}
                  date={date}
                  showMonthAndYearPickers={false}
                />
              </div>
              <p className="block text-xs text-gray-600">{periodMsg}</p>
            </div>

            <div className="pl-6">
              <div className="mt-4">
                <Controller
                  name="job"
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      {...field}
                      label={formatMessage({id: 'job-location-required'})}
                      id="select-job"
                      defaultValue={initialData ? initialData.work_id?._id : ''}
                      options={jobs.data.map((j) => ({
                        label: j.name,
                        value: j._id,
                      }))}
                      error={errors.job}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Controller
                  name="category"
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      {...field}
                      label={formatMessage({id: 'category-required'})}
                      id="select-Category"
                      defaultValue={initialData ? initialData.category_id : ''}
                      options={categories}
                      error={errors.category}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="amount"
                  control={control}
                  render={({field}) => (
                    <CurrencyInput
                      {...field}
                      label={formatMessage({id: 'amount-required'})}
                      error={errors.amount}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <TextArea
              label={formatMessage({id: 'description'})}
              id="title"
              type="text"
              error={errors.description}
              {...register('description')}
            />
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            extraClassName="mr-2"
            onClick={() => onClose()}>
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default FormEmployeesReceipts;
