/* eslint-disable react/no-unescaped-entities */
import {useLocation} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import {StripeHTTP} from '../../../company/providers/stripe';
import {useQuery} from '../../../../hooks/useQuery';

// import Loading from '../../../../components/Loading';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {});

const HomeStripe = () => {
  const location = useLocation();
  const queryParams = useQuery(location.search);
  const tokenParam = queryParams.get('token');
  const planParam = queryParams.get('plan');

  const fetchClientSecret = async () => {
    if (!tokenParam) return '';

    const {data} = await StripeHTTP.createPublicCheckoutSession({
      company: tokenParam,
      plan: planParam,
    });
    return data.client_secret;
  };

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{fetchClientSecret}}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default HomeStripe;
