/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {Navigate, useLocation} from 'react-router-dom';

import {useAuth} from '../modules/auth/hooks/useAuth';

import DefaultLayout from '../components/_layouts/DefaultLayout';
import AuthLayout from '../components/_layouts/AuthLayout';

import ErrorScreen from '../components/ErrorScreen';

const getLayout = (
  hasUser,
  isNewAccount,
  isClosed,
  isAdmin,
  isFinalizarCadastro,
) => {
  const showNewAccount = !isAdmin && isNewAccount;
  if (isClosed && !isAdmin) return AuthLayout;

  if (hasUser && !showNewAccount && !isFinalizarCadastro) return DefaultLayout;

  return AuthLayout;
};

function RouteWrapper({children, isPrivate}) {
  const {user} = useAuth();
  const location = useLocation();

  const isPtBrLpFlow = localStorage.getItem('@TrackMyGuys:ptBrLp');
  const isNewAccount =
    localStorage.getItem('@TrackMyGuys:flow') && !isPtBrLpFlow;
  const isPdfPreview = location.pathname.includes('/preview/pdf');
  const isOnboardingRoute = location.pathname === '/onboarding';
  const isFinalizarCadastroRoute = location.pathname === '/finalizar-cadastro';
  const isReactivationRoute = location.pathname === '/reactivation';
  const isReactivatePublicRoute = location.pathname === '/reactivate';
  const isClosed = user && user.company && user.company.status === 'inactive';
  const isAdmin = user && user.role && user.role === 'admin';
  const isSales =
    user && user.role && ['sales', 'sales_assistant'].includes(user.role);
  const isAdminOrSales = isAdmin || isSales;

  if (isPdfPreview) {
    return children;
  }

  if (isPrivate && !user) {
    return <Navigate to="/" replace />;
  }

  if (isPtBrLpFlow && !isFinalizarCadastroRoute) {
    return <Navigate to="/finalizar-cadastro" replace />;
  }

  if (isNewAccount && !isOnboardingRoute) {
    return <Navigate to="/onboarding" replace />;
  }

  if (isClosed && !isReactivationRoute && !isAdminOrSales) {
    return <Navigate to="/reactivation" replace />;
  }

  if (user && !isPrivate && !isReactivatePublicRoute) {
    if (user.role === 'admin')
      return <Navigate to="/admin/dashboard" replace />;
    if (['sales', 'sales_assistant'].includes(user.role))
      return <Navigate to="/sales/companies" replace />;

    return <Navigate to="/dashboard" replace />;
  }

  if (location.pathname.includes('/admin/') && !isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  if (location.pathname.includes('/sales/') && !isAdminOrSales) {
    return <Navigate to="/dashboard" replace />;
  }

  // const Layout = user && !isNewAccount ? DefaultLayout : AuthLayout;
  const Layout = getLayout(
    Boolean(user),
    isNewAccount,
    isClosed,
    isAdminOrSales,
    isPtBrLpFlow,
  );

  return (
    <Layout>
      {process.env.NODE_ENV === 'production' ? (
        <Sentry.ErrorBoundary
          fallback={({error, _componentStack, resetError}) => (
            <ErrorScreen error={error} resetError={() => resetError()} />
          )}
          showDialog={false}>
          {children}
        </Sentry.ErrorBoundary>
      ) : (
        children
      )}
    </Layout>
  );
}

RouteWrapper.defaultProps = {
  isPrivate: false,
};

RouteWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isPrivate: PropTypes.bool,
};

export default RouteWrapper;
