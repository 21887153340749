import {useState} from 'react';
import {toast} from 'react-toastify';

import {CompaniesHTTP} from '../../../../../providers/companies';

import Modal from '../../../../../../../components/_modals/Modal';
import Button from '../../../../../../../components/Button';

const DeleteModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  companyId = '',
  companyName = '',
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await CompaniesHTTP.delete(companyId);

      toast.success('Company deleted successfully!');
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maxWidth="max-w-lg"
      minHeight={195}
      open={open}
      onClose={onClose}
      title="Delete a company">
      <div>
        <p className="text-medium font-normal text-gray-600 px-4 pb-1">
          Do you confirm that you want to delete this company{' '}
          <b>{companyName}</b>?
        </p>
        <p className="text-sm font-bold text-gray-600 px-4 pb-6">
          This action is irreversible!
        </p>
        <div className="form-footer">
          <Button type="button" onClick={() => onSubmit()} loading={loading}>
            Confirm
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onClose()}
            extraClassName="mr-2">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
