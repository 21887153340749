import ptBR from './pt-BR.json';
import en from './en.json';
import es from './es.json';

export default {
  'pt-BR': ptBR,
  pt: ptBR,
  en,
  es,
};
