import {useIntl} from 'react-intl';
import Modal from '../../../../../../../../components/_modals/Modal';
import Button from '../../../../../../../../components/Button';

const ConfirmModal = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  loading = false,
}) => {
  const {formatMessage} = useIntl();
  return (
    <Modal
      maxWidth="max-w-lg"
      minHeight={195}
      open={open}
      onClose={onClose}
      title={formatMessage({id: 'settings.close-acc-permanently-close'})}>
      <div>
        <p className="text-medium font-normal text-gray-600 px-4 pb-6">
          {formatMessage({id: 'settings.close-acc-permanently-close-text'})}
        </p>

        <div className="form-footer">
          <Button
            variant="error"
            type="button"
            onClick={() => onConfirm()}
            loading={loading}>
            {formatMessage({id: 'confirm'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onClose()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
