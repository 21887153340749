/* eslint-disable react/no-unescaped-entities */
import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

import api from '../../services/api';
import {SubscriptionsHTTP} from '../../modules/company/providers/subscriptions';

import Loading from '../Loading';
import ConfirmModal from './components/ConfirmModal';

const CheckIcon = () => (
  <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-yellow-500 text-white rounded-full flex-shrink-0">
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      className="w-3 h-3"
      viewBox="0 0 24 24">
      <path d="M20 6L9 17l-5-5" />
    </svg>
  </span>
);

const PlansList = ({
  currentPlan = null,
  subscriptionId = '',
  onSuccess = () => {},
}) => {
  const [plans, setPlans] = useState({
    loading: true,
    data: {monthly: [], annually: []},
  });
  const [tab, setTab] = useState(
    currentPlan && currentPlan.period === 'year' ? 'annually' : 'monthly',
  );
  const [confirmModal, setConfirmModal] = useState({open: false, selected: {}});
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    async function fetchPlans() {
      const {data} = await api.get('/plans');
      const plansData = data.filter((p) =>
        ['workpodium-yearly', 'workpodium'].includes(p.slug),
      );
      setPlans({
        loading: false,
        data: {
          monthly: plansData.filter((p) => p.period === 'month'),
          annually: plansData.filter((p) => p.period === 'year'),
        },
      });
    }

    fetchPlans();
  }, []);

  const handleUpdatePlan = async () => {
    try {
      setUpdateLoading(true);
      await SubscriptionsHTTP.update(subscriptionId, {
        plan_id: confirmModal.selected._id,
        stripe_id: confirmModal.selected.stripe_id,
      });

      onSuccess();
      toast.success('Plan updated successfully!');
      setConfirmModal({open: false, selected: {}});
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <>
      {plans.loading ? (
        <div className="m-4">
          <Loading />
        </div>
      ) : (
        <section className="text-gray-600 body-font overflow-hidden">
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-center w-full mb-12">
              <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
                Plans
              </h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
                Discover our plans and discover what best fits your company
              </p>
              <div className="flex mx-auto border-2 border-yellow-500 rounded overflow-hidden mt-6">
                <button
                  type="button"
                  className={`py-1 px-4 focus:outline-none ${
                    tab === 'monthly' ? 'bg-yellow-500 text-white' : ''
                  }`}
                  onClick={() => setTab('monthly')}>
                  Monthly
                </button>
                <button
                  type="button"
                  className={`py-1 px-4 focus:outline-none ${
                    tab === 'annually' ? 'bg-yellow-500 text-white' : ''
                  }`}
                  onClick={() => setTab('annually')}>
                  Yearly 20% OFF
                </button>
              </div>
            </div>
            <div className="flex flex-wrap -m-4">
              {plans.data[tab].map((plan) => (
                <div key={plan._id} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                  <div
                    className={`h-full p-6 rounded-lg border-2 ${
                      plan._id === currentPlan._id
                        ? 'border-yellow-500'
                        : 'border-gray-200'
                    } flex flex-col relative overflow-hidden`}>
                    {plan._id === currentPlan._id && (
                      <span className="bg-yellow-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                        CURRENT PLAN
                      </span>
                    )}
                    <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                      {plan.name}
                    </h2>
                    <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span>${plan.price.$numberDecimal}</span>
                      <span className="text-lg ml-1 font-normal text-gray-500">
                        /{tab === 'monthly' ? 'mo' : 'yr'}
                      </span>
                    </h1>
                    <p className="flex items-center text-gray-600 mb-2">
                      <CheckIcon />
                      No. Employees:{' '}
                      {['workpodium-yearly', 'workpodium'].includes(plan.slug)
                        ? 'unlimited'
                        : plan.employees}
                    </p>
                    <p className="flex items-center text-gray-600 mb-2">
                      <CheckIcon />
                      No. Boss:{' '}
                      {['workpodium-yearly', 'workpodium'].includes(plan.slug)
                        ? 'unlimited'
                        : plan.employees}
                    </p>
                    <p className="flex items-center text-gray-600 mb-2">
                      <CheckIcon />
                      Time Tracking
                    </p>
                    <p className="flex items-center text-gray-600 mb-2">
                      <CheckIcon />
                      Reports
                    </p>
                    <p className="flex items-center text-gray-600 mb-4">
                      <CheckIcon />
                      GPS Location
                    </p>
                    {plan._id !== currentPlan._id && (
                      <>
                        <button
                          type="button"
                          className="flex items-center mt-auto text-white bg-yellow-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-yellow-700 rounded"
                          onClick={() =>
                            setConfirmModal({open: true, selected: plan})
                          }>
                          Update now
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-auto"
                            viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7" />
                          </svg>
                        </button>
                        <p className="text-xs text-gray-500 mt-3">
                          In your next billing cycle, the new value it will be
                          charge.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <ConfirmModal
        open={confirmModal.open}
        onClose={() => setConfirmModal({open: false, selected: {}})}
        loading={updateLoading}
        onConfirm={handleUpdatePlan}
        plan={confirmModal.selected}
      />
    </>
  );
};

export default PlansList;
