import {useEffect, useState, useMemo} from 'react';
// import {useNavigate} from 'react-router-dom';
import {
  PencilSquareIcon,
  TrashIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import debounce from 'lodash/debounce';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {useCompanies} from '../../../hooks/useCompanies';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import SectionWrapper from '../../../../../components/SectionWrapper';
import {FormatDate} from '../../../../../components/FormatDate';
import Search from '../../../../../components/Search';
import Pagination from '../../../../../components/Pagination';
import DropdownButton from '../../../../../components/DropdownButton';
import Button from '../../../../../components/Button';

import DeleteModal from './components/DeleteModal';
import CompanyFormModal from './components/CompanyFormModal';

const managerCompanyInitialState = {
  action: '',
  openModal: false,
  id: '',
  name: '',
};

const CompaniesPage = () => {
  // const navigate = useNavigate();
  const {companies, getCompanies, removeCompanyFromList} = useCompanies();
  const {setCurrentCompany, user, currentCompany} = useAuth();
  const isSalesFlow =
    ['sales', 'sales_assistant'].includes(user.role) || user.company.sales;

  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [managerCompany, setManager] = useState(managerCompanyInitialState);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleCreateCompanyWithSuccess = () => {
    getCompanies({
      page: 1,
      name: '',
      status: '',
      sales: false,
      ...(isSalesFlow ? {sale_id: currentCompany || user.company_id} : {}),
    });
    setActiveTab('all');
    setOpenCreateModal(false);
  };

  useEffect(() => {
    getCompanies({
      page: currentPage,
      name: search,
      sales: false,
      sort: activeTab === 'closed' ? '-close_at' : '-created_at',
      status:
        activeTab === 'all' || activeTab === 'trial' || activeTab === 'closed'
          ? ''
          : activeTab,
      // eslint-disable-next-line no-nested-ternary
      ...(activeTab === 'trial'
        ? {trial: true}
        : activeTab === 'active'
        ? {trial: false}
        : {}),
      ...(activeTab === 'closed' ? {closed: true, status: 'inactive'} : {}),
      ...(isSalesFlow ? {sale_id: currentCompany || user.company_id} : {}),
    });
  }, [currentPage, search, activeTab]);

  const searchDebounce = debounce(setSearch, 500);

  const handleSelectCompany = async (companyId, companyName) => {
    await setCurrentCompany(companyId, companyName);
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (window.location.href = '/dashboard'), 600);
    // window.scrollTo(0, 0);
    // setTimeout(() => navigate('/dashboard'), 500);
  };

  // const handleReactiveCompany = async (companyId, companyName) => {
  //   await setCurrentCompany(companyId, companyName);
  //   // eslint-disable-next-line no-return-assign
  //   setTimeout(() => (window.location.href = '/reactivation'), 600);
  //   // window.scrollTo(0, 0);
  //   // setTimeout(() => navigate('/dashboard'), 500);
  // };

  const handleOpenStripePortal = (customerId) => {
    if (customerId && customerId !== 'no_provider') {
      const portalLink = `https://portal.trackmyguys.com/?id=${customerId}`;
      window.open(portalLink, '_blank');
    } else {
      // eslint-disable-next-line no-alert
      alert("This company haven't a stripe account");
    }
  };

  const handleOpenPaymentLink = (companyData) => {
    if (companyData && companyData.stripe_customer !== 'no_provider') {
      // eslint-disable-next-line no-alert
      alert(
        `link: https://app.workpodium.com/reactivate?token=${companyData._id}`,
      );
      // navigate(`/reactivate?token=${companyData._id}`);
    } else {
      // eslint-disable-next-line no-alert
      alert("This company haven't a stripe account");
    }
  };

  const handleUpdateList = async (id) => {
    await removeCompanyFromList(id);
    setManager(managerCompanyInitialState);
  };

  const companyOptionsMenu = (row) => {
    const menu = [];

    if (row.original.status === 'inactive') {
      menu.push({
        id: 'stripe-payment-link-company',
        label: 'Create Stripe Payment Link',
        icon: ArrowTopRightOnSquareIcon,
        onClick: () => handleOpenPaymentLink(row.original),
      });
      // menu.push({
      //   id: 'reactive-company',
      //   label: 'Reactive company',
      //   icon: Cog6ToothIcon,
      //   onClick: () =>
      //     handleReactiveCompany(row.original._id, row.original.name),
      // });
    }

    menu.push(
      {
        id: 'edit-company',
        label: 'Edit company',
        icon: PencilSquareIcon,
        onClick: () => {},
      },
      {
        id: 'stripe-portal-company',
        label: 'Go To Stripe Portal',
        icon: ArrowTopRightOnSquareIcon,
        onClick: () => handleOpenStripePortal(row.original.stripe_customer),
      },
      {
        id: 'delete-company',
        label: 'Delete Company',
        icon: TrashIcon,
        onClick: () =>
          setManager({
            action: 'delete',
            openModal: true,
            id: row.original._id,
            name: row.original.name,
          }),
      },
    );

    return menu;
  };

  const tabItems = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'trial',
        label: 'Trial',
      },
      {
        value: 'closed',
        label: 'Closed',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
    [],
  );

  const communColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex flex-col">
            {/* {!row.original.stripe_source ||
              (row.original.stripe_source === 'no_provider' && (
                <Badge
                  variant="error"
                  extraClassName="my-2"
                  style={{maxWidth: 100}}>
                  No credit card
                </Badge>
              ))} */}

            {row.original.name}
            {row.original.boss && row.original.boss[0] && (
              <p>
                <b>Boss:</b> {row.original.boss[0].name}
              </p>
            )}
            <Badge variant="info" extraClassName="my-2" style={{maxWidth: 220}}>
              ID: {row.original._id}
            </Badge>
            {row.original.stripe_customer && (
              <Badge
                variant="info"
                extraClassName="mb-2"
                style={{maxWidth: 210}}>
                {row.original.stripe_customer
                  ? `Stripe: ${row.original.stripe_customer}`
                  : ''}
              </Badge>
            )}
            {row.original.uuid && (
              <Badge
                variant="info"
                extraClassName="mb-2"
                style={{maxWidth: 195}}>
                SUPPORT ID: {row.original.uuid ?? ''}
              </Badge>
            )}
          </div>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Plan',
        accessor: 'plan_id',
        Cell: ({row}) =>
          row.original.plan_id && (
            <p>
              {row.original.plan_id.name} - $
              {row.original.plan_id.price.$numberDecimal}
            </p>
          ),
      },
      {
        Header: 'Company Data',
        accessor: 'new_company',
        Cell: ({row}) => (
          <div style={{fontSize: 12, lineHeight: '0.675rem'}}>
            <p>Employees: {row.original.employees_qty}</p> <br />
            <p>Punches Count: {row.original.punches_qty}</p> <br />
            <p>
              Last Punch:{' '}
              {row.original.last_punch &&
              row.original.last_punch.start &&
              row.original.last_punch.start.time ? (
                <FormatDate value={row.original.last_punch.start.time} />
              ) : (
                'No data'
              )}
            </p>
          </div>
        ),
      },
    ],
    [],
  );
  const columns = useMemo(
    () => [
      ...communColumns,
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          if (row.original.trial) {
            return (
              <Badge variant="info" style={{maxWidth: 150}}>
                trial
              </Badge>
            );
          }

          return (
            <Badge
              variant={row.original.status === 'inactive' ? 'error' : 'success'}
              style={{maxWidth: 165}}>
              {row.original.status}
            </Badge>
          );
        },
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.created_at), 'MM/dd/yyyy'),
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex flex-col items-end gap-2">
            <Button
              onClick={() =>
                handleSelectCompany(row.original._id, row.original.name)
              }>
              Manager
            </Button>

            {row.original.status === 'inactive' && (
              <Button onClick={() => handleOpenPaymentLink(row.original)}>
                Stripe reactive link
              </Button>
            )}

            <DropdownButton
              label="Edit"
              variant="secondary"
              options={companyOptionsMenu(row)}
            />
          </div>
        ),
      },
    ],
    [communColumns],
  );

  const closeColumns = useMemo(
    () => [
      ...communColumns,
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.created_at), 'MM/dd/yyyy'),
      },
      {
        Header: 'Closed at',
        accessor: 'close_at',
        Cell: ({row}) =>
          row.original.close_at
            ? formatDate(new Date(row.original.close_at), 'MM/dd/yyyy')
            : 'n/i',
      },
      {
        Header: 'Reason to close',
        accessor: 'reason_close',
        Cell: ({row}) => (
          <p style={{maxWidth: 300, textWrap: 'wrap', fontSize: 11}}>
            {row.original.reason_close}
          </p>
        ),
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex flex-col items-end gap-2">
            <Button
              onClick={() =>
                handleSelectCompany(row.original._id, row.original.name)
              }>
              Manager
            </Button>

            {row.original.status === 'inactive' && (
              <Button onClick={() => handleOpenPaymentLink(row.original)}>
                Stripe reactive link
              </Button>
            )}

            <DropdownButton
              label="Edit"
              variant="secondary"
              options={companyOptionsMenu(row)}
            />
          </div>
        ),
      },
    ],
    [communColumns],
  );

  return (
    <>
      <PageHeader
        title="Companies"
        breadcrumbs={[
          {
            id: 'companies',
            label: 'Companies',
          },
        ]}
        rightContent={
          <Button
            onClick={() => setOpenCreateModal(true)}
            extraClassName="mr-2">
            New company
          </Button>
        }
      />

      <SectionWrapper
        isLoading={companies.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table
          columns={activeTab === 'closed' ? closeColumns : columns}
          data={companies.data}
        />

        <Pagination
          totalPages={companies.pagination.totalPages}
          currentPage={currentPage}
          totalRecords={companies.pagination.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      <CompanyFormModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onSucess={() => handleCreateCompanyWithSuccess()}
      />

      <DeleteModal
        open={managerCompany.openModal}
        onClose={() => setManager(managerCompanyInitialState)}
        onSuccess={() => handleUpdateList(managerCompany.id)}
        companyId={managerCompany.id}
        companyName={managerCompany.name}
      />
    </>
  );
};

export default CompaniesPage;
