import {useEffect, useState, useMemo} from 'react';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import debounce from 'lodash/debounce';

import {useUsers} from '../../../hooks/useUsers';

import Avatar from '../../../../../components/Avatar';
import IconButton from '../../../../../components/IconButton';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import Table from '../../../../../components/Table';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Search from '../../../../../components/Search';
import Pagination from '../../../../../components/Pagination';

const tabItems = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];

const UsersPage = () => {
  const navigate = useNavigate();
  const {users, getUsers} = useUsers();

  const [activeTab, setActiveTab] = useState('active');
  const [searchOption] = useState('name');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  // const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setTimeout(
      () =>
        getUsers({
          page: currentPage,
          [searchOption]: search,
          status: activeTab === 'all' ? '' : activeTab,
        }),
      350,
    );
  }, [currentPage, search, searchOption, activeTab]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Avatar
              id={row.original._id}
              photo={row.original.photo || null}
              name={row.original.name}
              size={10}
            />
            <p className="ml-2">
              {row.original.name} <br />
              {row.original.company_id && (
                <strong>{row.original.company_id.name}</strong>
              )}
            </p>
          </div>
        ),
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      {
        Header: 'Last Punch',
        accessor: 'last_punch',
      },
      {
        Header: 'Updated at',
        accessor: 'updated_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.updated_at), 'MM/dd/yyyy hh:mm:ss'),
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => navigate(`/user/${row.original._id}`)}
          />
        ),
      },
    ],
    [],
  );

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title="Users"
        breadcrumbs={[
          {
            id: 'users',
            label: 'Users',
          },
        ]}
      />

      <SectionWrapper
        isLoading={users.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={<Search onChange={(e) => searchDebounce(e.target.value)} />}>
        <Table columns={columns} data={users[activeTab]} />

        <Pagination
          totalPages={users.pagination.totalPages}
          currentPage={currentPage}
          totalRecords={users.pagination.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>
    </>
  );
};

export default UsersPage;
