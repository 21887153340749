import React, {useCallback, useState, useMemo} from 'react';
import {createContext} from 'use-context-selector';
import api from '../../../services/api';

export const UsersContext = createContext({});

export function UsersProvider({children}) {
  const [users, setUsers] = useState({
    loading: false,
    pagination: {
      perPage: 50,
      currentPage: 1,
      totalPages: 1,
      totalDocs: 50,
    },
    active: [],
    inactive: [],
    all: [],
  });
  const [user, setUser] = useState({
    loading: true,
    data: {},
  });

  const getUsers = useCallback(async (params) => {
    setUsers((prev) => ({...prev, loading: true}));
    const {data} = await api.get('/users', {params});
    setUsers({
      loading: false,
      pagination: {
        perPage: data.users.limit,
        currentPage: data.users.page,
        totalPages: data.users.totalPages,
        totalDocs: data.users.totalDocs,
      },
      active: data.users.docs.filter((i) => i.status === 'active'),
      inactive: data.users.docs.filter((i) => i.status === 'inactive'),
      all: data.users.docs,
    });
  }, []);

  const getUser = useCallback(async (id) => {
    setUser((prev) => ({...prev, loading: true}));
    const {data} = await api.get(`/users/${id}`);
    setUser({
      loading: false,
      data,
    });
  }, []);

  const updateUser = useCallback(
    async (id, payload) => api.put(`/users/${id}`, payload),
    [],
  );

  const values = useMemo(
    () => ({
      users,
      user,
      getUsers,
      getUser,
      updateUser,
    }),
    [users, user, getUsers, getUser, updateUser],
  );

  return (
    <UsersContext.Provider value={values}>{children}</UsersContext.Provider>
  );
}
