/* eslint-disable react/no-unescaped-entities */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {
  format as formatDate,
  differenceInMinutes,
  minutesToHours,
} from 'date-fns';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {PunchHTTP} from '../../../../providers/punch';
import {resetTimezone} from '../../../../../../utils/utils';

import Modal from '../../../../../../components/_modals/Modal';
import InputDatePicker from '../../../../../../components/_form/InputDatePicker';
import TimePicker from '../../../../../../components/_form/TimePicker';
import Button from '../../../../../../components/Button';
import Switch from '../../../../../../components/Switch';

const UpdatePunchModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  punchId = '',
  punch = {},
}) => {
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: {errors, isDirty},
  } = useForm();
  const {formatMessage} = useIntl();
  const [loading, setLoading] = useState(false);
  const [invalidDates, setInvalidDates] = useState(false);
  const [hoursAlert, setHoursAlert] = useState(false);
  const [hours, setHours] = useState({
    amount: punch.hours,
    after_deduction: punch.hours_with_deduction,
    maxShift: false,
    hasLunchDeduction:
      !punch.lunch_deduction_disabled_manually && punch.lunch_deduction,
    doLunchDeduction:
      punch.hours !== punch.hours_with_deduction ||
      punch.lunch_deduction_disabled_manually,
  });
  const [disableLunchDeduction, setDisableLunchDeduction] = useState(() =>
    punch.lunch_deduction_disabled_manually ? true : !punch.lunch_deduction,
  );

  const onCancel = () => {
    reset();
    onClose();
  };

  const handleCheckPeriodTime = (timeValue) => {
    let hourValue = parseInt(timeValue.value.split(':')[0]);
    const minutesValue = timeValue.value.split(':')[1];

    let time = timeValue.value;
    if (timeValue.period === 'PM' && hourValue !== 12) {
      hourValue += 12;
      time = `${hourValue}:${minutesValue}`;
    }

    return time;
  };

  const handleFormatDate = (dateValue) => {
    const values = dateValue.split('/');
    return `${values[2]}-${values[0]}-${values[1]}`;
  };

  const onSubmit = async (values) => {
    const startDate = handleFormatDate(values.start_day);
    const endDate = handleFormatDate(values.end_day);
    const invalidCurrentDates =
      new Date(startDate).getTime() >= new Date(endDate).getTime();

    if (!startDate) {
      setError('start_day', 'invalid');
      return;
    }
    if (!endDate) {
      setError('end_day', 'invalid');
      return;
    }

    if (hoursAlert && invalidCurrentDates) {
      // eslint-disable-next-line no-alert
      alert(formatMessage({id: 'timecard.alert-start-date'}));
      return;
    }

    const startTime = handleCheckPeriodTime(values.start_time);
    const endTime = handleCheckPeriodTime(values.end_time);
    if (!startTime) {
      setError('start_time', 'invalid');
      return;
    }
    if (!endTime) {
      setError('end_time', 'invalid');
      return;
    }
    if (startTime.includes('_')) {
      toast.error(formatMessage({id: 'toast.valid-start-time'}));
      setError('start_time', 'invalid');
      return;
    }
    if (endTime.includes('_')) {
      toast.error(formatMessage({id: 'toast.valid-finishing-time'}));
      setError('end_time', 'invalid');
      return;
    }

    const payload = {
      start: {
        ...punch.start,
        time: `${startDate}T${startTime}:00.000Z`,
      },
      end: {
        ...(punch.end ? punch.end : punch.start),
        time: `${endDate}T${endTime}:00.000Z`,
      },
      updated_device: 'web',
      ...(disableLunchDeduction
        ? {lunch_deduction_disabled_manually: true}
        : {}),
    };

    try {
      setLoading(true);

      await PunchHTTP.updateHours(punch._id, payload);

      toast.success(formatMessage({id: 'toast.punch-updated'}));
      setTimeout(() => onSuccess(), 300);
    } finally {
      setLoading(false);
    }
  };

  const startDayInput = watch('start_day');
  const startTimeInput = watch('start_time');
  const endDayInput = watch('end_day');
  const endTimeInput = watch('end_time');

  useEffect(() => {
    if (startTimeInput && endTimeInput) {
      const startTimeValue = handleCheckPeriodTime(startTimeInput).split(':');
      const endTimeValue = handleCheckPeriodTime(endTimeInput).split(':');

      const startHour = parseInt(startTimeValue[0]);
      const startMinutes = parseInt(startTimeValue[1]);

      const endHour = parseInt(endTimeValue[0]);
      const endMinutes = parseInt(endTimeValue[1]);

      if (startHour > endHour) {
        setHoursAlert(true);
        return;
      }

      if (startHour === endHour && startMinutes >= endMinutes) {
        setHoursAlert(true);
        return;
      }

      setHoursAlert(false);
    }
  }, [startTimeInput, endTimeInput]);

  useEffect(() => {
    if (
      isDirty &&
      startDayInput &&
      startTimeInput &&
      endDayInput &&
      endTimeInput
    ) {
      const startDate = handleFormatDate(startDayInput);
      const endDate = handleFormatDate(endDayInput);

      const startTime = handleCheckPeriodTime(startTimeInput);
      const endTime = handleCheckPeriodTime(endTimeInput);

      const startTimeFull = resetTimezone(`${startDate} ${startTime}:00`);
      const endTimeFull = resetTimezone(`${endDate} ${endTime}:00`);

      const invalidCurrentDates =
        new Date(startTimeFull).getTime() >= new Date(endTimeFull).getTime();

      if (invalidCurrentDates) {
        // eslint-disable-next-line no-alert
        alert(formatMessage({id: 'timecard.alert-start-date'}));
        setInvalidDates(true);
      } else {
        if (invalidDates) setInvalidDates(false);

        const minutesAmount = differenceInMinutes(endTimeFull, startTimeFull);
        let minutesAmountAfterDeduction = minutesAmount;

        const hrs = minutesToHours(minutesAmount);
        const mins = minutesAmount % 60;
        const formatHours = `${hrs < 10 ? `0${hrs}` : hrs}:${
          mins < 10 ? `0${mins}` : mins
        }`;

        const deductionAfterMinutes =
          punch.deduction_after_hours * 60 + punch.deduction_after_minutes;
        const doLunchDeduction = minutesAmount > deductionAfterMinutes;
        const hasLunchDeduction =
          !disableLunchDeduction || punch.lunch_deduction;

        if (hasLunchDeduction && doLunchDeduction) {
          const explodeLunchDeductionTime =
            punch.lunch_deduction_time.split(':');
          const lunchDeductionMinutes =
            parseInt(explodeLunchDeductionTime[0]) * 60 +
            parseInt(explodeLunchDeductionTime[1]);

          minutesAmountAfterDeduction -= lunchDeductionMinutes;
        }
        const hrsAfterDeduction = minutesToHours(minutesAmountAfterDeduction);
        const minsAfterDeduction = minutesAmountAfterDeduction % 60;
        const formatHoursAfterDeduction = `${
          hrsAfterDeduction < 10 ? `0${hrsAfterDeduction}` : hrsAfterDeduction
        }:${
          minsAfterDeduction < 10
            ? `0${minsAfterDeduction}`
            : minsAfterDeduction
        }`;

        if (!Number.isNaN(hrs) && !Number.isNaN(mins)) {
          setHours({
            amount: formatHours,
            after_deduction: formatHoursAfterDeduction,
            maxShift: hrsAfterDeduction > punch.max_shift,
            hasLunchDeduction: hasLunchDeduction && doLunchDeduction,
            doLunchDeduction,
          });
        }
      }
    }
  }, [
    startDayInput,
    startTimeInput,
    endDayInput,
    endTimeInput,
    disableLunchDeduction,
  ]);

  useEffect(() => {
    if (open && punch) {
      const hasStart = punch.start && punch.start.time;
      const hasEnd = punch.end && punch.end.time;
      const startReseted = hasStart ? resetTimezone(punch.start.time) : '';
      const endReseted = hasEnd ? resetTimezone(punch.end.time) : '';

      reset({
        start_day: hasStart ? formatDate(startReseted, 'MM/dd/yyyy') : '',
        start_time: hasStart
          ? {
              value: formatDate(startReseted, 'hh:mm'),
              period: formatDate(startReseted, 'aa'),
            }
          : '',
        end_day: hasEnd ? formatDate(endReseted, 'MM/dd/yyyy') : '',
        end_time: hasEnd
          ? {
              value: formatDate(endReseted, 'hh:mm'),
              period: formatDate(endReseted, 'aa'),
            }
          : '',
      });
    }
  }, [open, punch]);

  return (
    <Modal
      maxWidth="max-w-2xl"
      minHeight={320}
      open={open}
      onClose={onCancel}
      title={formatMessage({id: 'timecard.update-punch'})}>
      <form
        className="flex flex-col justify-between"
        style={{minHeight: 400}}
        onSubmit={handleSubmit(onSubmit)}>
        <div className="form-content">
          {invalidDates && (
            <div className="flex p-4 bg-red-400 border border-red-600 rounded-lg mb-4">
              <p className="text-sm font-medium text-white">
                {formatMessage({id: 'timecard.alert-start-date'})}
              </p>
            </div>
          )}

          {hours.maxShift && (
            <div className="flex p-4 bg-yellow-100 border border-yellow-200 rounded-lg mb-4">
              <p className="text-sm font-medium text-yellow-800">
                {formatMessage({id: 'timecard.number-hours-1'})}
                {punch.max_shift}
                {formatMessage({id: 'timecard.number-hours-2'})}
              </p>
            </div>
          )}

          <div className="flex flex-row justify-between">
            <div className="flex flex-col flex-grow pr-4">
              <p className="block text-sm font-medium text-gray-700 mb-4">
                {formatMessage({id: 'timecard.start-date-time'})}
              </p>
              <Controller
                rules={{
                  required: true,
                }}
                name="start_day"
                control={control}
                render={({field}) => (
                  <InputDatePicker
                    {...field}
                    label={formatMessage({id: 'date-required'})}
                    error={errors.start_day}
                  />
                )}
              />

              <Controller
                rules={{
                  required: true,
                }}
                name="start_time"
                control={control}
                render={({field}) => (
                  <TimePicker
                    {...field}
                    label={formatMessage({id: 'time-required'})}
                    error={errors.start_time}
                    extraClassName="mt-2"
                  />
                )}
              />
            </div>

            <div className="flex flex-col flex-grow pl-4">
              <p className="block text-sm font-medium text-gray-700 mb-4">
                {formatMessage({id: 'timecard.end-date-time'})}
              </p>
              <Controller
                rules={{
                  required: true,
                }}
                name="end_day"
                control={control}
                render={({field}) => (
                  <InputDatePicker
                    {...field}
                    label={formatMessage({id: 'date-required'})}
                    error={errors.end_day}
                    maxDate={new Date()}
                  />
                )}
              />

              <Controller
                rules={{
                  required: true,
                }}
                name="end_time"
                control={control}
                render={({field}) => (
                  <TimePicker
                    {...field}
                    label={formatMessage({id: 'time-required'})}
                    error={errors.end_time}
                    extraClassName="mt-2"
                  />
                )}
              />
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <p className="text-sm font-medium text-gray-800">
              {formatMessage({id: 'hours'})}: {hours.amount}
            </p>
            <p className="text-xs text-gray-600 mb-1">
              {formatMessage({id: 'timecard.lunch-deduction-after'})}{' '}
              {punch.deduction_after_hours}{' '}
              {formatMessage({id: 'timecard.hours-and'})}{' '}
              {punch.deduction_after_minutes}{' '}
              {formatMessage({id: 'timecard.minutes-work'})}
            </p>

            {hours.doLunchDeduction && (
              <Switch
                label={formatMessage({id: 'timecard.disable-lunch-deduction'})}
                checked={disableLunchDeduction}
                onChange={setDisableLunchDeduction}
              />
            )}
            {hours.hasLunchDeduction && (
              <>
                <p className="text-sm font-medium text-gray-700 mt-2">
                  {formatMessage({id: 'lunch-deduction'})}:{' '}
                  {punch.lunch_deduction_time}
                </p>
                <p className="text-sm font-medium text-gray-700">
                  {formatMessage({id: 'hours-after-deduction'})}:{' '}
                  {hours.after_deduction}
                </p>
              </>
            )}
          </div>
        </div>

        <div className="form-footer">
          <Button
            type="submit"
            loading={loading}
            disabled={invalidDates || hours.maxShift}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onCancel()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdatePunchModal;
