/* import PageHeader from '../../../../components/_layouts/PageHeader'; */
import {useIntl} from 'react-intl';
import Card from '../../../../components/Card';

const Tutorial = () => {
  const {formatMessage} = useIntl();
  return (
    <div className="pt-14 grid sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 flex justify-center gap-4">
      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-full-website-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/58lUm1RPwY4?si=bu54nO6pQ2BX4cTD"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({
            id: 'tutorial.workpodium-trackmyguys-iphone-boss-app-tutorial',
          })}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/uNCglvLG4fU?si=_TOQFOEOpv34m2c-"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({
            id: 'tutorial.workpodium-trackmyguys-iphone-app-tutorial',
          })}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/4bzrGOBDAjI?si=8S1Ni_wwvf1H_okw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-settings-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/aVHlkiJTpXc?si=O0Hgj-EgOZgohA9F"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-reports-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/gkbU7HmEius?si=BBVKAKsmbud7bOKv"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-extras-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wXJL080ed1s?si=3UU3WRTrx8s7NS-K"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-timecard-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/gaDI44tChGs?si=t6I4FXzeQhrp1wwQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-track-my-guys-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Q4dMqpCrg-4?si=IWbFXF-yjhjTb9gR"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-company-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/nWpTPHeOQWs?si=_HUXXtOcBepjDxha"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-users-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MzecV6I6GY0?si=VGyWb_meJut1ZkBb"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>

      <div>
        <h2 className="p-2 font-semibold text-gray-700 flex justify-center">
          {formatMessage({id: 'tutorial.workpodium-dashboard-tutorial'})}
        </h2>
        <div className="flex justify-center">
          <Card padding="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/VDUl-pIkvhk?si=wk8P7V3gkXfCI6wl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Tutorial;
