import {useEffect, useState, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {ReceiptsHTTP} from '../../../../providers/receipts';
import {currencyFormat} from '../../../../../../utils/currency';

import Modal from '../../../../../../components/_modals/Modal';
import Loading from '../../../../../../components/Loading';
import Table from '../../../../../../components/Table';
import EmptyData from '../../../../../../components/EmptyData';
import {FormatDate} from '../../../../../../components/FormatDate';
import Button from '../../../../../../components/Button';
import PreviewImageModal from '../../../../../../components/_modals/PreviewImageModal';
import PreviewMapModal from '../../../../../../components/_modals/PreviewMapModal';

const ViewReceiptsModal = ({
  open = false,
  punchId = '',
  onClose = () => {},
}) => {
  const {formatMessage} = useIntl();
  const [receipts, setReceipts] = useState({loading: true, data: []});
  const [viewReceiptDetails, setViewReceiptDetails] = useState({
    open: false,
    type: 'photo',
    previewUrl: '',
    coords: {lat: '', lng: ''},
  });

  const handleViewDetails = (type, data) => {
    const isPhoto = type === 'photo';
    setViewReceiptDetails({
      open: true,
      type,
      previewUrl: isPhoto ? data : '',
      coords: !isPhoto ? data : {lat: '', lng: ''},
    });
  };

  const handleCloseViewDetails = () => {
    setViewReceiptDetails({
      open: false,
      type: 'photo',
      previewUrl: '',
      coords: {lat: '', lng: ''},
    });
  };

  useEffect(() => {
    async function fetchReceipts() {
      const {data} = await ReceiptsHTTP.get({punch_id: punchId});
      setReceipts({loading: false, data});
    }

    if (punchId) fetchReceipts();
  }, [punchId]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'timecard.date-location'})}`,
        accessor: 'created_at',
        Cell: ({row}) => (
          <div className="flex flex-col items-start">
            <FormatDate value={row.original.created_at} />
            <Button
              variant="secondary"
              extraClassName="mt-2"
              onClick={() =>
                handleViewDetails('map', {
                  lat: row.original.location.coordinates[0],
                  lng: row.original.location.coordinates[1],
                })
              }>
              {formatMessage({id: 'see-location'})}
            </Button>
          </div>
        ),
      },
      {
        Header: `${formatMessage({id: 'category'})}`,
        accessor: 'category_id.name',
      },
      {
        Header: `${formatMessage({id: 'description'})}`,
        accessor: 'description',
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: ({amount}) => currencyFormat(amount.$numberDecimal),
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <div>
            <Button
              onClick={() => handleViewDetails('photo', row.original.file)}>
              {formatMessage({id: 'timecard.view-receipt'})}
            </Button>
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  return (
    <>
      <Modal
        title={formatMessage({id: 'receipts'})}
        maxWidth="max-w-4xl"
        minHeight={320}
        open={open}
        onClose={onClose}>
        <div>
          {receipts.loading && (
            <div className="m-4">
              <Loading />
            </div>
          )}

          {!receipts.loading && receipts.data.length === 0 ? (
            <EmptyData
              description={formatMessage({id: 'timecard.nothing-found'})}
            />
          ) : (
            <Table columns={columns} data={receipts.data} />
          )}
        </div>
      </Modal>
      <PreviewImageModal
        open={viewReceiptDetails.open && viewReceiptDetails.type === 'photo'}
        previewUrl={viewReceiptDetails.previewUrl}
        onClose={() => handleCloseViewDetails()}
      />
      <PreviewMapModal
        open={viewReceiptDetails.open && viewReceiptDetails.type === 'map'}
        coordinates={viewReceiptDetails.coords}
        onClose={() => handleCloseViewDetails()}
      />
    </>
  );
};

export default ViewReceiptsModal;
