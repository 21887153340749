import {useMemo, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {EmployeesHTTP} from '../../../../../providers/employees';

import Table from '../../../../../../../components/Table';
import Loading from '../../../../../../../components/Loading';

const DevicesPage = ({userId = ''}) => {
  const {formatMessage} = useIntl();
  const [devices, setDevices] = useState({
    loading: true,
    data: [],
  });

  const columns = useMemo(
    () => [
      {
        Header: formatMessage({id: 'brand-and-model'}),
        accessor: 'device.brand',
        Cell: ({row}) =>
          `${row.original.device.brand} - ${row.original.device.model}`,
      },
      {
        Header: formatMessage({id: 'device-id'}),
        accessor: 'device.deviceId',
      },
      {
        Header: formatMessage({id: 'device-carrier'}),
        accessor: 'device.carrier',
      },
      {
        Header: formatMessage({id: 'device-os-name-and-version'}),
        accessor: 'device.systemVersion',
        Cell: ({row}) =>
          `${row.original.device.systemName} - ${row.original.device.systemVersion}`,
      },
      {
        Header: formatMessage({id: 'app-version'}),
        accessor: 'device.app_version',
      },
    ],
    [],
  );

  useEffect(() => {
    async function fetchDevices() {
      const {data} = await EmployeesHTTP.getDevices(userId);
      setDevices({
        loading: false,
        data,
      });
    }

    fetchDevices();
  }, [userId]);

  if (devices.loading)
    return (
      <div className="w-full flex justify-center p-8">
        <Loading />
      </div>
    );

  return <Table columns={columns} data={devices.data} />;
};

export default DevicesPage;
