import {useContextSelector} from 'use-context-selector';
import {UsersContext} from '../context/UsersContext';

export function useUsers() {
  const users = useContextSelector(UsersContext, (data) => data.users);
  const user = useContextSelector(UsersContext, (data) => data.user);

  const getUsers = useContextSelector(UsersContext, (data) => data.getUsers);

  const getUser = useContextSelector(UsersContext, (data) => data.getUser);

  const updateUser = useContextSelector(
    UsersContext,
    (data) => data.updateUser,
  );

  return {
    users,
    user,
    getUsers,
    getUser,
    updateUser,
  };
}
