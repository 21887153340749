import {useEffect, useState, useMemo, useCallback} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';
// import {useNavigate} from 'react-router-dom';

import {ExtrasHTTP} from '../../../providers/extras';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Button from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton';
import Table from '../../../../../components/Table';
import {FormatDate} from '../../../../../components/FormatDate';
import Modal from '../../../../../components/_modals/Modal';
import CategoryForm from './components/Form';

const ReceiptsCategory = () => {
  const {formatMessage} = useIntl();
  const [formType, setFormType] = useState({
    loading: false,
    type: 'create',
    selected: {},
    modal: false,
  });

  const [receipts, setReceipts] = useState({
    loading: true,
    data: {},
  });

  async function fetchReceiptsCategory() {
    setReceipts({
      loading: true,
    });
    const {data} = await ExtrasHTTP.getCategories();
    setReceipts({
      data,
      loading: false,
    });
  }
  function handleEdit(data) {
    setFormType((prev) => ({
      ...prev,
      type: 'edit',
      selected: data,
      modal: true,
    }));
  }
  const handleSubmit = useCallback(
    async (value) => {
      try {
        setFormType((prev) => ({...prev, loading: true}));
        const payload = {
          name: value.name,
          status: value.status,
          category: 'receipt',
        };

        if (formType.type === 'create') {
          await ExtrasHTTP.createCategory(payload);
          toast.success(formatMessage({id: 'toast.category-created'}));
        } else {
          await ExtrasHTTP.updateCategory(formType.selected._id, payload);
          toast.success(formatMessage({id: 'toast.category-upd'}));
        }
        fetchReceiptsCategory();
      } finally {
        setFormType({
          loading: false,
          type: 'create',
          selected: {},
          modal: false,
        });
      }
    },
    [formType],
  );

  // const navigate = useNavigate();

  useEffect(() => {
    fetchReceiptsCategory();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
      },
      {
        Header: `${formatMessage({id: 'status'})}`,
        accessor: 'status',
      },
      {
        Header: `${formatMessage({id: 'created-at'})}`,
        accessor: 'created_at',
        Cell: ({value}) => <FormatDate value={value} pattern="P" />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => handleEdit(row.original)}
          />
        ),
      },
    ],
    [formatMessage],
  );

  // if (employees.loading) return <h1>loading...</h1>;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'receipts-category'})}
        breadcrumbs={[
          {
            id: 'extras',
            label: formatMessage({id: 'extras'}),
          },
          {
            id: 'receipts',
            label: formatMessage({id: 'receipts-category'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => setFormType((prev) => ({...prev, modal: true}))}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'extras.new-category'})}
            </>
          </Button>
        }
      />

      <SectionWrapper isLoading={receipts.loading}>
        <Table columns={columns} data={receipts.data} />
      </SectionWrapper>

      <Modal
        maxWidth="max-w-2xl"
        open={formType.modal}
        title={formatMessage({id: 'extras.new-category'})}
        onClose={() =>
          setFormType((prev) => ({...prev, modal: false, selected: {}}))
        }>
        <CategoryForm
          loading={formType.loading}
          initialData={formType.selected}
          onSubmit={(value) => handleSubmit(value)}
          onCancel={() =>
            setFormType((prev) => ({...prev, modal: false, selected: {}}))
          }
        />
      </Modal>
    </>
  );
};

export default ReceiptsCategory;
