import {useEffect, useState} from 'react';

import {CompaniesHTTP} from '../../providers/companies';

import PageHeader from '../../../../components/_layouts/PageHeader';
import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';
import PlansList from '../../../../components/PlansList';

const Plans = () => {
  const [company, setCompany] = useState({loading: true, data: {}});

  const getCompany = async () => {
    if (!company.loading) setCompany({loading: true, data: {}});
    const {data} = await CompaniesHTTP.myCompany();
    setCompany({loading: false, data});
  };

  useEffect(() => {
    getCompany();
  }, []);

  const currentPlan = company.data?.plan_id || null;

  return (
    <>
      <PageHeader
        title="Plans"
        breadcrumbs={[
          {
            id: 'company',
            label: 'Company',
          },
          {
            id: 'plans',
            label: 'Plans',
          },
        ]}
      />
      <Card padding="p-0" className="overflow-hidden mb-6">
        {company.loading ? (
          <div className="m-4">
            <Loading />
          </div>
        ) : (
          <div className="p-6 pt-1">
            <PlansList
              subscriptionId={company.data.subscription.id}
              currentPlan={currentPlan}
              onSuccess={() => {
                getCompany();
              }}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default Plans;
