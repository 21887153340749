import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/solid';

const variants = {
  primary:
    'w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500',
  secondary:
    'w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-gray-500 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-sm',
};

const DropdownButton = ({
  label = 'Options',
  variant = 'primary',
  options = [],
}) => (
  <Menu as="div" className="relative inline-block text-left">
    <Menu.Button className={`relative z-0 ${variants[variant]}`}>
      {label}
      <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
    </Menu.Button>
    <Transition
      className="relative z-40"
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items
        as="ul"
        className="absolute z-50 right-0 w-56 mt-2 p-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {options.map(({id, onClick, label: buttonLabel, icon: Icon}) => (
          <Menu.Item as="li" key={id}>
            {({active}) => (
              <button
                type="button"
                className={`${
                  active ? 'bg-yellow-500 text-white' : 'text-gray-900'
                } group flex rounded-md items-center w-full p-2 text-sm`}
                onClick={onClick}>
                <Icon
                  className={`w-5 h-5 mr-2 ${
                    active ? 'text-white' : 'text-yellow-500'
                  }`}
                  aria-hidden="true"
                />
                {buttonLabel}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  </Menu>
);

export default DropdownButton;
