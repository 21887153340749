import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Calendar} from 'react-date-range';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {ExtrasHTTP} from '../../../../../providers/extras';

import Modal from '../../../../../../../components/_modals/Modal';
import Input from '../../../../../../../components/_form/Input';
import TextArea from '../../../../../../../components/_form/TextArea';
import SearchSelect from '../../../../../../../components/_form/SearchSelect';
import Button from '../../../../../../../components/Button';
import CurrencyInput from '../../../../../../../components/_form/MaskedInput/Currency';
import {currencyFormat} from '../../../../../../../utils/currency';
import {formatDateTZ, isValidDate} from '../../../../../../../utils/date';

const ManHoursForm = ({
  jobs = [],
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  id = null,
  initialData = {},
}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({defaultValues: initialData});

  const [date, setDate] = useState(
    initialData && initialData.period ? initialData.period : new Date(),
  );
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      let period = new Date(
        `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`,
      );
      if (!period || !isValidDate(period)) {
        period = `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`;
      }
      const payload = {
        ...values,
        amount: parseFloat(values.hours) * values.hour_rate,
        work_id: values.work_id.value,
        period,
      };

      if (id && initialData) {
        await ExtrasHTTP.updateManHours(id, payload);
        toast.success(formatMessage({id: 'toast.updated-sucess'}));
      } else {
        await ExtrasHTTP.createManHours(payload);
        toast.success(formatMessage({id: 'toast.created-sucess'}));
      }

      reset();
      onSuccess();
    } catch {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  const watchManyHours = watch('hours') || 0;
  const watchHourRate = watch('hour_rate') || 0;
  // eslint-disable-next-line radix
  const menHoursResult = parseFloat(watchManyHours) * watchHourRate;

  return (
    <Modal
      maxWidth="max-w-3xl"
      open={open}
      onClose={() => onClose()}
      title={
        initialData && id
          ? formatMessage({id: 'extra.edit-extra'})
          : formatMessage({id: 'extra.new-extra-man'})
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div style={{width: 360}}>
              <Calendar
                date={date}
                maxDate={new Date()}
                onChange={(item) => setDate(item)}
              />
            </div>
            <div className="pl-6">
              <Input
                label={formatMessage({id: 'worker-name-required'})}
                id="name"
                type="text"
                error={errors.name}
                {...register('name', {required: true})}
              />

              <Controller
                rules={{
                  required: true,
                }}
                name="work_id"
                control={control}
                render={({field}) => (
                  <SearchSelect
                    {...field}
                    label={formatMessage({id: 'job-location-required'})}
                    placeholder={formatMessage({
                      id: 'timecard.select-job-location',
                    })}
                    id="work_id"
                    options={jobs}
                    className="mt-2 w-full"
                    error={errors.work_id}
                  />
                )}
              />
              {/* <Controller
                name="work_id"
                control={control}
                render={({field}) => (
                  <Select
                    {...field}
                    label="Job Location"
                    id="select-language"
                    options={jobs}
                  />
                )}
              /> */}

              <div className="mt-2">
                <Input
                  label={formatMessage({id: 'how-many-hours-required'})}
                  id="hours"
                  error={errors.name}
                  {...register('hours', {required: true})}
                />
              </div>

              <Controller
                rules={{
                  required: true,
                }}
                name="hour_rate"
                control={control}
                render={({field}) => (
                  <CurrencyInput
                    {...field}
                    label={formatMessage({id: 'hours-rate-required'})}
                    error={errors.hour_rate}
                  />
                )}
              />

              <p className="mt-4">
                {formatMessage({id: 'amount'})}:{' '}
                {currencyFormat(menHoursResult) || '0.00'}
              </p>

              <div className="mt-4">
                <TextArea
                  label={formatMessage({id: 'description'})}
                  id="description"
                  type="text"
                  error={errors.description}
                  {...register('description')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            extraClassName="mr-2"
            onClick={() => onClose()}>
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ManHoursForm;
