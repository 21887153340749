import {useEffect, useState, useMemo} from 'react';
import {
  PlusIcon,
  ChevronRightIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import {useIntl} from 'react-intl';

import {ExtrasHTTP} from '../../../providers/extras';
import {useJobLocation} from '../../../hooks/useJobLocation';

import {currencyFormat} from '../../../../../utils/currency';
import noPictureImage from '../../../../../assets/images/no-file.png';
import pdfImage from '../../../../../assets/images/pdf.png';
import fileDownloadIcon from '../../../../../assets/images/file-download.png';
import {checkIsImage} from '../../../../../utils/utils';

import ConfirmationModal from '../../../../../components/_modals/ConfirmationModal';
import SectionWrapper from '../../../../../components/SectionWrapper';
import {FormatDate} from '../../../../../components/FormatDate';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import IconButton from '../../../../../components/IconButton';
import Button from '../../../../../components/Button';
import SearchSelect from '../../../../../components/_form/SearchSelect';
import Search from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import PreviewModal from '../../../../../components/_modals/PreviewModal';
import Pagination from '../../../../../components/Pagination';
import FormModal from './components/Form';

const getFileImage = (file, company_id = '') => {
  if (!file) return noPictureImage;
  if (!checkIsImage(file)) return fileDownloadIcon;

  if (file.includes('.pdf')) return pdfImage;

  if (!file.includes('storage.googleapis')) {
    return `${process.env.REACT_APP_API_URL}/files?model=expenses&company=${company_id}&file=${file}`;
  }

  return file;
};

const ExtraBills = () => {
  const {formatMessage} = useIntl();
  const {jobs, getJobs} = useJobLocation();

  const [categories, setCategories] = useState({
    loading: true,
    data: [],
  });
  const [extras, setExtras] = useState({
    loading: true,
    data: [],
  });
  const [previewFile, setPreviewFile] = useState({
    open: false,
    file: '',
  });
  const [modal, setModal] = useState({
    open: false,
    type: 'create',
    data: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({category: '', work: ''});
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
  });

  const handleOpenPreview = (file, company_id) => {
    if (!file) return null;

    const fileUrl =
      !file.includes('trackmyguys.com/storage') &&
      !file.includes('storage.googleapis')
        ? `${process.env.REACT_APP_API_URL}/files?model=expenses&company=${company_id}&file=${file}`
        : file;

    if (file.includes('.pdf')) return window.open(fileUrl, '_blank');

    if (!checkIsImage(file)) return window.open(fileUrl, '_blank');

    return setPreviewFile({open: true, file: fileUrl});
  };

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'file'})}`,
        accessor: ({file, company_id}) => (
          <button
            className="border-0 bg-transparent"
            onClick={() => handleOpenPreview(file, company_id)}
            type="button">
            <img
              src={getFileImage(file, company_id)}
              alt="Receipt"
              width="70"
              loading="lazy"
            />
          </button>
        ),
      },
      {
        Header: `${formatMessage({id: 'title'})}`,
        accessor: 'title',
      },
      {
        Header: `${formatMessage({id: 'category'})}`,
        accessor: 'category_id.name',
      },
      {
        Header: `${formatMessage({id: 'job-location'})}`,
        // eslint-disable-next-line react/destructuring-assignment
        accessor: (row) => <Badge variant="info">{row.work_id.name}</Badge>,
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: ({amount}) => currencyFormat(amount?.$numberDecimal),
      },
      {
        Header: `${formatMessage({id: 'date'})}`,
        accessor: 'period',
        Cell: ({value}) => <FormatDate value={value} pattern="P" />,
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex gap-2">
            <IconButton
              icon={TrashIcon}
              onClick={() =>
                setDeleteModal({open: true, id: row.original?._id})
              }
            />
            <IconButton
              icon={ChevronRightIcon}
              onClick={() =>
                setModal({type: 'edit', open: true, data: row.original})
              }
            />
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  const fetchExtras = async (doLoading = true) => {
    if (doLoading && !extras.loading) setExtras({loading: true, data: []});

    const {data} = await ExtrasHTTP.getExtraBills({
      page: currentPage,
      ...filters,
      ...(search ? {title: search} : {}),
    });

    setExtras({
      loading: false,
      data,
    });
  };

  const handleFilters = (type, selected) => {
    if (selected && selected.value) {
      setFilters((prev) => ({...prev, [type]: selected.value}));
    } else {
      setFilters((prev) => ({...prev, [type]: ''}));
    }
  };

  const handleDelete = async () => {
    try {
      await ExtrasHTTP.deleteExtraBills(deleteModal.id);
      await fetchExtras(false);
      setDeleteModal({open: false, id: null});
    } catch {
      throw new Error();
    }
  };

  useEffect(() => {
    fetchExtras();
  }, [currentPage, filters, search]);

  useEffect(() => {
    async function getCategories() {
      const {data} = await ExtrasHTTP.getCategories({status: 'active'});
      setCategories({loading: false, data});
    }

    getJobs();
    getCategories();
  }, [formatMessage]);

  const searchDebounce = debounce(setSearch, 500);

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'bills'})}
        breadcrumbs={[
          {
            id: 'extras',
            label: formatMessage({id: 'extras'}),
          },
          {
            id: 'bills',
            label: formatMessage({id: 'bills'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() =>
              setModal({
                open: true,
                type: 'create',
                data: {},
              })
            }>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'extras.new-bill'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={extras.loading}
        toolbar={
          <>
            <div style={{width: 350}}>
              <SearchSelect
                placeholder={formatMessage({id: 'filter.job-location'})}
                options={jobs.data.map((j) => ({
                  value: j._id,
                  label: j.name,
                }))}
                onChange={(selected) => handleFilters('work', selected)}
              />
            </div>
            <div style={{width: 310}}>
              <SearchSelect
                placeholder={formatMessage({id: 'filter.category'})}
                options={categories.data.map((j) => ({
                  value: j._id,
                  label: j.name,
                }))}
                onChange={(selected) => handleFilters('category', selected)}
              />
            </div>
            <Search
              placeholder={formatMessage({id: 'search.title'})}
              onChange={(e) => searchDebounce(e.target.value)}
            />
          </>
        }>
        <Table columns={columns} data={extras.data.docs} />

        <Pagination
          totalPages={extras.data.totalPages}
          currentPage={currentPage}
          totalRecords={extras.data.totalDocs}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </SectionWrapper>

      <PreviewModal
        file={previewFile.file}
        open={previewFile.open}
        onClose={() => setPreviewFile({open: false, file: ''})}
      />

      <FormModal
        open={modal.open}
        onClose={() => setModal((prev) => ({...prev, data: {}, open: false}))}
        onSuccess={() => {
          fetchExtras(!modal.data);
          setModal((prev) => ({...prev, open: false}));
        }}
        id={modal.data?._id}
        initialData={
          modal.data && modal.type === 'edit'
            ? {
                amount: modal.data?.amount?.$numberDecimal,
                category_id: modal.data?.category_id?._id,
                work_id: modal.data?.work_id,
                title: modal.data?.title,
                description: modal.data?.description,
                period: new Date(modal.data?.period),
                file: modal.data?.file,
              }
            : {}
        }
      />

      <ConfirmationModal
        open={deleteModal.open}
        onClose={() => setDeleteModal({open: false, id: null})}
        onSubmit={handleDelete}
        title={formatMessage({id: 'extras.delete-bill'})}
      />
    </>
  );
};

export default ExtraBills;
