import {useCallback, useState, useMemo} from 'react';
import {createContext} from 'use-context-selector';
import {IntlProvider} from 'react-intl';

import {useAuth} from '../../auth/hooks/useAuth';
import messages from '../utils/langs';

export const TranslationContext = createContext({});

export function TranslationProvider({children}) {
  const {user} = useAuth();

  const [currentLang, setCurrentLang] = useState(() => {
    // const userData = localStorage.getItem('@TrackMyGuys:user');
    // const user = JSON.parse(userData);
    if (user && user.language) {
      if (['pt', 'pt-br', 'portuguese'].includes(user.language)) return 'pt-BR';
      // return user.language; //TO DO, TEM QUE IMPLEMENTAR A TRADUCAO E IF PARA 'PORTUGUESE', TEM ALGUNS USERS QUE VEM ASSIM
      return 'en';
    }

    if (navigator && navigator.language) {
      const langs = ['pt-BR', 'en'];
      if (langs.includes(navigator.language)) return navigator.language;
    }

    return 'en';
  });

  const onChangeLang = useCallback((lang) => {
    setCurrentLang(lang);
  }, []);

  const values = useMemo(
    () => ({onChangeLang, lang: currentLang}),
    [onChangeLang, currentLang],
  );

  return (
    <TranslationContext.Provider value={values}>
      <IntlProvider locale={currentLang} messages={messages[currentLang]}>
        {children}
      </IntlProvider>
    </TranslationContext.Provider>
  );
}
