import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import {
  CurrencyDollarIcon,
  UsersIcon,
  UserPlusIcon,
  ReceiptRefundIcon,
} from '@heroicons/react/24/outline';

import {currencyFormat} from '../../../../../../utils/currency';
import {DashboardHTTP} from '../../../../providers/dashboard';
import {useDashboard} from '../../../../hooks/useDashboard';

import Loading from '../../../../../../components/Loading';

const NumbersCard = () => {
  const {period} = useDashboard();
  const {startDate, endDate} = period;

  const {formatMessage} = useIntl();

  const [summary, setSummary] = useState({
    loading: true,
    data: {},
  });

  useEffect(() => {
    async function fetchSummary() {
      const {data} = await DashboardHTTP.getCompanyExpenses({
        start: startDate,
        end: endDate,
      });
      setSummary({
        loading: false,
        data: {
          employees:
            data.men_hours[0] && data.men_hours[0].amount
              ? data.men_hours[0].amount.$numberDecimal
              : 0.0,
          extra_men:
            data.extraEmployees[0] && data.extraEmployees[0].amount
              ? data.extraEmployees[0].amount.$numberDecimal
              : 0.0,
          receipts: parseFloat(
            data.receipts[0] && data.receipts[0].total
              ? data.receipts[0].total.$numberDecimal
              : 0.0,
          ),
          boss_receipts: parseFloat(
            data.expenses[0] && data.expenses[0].total
              ? data.expenses[0].total.$numberDecimal
              : 0.0,
          ),
          total: data.total,
        },
      });
    }

    fetchSummary();
  }, [startDate, endDate]);

  return (
    <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <CurrencyDollarIcon
          className="h-6 w-6 text-yellow-500"
          aria-hidden="true"
        />
        <div className="ml-4">
          <h3 className="text-gray-500">
            {formatMessage({id: 'dashboard.total-spent'})}
          </h3>
          {summary.loading ? (
            <Loading />
          ) : (
            <p className="text-2xl font-bold mb-0">
              {currencyFormat(summary.data.total)}
            </p>
          )}
          <span className="text-xs text-gray-400">
            {formatMessage({id: 'dashboard.total-spent'})}
          </span>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <UsersIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        <div className="ml-4">
          <h3 className="text-gray-500">
            {formatMessage({id: 'dashboard.employes'})}
          </h3>
          {summary.loading ? (
            <Loading />
          ) : (
            <p className="text-2xl font-bold mb-0">
              {currencyFormat(summary.data.employees)}
            </p>
          )}
          <span className="text-xs text-gray-400">
            {formatMessage({id: 'dashboard.employes.payments'})}
          </span>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <ReceiptRefundIcon
          className="h-6 w-6 text-yellow-500"
          aria-hidden="true"
        />
        <div className="ml-4">
          <h3 className="text-gray-500">
            {formatMessage({id: 'dashboard.receipts'})}
          </h3>
          {summary.loading ? (
            <Loading />
          ) : (
            <p className="text-2xl font-bold mb-0">
              {currencyFormat(
                parseFloat(summary.data.receipts + summary.data.boss_receipts),
              )}
            </p>
          )}
          <span className="text-xs text-gray-400">
            {formatMessage({id: 'dashboard.boss-plus-employees'})}
          </span>
        </div>
      </div>
      <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
        <UserPlusIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
        <div className="ml-4">
          <h3 className="text-gray-500">
            {formatMessage({id: 'dashboard.extra-men'})}
          </h3>
          {summary.loading ? (
            <Loading />
          ) : (
            <p className="text-2xl font-bold mb-0">
              {currencyFormat(summary.data.extra_men)}
            </p>
          )}
          <span className="text-xs text-gray-400">
            {formatMessage({id: 'dashboard.extra-men-payments'})}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NumbersCard;
