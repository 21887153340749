import {useEffect, useMemo, useState, useCallback} from 'react';
import {
  SignalSlashIcon,
  MapPinIcon,
  ChatBubbleLeftIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {ReportsHTTP} from '../../../providers/reports';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import {FormatDate} from '../../../../../components/FormatDate';
import DropdownButton from '../../../../../components/DropdownButton';
import Table from '../../../../../components/Table';
import Badge from '../../../../../components/Badge';
import EmptyData from '../../../../../components/EmptyData';
import Pagination from '../../../../../components/Pagination';

import BossCommentModal from '../../Timecard/components/BossCommentModal';
import UpdatePunchModal from '../../Timecard/components/UpdatePunchModal';
import DeletePunchModal from '../../Timecard/components/DeletePunchModal';

const PunchesMissedOut = () => {
  const {formatMessage} = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [punches, setPunches] = useState({
    loading: true,
    data: {docs: []},
  });
  const [actionsModal, setActionsModal] = useState({
    type: '',
    open: false,
    punchId: '',
    params: {}, // params to
    punch: {},
  });

  const handleCloseActionsModal = () => {
    setActionsModal({
      type: '',
      open: false,
      punchId: '',
      params: {},
      punch: null,
    });
  };

  const fetchPunches = async () => {
    setPunches({loading: true, data: {docs: []}});
    const {data} = await ReportsHTTP.getPunchesMissedOut({
      paginate: true,
      page: currentPage,
    });
    setPunches({loading: false, data});
  };

  const checkIfOffline = (punch) => {
    if (punch.start_offline) return 'start';
    if (punch.end_offline) return 'end';
    if (punch.offline) return 'start';

    return 'online';
  };

  const renderJobLocationInfo = (work_name) => {
    if (!work_name) {
      return (
        <Badge variant="error" extraClassName="my-2">
          {formatMessage({id: 'timecard.without-job-location'})}
        </Badge>
      );
    }

    return (
      <Badge variant="info" extraClassName="my-2">
        <MapPinIcon className="w-4" /> {work_name}
      </Badge>
    );
  };

  useEffect(() => {
    fetchPunches();
  }, [currentPage]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'day'})}`,
        accessor: 'department_name',
        Cell: ({row}) => {
          const bossUpdated = row.original.boss_editing;
          const bossCreated = row.original.boss_created;
          const managerCreated = row.original.manager_created;

          return (
            <div className="flex flex-col items-start">
              <div className="flex mb-2">
                {bossUpdated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'update-by'})}:{' '}
                    {row.original.last_boss_edit_name}
                  </Badge>
                )}
                {bossCreated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'created-by-boss'})}
                  </Badge>
                )}
                {managerCreated && (
                  <Badge variant="danger">
                    {formatMessage({id: 'created-by-manager'})}
                  </Badge>
                )}
              </div>

              <p className="font-bold">
                {row.original.start ? (
                  <FormatDate
                    value={row.original.start.time}
                    pattern="MM/dd/yyyy"
                  />
                ) : (
                  formatMessage({id: 'timecard.puch-with-issue'})
                )}
              </p>
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'employee'})}`,
        accessor: 'employee_name',
        Cell: ({row}) => (
          <div className="flex flex-col items-start">
            <p className="font-bold">{row.original.employee_name}</p>
            <p className="text-xs text-gray-600">
              {row.original.department_name} - Max shift:{' '}
              {row.original.max_shift}
            </p>
          </div>
        ),
      },
      {
        Header: `${formatMessage({id: 'in'})}`,
        accessor: 'start',
        Cell: ({row}) => {
          const {original} = row;
          const {start, work_name} = original;
          return (
            <div style={{maxWidth: '30vw'}}>
              {start ? (
                <div className="flex flex-col items-start">
                  {checkIfOffline(original) === 'start' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" />{' '}
                      {formatMessage({id: 'offline'})}
                    </Badge>
                  )}
                  <button
                    type="button"
                    className="max-w-full break-all whitespace-normal text-left text-yellow-600 font-bold text-xs 2xl:text-sm hover:text-yellow-700"
                    title={start.location.address}
                    onClick={() =>
                      setActionsModal({
                        type: 'view-map',
                        open: true,
                        punch: row.original,
                        params: {
                          coordinates: row.original.start.location.coordinates,
                          address: row.original.start.location.address,
                          city: row.original.start.location.city,
                        },
                      })
                    }>
                    {start.location.address}
                  </button>
                  <p className="text-xs 2xl:text-sm">
                    <FormatDate value={start.time} />
                  </p>

                  {renderJobLocationInfo(work_name)}
                </div>
              ) : (
                <p className="text-xs 2xl:text-sm">
                  {formatMessage({id: 'timecard.puch-with-issue'})}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <DropdownButton
            label="Edit"
            options={[
              {
                id: 'boss-comment',
                label: formatMessage({id: 'boss-comment'}),
                icon: ChatBubbleLeftIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'bossComments',
                    open: true,
                    punchId: row.original._id,
                    params: {
                      boss_comment: row.original.boss_comment
                        ? row.original.boss_comment
                        : '',
                    },
                  }),
              },
              {
                id: 'edit-punch',
                label: formatMessage({id: 'edit-hours'}),
                icon: PencilSquareIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'punch',
                    open: true,
                    punchId: row.original._id,
                    params: row.original,
                  }),
              },
              {
                id: 'delete-punch',
                label: formatMessage({id: 'delete-punch'}),
                icon: TrashIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'delete',
                    open: true,
                    punchId: row.original._id,
                    params: {},
                  }),
              },
            ]}
          />
        ),
      },
    ],
    [formatMessage],
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({row, hasBg}) => (
      <>
        <td
          className={`px-6 py-4 text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={2}>
          <Badge variant="secondary">
            {formatMessage({id: 'employee-comment'})}
          </Badge>
          <pre className="w-full max-w-lg whitespace-normal text-xs leading-3 mt-2">
            <code>
              {row.original.comments
                ? row.original.comments
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
        <td
          className={`px-6 py-4 text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={2}>
          <Badge variant="secondary">
            {formatMessage({id: 'boss-comment'})}
          </Badge>
          <pre className="w-full max-w-lg whitespace-normal text-xs leading-3 mt-2">
            <code>
              {row.original.boss_comment && row.original.boss_comment.comment
                ? row.original.boss_comment.comment
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
      </>
    ),
    [],
  );

  const isLoading = punches.loading;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'timecard.missed-out'})}
        breadcrumbs={[
          {
            id: 'reports-missed-out',
            label: formatMessage({id: 'menu.reports.punches-missed-out'}),
          },
        ]}
      />

      <SectionWrapper isLoading={isLoading}>
        {punches.data.docs.length === 0 ? (
          <EmptyData
            description={formatMessage({id: 'report.no-missed-punches'})}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={punches.data.docs}
              hasOver
              renderRowSubComponent={renderRowSubComponent}
              showComment
            />
            <Pagination
              totalPages={punches.data.totalPages}
              currentPage={currentPage}
              totalRecords={punches.data.totalDocs}
              onChangePage={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </SectionWrapper>

      <BossCommentModal
        open={actionsModal.open && actionsModal.type === 'bossComments'}
        punchId={actionsModal.punchId}
        bossComment={
          actionsModal.params &&
          Object.prototype.hasOwnProperty.call(
            actionsModal.params,
            'boss_comment',
          )
            ? actionsModal.params.boss_comment.comment
            : ''
        }
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchPunches();
          handleCloseActionsModal();
        }}
      />

      {actionsModal.open && actionsModal.type === 'punch' && (
        <UpdatePunchModal
          open={actionsModal.open && actionsModal.type === 'punch'}
          punchId={actionsModal.punchId}
          punch={actionsModal.params}
          onClose={() => handleCloseActionsModal()}
          onSuccess={() => {
            fetchPunches();
            handleCloseActionsModal();
          }}
        />
      )}

      <DeletePunchModal
        open={actionsModal.open && actionsModal.type === 'delete'}
        punchId={actionsModal.punchId}
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchPunches();
          handleCloseActionsModal();
        }}
      />
    </>
  );
};

export default PunchesMissedOut;
