import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import {apiAdonis} from '../../../../../../services/api';
import {useAuth} from '../../../../../auth/hooks/useAuth';

import Modal from '../../../../../../components/_modals/Modal';
import Input from '../../../../../../components/_form/Input';
import Button from '../../../../../../components/Button';
import {LoadingBox} from '../../../../../../components/LoadingBox';

const TradeFormModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {user} = useAuth();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({});

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        isGlobal: true,
        companyId: user.companyId,
      };
      await apiAdonis.post('/trades', payload);

      toast.success('Trade created successfully!');

      onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        maxWidth="max-w-xl"
        open={open}
        onClose={onClose}
        title="Create a Trade">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Name *"
                id="name"
                error={errors.name}
                errorMessage={errors.name?.message}
                {...register('name', {required: true})}
              />
            </div>
          </div>

          <div className="form-footer">
            <Button type="submit" loading={loading}>
              Save
            </Button>
            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => onClose()}
              extraClassName="mr-2">
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      {loading && <LoadingBox info="Creating trade..." />}
    </>
  );
};

export default TradeFormModal;
