import {useAuth} from '../../../auth/hooks/useAuth';

import Admin from './Admin';

const Dashboard = () => {
  const {user} = useAuth();

  if (user.role === 'admin') return <Admin />;

  return <div>Welcome</div>;
};

export default Dashboard;
