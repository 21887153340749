/* eslint-disable no-case-declarations */
import {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {useEmployee} from '../../../../hooks/useEmployee';

import PageHeader from '../../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../../components/SectionWrapper';

import EmployeeForm from '../components/Form';
import Timeline from './Timeline';
import Punches from './Punches';
import Devices from './Devices';

const ProfilePage = () => {
  const {id} = useParams();
  const {getEmployee, employee, updateEmployee} = useEmployee();
  const {formatMessage} = useIntl();

  const [activeTab, setActiveTab] = useState('edit');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    getEmployee(id);
  }, [id]);

  const handleUpdate = useCallback(
    async ({password, ...rest}) => {
      try {
        setUpdateLoading(true);
        await updateEmployee(id, {...rest, ...(password ? {password} : {})});
        toast.success('Done! employee updated successfully.');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.field) {
          setErrors(err.response.data);
        }
      } finally {
        setUpdateLoading(false);
      }
    },
    [id],
  );

  const tabItems = [
    {
      value: 'edit',
      label: formatMessage({id: 'edit'}),
    },
    {
      value: 'timeline',
      label: formatMessage({id: 'timeline'}),
    },
    {
      value: 'punches',
      label: formatMessage({id: 'punches'}),
    },
    {
      value: 'devices',
      label: formatMessage({id: 'devices'}),
    },
  ];

  const handleRenderTabs = () => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case 'edit':
        // eslint-disable-next-line prefer-object-spread
        const initialDataEdit = Object.assign({}, employee.data);
        delete initialDataEdit.company_id;
        delete initialDataEdit.created_at;
        delete initialDataEdit.updated_at;
        delete initialDataEdit.super;
        delete initialDataEdit.role;

        return (
          <EmployeeForm
            onSubmit={(data) => handleUpdate(data)}
            initialData={{
              ...initialDataEdit,
              hourprice: employee.data.hourprice
                ? employee.data.hourprice.$numberDecimal
                : 0.0,
            }}
            type="edit"
            loading={updateLoading}
            submitErrors={errors}
          />
        );
      case 'timeline':
        return <Timeline userId={id} />;
      case 'punches':
        return <Punches userId={id} />;
      case 'devices':
        return <Devices userId={id} />;
      //   default:
    }

    return true;
  };

  return (
    <>
      <PageHeader
        title={`${employee.loading ? 'loading...' : employee.data.name}`}
        breadcrumbs={[
          {
            id: 'users',
            label: formatMessage({id: 'menu.users'}),
          },
          {
            id: 'employees',
            label: formatMessage({id: 'menu.employees'}),
          },
          {
            id: 'profile',
            label: formatMessage({id: 'profile'}),
          },
        ]}
      />

      <SectionWrapper
        isLoading={employee.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}>
        {handleRenderTabs()}
      </SectionWrapper>
    </>
  );
};

export default ProfilePage;
