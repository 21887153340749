import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

const Search = ({placeholder = 'Search', ...rest}) => (
  <div className="flex relative items-center">
    <MagnifyingGlassIcon
      className="h-5 w-5 absolute index-10 left-2 top-2 text-gray-400"
      aria-hidden="true"
    />
    <input
      className="pl-10 md:pl-8 focus:ring-yellow-200 focus:border-yellow-100 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      style={{minHeight: 38}}
      type="text"
      placeholder={placeholder}
      {...rest}
    />
  </div>
);

export default Search;
