import {useEffect, useState, useMemo} from 'react';
import {
  SignalSlashIcon,
  TrashIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useJobLocation} from '../../../hooks/useJobLocation';
import DropdownButton from '../../../../../components/DropdownButton';
import Map from '../../../../../components/Map';

import {ReportsHTTP} from '../../../providers/reports';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import {FormatDate} from '../../../../../components/FormatDate';
import Badge from '../../../../../components/Badge';
import Pagination from '../../../../../components/Pagination';
import Table from '../../../../../components/Table';
import EmployeeSelect from '../../../../../components/EmployeeSelect';

import DeletePunchModal from './components/DeletePunchModal';
import UpdateJobLocationModal from './components/UpdateJobLocationModal';

const PunchesWoJobLocation = () => {
  const {formatMessage} = useIntl();
  const {jobs, getJobs} = useJobLocation();
  const navigate = useNavigate();
  const handleGoToPage = (id) => {
    navigate(`/timecard/${id}`, {preventScrollReset: false});
  };

  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [actionsModal, setActionsModal] = useState({
    type: '',
    open: false,
    punchId: '',
    params: {}, // params to
  });
  const [punches, setPunches] = useState({
    loading: true,
    data: {},
  });
  const [currentPage, setCurrentPage] = useState(1);

  const checkIfOffline = (punch) => {
    if (punch.start_offline) return 'start';
    if (punch.end_offline) return 'end';
    if (punch.offline) return 'start';

    return 'online';
  };

  async function fetchPunchesWoJobLocation() {
    setPunches({
      data: [],
      loading: true,
    });
    const {data} = await ReportsHTTP.getPunchesNoJob({
      paginate: true,
      page: currentPage,
      ...(selectedEmployee ? {employee: selectedEmployee} : {}),
    });
    setPunches({
      data,
      loading: false,
    });
  }

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    fetchPunchesWoJobLocation();
  }, [selectedEmployee]);

  const handleCloseActionsModal = () => {
    setActionsModal({type: '', open: false, punchId: '', params: {}});
  };

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'employee'})}`,
        accessor: 'employee_name',
        Cell: ({row}) => (
          <>
            <button
              style={{border: 0, background: 'transparent', cursor: 'pointer'}}
              type="button"
              className="font-bold text-xs"
              onClick={() => handleGoToPage(row.original.user_id._id)}>
              {row.original.employee_name}
            </button>
            <p className="text-xs">{row.original.department_name}</p>
          </>
        ),
      },
      {
        Header: `${formatMessage({id: 'in-out'})}`,
        accessor: 'start',
        Cell: ({row}) => {
          const {start, end, missed_out} = row.original;
          return (
            <div className="max-w-xs 2xl:max-w-md oveflow-hidden">
              {start ? (
                <div className="flex flex-col items-start max-w-md md:max-w-xs">
                  {checkIfOffline(row.original) === 'start' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" />{' '}
                      {formatMessage({id: 'offline'})}
                    </Badge>
                  )}
                  <p className="text-green-600 font-medium text-xs truncate w-full">
                    {start.location.address}
                  </p>
                  <p className="text-xs">
                    <FormatDate value={start.time} />
                  </p>
                </div>
              ) : (
                <p>{formatMessage({id: 'timecard.puch-with-issue'})}</p>
              )}
              <hr className="my-2" />
              {end ? (
                <div className="flex flex-col items-start max-w-md md:max-w-xs">
                  {checkIfOffline(row.original) === 'end' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" />{' '}
                      {formatMessage({id: 'offline'})}
                    </Badge>
                  )}
                  <p className="text-red-600 font-medium text-xs truncate w-full">
                    {end.location.address}
                  </p>
                  {end.time && (
                    <p className="text-xs">
                      <FormatDate value={end.time} />
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {row.original && row.original.in && (
                    <Badge variant="success">{formatMessage({id: 'in'})}</Badge>
                  )}
                  {missed_out && (
                    <Badge variant="error">
                      {formatMessage({id: 'timecard.missed-out'})}
                    </Badge>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'total'})}`,
        accessor: 'hours',
        Cell: ({row}) => (
          <p className="text-xs">
            {row.original.hours ? row.original.hours : '0.00'}
          </p>
        ),
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: '_id',
        Cell: ({row}) => (
          <DropdownButton
            label={formatMessage({id: 'edit'})}
            options={[
              {
                id: 'update-job-location',
                label: formatMessage({id: 'assign-job-location'}),
                icon: MapPinIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'jobLocation',
                    open: true,
                    punchId: row.original._id,
                    params: {
                      work_id: row.original.work_id
                        ? row.original.work_id
                        : null,
                    },
                  }),
              },

              {
                id: 'delete-punch',
                label: formatMessage({id: 'delete-punch'}),
                icon: TrashIcon,
                onClick: () =>
                  setActionsModal({
                    type: 'delete',
                    open: true,
                    punchId: row.original._id,
                    params: {},
                  }),
              },
            ]}
          />
        ),
      },
    ],
    [formatMessage],
  );

  return (
    <>
      <PageHeader
        title={`${formatMessage({
          id: 'report.punches-without-job-location',
        })}: ${punches.data.totalDocs}`}
        breadcrumbs={[
          {
            id: 'reports',
            label: formatMessage({id: 'menu.reports'}),
          },
          {
            id: 'punchesworeports',
            label: formatMessage({id: 'report.punches-without-job-location'}),
          },
        ]}
        rightContent={
          <div style={{minWidth: 280}}>
            <EmployeeSelect
              onSelect={(e) => setSelectedEmployee(e && e.value ? e.value : '')}
            />
          </div>
        }
      />

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
        <div className="bg-white md:rounded-lg shadow-md overflow-hidden mb-6">
          {!jobs.loading && !punches.loading && (
            <Map
              circles={jobs.data}
              points={punches.data.docs}
              initialData={{
                coordinates: punches.data.docs[0]?.start?.location?.coordinates,
                address: punches.data.docs[0]?.start?.location?.address,
                city: punches.data.docs[0]?.start?.location?.city,
              }}
            />
          )}
        </div>

        <div className="col-span-1 bg-white md:rounded-lg shadow-md overflow-hidden 2xl:col-span-2">
          {punches.loading ? (
            <h1 className="p-3">{formatMessage({id: 'loading'})}</h1>
          ) : (
            <>
              <Table columns={columns} data={punches.data.docs} />

              <Pagination
                totalPages={punches.data.totalPages}
                currentPage={currentPage}
                totalRecords={punches.data.totalDocs}
                onChangePage={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </div>
      </div>

      <DeletePunchModal
        open={actionsModal.open && actionsModal.type === 'delete'}
        punchId={actionsModal.punchId}
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchPunchesWoJobLocation();
          handleCloseActionsModal();
        }}
      />

      <UpdateJobLocationModal
        open={actionsModal.open && actionsModal.type === 'jobLocation'}
        punchId={actionsModal.punchId}
        selectedWork={
          Object.prototype.hasOwnProperty.call(actionsModal.params, 'work_id')
            ? actionsModal.params.work_id
            : null
        }
        onClose={() => handleCloseActionsModal()}
        onSuccess={() => {
          fetchPunchesWoJobLocation();
          handleCloseActionsModal();
        }}
      />
    </>
  );
};

export default PunchesWoJobLocation;
