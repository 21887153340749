import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import {apiAdonis} from '../../../../../../../services/api';

import Modal from '../../../../../../../components/_modals/Modal';
import Input from '../../../../../../../components/_form/Input';
import Button from '../../../../../../../components/Button';
import {LoadingBox} from '../../../../../../../components/LoadingBox';

const ItemFormModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  tradeId = '',
  item = null,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({defaultValues: {name: item?.name}});

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (item) {
        const payload = {
          name: data.name,
        };
        await apiAdonis.put(`/trade-items/${item.id}`, payload);
      } else {
        const payload = {
          ...data,
          tradeId,
        };
        await apiAdonis.post('/trade-items', payload);
      }

      toast.success('Item created successfully!');

      onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue('name', item ? item.name : '');
  }, [item]);

  return (
    <>
      <Modal
        maxWidth="max-w-xl"
        open={open}
        onClose={onClose}
        title={item ? 'Edit a Item' : 'Create a Item'}>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Name *"
                id="name"
                error={errors.name}
                errorMessage={errors.name?.message}
                {...register('name', {required: true})}
              />
            </div>
          </div>

          <div className="form-footer">
            <Button type="submit" loading={loading}>
              Save
            </Button>
            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => onClose()}
              extraClassName="mr-2">
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      {loading && <LoadingBox info="Save item..." />}
    </>
  );
};

export default ItemFormModal;
