import {useIntl} from 'react-intl';
import {useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {format as formatDate} from 'date-fns';

import {DashboardHTTP} from '../../../../providers/dashboard';
import {useDashboard} from '../../../../hooks/useDashboard';
import {currencyFormat} from '../../../../../../utils/currency';
import {isValidDate} from '../../../../../../utils/date';

import Card from '../../../../../../components/Card';
import Loading from '../../../../../../components/Loading';

const CustomTooltip = ({active, payload, label}) => {
  Sentry.setContext('CustomTooltip', {
    label,
    pl: payload,
  });

  if (active && payload && payload.length) {
    const month =
      // eslint-disable-next-line radix
      parseInt(label.substr(4, 2)) < 10
        ? `0${label.substr(4, 2)}`
        : label.substr(4, 2);

    return (
      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-yellow-100 p-2">
          <p className="text-sm font-semibold text-yellow-600">
            {formatDate(
              new Date(`${label.substr(0, 4)}-${month}-02`),
              'MMMM yyyy',
            )}
          </p>
        </div>
        <p className="p-2 text-sm">
          Expenses: {currencyFormat(payload[0].value)}
        </p>
      </div>
    );
  }

  return null;
};

const CustomizedAxisTick = ({x, y, payload}) => {
  if (!payload || !payload.value) return <g />;

  const currDate = new Date(
    `${payload.value.substr(0, 4)}-${payload.value.substr(4, 2)}-02`,
  );

  if (!isValidDate(currDate)) return <g />;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-30} y={0} dy={12} className="text-xs" fill="#999">
        {formatDate(currDate, 'MMM yyyy')}
      </text>
    </g>
  );
};

const CustomizedAxisTickY = ({x, y, payload}) => (
  <g transform={`translate(${x},${y})`}>
    <text x={-28} y={0} dy={0} className="text-xs" fill="#666">
      {payload.value}
    </text>
  </g>
);

const MonthlyExpenses = () => {
  const {period} = useDashboard();
  const {startDate, endDate} = period;

  const {formatMessage} = useIntl();

  const [report, setReport] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    async function getReport() {
      setReport({loading: true});
      const response = await DashboardHTTP.getMonthlyExpenses({
        start: startDate,
        end: endDate,
      });
      const data = response.data.map((i) => ({
        amount: i.amount.$numberDecimal,
        label: `${i._id.year}${i._id.month}`,
        // eslint-disable-next-line radix
        ym: parseInt(
          `${i._id.year}${i._id.month < 10 ? `0${i._id.month}` : i._id.month}`,
        ),
      }));
      setReport({
        loading: false,
        data: data.sort((a, b) => a.ym - b.ym),
      });
    }

    getReport();
  }, [startDate, endDate]);

  return (
    <Card className="h-full">
      <h2 className="text-2xl font-bold text-gray-700">
        {formatMessage({id: 'dashboard.men-payments'})}
      </h2>
      <p className="text-gray-500 mb-4">
        {formatMessage({id: 'dashboard.men-payments-description'})}
      </p>
      {report.loading ? (
        <Loading />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={report.data}
            margin={{
              top: 30,
              right: 30,
              left: 0,
              bottom: 0,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tick={<CustomizedAxisTick />} />
            <YAxis
              dataKey="amount"
              type="number"
              tickCount={8}
              tick={<CustomizedAxisTickY />}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="amount"
              nameKey="label"
              stroke="#F59E0B"
              fill="#FCD34D"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default MonthlyExpenses;
