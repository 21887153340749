import {
  RectangleGroupIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  BoltIcon,
  MapPinIcon,
  BuildingOfficeIcon,
  IdentificationIcon,
  UserCircleIcon,
  ClipboardDocumentListIcon,
  DocumentPlusIcon,
  DocumentChartBarIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  CogIcon,
  UserPlusIcon,
  FolderPlusIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';

export default [
  {
    route: 'dashboard',
    routerGroup: ['dashboard'],
    label: 'menu.dashboard',
    icon: RectangleGroupIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/dashboard',
        label: 'menu.dashboard',
        icon: RectangleGroupIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#dashboard',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'users-menu',
    routerGroup: ['/employee', '/employees', '/boss'],
    label: 'menu.users',
    icon: UserGroupIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/employees',
        label: 'menu.employees',
        icon: UsersIcon,
      },
      {
        route: '/boss',
        label: 'menu.boss',
        icon: UserIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#users',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'company',
    routerGroup: ['/skills', '/job-location', '/departments'],
    label: 'menu.company',
    icon: BuildingOfficeIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/skills',
        label: 'menu.skills',
        icon: BoltIcon,
      },
      {
        route: '/job-location',
        label: 'menu.job-location',
        icon: MapPinIcon,
      },
      {
        route: '/departments',
        label: 'menu.departments',
        icon: IdentificationIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#company',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'book-podium',
    routerGroup: ['/customer'],
    label: 'menu.bookpodium',
    icon: BuildingOfficeIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/book-podium/customers',
        label: 'menu.bookpodium.customers',
        icon: UsersIcon,
      },
      {
        route: '/book-podium/estimates',
        label: 'menu.bookpodium.estimates',
        icon: DocumentTextIcon,
      },
      {
        route: '/book-podium/invoices',
        label: 'menu.bookpodium.invoices',
        icon: CurrencyDollarIcon,
      },
    ],
  },
  {
    route: 'who-is-in',
    routerGroup: ['/who-is-in'],
    label: 'menu.who-is-in',
    icon: UserCircleIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/who-is-in',
        label: 'menu.who-is-in',
        icon: UserCircleIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#TrackMyGuys',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'timecard',
    routerGroup: ['/timecard'],
    label: 'menu.timecard',
    icon: ClipboardDocumentListIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/timecard',
        label: 'menu.timecard',
        icon: ClipboardDocumentListIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#timecard',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'extras',
    routerGroup: [
      '/extras/bills',
      '/extras/guys',
      '/extras/employees-receipts',
      '/extras/receipts-category',
    ],
    label: 'menu.extras',
    icon: FolderPlusIcon,
    hasDropDown: true,
    dropDownItems: [
      // {
      //   route: '/material-supplier',
      //   label: 'Material Supplier',
      //   icon: BoltIcon,
      // },
      // {
      //   route: '/sub-contractor-invoice',
      //   label: 'Sub-contractor Invoices',
      //   icon: MapPinIcon,
      // },
      {
        route: '/extras/bills',
        label: 'menu.extras.bills',
        icon: DocumentPlusIcon,
      },
      {
        route: '/extras/guys',
        label: 'menu.extras.guys',
        icon: UserPlusIcon,
      },
      {
        route: '/extras/employees-receipts',
        label: 'menu.extras.employees-receipts',
        icon: MapPinIcon,
      },
      {
        route: '/extras/receipts-category',
        label: 'menu.extras.receipts-category',
        icon: DocumentPlusIcon,
      },
      // {
      //   route: '/payments-received',
      //   label: 'Payment Received',
      //   icon: MapPinIcon,
      // },
      {
        route: '#tutorial-modal',
        videoLink: '#extras',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: 'reports',
    routerGroup: [
      '/reports/job-location',
      '/reports/timecard',
      '/reports/punches-whitout-job-location',
      '/reports/punches-missed-out',
      '/reports/employee-earnings',
    ],
    label: 'menu.reports',
    icon: DocumentChartBarIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/reports/job-location',
        label: 'menu.reports.job-location',
        icon: MapPinIcon,
      },
      {
        route: '/reports/timecard',
        label: 'menu.reports.timecard',
        icon: DocumentPlusIcon,
      },
      {
        route: '/reports/employee-earnings',
        label: 'menu.reports.employee-earnings',
        icon: DocumentPlusIcon,
      },
      {
        route: '/reports/punches-whitout-job-location',
        label: 'menu.reports.punches-whitout-job-location',
        icon: DocumentPlusIcon,
      },
      {
        route: '/reports/punches-missed-out',
        label: 'menu.reports.punches-missed-out',
        icon: DocumentPlusIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#reports',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: '/settings',
    routerGroup: ['/settings'],
    label: 'menu.settings',
    icon: CogIcon,
    hasDropDown: true,
    dropDownItems: [
      {
        route: '/settings',
        label: 'menu.settings',
        icon: CogIcon,
      },
      {
        route: '#tutorial-modal',
        videoLink: '#settings',
        label: 'menu.tutorial',
        icon: VideoCameraIcon,
      },
    ],
  },
  {
    route: '/tutorial',
    routerGroup: ['/tutorial'],
    label: 'menu.tutorial',
    icon: VideoCameraIcon,
  },
];
