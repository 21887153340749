import {useState} from 'react';
import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import {clarity} from 'react-microsoft-clarity';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {ReportsHTTP} from '../../../providers/reports';

import Button from '../../../../../components/Button';
import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import SearchSelect from '../../../../../components/_form/SearchSelect';

const TimecardReports = () => {
  const {formatMessage} = useIntl();
  // eslint-disable-next-line no-unused-vars
  const {payPeriod, user, currentCompany} = useAuth();

  const [loading, setLoading] = useState(false);
  const [seletectedPeriod, setPeriod] = useState(null);

  const createdPDF = async () => {
    try {
      if (!seletectedPeriod) return;

      setLoading(true);

      if (clarity.hasStarted()) {
        clarity.setTag('timecard-pdf', 'new-pdfdownload');
      }

      await ReportsHTTP.newDownloadTimecardPDF({...seletectedPeriod.period});
      // const companyId = currentCompany || user.company_id;
      // if (
      //   ['5c13d5670919e005a4b6fe3a', '5ce3fa11ef18395f671243c0'].includes(
      //     companyId,
      //   )
      // ) {
      //   await ReportsHTTP.newDownloadTimecardPDF({...seletectedPeriod.period});
      // } else {
      //   await ReportsHTTP.downloadTimecardPDF({...seletectedPeriod.period});
      // }
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'menu.reports.timecard'})}
        breadcrumbs={[
          {
            id: 'reports',
            label: formatMessage({id: 'reports'}),
          },
          {
            id: 'timecard-pdf',
            label: formatMessage({id: 'menu.reports.timecard'}),
          },
        ]}
      />

      <SectionWrapper
        toolbarTitle={formatMessage({id: 'select-pay-period'})}
        toolbar={
          <>
            <div style={{width: 350}}>
              <SearchSelect
                placeholder={formatMessage({id: 'dashboard.select-period'})}
                options={payPeriod}
                onChange={(selected) => setPeriod(selected)}
                value={seletectedPeriod || ''}
              />
            </div>

            <Button
              extraClassName="shadow-sm ml-5"
              disabled={loading || !seletectedPeriod}
              onClick={() => createdPDF()}>
              <>
                <ArrowDownTrayIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                {loading
                  ? formatMessage({id: 'creating-pdf'})
                  : formatMessage({id: 'download-pdf'})}
              </>
            </Button>
          </>
        }>
        <div style={{height: 330}} />
      </SectionWrapper>
    </>
  );
};

export default TimecardReports;
