import {useEffect, useState, useMemo} from 'react';
import {
  PlusIcon,
  ChevronRightIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {useJobLocation} from '../../hooks/useJobLocation';
import {currencyFormat} from '../../../../utils/currency';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import {FormatDate} from '../../../../components/FormatDate';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import Search from '../../../../components/Search';
import Table from '../../../../components/Table';
import Badge from '../../../../components/Badge';
import Select from '../../../../components/_form/Select';

const JobLocationPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {getJobs, jobs, syncPunches} = useJobLocation();
  const {formatMessage} = useIntl();

  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [searchFiled, setSearchField] = useState('name');

  const tabItems = [
    {
      value: 'all',
      label: formatMessage({id: 'all'}),
    },
    {
      value: 'active',
      label: formatMessage({id: 'active'}),
    },
    {
      value: 'done',
      label: formatMessage({id: 'done'}),
    },
    {
      value: 'inactive',
      label: formatMessage({id: 'inactive'}),
    },
  ];

  useEffect(() => {
    getJobs({status: activeTab});
  }, [activeTab]);

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
        Cell: ({row, value}) => {
          const hasAddress =
            row.original.location &&
            row.original.location.coordinates &&
            row.original.location.coordinates[0] &&
            row.original.location.coordinates[1] &&
            row.original.location.address;

          return (
            <>
              <p>{value}</p>
              {hasAddress ? (
                <p className="text-xs text-gray-500">
                  {row.original.location.address}
                </p>
              ) : (
                <Badge variant="error">
                  {formatMessage({id: 'joblocation.no-adress'})}
                </Badge>
              )}
            </>
          );
        },
      },
      {
        accessor: 'budget.$numberDecimal',
        Header: `${formatMessage({id: 'budget'})}`,
        Cell: ({value}) => currencyFormat(value),
      },
      {
        accessor: 'status',
        Header: `${formatMessage({id: 'status'})}`,
      },
      {
        accessor: 'updated_at',
        Header: `${formatMessage({id: 'updated-at'})}`,
        Cell: ({value}) => <FormatDate value={value} />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex">
            <Button
              variant="secondary"
              extraClassName="shadow-sm mr-2"
              onClick={() => syncPunches(row.original._id)}>
              <>
                <ArrowPathIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                {intl.formatMessage({id: 'joblocation-sync-punches'})}
              </>
            </Button>
            <IconButton
              icon={ChevronRightIcon}
              onClick={() => navigate(`/job-location/${row.original._id}`)}
            />
          </div>
        ),
      },
    ],
    [formatMessage],
  );

  const filterJobs = () => {
    if (searchFiled === 'address') {
      return jobs.data.filter(
        (j) =>
          j.location &&
          j.location.address &&
          j.location.address.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (searchFiled === 'city') {
      return jobs.data.filter(
        (j) =>
          j.location &&
          j.location.city &&
          j.location.city.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return jobs.data.filter((j) =>
      j.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const jobsSearch = search.length > 0 ? filterJobs() : jobs.data;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'job-location'})}
        breadcrumbs={[
          {
            id: 'job-location',
            label: formatMessage({id: 'job-location'}),
          },
          {
            id: 'job-location-home',
            label: formatMessage({id: 'home'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => navigate('/job-location/new')}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {intl.formatMessage({id: 'new-jobLocation'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={jobs.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <>
            <div style={{minWidth: 120}}>
              <Select
                label=""
                id="search-field"
                options={[
                  {
                    label: formatMessage({id: 'name'}),
                    value: 'name',
                  },
                  {
                    label: formatMessage({id: 'address'}),
                    value: 'address',
                  },
                  {
                    label: formatMessage({id: 'city'}),
                    value: 'city',
                  },
                ]}
                defaultValue="name"
                value={searchFiled}
                onChange={(e) => setSearchField(e)}
                placeholder="Select the field to search"
              />
            </div>
            <Search
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </>
        }>
        <Table columns={columns} data={jobsSearch} />
      </SectionWrapper>
    </>
  );
};

export default JobLocationPage;
