import {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';

import Button from '../../../../../../components/Button';
import Checkbox from '../../../../../../components/_form/Checkbox';
import {FieldsetTitle} from '../../../../../../components/_form/FieldsetTitle';
import Input from '../../../../../../components/_form/Input';
import Select from '../../../../../../components/_form/Select';
import Switch from '../../../../../../components/Switch';

const DepartmentForm = ({
  onSubmit,
  onCancel = () => {},
  initialData = {},
  type = 'create',
  loading = false,
}) => {
  const {formatMessage} = useIntl();
  const days = [
    {
      id: 'sunday',
      week_day: 0,
      label: formatMessage({id: 'sunday'}),
      selected: false,
    },
    {
      id: 'monday',
      week_day: 1,
      label: formatMessage({id: 'monday'}),
      selected: true,
    },
    {
      id: 'tuesday',
      week_day: 2,
      label: formatMessage({id: 'tuesday'}),
      selected: true,
    },
    {
      id: 'wednesday',
      week_day: 3,
      label: formatMessage({id: 'wednesday'}),
      selected: true,
    },
    {
      id: 'thursday',
      week_day: 4,
      label: formatMessage({id: 'thursday'}),
      selected: true,
    },
    {
      id: 'friday',
      week_day: 5,
      label: formatMessage({id: 'friday'}),
      selected: true,
    },
    {
      id: 'saturday',
      week_day: 6,
      label: formatMessage({id: 'saturday'}),
      selected: true,
    },
  ];

  const isNew = type === 'create';
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: isNew
      ? {lunch_deduction_days: days}
      : {
          ...initialData,
          lunch_deduction_days:
            initialData.deduction_days && initialData.deduction_days.length > 0
              ? days.map((d) => ({
                  ...d,
                  selected: initialData.deduction_days.includes(d.week_day),
                }))
              : days,
        },
  });

  const [lunchDeductionActived, setLunchDeductionActived] = useState(() => {
    if (isNew) return true;

    if (initialData.deduction_after !== 'off') return true;

    return false;
  });
  const [overtimeActived, setOvertimeActived] = useState(() => {
    if (isNew) return false;

    if (initialData.overtime_active) return true;

    return false;
  });

  useEffect(() => {
    setValue('overtime_active', overtimeActived);
  }, [overtimeActived]);

  useEffect(() => {
    if (!lunchDeductionActived) setValue('deduction_after', 'off');
  }, [lunchDeductionActived]);

  return (
    <form
      className="flex flex-col justify-between"
      style={{minHeight: overtimeActived ? 400 : 300}}
      onSubmit={handleSubmit(onSubmit)}>
      <div className="form-content">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={formatMessage({id: 'name-required'})}
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
          <Controller
            name="max_shift"
            rules={{
              required: true,
            }}
            control={control}
            defaultValue={8}
            render={({field}) => (
              <Select
                {...field}
                label={formatMessage({id: 'max-shift'})}
                id="max_shift"
                error={errors.max_shift}
                options={[
                  {
                    value: 8,
                    label: `${String(8)} ${formatMessage({id: 'hours'})}`,
                  },
                  {
                    value: 10,
                    label: `${String(10)} ${formatMessage({id: 'hours'})}`,
                  },
                  {
                    value: 12,
                    label: `${String(12)} ${formatMessage({id: 'hours'})}`,
                  },
                  {
                    value: 14,
                    label: `${String(14)} ${formatMessage({id: 'hours'})}`,
                  },
                  {
                    value: 16,
                    label: `${String(16)} ${formatMessage({id: 'hours'})}`,
                  },
                  {
                    value: 18,
                    label: `${String(18)} ${formatMessage({id: 'hours'})}`,
                  },
                ]}
              />
            )}
          />
        </div>
        {type === 'edit' && (
          <div className="grid grid-cols-3 gap-4">
            <Controller
              name="status"
              control={control}
              defaultValue="active"
              render={({field}) => (
                <Select
                  {...field}
                  label="Status"
                  id="status"
                  options={[
                    {value: 'active', label: formatMessage({id: 'active'})},
                    {value: 'inactive', label: formatMessage({id: 'inactive'})},
                  ]}
                />
              )}
            />
          </div>
        )}

        <FieldsetTitle>
          {formatMessage({id: 'lunch-deduction'})}
          <Switch
            checked={lunchDeductionActived}
            onChange={setLunchDeductionActived}
            extraClassName="ml-4"
          />
        </FieldsetTitle>
        {lunchDeductionActived && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <Controller
                name="deduction_after"
                control={control}
                rules={{
                  required: lunchDeductionActived,
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({
                      id: 'departments.deduction-after-required',
                    })}
                    id="deduction_after"
                    error={errors.deduction_after}
                    options={[
                      {
                        value: '03:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(3)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '03:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(3.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '04:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(4)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '04:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(4.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '05:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '05:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(5.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '06:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(6)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '06:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(6.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '07:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(7)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '07:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(7.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '08:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(8)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '08:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(8.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '09:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(9)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '09:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(9.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '10:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(10)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '10:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(10.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '11:00',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(11)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                      {
                        value: '11:30',
                        label: `${formatMessage({
                          id: 'departments.after',
                        })} ${String(11.5)} ${formatMessage({
                          id: 'departments.hours-worked',
                        })}`,
                      },
                    ]}
                  />
                )}
              />
              <Controller
                name="lunch_deduction_time"
                control={control}
                rules={{
                  required: lunchDeductionActived,
                }}
                defaultValue="00:30"
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({
                      id: 'departments.deduction-time-required',
                    })}
                    id="lunch_deduction_time"
                    error={errors.lunch_deduction_time}
                    options={[
                      {
                        value: '00:10',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(10)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:15',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(15)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:20',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(20)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:24',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(24)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:30',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(30)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:40',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(40)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:42',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(42)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:45',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(45)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '00:55',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(55)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '01:00',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(60)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '01:15',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(75)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '01:30',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(90)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                      {
                        value: '02:00',
                        label: `${formatMessage({
                          id: 'departments.deduction-minutes',
                        })} ${String(120)} ${formatMessage({
                          id: 'departments.minutes',
                        })}`,
                      },
                    ]}
                  />
                )}
              />
            </div>

            <div className="mt-2">
              <p className="block text-sm font-medium text-gray-700 mb-1">
                {formatMessage({id: 'departments.deduction-days'})}
              </p>
              <div className="grid grid-cols-4 gap-4">
                {days.map((day, index) => (
                  <Controller
                    name={`lunch_deduction_days[${index}].selected`}
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        label={day.label}
                        id={`${day.id}-checkbox`}
                        checked={field.value}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </>
        )}

        <FieldsetTitle>
          {formatMessage({id: 'departments.overtime-settings'})}
          <Switch
            checked={overtimeActived}
            onChange={setOvertimeActived}
            extraClassName="ml-4"
          />
        </FieldsetTitle>
        <Input
          id="overtime_active"
          type="hidden"
          error={errors.overtime_active}
          {...register('overtime_active')}
        />
        <div className="grid grid-cols-2 gap-4">
          {overtimeActived && (
            <>
              <Controller
                name="overtime_hours"
                control={control}
                rules={{
                  required: overtimeActived,
                }}
                defaultValue={44}
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({
                      id: 'departments.overtime-after-required',
                    })}
                    id="overtime_hours"
                    error={errors.overtime_hours}
                    options={[
                      {
                        value: 32,
                        label: `${String(32)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 35,
                        label: `${String(35)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 40,
                        label: `${String(40)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 42,
                        label: `${String(42)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 43,
                        label: `${String(43)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 44,
                        label: `${String(44)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 45,
                        label: `${String(45)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 46,
                        label: `${String(46)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 48,
                        label: `${String(48)} ${formatMessage({id: 'hours'})}`,
                      },
                      {
                        value: 60,
                        label: `${String(60)} ${formatMessage({id: 'hours'})}`,
                      },
                    ]}
                  />
                )}
              />
              <Controller
                name="overtime_value"
                control={control}
                rules={{
                  required: overtimeActived,
                }}
                defaultValue="1.5"
                render={({field}) => (
                  <Select
                    {...field}
                    label={formatMessage({
                      id: 'departments.overtime-value-required',
                    })}
                    id="overtime_value"
                    error={errors.overtime_value}
                    options={[
                      {value: '1.5', label: '1.5 x'},
                      {value: '2', label: '2 x'},
                      {value: '2.5', label: '2.5 x'},
                      {value: '3', label: '3 x'},
                    ]}
                  />
                )}
              />
            </>
          )}
        </div>
      </div>

      <div className="form-footer">
        <Button type="submit" loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => onCancel()}
          extraClassName="mr-2">
          {formatMessage({id: 'cancel'})}
        </Button>
      </div>
    </form>
  );
};

export default DepartmentForm;
