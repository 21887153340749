import {CheckIcon} from '@heroicons/react/20/solid';

import Switch from '../../../../components/Switch';
import Button from '../../../../components/Button';

import logo from '../../../../assets/images/logos-inline-color.png';

const includedFeatures = [
  'WorkPodium System & Dashboard',
  'TrackMyGuys Employee Punch App',
  'Punch GPS Location',
  'Punch offline and online',
  'Timesheet Tracking Report',
  'Job Location Labor',
  'Material Track Report',
  'Timecard & Payroll PDF',
];

const MonthlyPlan = () => (
  <>
    <p className="mt-6 flex items-baseline justify-center gap-x-2">
      <span
        className="text-3xl font-bold tracking-tight text-gray-400"
        style={{textDecoration: 'line-through'}}>
        $69.99
      </span>
    </p>
    <p className="flex items-baseline justify-center gap-x-2 text-center">
      <span className="text-5xl font-bold tracking-tight text-gray-900">
        $49.99
      </span>
      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
        USD
      </span>
    </p>
  </>
);

const YearlyPlan = () => (
  <>
    <p className="mt-6 flex items-baseline justify-center gap-x-2">
      <span
        className="text-3xl font-bold tracking-tight text-gray-400"
        style={{textDecoration: 'line-through'}}>
        $670
      </span>
    </p>
    <p className="flex items-baseline justify-center gap-x-2 text-center">
      <span className="text-5xl font-bold tracking-tight text-gray-900">
        $399.99
      </span>
      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
        USD
      </span>
    </p>
    <p className="text-xs leading-5 text-gray-600">yearly</p>
  </>
);

const Plan = ({plan, onChangePlan, onSelectPlan}) => (
  <div className="relative bg-white border-t-4 border-yellow-500">
    <div className="bg-white pt-12 pb-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <img
            className="mx-auto h-12 w-auto mb-4"
            src={logo}
            alt="WorkPodium - Logo"
          />
          <h1 className="text-2xl font-extralight text-center lg:text-4xl">
            Select <span className="font-bold">your plan</span>
          </h1>
        </div>

        <div className="mx-auto mt-8 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-bold tracking-tight text-gray-900">
              WorkPodium
            </h3>
            {/* <p className="text-base leading-7 text-gray-400">Plan</p> */}
            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-yellow-500">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-yellow-600"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-yellow-400 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="flex items-center justify-center text-base font-semibold text-yellow-500">
                  <span className="text-sm text-gray-400 mr-2">Monthly</span>{' '}
                  <Switch
                    checked={plan === 'yearly'}
                    onChange={() =>
                      onChangePlan(plan === 'yearly' ? 'monthly' : 'yearly')
                    }
                    extraClassName="mr-2"
                  />{' '}
                  yearly 40% OFF
                </p>
                {plan === 'yearly' ? <YearlyPlan /> : <MonthlyPlan />}
                <Button
                  type="button"
                  className="mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                  onClick={() => onSelectPlan()}>
                  Start now
                </Button>
                {/* <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Plan;
