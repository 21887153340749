import {ChevronRightIcon} from '@heroicons/react/24/outline';

const PageHeader = ({title, breadcrumbs, rightContent}) => (
  <div className="lg:flex lg:items-center lg:justify-between mb-6 px-4 md:px-4">
    <div className="flex-1 min-w-0">
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        {title}
      </h2>
      <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
        {breadcrumbs.map((item, index) => (
          <div
            key={item.id}
            className="mt-2 flex items-center text-sm text-gray-500">
            {index > 0 && (
              <ChevronRightIcon
                className="flex-shrink-0 ml-1.5 mr-1.5 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            )}
            {item.label}
          </div>
        ))}
      </div>
    </div>
    {rightContent && (
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="sm:ml-3">{rightContent}</span>
      </div>
    )}
  </div>
);

export default PageHeader;
