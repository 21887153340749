import {forwardRef} from 'react';

const TextArea = forwardRef(
  (
    {
      label = '',
      id,
      error,
      className = 'mb-2',
      textAreaExtraClassName = '',
      ...rest
    },
    ref,
  ) => (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <textarea
        id={id}
        className={`mt-1 focus:ring-yellow-200 focus:border-yellow-100 block w-full shadow-sm sm:text-sm rounded-md placeholder-gray-400 ${
          error ? 'border-red-300 ' : 'border-gray-300 '
        } ${textAreaExtraClassName}`}
        ref={ref}
        {...rest}
      />
      {error && (
        <p className="text-xs text-red-500 mt-2">This field is required</p>
      )}
    </div>
  ),
);

export default TextArea;
