import {useContextSelector} from 'use-context-selector';
import {TranslationContext} from '../context/TranslationContext';

export function useTranslation() {
  const onChangeLang = useContextSelector(
    TranslationContext,
    (trs) => trs.onChangeLang,
  );
  const lang = useContextSelector(TranslationContext, (trs) => trs.lang);

  return {
    onChangeLang,
    lang,
  };
}
