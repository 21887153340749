import {useMemo, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {PunchHTTP} from '../../../../../providers/punch';

import Table from '../../../../../../../components/Table';
import Loading from '../../../../../../../components/Loading';
import Pagination from '../../../../../../../components/Pagination';
import {FormatDate} from '../../../../../../../components/FormatDate';

const TimelinePage = ({userId = ''}) => {
  const {formatMessage} = useIntl();
  const [punches, setPunches] = useState({
    loading: true,
    data: {},
  });
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useMemo(
    () => [
      {
        Header: formatMessage({id: 'check-in'}),
        accessor: 'start.time',
        Cell: ({row}) =>
          row.original.start ? (
            <FormatDate value={row.original.start.time} />
          ) : (
            'not provider'
          ),
      },
      {
        Header: formatMessage({id: 'check-out'}),
        accessor: 'end.time',
        Cell: ({row}) =>
          row.original.end ? (
            <FormatDate value={row.original.end.time} />
          ) : (
            'IN'
          ),
      },
      {
        Header: formatMessage({id: 'department'}),
        accessor: 'department_name',
        Cell: ({row}) => `${row.original.department_name}`,
      },
      {
        Header: formatMessage({id: 'menu.job-location'}),
        accessor: 'work_name',
        Cell: ({row}) =>
          `${
            row.original.work_name
              ? row.original.work_name
              : `Address: ${row.original.start.location.address}`
          }`,
      },
      {
        Header: formatMessage({id: 'hours'}),
        accessor: 'hours',
        Cell: ({row}) =>
          row.original.hours ? `${row.original.hours}` : '00:00',
      },
      {
        Header: formatMessage({id: 'app-version'}),
        accessor: 'device_info.app_version',
        Cell: ({row}) =>
          row.original.device_info
            ? `${row.original.device_info.app_version}`
            : '',
      },
      {
        Header: formatMessage({id: 'devices-info'}),
        accessor: 'device_info.model',
        Cell: ({row}) =>
          row.original.device_info ? `${row.original.device_info.model}` : '',
      },
    ],
    [],
  );

  useEffect(() => {
    async function fetchPunchesEmployees() {
      const {data} = await PunchHTTP.get({
        employee: userId,
        paginate: true,
        page: currentPage,
      });
      setPunches({loading: false, data});
    }
    fetchPunchesEmployees();
  }, [userId]);

  if (punches.loading)
    return (
      <div className="w-full flex justify-center p-8">
        <Loading />
      </div>
    );

  return (
    <>
      <Table columns={columns} data={punches.data.docs} />

      <Pagination
        totalPages={punches.data.totalPages}
        currentPage={currentPage}
        totalRecords={punches.data.totalDocs}
        onChangePage={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default TimelinePage;
