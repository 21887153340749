import {useState, useEffect, useCallback} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {BossHTTP} from '../../../../providers/boss';

import PageHeader from '../../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../../components/SectionWrapper';

import BossForm from '../components/Form';

const BossProfile = () => {
  const {formatMessage} = useIntl();
  const {id} = useParams();
  const navigate = useNavigate();

  const [boss, setBoss] = useState({
    loading: false,
    data: [],
  });
  const [activeTab, setActiveTab] = useState('edit');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const tabItems = [
    {
      value: 'edit',
      label: formatMessage({id: 'edit'}),
    },
  ];

  const fetchBoss = async () => {
    setBoss({loading: true});
    const {data} = await BossHTTP.getById(id);
    setBoss({loading: false, data});
  };

  useEffect(() => {
    fetchBoss();
  }, [id]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setSubmitLoading(true);
        await BossHTTP.update(id, values);

        toast.success('Boss updated successfully!');
        navigate('/boss');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.field) {
          setErrors(err.response.data);
        }
      } finally {
        setSubmitLoading(false);
      }
    },
    [id],
  );

  const handleRenderTabs = () => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case 'edit':
        return (
          <BossForm
            onSubmit={(data) => handleSubmit(data)}
            initialData={{
              ...boss.data,
            }}
            loading={submitLoading}
            submitErrors={errors}
            onCancel={() => navigate('/boss')}
          />
        );
    }

    return true;
  };

  return (
    <>
      <PageHeader
        title={boss.loading ? 'loading...' : boss.data.name}
        breadcrumbs={[
          {
            id: 'users',
            label: formatMessage({id: 'menu.users'}),
          },
          {
            id: 'profile',
            label: formatMessage({id: 'boss'}),
          },
          {
            id: 'profile',
            label: formatMessage({id: 'profile'}),
          },
        ]}
      />

      <SectionWrapper
        isLoading={boss.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}>
        {handleRenderTabs()}
      </SectionWrapper>
    </>
  );
};

export default BossProfile;
