import {useIntl} from 'react-intl';
import {useState, useLayoutEffect} from 'react';
import {
  BanknotesIcon,
  CurrencyDollarIcon,
  XCircleIcon,
  PlusCircleIcon,
  UsersIcon,
  UserPlusIcon,
  ReceiptRefundIcon,
} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';
import {format as formatDate} from 'date-fns';

import {currencyFormat} from '../../../../../../utils/currency';
import {DashboardHTTP} from '../../../../providers/dashboard';
// import {useDashboard} from '../../../../hooks/useDashboard';

import PieChart from '../../../../../../components/_charts/PieChart';
import Button from '../../../../../../components/Button';
import Loading from '../../../../../../components/Loading';
import Card from '../../../../../../components/Card';
import ProgressBar from '../../../../../../components/ProgressBar';

import AddJobModal from './AddJobModal';

const JobLocation = () => {
  // const {period} = useDashboard();
  // const {startDate, endDate} = period;
  const {formatMessage} = useIntl();
  const [startDate, endDate] = [
    '01-01-2018',
    formatDate(new Date(), 'MM-dd-yyyy'),
  ];
  const navigate = useNavigate();
  const [jobs, setJobs] = useState({
    loading: true,
    data: [],
  });
  const [selected, setSelected] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  async function getJobs() {
    setSelected({});
    setJobs((prev) => ({...prev, loading: true}));
    const {data} = await DashboardHTTP.getJobLocationReports({
      start: startDate,
      end: endDate,
    });
    setJobs({loading: false, data});
    setSelected(data[0] || {});
  }

  const handleRemoveJob = async (jobId = '') => {
    try {
      setRemoveLoading(true);
      await DashboardHTTP.updateJobs({work_id: jobId, remove: true});
      getJobs();
    } finally {
      setRemoveLoading(false);
    }
  };

  useLayoutEffect(() => {
    getJobs();
  }, [startDate, endDate]);

  const isLoading = jobs.loading || removeLoading;
  const hasSelectedJob = selected && selected.job;

  return (
    <>
      <div className="flex items-center justify-between mt-4 mb-2 p-2 bg-white rounded-lg border border-yellow-300">
        <h4>
          {formatMessage({id: 'dashboard.reports'})}{' '}
          <span className="font-bold">
            {formatMessage({id: 'dashboard.reports-one'})}
          </span>
          {formatMessage({id: 'dashboard.reports-two'})}
        </h4>
      </div>
      <Card className="h-full overflow-hidden">
        <h2 className="text-2xl font-bold text-gray-700">
          {formatMessage({id: 'dashboard.job-location'})}
        </h2>
        <p className="text-gray-500 mb-4">
          {formatMessage({id: 'dashboard.job-location-description'})}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 max-h-full">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="flex flex-col  overflow-y-auto">
              <ul className="flex flex-col w-full m-0 p-0">
                {jobs.data.map((item) => (
                  <li
                    key={item.job._id}
                    className={`flex group w-full mb-1 p-3 pl-1 rounded-lg hover:bg-gray-50 transition-colors duration-200 ${
                      hasSelectedJob && selected.job._id === item.job._id
                        ? 'bg-gray-50'
                        : ''
                    }`}>
                    <button
                      disabled={removeLoading}
                      onClick={() => handleRemoveJob(item.job._id)}
                      type="button"
                      className="bg-transparent border-0 hidden group-hover:block mr-1 transition-colors duration-200">
                      <XCircleIcon className="w-6 h-6 text-yellow-500 m-0" />
                    </button>
                    <button
                      type="button"
                      className="flex flex-col flex-grow"
                      onClick={() => setSelected(item)}>
                      <h3
                        className={`text-2xl font-bold ${
                          hasSelectedJob && selected.job._id === item.job._id
                            ? 'text-yellow-500'
                            : ''
                        }`}>
                        {currencyFormat(parseFloat(item.amount))}
                      </h3>
                      <p>{item.job.name}</p>

                      <ProgressBar
                        bgColor={item.bar_class}
                        progress={item.expend_percent_budget}
                      />
                    </button>
                  </li>
                ))}
                {jobs.data.length < 5 && (
                  <li className="flex group w-full mb-1 p-3 pl-1 rounded-lg hover:bg-gray-50 border-2 border-gray-200 border-dashed transition-colors duration-200">
                    <button
                      type="button"
                      onClick={() => setOpenAddModal(true)}
                      className="flex w-full justify-center items-center">
                      <PlusCircleIcon className="w-10 h-10 text-yellow-500 mr-2" />
                      <h3 className="text-xl font-bold text-gray-700">
                        {formatMessage({id: 'dashboard.add-job-location'})}
                      </h3>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}

          {hasSelectedJob && (
            <ul className="flex flex-col max-h-full rounded-lg bg-gray-100 shadow-lg">
              <li className="flex items-center border-b border-gray-200 p-3">
                <BanknotesIcon className="w-8 mr-2 text-yellow-500" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold">
                    {formatMessage({id: 'dashboard.job-location-budget'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({
                      id: 'dashboard.job-location-budget-description',
                    })}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(parseFloat(selected.budget))}
                </p>
              </li>
              <li className="flex items-center border-b border-gray-200 p-3">
                <CurrencyDollarIcon className="w-8 mr-2 text-yellow-500" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold">
                    {formatMessage({id: 'dashboard.total-spent'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({id: 'dashboard.job-location-total-spent'})}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(parseFloat(selected.amount))}
                </p>
              </li>
              <li className="flex items-center border-b border-gray-100 p-3 bg-gray-50">
                <UsersIcon className="w-6 mr-2 ml-4 text-gray-600" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold text-gray-700">
                    {' '}
                    {formatMessage({id: 'dashboard.employes'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({id: 'dashboard.job-employes-payments'})}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(parseFloat(selected.employees_amount))}
                </p>
              </li>
              <li className="flex items-center border-b border-gray-100 p-3 bg-gray-50">
                <UserPlusIcon className="w-6 mr-2 ml-4 text-gray-600" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold text-gray-700">
                    {formatMessage({id: 'dashboard.extra-men'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({
                      id: 'dashboard.job-location-extra-men-payments',
                    })}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(parseFloat(selected.extra_men_amount))}
                </p>
              </li>
              <li className="flex items-center border-b border-gray-100 p-3 bg-gray-50">
                <ReceiptRefundIcon className="w-6 mr-2 ml-4 text-gray-600" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold text-gray-700">
                    {formatMessage({id: 'dashboard.receipts'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({
                      id: 'dashboard.job-location-receipts-description',
                    })}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(
                    parseFloat(selected.employees_receipts_amount),
                  )}
                </p>
              </li>
              <li className="flex items-center border-b border-gray-100 p-3 bg-gray-50">
                <PlusCircleIcon className="w-6 mr-2 ml-4 text-gray-600" />
                <div className="flex flex-col flex-grow px-2">
                  <h4 className="text-lg font-bold text-gray-700">
                    {formatMessage({id: 'dashboard.job-location-extras'})}
                  </h4>
                  <p className="text-gray-500">
                    {formatMessage({
                      id: 'dashboard.job-location-extras-description',
                    })}
                  </p>
                </div>
                <p className="text-2xl font-bold">
                  {currencyFormat(parseFloat(selected.boss_receipts_amount))}
                </p>
              </li>
            </ul>
          )}

          {hasSelectedJob && (
            <div className="flex flex-col w-full justify-center items-center">
              <h2 className="w-full text-left text-2xl font-bold text-gray-700 m-0">
                {selected.job.name}
              </h2>
              <p className="w-full text-left text-gray-500 mb-4">
                {selected.job.location.address}
              </p>
              <div className="w-full mb-4" style={{height: 250}}>
                <PieChart
                  size={250}
                  data={[
                    {
                      label: 'Employees',
                      amount: parseFloat(selected.employees_amount),
                      valueFormatted: currencyFormat(
                        parseFloat(selected.employees_amount),
                      ),
                    },
                    {
                      label: 'Receipts',
                      amount: parseFloat(selected.employees_receipts_amount),
                      valueFormatted: currencyFormat(
                        selected.employees_receipts_amount,
                      ),
                    },
                    {
                      label: 'Extras',
                      amount: parseFloat(selected.boss_receipts_amount),
                      valueFormatted: currencyFormat(
                        selected.boss_receipts_amount,
                      ),
                    },
                    {
                      label: 'Extra Men',
                      amount: parseFloat(selected.extra_men_amount),
                      valueFormatted: currencyFormat(selected.extra_men_amount),
                    },
                  ]}
                />
              </div>
              <Button
                onClick={() =>
                  navigate(`/reports/job-location?id=${selected.job._id}`)
                }>
                {formatMessage({
                  id: 'dashboard.full-report',
                })}
              </Button>
            </div>
          )}
        </div>
      </Card>

      <AddJobModal
        open={openAddModal}
        onClose={() => {
          setOpenAddModal(false);
          getJobs();
        }}
      />
    </>
  );
};

export default JobLocation;
