import {useState} from 'react';
import api from '../services/api';

export function useFile() {
  const [uploading, setUploading] = useState(false);

  const upload = async (file, model) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('model', model);
      const {data} = await api.post('/files', formData);
      return data;
    } catch (error) {
      return {error: error.response.data || true};
    } finally {
      setUploading(false);
    }
  };

  return {upload, uploading};
}
