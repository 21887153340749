import {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ChatBubbleLeftEllipsisIcon} from '@heroicons/react/24/outline';

import {useUsers} from '../../../hooks/useUsers';
import {UsersHTTP} from '../../../providers/users';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import Tabs from '../../../../../components/Tabs';
import {LoadingBox} from '../../../../../components/LoadingBox';
import Button from '../../../../../components/Button';

import UserForm from '../components/Form';
import Timeline from './Timeline';
import Punches from './Punches';

const tabItems = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'timeline',
    label: 'Timeline',
  },
  {
    value: 'punches',
    label: 'Last Punches',
  },
  // {
  //   value: 'logs',
  //   label: 'Logs',
  // },
];

const ProfilePage = () => {
  const {id} = useParams();
  const {getUser, user: userData, updateUser} = useUsers();
  const {user, lastPunches} = userData.data;

  const [activeTab, setActiveTab] = useState('edit');
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);

  useEffect(() => {
    getUser(id);
  }, [id]);

  const handleUpdateUser = useCallback(async (payload) => {
    try {
      setUpdateUserLoading(true);
      await updateUser(user._id, payload);
      toast.success('Done! Updated successfully.');
    } catch (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (!error.response.data.hasOwnProperty('msg')) {
        toast.error('Something wrong! Please, try again.');
      }
    } finally {
      setTimeout(() => setUpdateUserLoading(false), 350);
    }
  }, []);

  const handleSendTextMessage = async () => {
    try {
      setSendSmsLoading(true);
      const {data} = await UsersHTTP.sendSMS(user._id);
      toast.success(data.msg);
    } catch (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (!error.response.data.hasOwnProperty('msg')) {
        toast.error('Something wrong! Please, try again.');
      }
    } finally {
      setTimeout(() => setSendSmsLoading(false), 350);
    }
  };

  const handleRenderTabs = () => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case 'edit':
        return (
          <UserForm
            onSubmit={(data) => handleUpdateUser(data)}
            initialData={{
              ...user,
              hourprice:
                user && user.hourprice ? user.hourprice.$numberDecimal : 0.0,
            }}
          />
        );
      case 'timeline':
        return <Timeline userId={id} />;
      case 'punches':
        return <Punches punches={lastPunches} />;
      //   default:
    }

    return true;
  };

  if (userData.loading) return <h1>loading...</h1>;

  return (
    <>
      <PageHeader
        title={user.name}
        breadcrumbs={[
          {
            id: 'users',
            label: 'Users',
          },
          {
            id: 'profile',
            label: 'Profile',
          },
        ]}
        rightContent={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {user.phone && (
              <Button
                extraClassName="shadow-sm"
                onClick={() => handleSendTextMessage()}>
                <>
                  <ChatBubbleLeftEllipsisIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Send Download app SMS
                </>
              </Button>
            )}
          </>
        }
      />

      <div className="flex px-4 md:px-4">
        <Tabs
          items={tabItems}
          onChange={(val) => setActiveTab(val)}
          actived={activeTab}
        />
      </div>

      <div className="bg-white md:rounded-lg shadow-md overflow-hidden">
        {handleRenderTabs()}
      </div>

      {updateUserLoading && <LoadingBox info="updating user..." />}
      {sendSmsLoading && <LoadingBox info="sending sms..." />}
    </>
  );
};

export default ProfilePage;
