import {useState} from 'react';
import {useIntl} from 'react-intl';

import {currencyFormat} from '../../../../../../../../utils/currency';

import Button from '../../../../../../../../components/Button';
import Card from '../../../../../../../../components/Card';
import Loading from '../../../../../../../../components/Loading';
import {FormatDate} from '../../../../../../../../components/FormatDate';

import PlansList from '../../../../../../../../components/PlansList';

const PlanAndSubscription = ({company, onRefetchCompany = () => {}}) => {
  const {formatMessage} = useIntl();
  const [updatePlan, setUpdatePlan] = useState(false);

  const {subscription} = company.data;
  const currentPlan = company.data.plan_id || null;

  return (
    <Card padding="p-0" className="overflow-hidden mb-6">
      <h2 className="pl-6 pt-4 text-lg font-medium text-gray-800">
        {formatMessage({id: 'subscription'})}
      </h2>
      {company.loading ? (
        <div className="m-4">
          <Loading />
        </div>
      ) : (
        <div className="p-6">
          {company.data.trial && (
            <p className="mb-2 text-gray-800">
              <span className="font-semibold">
                {formatMessage({id: 'settings.trial-expire'})}
              </span>{' '}
              <FormatDate
                value={company.data.expire_date || new Date()}
                pattern="MM/dd/yyyy"
              />
            </p>
          )}
          <p className="mb-2 text-gray-800">
            <span className="font-semibold">
              {formatMessage({id: 'settings.next-billing'})}
            </span>{' '}
            <FormatDate
              value={
                subscription.current_period_end
                  ? new Date(subscription.current_period_end * 1000)
                  : new Date()
              }
              pattern="MM/dd/yyyy"
            />
          </p>
          <p className="mb-2 text-gray-800">
            <span className="font-semibold">
              {formatMessage({id: 'settings.active-plan'})}
            </span>{' '}
            {currentPlan ? (
              <>
                {currentPlan.name} -{' '}
                {currencyFormat(currentPlan.price.$numberDecimal)}
              </>
            ) : (
              <p>{formatMessage({id: 'settings.no-current-plan'})}</p>
            )}
          </p>
          <Button onClick={() => setUpdatePlan((prev) => !prev)}>
            {formatMessage({id: 'settings.update-plan'})}
          </Button>

          {updatePlan && (
            <PlansList
              subscriptionId={subscription.id}
              currentPlan={currentPlan}
              onSuccess={() => {
                onRefetchCompany();
                setUpdatePlan(false);
              }}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default PlanAndSubscription;
