/* eslint-disable no-unused-vars */
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';

import Input from '../../../../../../../components/_form/Input';
import Select from '../../../../../../../components/_form/Select';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import CurrencyInput from '../../../../../../../components/_form/MaskedInput/Currency';
import Button from '../../../../../../../components/Button';

const UpdateForm = ({onSubmit, initialData, loading = false}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: initialData,
  });

  return (
    <div className="grid grid-cols-1 gap-4">
      <form
        className="bg-white rounded-lg overflow-hidden"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="grid grid-cols-1 gap-4">
            <Input
              label={formatMessage({id: 'job-name-required'})}
              id="name"
              type="text"
              error={errors.name}
              {...register('name', {required: true})}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <Controller
              rules={{
                required: true,
              }}
              name="budget"
              control={control}
              render={({field}) => (
                <CurrencyInput
                  {...field}
                  disabled
                  label={formatMessage({id: 'joblocation.initial-budget'})}
                  error={errors.budget}
                />
              )}
            />
            <Controller
              rules={{
                required: true,
              }}
              name="started_at"
              control={control}
              render={({field}) => (
                <MaskedInput
                  {...field}
                  defaultValue={field.value}
                  removeMask={false}
                  mask="99/99/9999"
                  maskPlaceholder="MM/DD/YYYY"
                  label={formatMessage({id: 'started-at-required'})}
                  error={errors.started_at}
                  alwaysShowMask={false}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 mb-12">
            <Controller
              name="status"
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  label={formatMessage({id: 'status'})}
                  id="select-status"
                  options={[
                    {value: 'active', label: formatMessage({id: 'active'})},
                    {value: 'inactive', label: formatMessage({id: 'inactive'})},
                    {value: 'done', label: formatMessage({id: 'done'})},
                  ]}
                />
              )}
            />
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateForm;
