import api from '../../../services/api';

export const DashboardHTTP = {
  updateGrid: (payload = {}) =>
    api.put('/app/dashboard/dashboard-grid', payload),
  updateJobs: (payload = {}) => api.put('/dashboard/jobs', payload),
  getJobLocationReports: (params = {}) => api.get('/dashboard/jobs', {params}),
  getJobLocation: (params = {}) =>
    api.get('/dashboard/jobs/get-jobs', {params}),
  getMonthlyExpenses: (params = {}) =>
    api.get('/app/dashboard/months-expenses', {params}),
  getCompanyExpenses: (params = {}) => api.get('/summary/global', {params}),
  getCompanyPunches: (params = {}) =>
    api.get('/app/dashboard/punches', {params}),
  getLastPunches: (params = {}) =>
    api.get('/app/dashboard/last-punches', {params}),
};
