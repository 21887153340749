import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';

import {DepartmentsHTTP} from '../../../../../company/providers/departments';
import {SkillsHTTP} from '../../../../../company/providers/skills';
import api from '../../../../../../services/api';
import {
  validateWhiteSpaces,
  validateUppercase,
} from '../../../../../../utils/utils';

import Loading from '../../../../../../components/Loading';
import Input from '../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../components/_form/MaskedInput';
import CurrencyInput from '../../../../../../components/_form/MaskedInput/Currency';
import Select from '../../../../../../components/_form/Select';
import Button from '../../../../../../components/Button';
import {FieldsetTitle} from '../../../../../../components/_form/FieldsetTitle';

const EmployeeForm = ({onSubmit, loading = false, submitErrors = null}) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm();

  const [skills, setSkills] = useState([]);
  const [loadingDepartments, setLoadingDepartments] = useState(true);
  const [departments, setDepartments] = useState([]);

  const canUseUsername = async (value) => {
    try {
      if (value.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'username',
        value,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  useEffect(() => {
    async function fetchSkills() {
      const {data} = await SkillsHTTP.get({status: 'active'});
      setSkills(() =>
        data.map((item) => ({
          id: item._id,
          value: item._id,
          label: item.name,
        })),
      );
    }
    fetchSkills();
  }, []);

  useEffect(() => {
    async function fetchDepartments() {
      const {data} = await DepartmentsHTTP.get({status: 'active'});
      setDepartments(() =>
        data.map((item) => ({
          id: item._id,
          value: item._id,
          label: item.name,
        })),
      );

      setTimeout(() => setLoadingDepartments(false), 300);
    }
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (submitErrors && submitErrors.field) {
      setError(submitErrors.field, {
        type: 'manual',
        message: submitErrors.msg || '',
      });
    }
  }, [submitErrors]);

  useEffect(() => clearErrors(), []);

  if (loadingDepartments)
    return (
      <div className="my-8 flex w-full justify-center items-center">
        <Loading />
      </div>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-3 ">
          <div className="lg:col-span-2">
            <Input
              label="Name *"
              id="name"
              type="text"
              error={errors.name}
              {...register('name', {
                required: true,
                pattern: {
                  value: /^[a-zA-Z\s]+$/i,
                  message: 'Provide a valid name',
                },
              })}
            />
          </div>

          <Controller
            rules={{
              required: true,
              pattern: {
                value: /^[^_]*$/i,
                message: 'Provide a valid phone number',
              },
            }}
            name="phone"
            control={control}
            render={({field}) => (
              <MaskedInput
                {...field}
                mask="(999) 999-9999"
                maskPlaceholder="_"
                label="Phone *"
                alwaysShowMask={false}
                removeMask={false}
                error={errors.phone}
                errorMessage={errors.phone ? errors.phone.message : ''}
              />
            )}
          />
        </div>

        <FieldsetTitle>Account Settings</FieldsetTitle>

        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <Controller
            name="department_id"
            control={control}
            rules={{required: true}}
            defaultValue={departments[0].value}
            render={({field}) => (
              <Select
                {...field}
                defaultValue={departments[0].value}
                label="Department *"
                id="select-department"
                error={errors.department_id}
                options={departments}
              />
            )}
          />
          <Controller
            name="skill_id"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <Select
                {...field}
                label="Skill trade *"
                id="select-skill"
                error={errors.skill_id}
                options={skills}
              />
            )}
          />
          <Controller
            rules={{
              required: true,
            }}
            name="hourprice"
            control={control}
            render={({field}) => (
              <CurrencyInput
                {...field}
                label="Hourly rate *"
                error={errors.hourprice}
              />
            )}
          />
        </div>

        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 lg:mt-0">
          <Input
            label="Username *"
            id="username"
            type="text"
            error={errors.username}
            errorMessage={errors.username ? errors.username.message : ''}
            {...register('username', {
              required: true,
              minLength: {
                value: 4,
                message: 'needs to be at least 4 characters',
              },
              validate: {
                checkUrl: async (value) =>
                  (await canUseUsername(value)) ||
                  'This username is already in use',
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
                uppercaseChar: (val) =>
                  validateUppercase(val) || 'Remove all uppercase letters!',
              },
            })}
          />
          <Input
            label="Password *"
            id="password"
            type="password"
            error={errors.password}
            {...register('password', {
              required: true,
              minLength: {
                value: 4,
                message: 'needs to be at least 4 characters',
              },
              validate: {
                whitespace: (val) =>
                  validateWhiteSpaces(val) || 'Remove all white spaces!',
              },
            })}
          />
        </div>
      </div>

      <div className="flex justify-center px-4 py-3">
        <Button type="submit" loading={loading}>
          Create employee and Next
        </Button>
      </div>
    </form>
  );
};

export default EmployeeForm;
