import Modal from '../Modal';

const PreviewImageModal = ({
  open = false,
  onClose = () => {},
  previewUrl = '',
}) => (
  <Modal maxWidth="max-w-4xl" minHeight={320} open={open} onClose={onClose}>
    <div className="flex p-4 pt-0">
      <img src={previewUrl} alt="Preview" className="w-full h-full" />
    </div>
  </Modal>
);

export default PreviewImageModal;
