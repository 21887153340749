import {useEffect, useState, useMemo} from 'react';
import {useIntl} from 'react-intl';

import {StripeHTTP} from '../../../../providers/stripe';
import {nativeCurrencyFormat} from '../../../../../../utils/currency';

import Card from '../../../../../../components/Card';
import Table from '../../../../../../components/Table';
import {FormatDate} from '../../../../../../components/FormatDate';
import Badge from '../../../../../../components/Badge';
import Button from '../../../../../../components/Button';

const Transactions = () => {
  const {formatMessage} = useIntl();
  const columnsUpComing = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'description'})}`,
        accessor: ({lines}) => {
          const description = lines.data[
            lines.data.length - 1
          ].description.replace('1 × ', '');
          const linesQty = lines.data.length;

          return (
            <div className="flex flex-col">
              <p className="font-semibold">{description}</p>
              {linesQty > 1 && (
                <ul className="flex flex-col-reverse p-2">
                  {lines.data.map((i) => (
                    <li key={`line-item-${i.id}`} className="text-xs">
                      {nativeCurrencyFormat(i.amount / 100)} - {i.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'date'})}`,
        accessor: ({lines}) => (
          <FormatDate
            value={lines.data[lines.data.length - 1].period.start}
            pattern="MMM dd, yyyy"
            unix
          />
        ),
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: ({amount_due}) => nativeCurrencyFormat(amount_due / 100),
      },
      {
        Header: `${formatMessage({id: 'status'})}`,
        accessor: () => (
          <Badge variant="info">{formatMessage({id: 'up-coming'})}</Badge>
        ),
      },
    ],
    [formatMessage],
  );
  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'description'})}`,
        accessor: ({lines}) => {
          const description = lines.data[
            lines.data.length - 1
          ].description.replace('1 × ', '');
          const linesQty = lines.data.length;

          return (
            <div className="flex flex-col">
              <p className="font-semibold">{description}</p>
              {linesQty > 1 && (
                <ul className="flex flex-col-reverse p-2">
                  {lines.data.map((i) => (
                    <li key={`line-item-${i.id}`} className="text-xs">
                      {nativeCurrencyFormat(i.amount / 100)} - {i.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'date'})}`,
        accessor: ({lines}) => (
          <>
            <FormatDate
              value={lines.data[lines.data.length - 1].period.start}
              pattern="MMM dd, yyyy"
              unix
            />
            {/* <FormatDate
              value={lines.data[lines.data.length - 1].period.start}
              pattern="MM/dd/yyyy"
              unix
            />{' '}
            -{' '}
            <FormatDate
              value={lines.data[lines.data.length - 1].period.end}
              pattern="MM/dd/yyyy"
              unix
            /> */}
          </>
        ),
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: ({amount_due}) => nativeCurrencyFormat(amount_due / 100),
      },
      {
        Header: `${formatMessage({id: 'status'})}`,
        accessor: ({paid}) => (
          <Badge variant={paid ? 'success' : 'error'}>
            {paid
              ? formatMessage({id: 'paidd'})
              : formatMessage({id: 'refused'})}
          </Badge>
        ),
      },
      {
        Header: `${formatMessage({id: 'actions'})}`,
        accessor: ({paid, hosted_invoice_url}) => (
          <Button
            variant={paid ? 'secondary' : 'primary'}
            onClick={() => window.open(hosted_invoice_url, '_blank')}>
            {paid ? 'See Invoice(stripe)' : 'Pay invoice(stripe)'}
          </Button>
        ),
      },
    ],
    [formatMessage],
  );

  const [transactions, setTransactions] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    async function fetchTransactions() {
      const {data} = await StripeHTTP.getInvoices();
      setTransactions({
        loading: false,
        data: data && data.invoices ? data.invoices.data : [],
        upcoming: data && data.upcoming ? [data.upcoming] : [],
      });
    }

    fetchTransactions();
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-2xl font-bold">
        {formatMessage({id: 'settings.transactions-next-invoice'})}
      </h2>
      <Card padding="p-0" className="overflow-hidden">
        {!transactions.loading && (
          <Table columns={columnsUpComing} data={transactions.upcoming} />
        )}
      </Card>

      <h2 className="text-2xl font-bold mt-4">
        {formatMessage({id: 'settings.transactions-latest-invoices'})}
      </h2>
      <Card padding="p-0" className="overflow-hidden">
        {!transactions.loading && (
          <Table columns={columns} data={transactions.data} />
        )}
      </Card>
    </div>
  );
};

export default Transactions;
