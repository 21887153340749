/* eslint-disable no-unreachable */
import {useState, useMemo} from 'react';

import worker1 from '../../../../assets/images/worker1.jpeg';

import Button from '../../../../components/Button';

import Steps from '../../components/Steps';
import Departments from '../../components/Departments';
import CreateEmployee from '../../components/CreateEmployee';
import PayPeriod from '../../components/PayPeriod';
import JobLocation from '../../components/JobLocation';
import DoneStep from '../../components/Done';
import StartFlowCalendar from '../../components/StartFlowCalendar';

const Home = () => {
  const [brKeepSystem, setBrKeepSystem] = useState(false);
  const [step, setStep] = useState(() => {
    const flowStep = localStorage.getItem('@TrackMyGuys:flow');

    if (flowStep && flowStep === 'settings') return 2;
    if (flowStep && flowStep === 'job-location') return 3;
    if (flowStep && flowStep === 'done') return 4;

    return 0;
  });

  const steps = useMemo(
    () => [
      {
        id: 1,
        title: 'Understanding the departments module',
        component: <Departments />,
        showNextButton: true,
        showSkipButtonMobile: true,
      },
      {
        id: 2,
        title: 'Create your first employee',
        component: <CreateEmployee nextStep={() => setStep(2)} />,
        showNextButton: false,
        showSkipButtonMobile: true,
      },
      {
        id: 3,
        title: 'Setting your pay period',
        component: <PayPeriod nextStep={() => setStep(3)} />,
        showNextButton: false,
        showSkipButtonMobile: false,
      },
      {
        id: 4,
        title: 'Create your first job location',
        component: <JobLocation nextStep={() => setStep(4)} />,
        showNextButton: false,
        showSkipButtonMobile: true,
      },
      {
        id: 5,
        title: 'All done',
        component: <DoneStep />,
        showNextButton: false,
      },
    ],
    [],
  );

  if (!brKeepSystem) {
    return (
      <div className="w-full h-screen overflow-hidden bg-white border-t-4 border-yellow-500">
        <div className="flex flex-col items-center justify-center w-full h-full p-4 md:p-8">
          <StartFlowCalendar nextStep={() => setBrKeepSystem(true)} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen overflow-hidden bg-white grid gap-4 grid-cols-1 lg:grid-cols-3">
      <div className="relative h-full overflow-hidden bg-gradient-to-b from-yellow-500 to-yellow-700 hidden lg:flex">
        <Steps steps={steps} activeStep={step} />
        <img
          className="absolute inset-0 z-10 h-full w-full object-cover lg:w-full lg:h-full opacity-10"
          src={worker1}
          alt="worker"
        />
      </div>

      <div className="m-h-full overflow-y-scroll lg:col-span-2">
        <div className="flex flex-col items-center justify-center w-full m-h-full p-4 md:p-8">
          {steps[step].component}

          {steps[step].showNextButton && (
            <div className="block w-full lg:w-auto">
              <Button onClick={() => setStep((prev) => prev + 1)}>Next</Button>
            </div>
          )}
          {steps[step].showSkipButtonMobile && (
            <div className="block w-full md:hidden">
              <Button
                variant="secondary"
                extraClassName="w-full"
                onClick={() => setStep((prev) => prev + 1)}>
                Skip and Next
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
