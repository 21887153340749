const bgColors = {
  'bg-warning': 'bg-yellow-500',
  'bg-danger': 'bg-red-500',
  'bg-success': 'bg-green-300',
};

const ProgressBar = ({progress = '0%', bgColor = 'bg-success'}) => (
  <div className="flex flex-col w-full relative mt-2">
    <span
      className="absolute z-10 text-sm text-gray-500"
      style={{right: 5, top: -21}}>
      {progress}
    </span>
    <div className="relative w-full h-1 bg-gray-100 overflow-hidden">
      <span
        className={`block h-full ${bgColors[bgColor]}`}
        style={{width: progress}}
      />
    </div>
  </div>
);

export default ProgressBar;
