import {useForm, Controller} from 'react-hook-form';

import {usStates} from '../../../../../../utils/states';

import Input from '../../../../../../components/_form/Input';
import Select from '../../../../../../components/_form/Select';
import Button from '../../../../../../components/Button';
import {FieldsetTitle} from '../../../../../../components/_form/FieldsetTitle';

const EmployeeForm = ({onSubmit, initialData = {}}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: initialData,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Name *"
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
        </div>

        <div className="grid grid-cols-3 gap-4">
          <Input
            label="Phone *"
            id="phone"
            type="text"
            error={errors.phone}
            {...register('phone', {required: true})}
          />
          <Input
            label="Email"
            id="email"
            type="email"
            error={errors.email}
            {...register('email')}
          />
          <Controller
            name="language"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label="Language"
                id="select-language"
                defaultValue="english"
                options={[
                  {value: 'english', label: 'English'},
                  {value: 'portuguese', label: 'Portuguese'},
                  {value: 'spanish', label: 'Spanish'},
                ]}
              />
            )}
          />
        </div>

        <FieldsetTitle>Address</FieldsetTitle>

        <div className="grid grid-cols-4 gap-4">
          <Input
            label="Street"
            id="street"
            type="text"
            error={errors.street}
            {...register('street')}
          />
          <Input
            label="City"
            id="city"
            type="text"
            error={errors.city}
            {...register('city')}
          />
          <Input
            label="Zip Code"
            id="zip_code"
            type="text"
            error={errors.zip_code}
            {...register('zip_code')}
          />
          <Controller
            name="state"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label="State"
                id="select-state"
                options={usStates}
              />
            )}
          />
        </div>

        <FieldsetTitle>Account Settings</FieldsetTitle>

        <div className="grid grid-cols-3 gap-4">
          <Input
            label="Hourly Rate *"
            id="hourprice"
            type="text"
            error={errors.hourprice}
            {...register('hourprice', {required: true})}
          />

          <Input
            label="Username *"
            id="username"
            type="text"
            error={errors.username}
            {...register('username', {required: true, min: 4})}
          />
          <Input
            label="Password *"
            id="password"
            type="password"
            error={errors.password}
            {...register('password', {min: 4})}
          />
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default EmployeeForm;
