import Modal from '../../../_modals/Modal';
import Button from '../../../Button';
import {currencyFormat} from '../../../../utils/currency';

const ConfirmModal = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  loading = false,
  plan = {},
}) => (
  <Modal
    maxWidth="max-w-lg"
    minHeight={195}
    open={open}
    onClose={onClose}
    title="Update plan">
    <div>
      <p className="text-medium font-normal text-gray-600 px-4 pb-1">
        Do you confirm that you want to update your plan?
      </p>
      <p className="text-sm font-bold text-gray-600 px-4 pb-6">
        New plan {plan?.name} - {currencyFormat(plan?.price?.$numberDecimal)}/
        {plan.period === 'month' ? 'mo' : 'yr'}
      </p>
      <div className="form-footer">
        <Button type="button" onClick={() => onConfirm()} loading={loading}>
          Confirm
        </Button>
        <Button
          variant="secondary"
          disabled={loading}
          onClick={() => onClose()}
          extraClassName="mr-2">
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

export default ConfirmModal;
