import api, {apiTs} from '../../../services/api';
import {downloadFile} from '../../../utils/download';

export const ReportsHTTP = {
  get: (params = {}) =>
    api.get(
      `/punches?paginate=true&company=620d4f6c5f881118f9eb4403&job=without&page=1`,
      {params},
    ),
  getJobLocationByPeriod: (
    workId = '',
    params = {
      start: '',
      end: '',
    },
  ) => api.get(`/reports/joblocation-period/${workId}`, {params}),
  getPunchesNoJob: (params = {}) => api.get('/punches/no-job', {params}),
  getPunchesMissedOut: (params = {}) =>
    api.get('/punches/missed-out', {params}),
  // job location reports
  getJobLocationSummary: (id = '') => api.get(`/reports/joblocation/${id}`),
  downloadJobLocationSummaryPDF: (id = '') =>
    downloadFile(api, `reports/pdf/joblocation/${id}`, 'summary'),
  // timecard PDF
  downloadTimecardPDF: (payload = {}) =>
    downloadFile(api, '/reports/pdf/timecard', 'timecard', payload),
  newDownloadTimecardPDF: (payload = {start: '', end: ''}) =>
    downloadFile(
      apiTs,
      `/timecard/pdf?start=${payload.start}&end=${payload.end}`,
      'timecard',
    ),
  // reports/pdf/employee-earnings
  employeeEarningsdPDF: (params = {}) =>
    downloadFile(
      api,
      'reports/pdf/employee-earnings',
      'employee-earnings',
      null,
      params,
    ),
};
