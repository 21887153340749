/* eslint-disable react/no-array-index-key */
import {useState, useLayoutEffect, useEffect, useMemo} from 'react';
import {
  UsersIcon,
  BuildingOfficeIcon,
  ClockIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import {DashboardHTTP} from '../../providers/dashboard';
import {PunchHTTP} from '../../providers/punches';

import PageHeader from '../../../../components/_layouts/PageHeader';
import Table from '../../../../components/Table';
import Avatar from '../../../../components/Avatar';
import Badge from '../../../../components/Badge';
import {nativeCurrencyFormat} from '../../../../utils/currency';
import {formatNumbersUS} from '../../../../utils/utils';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8B5CF6'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.label}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333">{`${payload.label}: ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const Dashboard = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexUsers, setActiveIndexUsers] = useState(0);
  const [activeIndexCPlans, setActiveIndexCPlans] = useState(0);
  const [dashboardData, setDashboardData] = useState({
    loading: true,
  });
  const [punchesIn, setPunchesIn] = useState({
    loading: true,
    data: [],
  });
  const [punchesOffline, setPunchesOffline] = useState({
    loading: true,
    data: [],
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: '_id',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Avatar
              id={row.original._id}
              photo={row.original.user_id.photo || null}
              name={row.original.user_id.name}
              size={10}
            />
            <div className="flex flex-col items-start">
              <p className="ml-2">{row.original.user_id.name}</p>
              <p className="ml-2">{row.original.company_id.name}</p>
              <Badge
                variant={
                  row.original.company_id.status === 'active' ? 'info' : 'error'
                }>
                Company: {row.original.company_id.status}
              </Badge>
            </div>
          </div>
        ),
      },
      {
        Header: 'Started at',
        accessor: 'created_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.start.time), 'MM/dd/yyyy hh:mm a'),
      },
    ],
    [],
  );
  const columnsCompaniesInactive = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => (
          <div>
            <p>{row.original.name}</p>
            <span>{row.original._id}</span>
          </div>
        ),
      },
      {
        Header: 'Last updated',
        accessor: 'updated_at',
        Cell: ({row}) =>
          formatDate(new Date(row.original.updated_at), 'MM/dd/yyyy hh:mm a'),
      },
    ],
    [],
  );

  useLayoutEffect(() => {
    async function fetchDashboard() {
      const {data} = await DashboardHTTP.get();
      setDashboardData({
        loading: false,
        ...data,
        newAccountsByMonth: data.newAccountsByMonth
          .map((i) => ({
            period: `${i._id.year}${
              i._id.month < 10 ? `0${i._id.month}` : i._id.month
            }`,
            period_formated: `${i._id.month}-${i._id.year}`,
            accounts: i.accounts,
          }))
          .sort((a, b) => a.period - b.period),
        activeCompaniesByPlan: data.activeCompaniesByPlan.map((i) => ({
          label: i._id.plan_name,
          amount: i.accounts,
        })),
      });
    }

    fetchDashboard();
  }, []);

  useEffect(() => {
    async function fetchPunchesIn() {
      const {data} = await PunchHTTP.get({status: 'in'});
      setPunchesIn({loading: false, data});
    }
    fetchPunchesIn();
  }, []);

  useEffect(() => {
    async function fetchPunchesOffline() {
      const {data} = await PunchHTTP.get({offline: true, paginate: true});
      setPunchesOffline({loading: false, data: data.docs});
    }
    fetchPunchesOffline();
  }, []);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const companiesInactivesWithEmployeesOn = punchesIn.data
    .filter((p) => p.company_id.status === 'inactive')
    .map((p) => ({
      _id: p.company_id._id,
      name: p.company_id.name,
      updated_at: p.company_id.updated_at,
      stripe_source: p.company_id.stripe_source,
    }))
    .reduce((accumulator, currentValue) => {
      const inArray = accumulator.find((a) => a._id === currentValue._id);
      if (!inArray) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

  if (dashboardData.loading) return <h1>loading...</h1>;

  return (
    <>
      <PageHeader
        title="Dashboard"
        breadcrumbs={[
          {
            id: '/',
            label: 'Dashboard',
          },
        ]}
      />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-4 mb-6">
        <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
          <UsersIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
          <div className="ml-4">
            <h3 className="text-gray-500">Companies</h3>
            <p className="text-2xl font-bold">
              {formatNumbersUS(dashboardData.quantities.companiesQty)}
            </p>
          </div>
        </div>
        <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
          <BuildingOfficeIcon
            className="h-6 w-6 text-yellow-500"
            aria-hidden="true"
          />
          <div className="ml-4">
            <h3 className="text-gray-500">Users</h3>
            <p className="text-2xl font-bold">
              {formatNumbersUS(dashboardData.quantities.usersQty)}
            </p>
          </div>
        </div>
        <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
          <ClockIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
          <div className="ml-4">
            <h3 className="text-gray-500">Punches until today</h3>
            <p className="text-2xl font-bold">
              {formatNumbersUS(dashboardData.quantities.punchesQty)}
            </p>
          </div>
        </div>
        <div className="flex items-center bg-white rounded-lg shadow overflow-hidden p-4">
          <CurrencyDollarIcon
            className="h-6 w-6 text-yellow-500"
            aria-hidden="true"
          />
          <div className="ml-4">
            <h3 className="text-gray-500">Companies Gross Pay</h3>
            <p className="text-2xl font-bold">
              {nativeCurrencyFormat(
                dashboardData.sumPunches[0]?.amount?.$numberDecimal,
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 mb-6">
        <div className="flex flex-col bg-white p-4 shadow rounded-lg h-96">
          <h2 className="text-2xl font-bold text-gray-700">Companies</h2>
          <p className="text-gray-500">
            Total trial, active, inactive and cloused accounts
          </p>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={dashboardData.companies}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                nameKey="label"
                dataKey="amount"
                onMouseEnter={onPieEnter}>
                {dashboardData.companies.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex flex-col bg-white p-4 shadow rounded-lg h-96">
          <h2 className="text-2xl font-bold text-gray-700">Users</h2>
          <p className="text-gray-500">Total active and inactive users</p>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
              <Pie
                activeIndex={activeIndexUsers}
                activeShape={renderActiveShape}
                data={dashboardData.users}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                nameKey="label"
                dataKey="amount"
                onMouseEnter={(_, index) => setActiveIndexUsers(index)}>
                {dashboardData.users.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex flex-col bg-white p-4 shadow rounded-lg h-96">
          <h2 className="text-2xl font-bold text-gray-700">
            Companies by Plans
          </h2>
          <p className="text-gray-500">Total of companies by plan</p>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
              <Pie
                activeIndex={activeIndexCPlans}
                activeShape={renderActiveShape}
                data={dashboardData.activeCompaniesByPlan}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#FCD34D"
                nameKey="label"
                dataKey="amount"
                onMouseEnter={(_, index) => setActiveIndexCPlans(index)}>
                {dashboardData.activeCompaniesByPlan.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <div className="flex flex-col bg-white p-4 shadow rounded-lg h-96">
          <h2 className="text-2xl font-bold text-gray-700">
            New accounts (last 12 months)
          </h2>
          <p className="text-gray-500">New accounts by month</p>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={dashboardData.newAccountsByMonth}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period_formated" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="accounts"
                nameKey="period_formated"
                stroke="#F59E0B"
                fill="#FCD34D"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        {!punchesIn.loading && (
          <div className="flex flex-col bg-white py-4 shadow rounded-lg h-full">
            <h2 className="text-2xl font-bold text-gray-700 mb-4 px-4">
              Online now: {punchesIn.data.length}
            </h2>
            <Table
              columns={columns}
              data={punchesIn.data.filter(
                (p) => p.company_id.status === 'active',
              )}
              scrollable
            />
          </div>
        )}
        {!punchesIn.loading && (
          <div className="flex flex-col bg-white py-4 shadow rounded-lg h-full">
            <h2 className="text-2xl font-bold text-gray-700 mb-4 px-4">
              Online now - inactive company
            </h2>
            <Table
              columns={columns}
              data={punchesIn.data.filter(
                (p) => p.company_id.status === 'inactive',
              )}
              scrollable
            />
          </div>
        )}
        {!punchesIn.loading && (
          <div className="flex flex-col bg-white py-4 shadow rounded-lg h-full">
            <h2 className="text-xl font-bold text-gray-700 mb-4 px-4">
              Inactive companies with employees online
            </h2>
            <Table
              columns={columnsCompaniesInactive}
              data={companiesInactivesWithEmployeesOn}
              scrollable
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 mt-5">
        {!punchesOffline.loading && (
          <div className="flex flex-col bg-white py-4 shadow rounded-lg h-full">
            <h2 className="text-2xl font-bold text-gray-700 mb-4 px-4">
              Lastest OFFLINE punches (Total of punches:{' '}
              {dashboardData.quantities.punchesOfflineQty})
            </h2>
            <Table columns={columns} data={punchesOffline.data} scrollable />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
