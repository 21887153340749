import {WomanDeviceVector} from '../../../../components/_marketing/WomanDeviceVector';

const Departments = () => (
  <div className="flex flex-col items-center justify-center p-8 max-w-2xl">
    <div className="px-8 py-4" style={{maxWidth: '80%'}}>
      <WomanDeviceVector />
    </div>
    <h1 className="text-4xl font-extralight text-center">
      Welcome to <span className="font-bold">WorkPodium</span>.
    </h1>
    <p className="mt-2 text-sm font-normal text-gray-700 text-center">
      The department module helps you organize your employees by groups, for
      example: Framing Crew, Trimming Crew. Here you configure the overtime,
      lunch deduction and max shift. To help you, we have already created a
      standard department.
    </p>
  </div>
);

export default Departments;
