import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';

import Input from '../../../../../../../components/_form/Input';
import TextArea from '../../../../../../../components/_form/TextArea';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Select from '../../../../../../../components/_form/Select';
import Button from '../../../../../../../components/Button';
import {usStates} from '../../../../../../../utils/states';

const CustomerForm = ({
  loading,
  onSubmit,
  onCancel = () => {},
  initialData = {},
}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: initialData,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={formatMessage({id: 'name-required'})}
            id="name"
            type="text"
            error={errors.name}
            {...register('name', {required: true})}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            rules={{
              required: true,
            }}
            name="phone"
            control={control}
            render={({field}) => (
              <MaskedInput
                {...field}
                defaultValue={field.value}
                label="Phone *"
                mask="(999) 999-9999"
                maskPlaceholder="(999) 999-9999"
                alwaysShowMask={false}
                removeMask={false}
                error={errors.phone}
                errorMessage={errors.phone ? errors.phone.message : ''}
              />
            )}
          />
          <Input
            label={formatMessage({id: 'email-required'})}
            id="email"
            type="email"
            error={errors.email}
            {...register('email', {required: true})}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={formatMessage({id: 'zip-code'})}
            id="zipcode"
            type="text"
            {...register('zipcode', {required: false})}
          />
          <Controller
            name="state"
            control={control}
            render={({field}) => (
              <Select
                {...field}
                label={formatMessage({id: 'state'})}
                id="select-state"
                options={usStates}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Input
            label={formatMessage({id: 'city-required'})}
            id="city"
            type="text"
            {...register('city', {required: false})}
          />
          <Input
            label={formatMessage({id: 'street'})}
            id="street"
            type="text"
            {...register('street', {required: false})}
          />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <TextArea
            label={formatMessage({id: 'notes'})}
            id="notes"
            {...register('notes', {required: false})}
          />
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <Button type="submit" loading={loading}>
          {formatMessage({id: 'save'})}
        </Button>
        <Button
          variant="secondary"
          extraClassName="mr-2"
          onClick={() => onCancel()}>
          {formatMessage({id: 'cancel'})}
        </Button>
      </div>
    </form>
  );
};

export default CustomerForm;
