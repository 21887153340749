import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {Calendar} from 'react-date-range';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';
import * as Sentry from '@sentry/react';

import {ExtrasHTTP} from '../../../../../providers/extras';
import {useJobLocation} from '../../../../../hooks/useJobLocation';
import {useAuth} from '../../../../../../auth/hooks/useAuth';

import Input from '../../../../../../../components/_form/Input';
import TextArea from '../../../../../../../components/_form/TextArea';
import Select from '../../../../../../../components/_form/Select';
import SearchSelect from '../../../../../../../components/_form/SearchSelect';
import Button from '../../../../../../../components/Button';
import Modal from '../../../../../../../components/_modals/Modal';
import DragAndDropUpload from '../../../../../../../components/DragAndDropUpload';
import CurrencyInput from '../../../../../../../components/_form/MaskedInput/Currency';
import {formatDateTZ, isValidDate} from '../../../../../../../utils/date';
import {clearCurrencyValue} from '../../../../../../../utils/utils';

const FormBill = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  id = null,
  initialData = {},
}) => {
  const {formatMessage} = useIntl();
  const {currentCompany, user} = useAuth();
  const {getJobs, jobs} = useJobLocation();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: {
      ...initialData,
      work_id: initialData.work_id?._id,
      category: initialData.category_id,
    },
  });

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [minDate, setMinDate] = useState(
    initialData && initialData.work_id
      ? new Date(initialData.work_id?.started_at)
      : new Date(),
  );
  const [maxDate, setMaxDate] = useState(new Date());
  const [date, setDate] = useState(
    initialData && initialData.period ? initialData.period : minDate,
  );
  const [periodMsg, setPeriodMsg] = useState(
    formatMessage({id: 'extras.select-job-location-first'}),
  );
  const selectedJobId = watch(
    'work_id',
    initialData ? initialData.work_id?._id : null,
  );

  const handleClose = () => {
    onClose();
    reset({});
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      if (!date) {
        toast.error(formatMessage({id: 'toast.provider-date'}));
        return;
      }
      let period = new Date(
        `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`,
      );
      if (!period || !isValidDate(period)) {
        period = `${formatDateTZ(date, 'yyyy-MM-dd')} 13:00:00 +000`;
      }
      const payload = {
        amount: clearCurrencyValue(values.amount),
        category_id: values.category,
        work_id: values.work_id.value,
        title: values.title,
        description: values.description,
        file: values.file,
        period,
        company_id: currentCompany || user.company_id,
      };

      Sentry.configureScope((scope) =>
        scope.setTransactionName('Create/Update bills'),
      );
      Sentry.setContext('request_data', payload);

      if (id && initialData) {
        await ExtrasHTTP.updateExtraBills(id, payload);
        toast.success(formatMessage({id: 'toast.updated-sucess'}));
      } else {
        payload.status = 'active';
        await ExtrasHTTP.createExtraBills(payload);
        toast.success(formatMessage({id: 'toast.created-sucess'}));
      }
      reset({});
      onSuccess();
    } catch {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      reset({});
    }
  }, [open]);

  useEffect(() => {
    const selectedJob = jobs.data.find((j) => j._id === selectedJobId?.value);

    if (selectedJob) {
      setMinDate(new Date(selectedJob.started_at));
      if (selectedJob.status === 'done') {
        setMaxDate(new Date(selectedJob.done_at));
        setPeriodMsg(
          `This job location started on ${formatDateTZ(
            selectedJob.started_at,
            'MM/dd/yyyy',
          )} and ended on ${formatDateTZ(
            selectedJob.done_at,
            'MM/dd/yyyy',
          )}, so you can only select a date between this period.`,
        );
      } else {
        setPeriodMsg(
          `this job location started on ${formatDateTZ(
            selectedJob.started_at,
            'MM/dd/yyyy',
          )}, so you can only select a date after this period.`,
        );
      }
    }
  }, [selectedJobId, formatMessage]);

  useEffect(() => {
    register('file', {required: false});
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const {data} = await ExtrasHTTP.getCategories();
      setCategories(data.map((c) => ({value: c._id, label: c.name})));
    };

    getCategories();
    getJobs();
  }, []);

  useEffect(() => {
    if (initialData && id && !jobs.loading) {
      const jobSelected = jobs.data.find(
        (j) => j._id === initialData.work_id?._id,
      );

      reset({
        ...initialData,
        work_id: {
          label: jobSelected.name,
          value: jobSelected._id,
        },
        category: initialData.category_id,
      });

      if (initialData.period) {
        setDate(initialData.period);
      }
    }
  }, [initialData, id, jobs]);

  const jobsToSearchSelect = jobs.data.map((j) => ({
    label: j.name,
    value: j._id,
  }));

  return (
    <Modal
      maxWidth="max-w-3xl"
      open={open}
      onClose={() => handleClose()}
      title={
        initialData && id
          ? formatMessage({id: 'extras.edit-bill'})
          : formatMessage({id: 'extras.new-bill'})
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pt-1 pb-4 sm:p-6 sm:pb-4 sm:pt-1">
          <div className="grid grid-cols-1 gap-4">
            <DragAndDropUpload
              model="expenses"
              onUpload={(file) => setValue('file', file)}
              error={errors.file}
            />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div style={{width: 360}}>
              <p className="block text-sm font-medium text-gray-700">
                {formatMessage({id: 'date'})}
              </p>
              <div style={{marginTop: -10}}>
                <Calendar
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(item) => setDate(item)}
                  date={date}
                  showMonthAndYearPickers={false}
                />
              </div>
              <p className="block text-xs text-gray-600">{periodMsg}</p>
            </div>

            <div className="pl-6">
              <div className="mt-4">
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="work_id"
                  control={control}
                  render={({field}) => (
                    <SearchSelect
                      {...field}
                      label={formatMessage({id: 'job-location-required'})}
                      placeholder={formatMessage({
                        id: 'timecard.select-job-location',
                      })}
                      id="work_id"
                      options={jobsToSearchSelect}
                      className="mt-2 w-full"
                      error={errors.work_id}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Controller
                  name="category"
                  control={control}
                  rules={{required: true}}
                  render={({field}) => (
                    <Select
                      {...field}
                      label={formatMessage({id: 'category-required'})}
                      id="select-Category"
                      options={categories}
                      error={errors.category}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Input
                  label={formatMessage({id: 'title-required'})}
                  id="title"
                  type="text"
                  error={errors.name}
                  {...register('title', {required: true})}
                />
              </div>

              <div className="mt-4">
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="amount"
                  control={control}
                  render={({field}) => (
                    <CurrencyInput
                      {...field}
                      label={formatMessage({id: 'amount-required'})}
                      error={errors.amount}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <TextArea
              label={formatMessage({id: 'description'})}
              id="title"
              type="text"
              error={errors.description}
              {...register('description')}
            />
          </div>
        </div>

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <Button type="submit" loading={loading}>
            {formatMessage({id: 'save'})}
          </Button>
          <Button
            variant="secondary"
            extraClassName="mr-2"
            onClick={() => handleClose()}>
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default FormBill;
