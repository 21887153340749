/* eslint-disable no-unused-vars */
import {useState, useLayoutEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';

import api, {apiTs} from '../../../../../../../services/api';
import {useAuth} from '../../../../../../auth/hooks/useAuth';
import {usStates} from '../../../../../../../utils/states';
import {currencyFormat} from '../../../../../../../utils/currency';

import Modal from '../../../../../../../components/_modals/Modal';
import Input from '../../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Button from '../../../../../../../components/Button';
import Select from '../../../../../../../components/_form/Select';
import {LoadingBox} from '../../../../../../../components/LoadingBox';
import {FieldsetTitle} from '../../../../../../../components/_form/FieldsetTitle';

const CompanyFormModal = ({
  open = false,
  onClose = () => {},
  onSucess = () => {},
}) => {
  const {user} = useAuth();
  const {
    control,
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {trial_period_days: 30},
  });

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState({
    loading: true,
    data: [],
  });

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const hasTrial = data.trial_period_days > 0;
      const payload = {
        ...data,
        status: hasTrial ? 'active' : 'inactive',
        role: 'customer',
        sale_id: ['sales', 'sales_assistant'].includes(user.role)
          ? user.company_id
          : null,
        trial: hasTrial,
        language: 'en',
      };
      await apiTs.post('/companies', payload);

      toast.success('Company created successfully!');

      onSucess();
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }

      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.data &&
        error.response.data.err.data.param
      ) {
        let field = error.response.data.err.data.param;

        if (
          error.response.data.err.data.param === 'exp_year' ||
          error.response.data.err.dataparam === 'exp_month'
        ) {
          field = 'expiry';
        }
        if (error.response.data.err.data.param === 'number') {
          field = 'card_number';
        }

        setError(field, {
          type: 'manual',
          message: error.response.data.err.data.msg || 'invalid data',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    async function fetchPlans() {
      const {data} = await apiTs.get('/plans');
      setPlans({loading: false, data});
    }
    fetchPlans();
  }, []);

  const trialDays = watch('trial_period_days');

  return (
    <>
      <Modal
        maxWidth="max-w-xl"
        minHeight={500}
        open={open}
        onClose={onClose}
        title="Create a company">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Name *"
                id="name"
                error={errors.name}
                errorMessage={errors.name?.message}
                {...register('name', {required: true})}
              />
            </div>

            <div className="mt-2 grid gap-4 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Email *"
                id="email"
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Provide a valid email',
                  },
                  validate: {
                    checkUrl: async (value) =>
                      (await canUseEmail(value)) ||
                      'This email is already in use',
                  },
                })}
              />

              <Controller
                rules={{
                  required: true,
                  minLength: 14,
                  pattern: {
                    value: /^[^_]*$/i,
                    message: 'Provide a valid phone number',
                  },
                }}
                name="phone"
                control={control}
                render={({field}) => (
                  <MaskedInput
                    {...field}
                    label="Phone *"
                    mask="(999) 999-9999"
                    maskPlaceholder="(999) 999-9999"
                    alwaysShowMask={false}
                    removeMask={false}
                    error={errors.phone}
                    errorMessage={errors.phone ? errors.phone.message : ''}
                  />
                )}
              />
            </div>

            <FieldsetTitle>Address</FieldsetTitle>

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Street"
                id="street"
                type="text"
                error={errors.street}
                {...register('street')}
              />
              <Input
                label="City"
                id="city"
                type="text"
                error={errors.city}
                {...register('city')}
              />
              <Input
                label="Zip Code"
                id="zip_code"
                type="text"
                error={errors.zip_code}
                {...register('zip_code')}
              />
              <Controller
                name="state"
                control={control}
                render={({field}) => (
                  <Select
                    {...field}
                    label="State"
                    id="select-state"
                    options={usStates}
                  />
                )}
              />
            </div>

            <FieldsetTitle>Subscription</FieldsetTitle>

            <div className="grid grid-cols-1 gap-4">
              <Controller
                name="plan_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    label="Plan"
                    id="select-plan"
                    error={errors.plan_id}
                    options={plans.data
                      .sort((a, b) => a.max_employees - b.max_employees)
                      .sort((a, b) => a.period.localeCompare(b.period))
                      .map((plan) => ({
                        label: `${plan.name} - ${currencyFormat(
                          plan.price.$numberDecimal,
                        )}`,
                        value: plan._id,
                      }))}
                  />
                )}
              />
              <Input
                label="Trial days"
                id="trial_period_days"
                type="number"
                error={errors.trial_period_days}
                {...register('trial_period_days')}
              />
              {/* <p className="text-sm text-gray-500 mt-0">
              If this subscription does not have a trial, enter 0 and enter
              payment details
            </p> */}
            </div>

            {trialDays < 1 && trialDays > 1 && (
              <>
                <FieldsetTitle>Payment Method</FieldsetTitle>
                <div className="flex flex-col flex-grow mt-2">
                  <Input
                    label="Cardholder name *"
                    id="cardholder"
                    type="text"
                    error={errors.cardholder}
                    errorMessage={errors.cardholder?.message}
                    {...register('cardholder', {required: true})}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-2">
                    <div className="col-span-2">
                      <Controller
                        rules={{
                          required: true,
                        }}
                        name="card_number"
                        control={control}
                        render={({field}) => (
                          <MaskedInput
                            {...field}
                            defaultValue={field.value}
                            mask="9999 9999 9999 9999"
                            maskPlaceholder="_"
                            type="tel"
                            label="Card number *"
                            alwaysShowMask={false}
                            error={errors.card_number}
                            errorMessage={errors.card_number?.message}
                          />
                        )}
                      />
                    </div>

                    <Controller
                      rules={{
                        required: true,
                        minLength: 6,
                      }}
                      name="expiry"
                      control={control}
                      render={({field}) => (
                        <MaskedInput
                          {...field}
                          defaultValue={field.value}
                          mask="99/9999"
                          maskPlaceholder="MM/YYYY"
                          label="Expiration *"
                          id="expiry"
                          type="tel"
                          alwaysShowMask={false}
                          error={errors.expiry}
                          errorMessage={
                            errors.expiry ? 'enter a valid date' : ''
                          }
                        />
                      )}
                    />
                    <Controller
                      rules={{
                        required: true,
                        minLength: 3,
                      }}
                      name="cvc"
                      control={control}
                      render={({field}) => (
                        <MaskedInput
                          {...field}
                          defaultValue={field.value}
                          mask="9999"
                          maskPlaceholder="_"
                          type="tel"
                          label="CVC *"
                          alwaysShowMask={false}
                          error={errors.cvc}
                          errorMessage={errors.cvc ? errors.cvc.message : ''}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="form-footer">
            <Button type="submit" loading={loading}>
              Save
            </Button>
            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => onClose()}
              extraClassName="mr-2">
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      {loading && <LoadingBox info="Creating account..." />}
    </>
  );
};

export default CompanyFormModal;
