import {apiAdonis} from '../../../services/api';

export const TradesHTTP = {
  get: (params = {}) => apiAdonis.get('trades', {params}),
  getTrade: ({id}) => apiAdonis.get(`trades/${id}`),
  updateTrade: (payload = {}) => apiAdonis.put(`trades/${payload.id}`, payload),
  createTrade: (payload = {}) => apiAdonis.post(`trades`, payload),
  deleteTrade: ({id}) => apiAdonis.delete(`trades/${id}`),
};

export const TradeItemsHTTP = {
  get: (params = {}) => apiAdonis.get('trade-items', {params}),
};

export const InvoiceTradesHTTP = {
  createTrade: (payload = {}) => apiAdonis.post(`invoice-trades`, payload),
  deleteTradeFromInvoice: ({id}) => apiAdonis.delete(`invoice-trades/${id}`),
  getTrade: ({id}) => apiAdonis.get(`invoice-trades/${id}`),
};

export const InvoiceTradeItemsHTTP = {
  get: (params = {}) => apiAdonis.get('invoice-trade-items', {params}),
  createItem: (payload = {}) => apiAdonis.post('invoice-trade-items', payload),
  updateItem: ({id, payload}) =>
    apiAdonis.put(`invoice-trade-items/${id}`, payload),
  deleteItem: ({id}) => apiAdonis.delete(`invoice-trade-items/${id}`),
  getItem: ({id}) => apiAdonis.get(`invoice-trade-items/${id}`),
};
