import {Fragment} from 'react';
import {useTable} from 'react-table';

import {TableWrapper, TableWrapperScroll} from './style';

const colClass = 'px-4 py-2 md:px-4 md:py-4';

const Wrapper = ({scrollable = false, children}) => {
  if (scrollable) return <TableWrapperScroll>{children}</TableWrapperScroll>;

  return <TableWrapper>{children}</TableWrapper>;
};

const Table = ({
  columns,
  data,
  renderRowSubComponent,
  showComment = false,
  hasOver = true,
  hasFooter = false,
  renderFooterComponent,
  loading = false,
  scrollable = false,
  onRowClick = null,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable({columns, data});

  const handleRowClick = (row) => {
    if (onRowClick) {
      return onRowClick(row);
    }

    return null;
  };

  return (
    <Wrapper scrollable={scrollable}>
      <table
        className={`w-full table-auto divide-y divide-gray-200 ${
          hasOver ? 'table-hover' : ''
        }`}
        {...getTableProps()}
        style={{minHeight: 420}}>
        <thead className="bg-gray-100 w-full">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="w-full">
              {headerGroup.headers.map((column) => (
                <th
                  scope="col"
                  className={`${colClass} text-left text-xs font-medium text-gray-600 uppercase tracking-wider`}
                  {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {loading ? (
          <p>loading...</p>
        ) : (
          <tbody className="bg-white w-full" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const hasBg = index % 2 === 0;
              const rowProps = row.getRowProps();

              return (
                // eslint-disable-next-line
              <Fragment key={rowProps.key}>
                  <tr
                    role={rowProps.role}
                    className={`border-t border-gray-200 w-full ${
                      onRowClick ? 'cursor-pointer' : ''
                    }`}
                    onClick={() => handleRowClick(row.original)}>
                    {row.cells.map((cell) => (
                      <td
                        className={`${colClass} whitespace-nowrap text-sm text-gray-800 ${
                          hasBg ? 'bg-gray-50' : ''
                        }`}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                  {showComment ? (
                    <tr role={rowProps.role} className="align-top">
                      {renderRowSubComponent({row, visibleColumns, hasBg})}
                    </tr>
                  ) : null}
                </Fragment>
              );
            })}
          </tbody>
        )}
        {hasFooter && !renderFooterComponent && (
          <tfoot className="bg-gray-100 w-full">
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()} className="w-full">
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps()}
                    className={`${colClass} text-left text-xs font-medium text-gray-600 uppercase tracking-wider`}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
        {renderFooterComponent && renderFooterComponent()}
      </table>
    </Wrapper>
  );
};

export default Table;
