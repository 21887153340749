import {useState} from 'react';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {PunchHTTP} from '../../../../../providers/punch';

import Modal from '../../../../../../../components/_modals/Modal';
import Button from '../../../../../../../components/Button';

const DeletePunchModal = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
  punchId = '',
}) => {
  const {formatMessage} = useIntl();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await PunchHTTP.destroy(punchId);

      toast.success(formatMessage({id: 'toast.punch-deleted'}));
      onSuccess();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      maxWidth="max-w-lg"
      minHeight={195}
      open={open}
      onClose={onClose}
      title={formatMessage({id: 'timecard.delete-punch'})}>
      <div>
        <p className="text-medium font-normal text-gray-600 px-4 pb-1">
          {formatMessage({id: 'timecard.confirm-delete'})}
        </p>
        <p className="text-sm font-bold text-gray-600 px-4 pb-6">
          {formatMessage({id: 'timecard.action-irreversible'})}
        </p>
        <div className="form-footer">
          <Button type="button" onClick={() => onSubmit()} loading={loading}>
            {formatMessage({id: 'confirm'})}
          </Button>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={() => onClose()}
            extraClassName="mr-2">
            {formatMessage({id: 'cancel'})}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePunchModal;
