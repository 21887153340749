import {useCallback, useEffect, useState, useMemo} from 'react';
import {PlusIcon} from '@heroicons/react/24/outline';
import {format as formatDate} from 'date-fns';
import {toast} from 'react-toastify';
// import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../../../auth/hooks/useAuth';
import api from '../../../../../services/api';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Avatar from '../../../../../components/Avatar';
import Button from '../../../../../components/Button';
// import IconButton from '../../../../../components/IconButton';
import Search from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import Modal from '../../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../../components/LoadingBox';
// import Switch from '../../../../../components/Switch';

import EmployeeForm from './components/Form';

const UsersPage = () => {
  // const navigate = useNavigate();
  const {user} = useAuth();

  const [activeTab, setActiveTab] = useState('active');
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [search, setSearch] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [usersState, setUsersState] = useState({
    loading: true,
    data: [],
  });

  const getUsers = async (params) => {
    if (!usersState.loading) setUsersState({loading: true, data: []});

    const {data} = await api.get('/users', {
      params: {...params, company_id: user.company._id},
    });

    setUsersState({loading: false, data: data.users});
  };

  useEffect(() => {
    getUsers({status: ''});
  }, []);

  const handleCreate = useCallback(async (payload) => {
    try {
      setCreateLoading(true);
      await api.post('/users', payload);
      getUsers({status: ''});
      setOpenModal(false);
      toast.success('User created successfully!');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.field) {
        setErrors(err.response.data);
      }
    } finally {
      setCreateLoading(false);
    }
  }, []);

  // const handleUpdateStatus = useCallback(async ({status, id}) => {
  //   try {
  //     console.log('status', status);
  //     console.log('status', id);
  //     await updateEmployee(id, {status: status ? 'active' : 'inactive'});
  //     toast.success('Done! status updated successfully.');
  //     await getUsers({status: ''});
  //   } catch (err) {
  //     if (err.response && err.response.data && err.response.data.field) {
  //       setErrors(err.response.data);
  //     }
  //   }
  // }, []);

  const handleCreateEmployee = () => {
    setOpenModal(true);
  };

  const tabItems = useMemo(
    () => [
      {
        value: 'all',
        label: `All`,
      },
      {
        value: 'active',
        label: `Active`,
      },
      {
        value: 'inactive',
        label: `Inactive`,
      },
    ],
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Avatar
              id={row.original._id}
              photo={row.original.photo || null}
              name={row.original.name}
              size={10}
            />
            <div className="ml-2">
              <p>{row.original.name}</p>
              {row.original.skill_id && (
                <p className="text-xs text-gray-500">
                  {row.original.skill_id.name}
                </p>
              )}
            </div>
          </div>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
      // {
      //   Header: 'Status',
      //   accessor: ({status, _id}) => (
      //     <Switch
      //       label={status}
      //       checked={status === 'active'}
      //       onChange={(val) => handleUpdateStatus({id: _id, status: val})}
      //     />
      //   ),
      // },
      {
        Header: 'Updated at',
        accessor: ({updated_at}) =>
          formatDate(new Date(updated_at), 'MM/dd/yyyy hh:mm:ss'),
      },
      // {
      //   Header: '',
      //   accessor: '_id',
      //   Cell: ({row}) => (
      //     <IconButton
      //       icon={ChevronRightIcon}
      //       onClick={() =>
      //         navigate(`/employee/${row.original._id}`, {replace: true})
      //       }
      //     />
      //   ),
      // },
    ],
    [],
  );

  return (
    <>
      <PageHeader
        title="Users"
        breadcrumbs={[
          {
            id: 'users',
            label: 'Users',
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() => handleCreateEmployee()}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New User
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={usersState.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <Search onChange={(e) => setSearch(e.target.value)} value={search} />
        }>
        <Table columns={columns} data={usersState.data.docs} />
      </SectionWrapper>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="New Employee">
        <EmployeeForm
          onSubmit={handleCreate}
          onCancel={() => setOpenModal(false)}
          submitErrors={errors}
        />
      </Modal>

      {createLoading && <LoadingBox info="creating new employee..." />}
    </>
  );
};

export default UsersPage;
