function currencyFormat(num) {
  return `$${parseFloat(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

function nativeCurrencyFormat(value = '1') {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export {currencyFormat, nativeCurrencyFormat};
