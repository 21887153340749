import {useMemo, useCallback} from 'react';
import {
  SignalSlashIcon,
  ReceiptRefundIcon,
  ClockIcon,
  CurrencyDollarIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {currencyFormat} from '../../../../../../../utils/currency';

import Map from '../../../../../../../components/Map';
import Table from '../../../../../../../components/Table';
// import Button from '../../../../../../../components/Button';
import EmptyData from '../../../../../../../components/EmptyData';
import Badge from '../../../../../../../components/Badge';
import {FormatDate} from '../../../../../../../components/FormatDate';

const checkIfOffline = (punch) => {
  if (punch.start_offline) return 'start';
  if (punch.end_offline) return 'end';
  if (punch.offline) return 'start';

  return 'online';
};

const JobLocationMenHours = ({report}) => {
  const {formatMessage} = useIntl();
  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({row, hasBg}) => (
      <>
        <td
          className={`px-6 py-4 whitespace-nowrap text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={3}>
          <Badge variant="secondary">
            {formatMessage({id: 'employee-comment'})}
          </Badge>
          <pre className="text-xs leading-3 mt-2">
            <code>
              {row.original.comments
                ? row.original.comments
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
        <td
          className={`px-6 py-4 whitespace-nowrap text-xs 2xl:text-sm text-gray-800 ${
            hasBg ? 'bg-gray-50' : ''
          }`}
          colSpan={3}>
          <Badge variant="secondary">
            {formatMessage({id: 'boss-comment'})}
          </Badge>
          <pre className="text-xs leading-3 mt-2">
            <code>
              {row.original.boss_comment && row.original.boss_comment.comment
                ? row.original.boss_comment.comment
                : formatMessage({id: 'no-comment'})}
            </code>
          </pre>
        </td>
      </>
    ),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'employee'})}`,
        accessor: 'department_name',
        Cell: ({row}) => {
          const hasReceipt = row.original.receipts;
          const bossUpdated = row.original.boss_editing;
          const bossCreated = row.original.boss_created;
          const managerCreated = row.original.manager_created;

          return (
            <div className="flex flex-col items-start">
              <div className="flex mb-2">
                {bossUpdated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'update-by'})}:{' '}
                    {row.original.last_boss_edit_name}
                  </Badge>
                )}
                {bossCreated && (
                  <Badge variant="danger" extraClassName="mr-2">
                    {formatMessage({id: 'created-by-boss'})}
                  </Badge>
                )}
                {managerCreated && (
                  <Badge variant="danger">
                    {formatMessage({id: 'created-by-manager'})}
                  </Badge>
                )}
              </div>

              <p className="font-bold">{row.original.employee_name}</p>
              <p className="text-xs text-gray-600">
                {row.original.department_name}
              </p>

              {hasReceipt && (
                <Badge variant="primary" extraClassName="mt-2">
                  <ReceiptRefundIcon className="w-3" />
                  {formatMessage({id: 'receipt'})}
                </Badge>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'in'})}`,
        accessor: 'start',
        Cell: ({row}) => {
          const {original} = row;
          const {start} = original;
          return (
            <div style={{maxWidth: 300}}>
              {start ? (
                <div className="flex flex-col items-start">
                  {checkIfOffline(original) === 'start' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" /> Offline
                    </Badge>
                  )}
                  <p className="text-yellow-600 font-bold text-xs 2xl:text-sm whitespace-normal">
                    {start.location.address}
                  </p>
                  <p className="text-xs 2xl:text-sm">
                    <FormatDate value={start.time} />
                    <br />
                    <FormatDate value={start.time} pattern="EEEE" />
                  </p>
                </div>
              ) : (
                <p className="text-xs 2xl:text-sm">
                  {formatMessage({id: 'timecard.puch-with-issue'})}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'out'})}`,
        accessor: 'in',
        Cell: ({row}) => {
          const {original} = row;
          const {end, missed_out} = original;
          return (
            <div style={{maxWidth: 300}}>
              {end ? (
                <div className="flex flex-col items-start">
                  {checkIfOffline(original) === 'end' && (
                    <Badge variant="danger" extraClassName="mb-2">
                      <SignalSlashIcon className="w-4" /> Offline
                    </Badge>
                  )}
                  <p className="text-yellow-600 font-bold text-xs 2xl:text-sm whitespace-normal">
                    {end.location.address}
                  </p>
                  {end.time && (
                    <p className="text-xs 2xl:text-sm">
                      <FormatDate value={end.time} />
                      <br />
                      <FormatDate value={end.time} pattern="EEEE" />
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {original.in && (
                    <Badge variant="success">{formatMessage({id: 'in'})}</Badge>
                  )}
                  {missed_out && (
                    <Badge variant="error">
                      {formatMessage({id: 'timecard.missed-out'})}
                    </Badge>
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'hours'})}`,
        accessor: 'hours',
        Cell: ({row}) => {
          const {original} = row;

          if (original.in)
            return <Badge variant="success">{formatMessage({id: 'in'})}</Badge>;

          return (
            <div className="flex flex-col items-start">
              <p className="text-gray-600 font-semibold text-xs 2xl:text-sm">
                {formatMessage({id: 'work'})}: {original.hours}
              </p>
              {original.lunch_deduction &&
                original.hours_with_deduction !== original.hours && (
                  <>
                    <p className="text-gray-500 text-xs 2xl:text-sm mt-2">
                      {formatMessage({id: 'lunch-deduction'})}: -{' '}
                      {original.lunch_deduction_time}
                    </p>
                    <p className="text-gray-600 font-bold text-xs 2xl:text-sm">
                      {formatMessage({id: 'after-deduction'})}:{' '}
                      {original.hours_with_deduction}
                    </p>
                  </>
                )}
            </div>
          );
        },
      },
      {
        Header: `${formatMessage({id: 'amount'})}`,
        accessor: 'total',
        Cell: ({row}) => {
          const hasReceipt = row.original.receipts;

          if (row.original.in)
            return <Badge variant="success">{formatMessage({id: 'in'})}</Badge>;

          return (
            <div>
              <p className="text-xs 2xl:text-sm">
                {currencyFormat(row.original?.total?.$numberDecimal)}
              </p>
              {hasReceipt && (
                <p className="text-xs text-gray-500">
                  {formatMessage({id: 'receipts-amount'})}:{' '}
                  {currencyFormat(row.original.receipts)}
                </p>
              )}
            </div>
          );
        },
      },
    ],
    [formatMessage],
  );

  return report.data?.punches?.length > 0 ? (
    <>
      <div className="grid grid-cols-4 gap-4 px-5 pb-5 pt-2">
        <div className="flex items-center p-5 rounded-md bg-gray-100">
          <ClockIcon className="mr-2 w-6 text-yellow-500" />
          <div>
            <p>{report.data.amounts.totalWorked}</p>
            <h5 className="text-sm font-semibold text-gray-700">
              {formatMessage({id: 'men-hours'})}
            </h5>
          </div>
        </div>
        <div className="flex items-center p-5 rounded-md bg-gray-100">
          <ClipboardDocumentListIcon className="mr-2 w-6 text-yellow-500" />
          <div>
            <p>{currencyFormat(report.data.amounts.menHoursAmount)}</p>
            <h5 className="text-sm font-semibold text-gray-700">
              {formatMessage({id: 'gross-pay'})}
            </h5>
          </div>
        </div>
        <div className="flex items-center p-5 rounded-md bg-gray-100">
          <ReceiptRefundIcon className="mr-2 w-6 text-yellow-500" />
          <div>
            <p>{currencyFormat(report.data.amounts.receiptsTotalAmount)}</p>
            <h5 className="text-sm font-semibold text-gray-700">
              {formatMessage({id: 'receipts-amount'})}
            </h5>
          </div>
        </div>
        <div className="flex items-center p-5 rounded-md bg-gray-100">
          <CurrencyDollarIcon className="mr-2 w-6 text-yellow-500" />
          <div>
            <p>{currencyFormat(report.data.amounts.globalAmount)}</p>
            <h5 className="text-sm font-semibold text-gray-700">
              {formatMessage({id: 'total-amount'})}
            </h5>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <Map
          circles={[report.job]}
          points={report.data?.punches}
          initialZoom={16}
          initialData={{
            coordinates: report.job.location.coordinates,
            radius: report.job.location.radius.$numberDecimal,
            address: report.job.location.address,
            city: report.job.location.city,
          }}
        />
      </div>
      <Table
        columns={columns}
        data={report.data?.punches}
        renderRowSubComponent={renderRowSubComponent}
        showComment
        hasOver={false}
      />
    </>
  ) : (
    <EmptyData
      title={formatMessage({id: 'no-data-found'})}
      description={formatMessage({id: 'no-data-this-job'})}
    />
  );
};

export default JobLocationMenHours;
