import {useMemo, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {EmployeesHTTP} from '../../../../../providers/employees';

import {currencyFormat} from '../../../../../../../utils/currency';
import {FormatDate} from '../../../../../../../components/FormatDate';
import Table from '../../../../../../../components/Table';
import Loading from '../../../../../../../components/Loading';

const TimelinePage = ({userId = ''}) => {
  const {formatMessage} = useIntl();
  const [timeline, setTimeline] = useState({
    loading: true,
    data: [],
  });

  const columns = useMemo(
    () => [
      {
        Header: formatMessage({id: 'update-by'}),
        accessor: ({created_by}) => created_by.name,
      },
      {
        Header: formatMessage({id: 'date'}),
        accessor: ({created_at}) => (
          <FormatDate value={created_at} pattern="MM/dd/yyyy" />
        ),
      },
      {
        Header: formatMessage({id: 'hourly-rate'}),
        accessor: ({new_value}) =>
          currencyFormat(parseFloat(new_value.$numberDecimal)),
      },
    ],
    [],
  );

  useEffect(() => {
    async function fetchTimeline() {
      const {data} = await EmployeesHTTP.getTimeline(userId);
      setTimeline({
        loading: false,
        data,
      });
    }

    fetchTimeline();
  }, [userId]);

  if (timeline.loading)
    return (
      <div className="w-full flex justify-center p-8">
        <Loading />
      </div>
    );

  return <Table columns={columns} data={timeline.data} />;
};

export default TimelinePage;
