// import Home from '../pages/Home';
import Home from '../pages/HomeStripe';
import HomePublic from '../pages/HomeStripePublic';

export default [
  {
    path: '/reactivation',
    element: Home,
    exact: false,
    isPrivate: true,
  },
  {
    path: '/reactivate',
    element: HomePublic,
    exact: false,
    isPrivate: false,
  },
];
