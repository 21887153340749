import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {SubscriptionsHTTP} from '../../../../providers/subscriptions';
import {useAuth} from '../../../../../auth/hooks/useAuth';

import Card from '../../../../../../components/Card';
import TextArea from '../../../../../../components/_form/TextArea';
import Button from '../../../../../../components/Button';

import ConfirmModal from './components/ConfirmModal';

const CloseAccount = () => {
  const {formatMessage} = useIntl();
  const {signOut} = useAuth();

  const {
    control,
    formState: {errors},
    watch,
  } = useForm();

  const reason_close = watch('reason_close');

  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const onSubmit = async () => {
    try {
      if (!reason_close) {
        toast.error(formatMessage({id: 'toast.close-acc'}));
        setConfirmModalOpen(false);
        return;
      }

      setLoading(true);

      await SubscriptionsHTTP.cancel({reason_close});

      toast.success(formatMessage({id: 'toast.closed-sucess'}));
      await signOut();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card padding="p-0" className="overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-bold mb-2">
            {formatMessage({id: 'settings.close-acc-title'})}
          </h2>
          <p>
            {formatMessage({id: 'settings.close-acc-text1'})} <br />
            {formatMessage({id: 'settings.close-acc-text2'})} <br />
            {formatMessage({id: 'settings.close-acc-text3'})} <br />
            {formatMessage({id: 'settings.close-acc-text4'})}
          </p>
        </div>
        <form className="flex flex-col justify-between">
          <div className="form-content">
            <Controller
              name="reason_close"
              control={control}
              rules={{
                required: true,
              }}
              render={({field}) => (
                <TextArea
                  {...field}
                  label={formatMessage({id: 'settings.close-acc-why'})}
                  id="reason_close"
                  style={{minHeight: 120}}
                  error={errors.reason_close}
                />
              )}
            />
          </div>

          <div className="form-footer">
            <Button
              variant="error"
              type="button"
              onClick={() => setConfirmModalOpen(true)}
              loading={loading}>
              {formatMessage({id: 'settings.close-button'})}
            </Button>
          </div>
        </form>
      </Card>

      <ConfirmModal
        open={confirmModalOpen}
        onConfirm={onSubmit}
        onClose={() => setConfirmModalOpen(false)}
        loading={loading}
      />
    </>
  );
};

export default CloseAccount;
