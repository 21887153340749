import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';

import SignUpPtBr from '../pages/SignUpPtBr';
import SignUpPromotional from '../pages/SignUpPromotional';
import PaymentPromotional from '../pages/SignUpPromotional/Payment';

import RegisterBlackFriday from '../pages/RegisterBlackFriday';
import PaymentBlackFriday from '../pages/RegisterBlackFriday/Payment';

export default [
  {
    path: '/',
    element: SignIn,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/new-account',
    element: SignUp,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/teste-gratis',
    element: SignUpPtBr,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    element: ResetPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/register',
    element: SignUpPromotional,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/payment',
    element: PaymentPromotional,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/black',
    element: RegisterBlackFriday,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/payment-black',
    element: PaymentBlackFriday,
    exact: true,
    isPrivate: false,
  },
];
