/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import {useIntl} from 'react-intl';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {DashboardHTTP} from '../../../../providers/dashboard';

import Card from '../../../../../../components/Card';
import Loading from '../../../../../../components/Loading';
import Avatar from '../../../../../../components/Avatar';
import Badge from '../../../../../../components/Badge';
import {FormatDate} from '../../../../../../components/FormatDate';

const getTime = (punch) => {
  if (punch.missed_out) return punch.start.time;

  if (punch.end && punch.end.time) return punch.end.time;

  return punch.start.time;
};

const PunchesCard = () => {
  const {formatMessage} = useIntl();

  const [punches, setPunches] = useState({
    loading: true,
    data: [],
  });
  const navigate = useNavigate();
  const handleGoToPage = (id) => {
    navigate(`/timecard/${id}`, {preventScrollReset: false});
  };

  useEffect(() => {
    async function fetchLastPunches() {
      const {data} = await DashboardHTTP.getLastPunches();
      setPunches({
        loading: false,
        data,
      });
    }

    fetchLastPunches();
  }, []);

  return (
    <Card className="h-full">
      <h2 className="text-2xl font-bold text-gray-700">
        {formatMessage({id: 'dashboard.last-punches'})}
      </h2>
      <p className="text-gray-500 mb-4">
        {formatMessage({id: 'dashboard.last-punches-description'})}
      </p>
      {punches.loading ? (
        <Loading />
      ) : (
        <ul className="m-h-full overflow-x-hidden overflow-y-scroll">
          {punches.data.map((i) => (
            <li
              onClick={() => handleGoToPage(i.user_id._id)}
              key={i._id}
              style={{cursor: 'pointer'}}
              className="flex items-center py-3 pr-4 border-b border-gray-200">
              <Avatar
                id={i.user_id._id}
                photo={i.user_id.photo || null}
                name={i.user_id.name}
                size={10}
              />
              <div className="flex flex-grow flex-col items-start pl-2">
                <p className="text-md">{i.user_id.name}</p>
                <Badge
                  variant={
                    i.missed_out ? 'danger' : i.in ? 'success' : 'error'
                  }>
                  {i.missed_out ? 'MISSED OUT' : i.in ? 'IN' : 'OUT'}
                </Badge>
              </div>
              <p className="text-sm text-gray-800">
                <FormatDate value={getTime(i)} />
              </p>
            </li>
          ))}
        </ul>
      )}
    </Card>
  );
};

export default PunchesCard;
