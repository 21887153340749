import api from '../../../services/api';

export const EmployeesHTTP = {
  get: (params = {}) => api.get('/employees', {params}),
  getById: (id = '') => api.get(`/employees/${id}`),
  create: (payload = {}) => api.post('/employees', payload),
  update: (id = '', payload = {}) => api.put(`/employees/${id}`, payload),
  getTimeline: (id = '') => api.get(`/employees/hourprice/logs/${id}`),
  getDevices: (id = '') => api.get(`/employees/devices/${id}`),
};
