import {Fragment, useMemo, useState} from 'react';
import {Link, useMatch, useLocation, useNavigate} from 'react-router-dom';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useTranslation} from '../../../modules/translation/hooks/useTranslation';
import adminMenu, {salesMenu} from '../../../modules/admin/menu';
import companyMenu from '../../../modules/company/menu';

import Modal from '../../_modals/Modal';

import logo from '../../../assets/images/logos-inline.png';
import avatarImg from '../../../assets/images/avatar.png';
import brFlag from '../../../assets/images/flags/brazil.png';
import usaFlag from '../../../assets/images/flags/united-states.png';

import SubMenu from './components/SubMenu';

const menus = {
  admin: adminMenu,
  boss: companyMenu,
  sales: salesMenu,
  sales_assistent: salesMenu,
};

const links = {
  '#dashboard': 'https://www.youtube.com/embed/VDUl-pIkvhk?si=BBK8i6JknmPnWJEO',
  '#users': 'https://www.youtube.com/embed/MzecV6I6GY0?si=F4lr7kHIotDK9kXE',
  '#company': 'https://www.youtube.com/embed/nWpTPHeOQWs?si=zFD2vFzR3Pk9S0wT',
  '#TrackMyGuys':
    'https://www.youtube.com/embed/Q4dMqpCrg-4?si=LmRXktZCmtzIDrCJ',
  '#timecard': 'https://www.youtube.com/embed/gaDI44tChGs?si=9B8w6whd_SAfC5ri',
  '#extras': 'https://www.youtube.com/embed/wXJL080ed1s?si=f5aZ12iIwHUyp7s8',
  '#reports': 'https://www.youtube.com/embed/gkbU7HmEius?si=i8PSbj6Dfy8FsWul',
  '#settings': 'https://www.youtube.com/embed/aVHlkiJTpXc?si=BM3kSSNVDTYrc3p',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const getMenu = (user, currentCompany, isSalesCompany = false) => {
  if (isSalesCompany) return menus.sales;
  if (currentCompany) return menus.boss;

  return menus[user.role];
};

const Header = () => {
  const {formatMessage} = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user,
    currentCompany,
    setCurrentCompany,
    currentCompanyName,
    currentCompanyUuid,
    signOut,
  } = useAuth();
  const {onChangeLang, lang} = useTranslation();

  const [tutorialModal, setTutorialModal] = useState({
    open: false,
    videoLink: '',
  });

  const isAdmin = user.role === 'admin';
  const isAdminOrSales =
    isAdmin || ['sales', 'sales_assistant'].includes(user.role);
  const isSalesCompany = user.company.sales;

  const adminHeader = Boolean(!currentCompany && isAdminOrSales);
  const navigation = getMenu(user, currentCompany, isSalesCompany);

  const headerClass = adminHeader
    ? 'from-gray-700 to-gray-800'
    : 'from-yellow-500 to-yellow-600';

  const isActive = (item) => {
    let isMatch = useMatch(item.route);

    if (!isMatch) {
      isMatch = item.routerGroup
        ? item.routerGroup.find((i) => location.pathname === i)
        : location.pathname.includes(item.route);
    }

    return isMatch;
  };

  const handleBackToAdmin = async () => {
    await setCurrentCompany(null);
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (window.location.href = '/admin/dashboard'), 600);
  };

  const onOpenTutorial = (videoLink) => {
    setTutorialModal({open: true, videoLink: links[videoLink]});
  };

  const profile = useMemo(
    () => [
      {
        id: 'my-profile',
        label: 'header.my-profile',
        onClick: () => navigate('/settings'),
      },
      {
        id: 'settings',
        label: 'header.settings',
        onClick: () => navigate('/settings'),
      },
      {
        id: 'logout',
        label: 'logout',
        onClick: () => signOut(),
      },
    ],
    [],
  );

  return (
    <>
      <Disclosure
        as="header"
        className={`bg-gradient-to-r ${headerClass} positive z-20`}>
        {({open}) => (
          <>
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-20">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-6 md:h-10 lg:h-12"
                      src={logo}
                      alt="Work Podium - Track My Guys"
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col items-end justify-center ml-6 mr-2 md:mr-0">
                    <p className="font-semibold text-sm text-white md:text-md">
                      {currentCompanyName || 'Track My Guys'}
                    </p>
                    <p className="font-semibold text-xs text-yellow-200">
                      {isAdminOrSales && 'CURRENT COMPANY - '}{' '}
                      {currentCompanyUuid
                        ? `SUPPORT ID: ${currentCompanyUuid}`
                        : 'MY COMPANY'}
                    </p>
                  </div>

                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="ml-3 relative z-30">
                        {({open: openProfile}) => (
                          <>
                            <div>
                              <Menu.Button className="overflow-hidden max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-yellow-700">
                                <span className="sr-only">Open languages</span>

                                <img
                                  className=""
                                  src={lang === 'en' ? usaFlag : brFlag}
                                  alt=""
                                  width={32}
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={openProfile}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95">
                              <Menu.Items
                                static
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      type="button"
                                      onClick={() => onChangeLang('en')}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex items-center w-full px-4 py-2 text-sm text-gray-700 text-left',
                                      )}>
                                      <img
                                        className="mr-2"
                                        src={usaFlag}
                                        alt=""
                                        width={26}
                                      />
                                      English (USA)
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      type="button"
                                      onClick={() => onChangeLang('pt-BR')}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex items-center w-full px-4 py-2 text-sm text-gray-700 text-left',
                                      )}>
                                      <img
                                        className="mr-2"
                                        src={brFlag}
                                        alt=""
                                        width={26}
                                      />
                                      Português
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                      {/* <button
                        type="button"
                        className="bg-yellow-900 p-2 rounded-full text-yellow-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-700 focus:ring-transparent">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative z-30">
                        {({open: openProfile}) => (
                          <>
                            <div>
                              <Menu.Button className="max-w-xs bg-yellow-900 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-yellow-700">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={user.photo || avatarImg}
                                  alt=""
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={openProfile}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95">
                              <Menu.Items
                                static
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {profile.map((item) => (
                                  <Menu.Item key={`user-menu-item-${item.id}`}>
                                    {({active}) => (
                                      <button
                                        type="button"
                                        onClick={item.onClick}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block w-full px-4 py-2 text-sm text-gray-700 text-left',
                                        )}>
                                        {formatMessage({id: item.label})}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                                {currentCompany && (
                                  <Menu.Item key="mobile-item-back-admin">
                                    {() => (
                                      <button
                                        onClick={() => handleBackToAdmin()}
                                        type="button"
                                        className="block px-4 py-2 text-sm text-yellow-600">
                                        Back to admin
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-yellow-700 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-50 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-transparent">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map(
                  ({
                    label,
                    route,
                    icon: ItemIcon,
                    hasDropDown = false,
                    dropDownItems,
                  }) =>
                    hasDropDown ? (
                      <>
                        <p className="px-3">{formatMessage({id: label})}</p>
                        {dropDownItems
                          .filter((i) => i.route !== '#tutorial-modal')
                          .map(
                            ({
                              label: labelSub,
                              route: routeSub,
                              icon: SubIcon,
                            }) => (
                              <Link
                                key={`mobile-sub-${routeSub}`}
                                to={routeSub}
                                className="text-white hover:text-yellow-800 flex items-center px-3 py-2 rounded-md text-base font-medium">
                                <>
                                  {' '}
                                  <SubIcon
                                    className="w-4 mr-2"
                                    aria-hidden="true"
                                  />{' '}
                                  {formatMessage({id: labelSub})}{' '}
                                </>
                              </Link>
                            ),
                          )}
                      </>
                    ) : (
                      <Link
                        key={`mobile-${route}`}
                        to={route}
                        className="text-white hover:text-yellow-800 flex items-center px-3 py-2 rounded-md text-base font-medium">
                        <>
                          {' '}
                          <ItemIcon
                            className="w-4 mr-2"
                            aria-hidden="true"
                          />{' '}
                          {label}{' '}
                        </>
                      </Link>
                    ),
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-yellow-600">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {profile.map((item) => (
                    <button
                      type="button"
                      key={`user-menu-${item.id}`}
                      onClick={item.onClick}
                      className="block w-full px-4 py-2 text-md text-white text-left">
                      {formatMessage({id: item.label})}
                    </button>
                  ))}
                  {currentCompany && (
                    <button
                      type="button"
                      onClick={() => handleBackToAdmin()}
                      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-yellow-800">
                      Back to admin
                    </button>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* menu items */}
      <nav className="bg-white shadow hidden md:block positive z-10">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-6">
          <div className="flex items-center justify-start h-16">
            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="flex items-baseline space-x-2">
                  {navigation.map(
                    ({
                      label,
                      route,
                      routerGroup,
                      icon: ItemIcon,
                      hasDropDown = false,
                      dropDownItems,
                    }) =>
                      hasDropDown ? (
                        <SubMenu
                          key={`dropdown-nav-${route}`}
                          label={label}
                          items={dropDownItems}
                          itemIcon={ItemIcon}
                          activeItem={isActive({route, routerGroup})}
                          onOpenTutorial={(videoLink) =>
                            onOpenTutorial(videoLink)
                          }
                        />
                      ) : (
                        <Link
                          key={`nav-${route}`}
                          to={route}
                          className={`px-3 py-3 rounded-md text-sm font-medium flex items-center ${
                            isActive({route, routerGroup})
                              ? 'text-yellow-600 bg-yellow-50'
                              : 'text-gray-600'
                          } hover:text-yellow-600 hover:bg-yellow-50`}>
                          <>
                            {' '}
                            <ItemIcon
                              className="w-4 mr-2"
                              aria-hidden="true"
                            />{' '}
                            {formatMessage({id: label})}{' '}
                          </>
                        </Link>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Modal
        open={tutorialModal.open}
        onClose={() => setTutorialModal((prev) => ({...prev, open: false}))}
        maxWidth="max-w-2xl"
        contentExtraClasses="items-center pb-4">
        <iframe
          width="560"
          height="315"
          src={tutorialModal.videoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Modal>
    </>
  );
};

export default Header;
