/* eslint-disable camelcase */
import {useCallback, useEffect, useState, useMemo} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useAuth} from '../../../../auth/hooks/useAuth';
import {BossHTTP} from '../../../providers/boss';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';
import Avatar from '../../../../../components/Avatar';
import Button from '../../../../../components/Button';
import {FormatDate} from '../../../../../components/FormatDate';
import IconButton from '../../../../../components/IconButton';
import Search from '../../../../../components/Search';
import Table from '../../../../../components/Table';
import Modal from '../../../../../components/_modals/Modal';
import {LoadingBox} from '../../../../../components/LoadingBox';

import BossForm from './components/Form';

const BossPage = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {canCreateBoss} = useAuth();

  const [activeTab, setActiveTab] = useState('active');
  const [openModal, setOpenModal] = useState(false);
  const [openLimitModal, setOpenLimitModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [bosses, setBosses] = useState({
    loading: false,
    all: [],
    active: [],
    inactive: [],
  });
  const [search, setSearch] = useState('');

  async function fetchBoss() {
    setBosses({loading: true});
    const {data} = await BossHTTP.get({status: 'all'});
    const all = data;
    const active = data.filter((item) => item.status === 'active');
    const inactive = data.filter((item) => item.status === 'inactive');

    setBosses({all, active, inactive, loading: false});
  }

  useEffect(() => {
    fetchBoss();
  }, []);

  const handleCreate = useCallback(async (payload) => {
    try {
      setCreateLoading(true);
      await BossHTTP.create(payload);
      setOpenModal(false);
      toast.success('Boss created successfully!');
      fetchBoss();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.field) {
        setErrors(err.response.data);
      }
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const handleCreateBoss = () => {
    if (!canCreateBoss) {
      setOpenLimitModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const tabItems = useMemo(
    () => [
      {
        value: 'all',
        label: `${formatMessage({id: 'all'})}`,
      },
      {
        value: 'active',
        label: `${formatMessage({id: 'active'})}`,
      },
      {
        value: 'inactive',
        label: `${formatMessage({id: 'inactive'})}`,
      },
    ],
    [bosses],
  );

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
        Cell: ({row}) => (
          <div className="flex items-center">
            <Avatar
              id={row.original._id}
              photo={row.original.photo || null}
              name={row.original.name}
              size={10}
            />
            <p className="ml-2">{row.original.name}</p>
          </div>
        ),
      },
      {
        Header: `${formatMessage({id: 'username'})}`,
        accessor: 'username',
      },
      {
        Header: `${formatMessage({id: 'phone'})}`,
        accessor: 'phone',
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updated_at}) => <FormatDate value={updated_at} />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => navigate(`/boss/${row.original._id}`)}
          />
        ),
      },
    ],
    [],
  );

  const bossSearch =
    search.length > 0
      ? bosses[activeTab].filter((boss) =>
          boss.name.toLowerCase().includes(search.toLowerCase()),
        )
      : bosses[activeTab];

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'boss'})}
        breadcrumbs={[
          {
            id: 'users',
            label: formatMessage({id: 'users'}),
          },
          {
            id: 'boss',
            label: formatMessage({id: 'boss'}),
          },
        ]}
        rightContent={
          <Button extraClassName="shadow-sm" onClick={() => handleCreateBoss()}>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'boss.new-button'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={bosses.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <Search onChange={(e) => setSearch(e.target.value)} value={search} />
        }>
        <Table columns={columns} data={bossSearch} />
      </SectionWrapper>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="New Boss">
        <BossForm
          onSubmit={handleCreate}
          onCancel={() => setOpenModal(false)}
          submitErrors={errors}
          loading={createLoading}
        />
      </Modal>

      <Modal
        open={openLimitModal}
        onClose={() => setOpenLimitModal(false)}
        maxWidth="max-w-2xl"
        title="New Boss">
        <div className="p-6">
          <h2 className="text-3xl font-bold">Ops...</h2>
          <p className="mt-2 mb-4">
            {formatMessage({id: 'boss.limit-description'})}
          </p>
          <Button onClick={() => navigate(`/plans`, {replace: true})}>
            {formatMessage({id: 'boss.see-plans'})}
          </Button>
        </div>
      </Modal>

      {createLoading && <LoadingBox info="creating new boss..." />}
    </>
  );
};

export default BossPage;
