/* eslint-disable no-restricted-globals */
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function clearCurrencyValue(value) {
  return parseFloat(value.replace(/,/g, '')).toFixed(2);
}

export function formatNumbersUS(value) {
  return new Intl.NumberFormat().format(value);
}

export function getDateWithoutTimezone(value, divider = '-') {
  const date = value.substring(0, 10).split('-');
  return `${date[1]}${divider}${date[2]}${divider}${date[0]}`;
}

export function resetTimezone(value) {
  const dt = new Date(value);

  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );

  return dtDateOnly;
}

const imagesTypes = ['.jpge', '.jpg', '.JPEG', '.JPG', '.png', '.gif'];
export const checkIsImage = (file = '') => {
  const isImage = imagesTypes.find((tp) => file.includes(tp));
  if (isImage) return true;

  return false;
};

export const validateWhiteSpaces = (value) => {
  if (/\s/.test(value)) {
    return false;
  }

  return true;
};

export const validateUppercase = (value) => {
  if (/[A-Z]/.test(value)) {
    return false;
  }

  return true;
};
