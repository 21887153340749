/* eslint-disable no-unused-vars */
import {useState, useLayoutEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';

import api, {apiTs} from '../../../../../../../services/api';
import {useAuth} from '../../../../../../auth/hooks/useAuth';
import {usStates} from '../../../../../../../utils/states';
import {currencyFormat} from '../../../../../../../utils/currency';

import Modal from '../../../../../../../components/_modals/Modal';
import Input from '../../../../../../../components/_form/Input';
import MaskedInput from '../../../../../../../components/_form/MaskedInput';
import Button from '../../../../../../../components/Button';
import Select from '../../../../../../../components/_form/Select';
import {LoadingBox} from '../../../../../../../components/LoadingBox';
import {FieldsetTitle} from '../../../../../../../components/_form/FieldsetTitle';

const CompanyFormModal = ({
  open = false,
  onClose = () => {},
  onSucess = () => {},
}) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {},
  });

  const [loading, setLoading] = useState(false);

  const canUseEmail = async (email) => {
    try {
      if (email.length < 5) return false;

      const {data} = await api.post('/validations', {
        type: 'full_email',
        value: email,
      });

      if (data.hasValue) return false;

      return true;
    } catch (_) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        ...data,
        role: 'sales',
        sales: true,
        trial: false,
        language: 'en',
      };
      await apiTs.post('/companies', payload);

      toast.success('Company created successfully!');

      onSucess();
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        maxWidth="max-w-xl"
        minHeight={500}
        open={open}
        onClose={onClose}
        title="Create a company">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="grid gap-4 mt-2 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Name *"
                id="name"
                error={errors.name}
                errorMessage={errors.name?.message}
                {...register('name', {required: true})}
              />
            </div>

            <div className="mt-2 grid gap-4 grid-cols-1 lg:grid-cols-2">
              <Input
                label="Email *"
                id="email"
                error={errors.email}
                errorMessage={errors.email && errors.email.message}
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Provide a valid email',
                  },
                  validate: {
                    checkUrl: async (value) =>
                      (await canUseEmail(value)) ||
                      'This email is already in use',
                  },
                })}
              />

              <Controller
                rules={{
                  required: true,
                  minLength: 14,
                  pattern: {
                    value: /^[^_]*$/i,
                    message: 'Provide a valid phone number',
                  },
                }}
                name="phone"
                control={control}
                render={({field}) => (
                  <MaskedInput
                    {...field}
                    label="Phone *"
                    mask="(999) 999-9999"
                    maskPlaceholder="(999) 999-9999"
                    alwaysShowMask={false}
                    removeMask={false}
                    error={errors.phone}
                    errorMessage={errors.phone ? errors.phone.message : ''}
                  />
                )}
              />
            </div>

            <FieldsetTitle>Address</FieldsetTitle>

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Street"
                id="street"
                type="text"
                error={errors.street}
                {...register('street')}
              />
              <Input
                label="City"
                id="city"
                type="text"
                error={errors.city}
                {...register('city')}
              />
              <Input
                label="Zip Code"
                id="zip_code"
                type="text"
                error={errors.zip_code}
                {...register('zip_code')}
              />
              <Controller
                name="state"
                control={control}
                render={({field}) => (
                  <Select
                    {...field}
                    label="State"
                    id="select-state"
                    options={usStates}
                  />
                )}
              />
            </div>

            <FieldsetTitle>Account</FieldsetTitle>
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Comission *"
                id="comission"
                type="number"
                error={errors.comission}
                {...register('comission')}
              />
              <Controller
                name="status"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    label="Status"
                    id="select-status"
                    error={errors.status}
                    options={[
                      {label: 'Active', value: 'active'},
                      {label: 'Inactive', value: 'inactive'},
                    ]}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-footer">
            <Button type="submit" loading={loading}>
              Save
            </Button>
            <Button
              variant="secondary"
              disabled={loading}
              onClick={() => onClose()}
              extraClassName="mr-2">
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      {loading && <LoadingBox info="Creating account..." />}
    </>
  );
};

export default CompanyFormModal;
