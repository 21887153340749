import {useEffect, useState, useMemo, useCallback} from 'react';
import {PlusIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {DepartmentsHTTP} from '../../providers/departments';

import PageHeader from '../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../components/SectionWrapper';
import {FormatDate} from '../../../../components/FormatDate';
import Button from '../../../../components/Button';
import Badge from '../../../../components/Badge';
import IconButton from '../../../../components/IconButton';
import Search from '../../../../components/Search';
import Table from '../../../../components/Table';
import Modal from '../../../../components/_modals/Modal';
import DepartmentForm from './components/Form';

const Departments = () => {
  const {formatMessage} = useIntl();
  const [activeTab, setActiveTab] = useState('active');
  const [search, setSearch] = useState('');
  const [departments, setDepartments] = useState({
    loading: true,
    data: [],
  });
  const [formType, setFormType] = useState({
    type: 'create',
    loading: false,
    selected: {},
    modal: false,
  });

  const tabItems = [
    {
      value: 'all',
      label: formatMessage({id: 'all'}),
    },
    {
      value: 'active',
      label: formatMessage({id: 'active'}),
    },
    {
      value: 'inactive',
      label: formatMessage({id: 'inactive'}),
    },
  ];

  function handleEdit(data) {
    setFormType({
      type: 'edit',
      loading: false,
      selected: {
        _id: data._id,
        name: data.name,
        status: data.status,
        max_shift: data.max_shift,
        deduction_after: data.deduction_after ? data.deduction_after : 'off',
        lunch_deduction_time: data.lunch_deduction_time,
        deduction_days: data.deduction_days,
        overtime_active: data.overtime.active,
        overtime_hours: data.overtime.hours,
        overtime_value: data.overtime.value
          ? data.overtime.value.$numberDecimal
          : 1,
      },
      modal: true,
    });
  }

  async function fetchDepartments() {
    setDepartments({
      loading: true,
      data: [],
    });
    const {data} = await DepartmentsHTTP.get({
      status: activeTab,
    });
    setDepartments({
      loading: false,
      data,
    });
  }

  const handleSubmit = useCallback(
    async (values) => {
      const deductionDays = values.lunch_deduction_days
        .filter((d) => d.selected)
        .map((d) => d.week_day);
      const {name, max_shift, status, deduction_after, overtime_active} =
        values;

      const hasLunchDeduction = Boolean(deduction_after !== 'off');
      const hasOvertime = overtime_active;

      const payload = {
        name,
        max_shift,
        status,
        lunch_deduction: hasLunchDeduction,
        lunch_deduction_time: values.lunch_deduction_time,
        deduction_after,
        deduction_days: deductionDays,
        overtime: {
          active: hasOvertime,
          hours: hasOvertime ? values.overtime_hours : 0,
          value: hasOvertime ? values.overtime_value : 1,
        },
      };

      try {
        setFormType((prev) => ({...prev, loading: true}));
        if (formType.type === 'create') {
          await DepartmentsHTTP.create(payload);
          toast.success('Department created successfully!');
        } else {
          await DepartmentsHTTP.update(formType.selected._id, payload);
          toast.success('Department edited successfully!');
        }
        fetchDepartments();

        setTimeout(() => {
          setFormType({
            loading: false,
            modal: false,
            selected: {},
          });
        }, 500);
      } catch (_) {
        setFormType((prev) => ({...prev, loading: false}));
      }
    },
    [formType],
  );

  const columns = useMemo(
    () => [
      {
        Header: `${formatMessage({id: 'name'})}`,
        accessor: 'name',
      },
      {
        Header: `${formatMessage({id: 'max-shift'})}`,
        accessor: 'max_shift',
      },
      {
        Header: `${formatMessage({id: 'lunch-deduction'})}`,
        accessor: ({lunch_deduction, lunch_deduction_time}) => (
          <Badge variant={lunch_deduction ? 'success' : 'danger'}>
            {lunch_deduction ? lunch_deduction_time : 'NO'}
          </Badge>
        ),
      },
      {
        Header: `${formatMessage({id: 'overtime'})}`,
        accessor: ({overtime}) => (
          <Badge variant={overtime.active ? 'success' : 'danger'}>
            {overtime.active ? 'YES' : 'NO'}
          </Badge>
        ),
      },
      {
        Header: `${formatMessage({id: 'updated-at'})}`,
        accessor: ({updated_at}) => <FormatDate value={updated_at} />,
      },
      {
        Header: '',
        accessor: '_id',
        Cell: ({row}) => (
          <IconButton
            icon={ChevronRightIcon}
            onClick={() => handleEdit(row.original)}
          />
        ),
      },
    ],
    [formatMessage],
  );

  useEffect(() => {
    fetchDepartments();
  }, [activeTab]);

  const departmentsSearch =
    search.length > 0
      ? departments.data.filter((j) =>
          j.name.toLowerCase().includes(search.toLowerCase()),
        )
      : departments.data;

  return (
    <>
      <PageHeader
        title={formatMessage({id: 'departments'})}
        breadcrumbs={[
          {
            id: 'company',
            label: formatMessage({id: 'company'}),
          },
          {
            id: 'department',
            label: formatMessage({id: 'departments'}),
          },
        ]}
        rightContent={
          <Button
            extraClassName="shadow-sm"
            onClick={() =>
              setFormType({
                type: 'create',
                loading: false,
                selected: {},
                modal: true,
              })
            }>
            <>
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {formatMessage({id: 'new-department'})}
            </>
          </Button>
        }
      />

      <SectionWrapper
        isLoading={departments.loading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}
        toolbar={
          <Search onChange={(e) => setSearch(e.target.value)} value={search} />
        }>
        <Table columns={columns} data={departmentsSearch} />
      </SectionWrapper>

      <Modal
        maxWidth="max-w-2xl"
        minHeight={320}
        open={formType.modal}
        title={formType.type === 'edit' ? 'Edit Department' : 'New Department'}
        onClose={() =>
          setFormType({
            type: 'create',
            loading: false,
            modal: false,
            selected: {},
          })
        }>
        <DepartmentForm
          loading={formType.loading}
          initialData={formType.selected}
          type={formType.type}
          onSubmit={handleSubmit}
          onCancel={() =>
            setFormType({
              type: 'create',
              loading: false,
              modal: false,
              selected: {},
            })
          }
        />
      </Modal>
    </>
  );
};

export default Departments;
