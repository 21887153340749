import {useEffect} from 'react';
import {useEmployee} from '../../modules/company/hooks/useEmployee';

import SearchSelect from '../_form/SearchSelect';

const EmployeeSelect = ({status = 'active', onSelect = () => {}}) => {
  const {getEmployees, employees} = useEmployee();

  useEffect(() => {
    getEmployees();
  }, []);

  if (employees.loading) return null;

  return (
    <SearchSelect
      placeholder="Select a employee"
      options={employees[status].map((e) => ({
        value: e._id,
        label: e.name,
      }))}
      onChange={(selected) => onSelect(selected)}
    />
  );
};

export default EmployeeSelect;
