export const downloadFile = async (
  api = () => {},
  path = '',
  fileName = 'report',
  payload = null,
  params = {},
) => {
  let response;

  if (payload) {
    response = await api.post(path, payload, {
      responseType: 'blob',
    });
  } else {
    response = await api.get(path, {
      responseType: 'blob',
      params,
    });
  }
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
