const colors = [
  'indigo-600',
  'green-400',
  'purple-500',
  'yellow-500',
  'blue-400',
  'red-400',
  'purple-600',
  'green-500',
  'indigo-500',
];

const Avatar = ({id = '', name = '', photo = '', size = 10}) => {
  const getColor = () => {
    const formattedColorValue = id.replace(/\D/g, '');
    const parsedColor = Number.parseInt(formattedColorValue, 10);
    if (!Number.isNaN(parsedColor)) {
      return colors[parsedColor % colors.length];
    }

    return colors[Math.floor(Math.random() * colors.length)];
  };

  const letters =
    name.split(' ').length > 1
      ? `${name.split(' ')[0].charAt(0)}${name.split(' ')[1].charAt(0)}`
      : `${name.charAt(0)}${name.charAt(1)}`;

  const sizeClasses = `w-${size} h-${size}`;

  return (
    <div className={`flex-shrink-0 overflow-hidden ${sizeClasses}`}>
      {photo ? (
        <img
          className={`rounded-full object-cover ${sizeClasses}`}
          src={photo}
          alt={name}
        />
      ) : (
        <span
          className={`flex items-center justify-center rounded-full ${sizeClasses} bg-${getColor()} text-center text-md font-medium text-white uppercase`}>
          {letters}
        </span>
      )}
    </div>
  );
};

export default Avatar;
