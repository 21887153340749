/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {apiTs} from '../../../../services/api';

import Input from '../../../../components/_form/Input';
import MaskedInput from '../../../../components/_form/MaskedInput';
import Button from '../../../../components/Button';
import {LoadingBox} from '../../../../components/LoadingBox';

import logo from '../../../../assets/images/logos--wp-tmg-inline.png';
import worker1 from '../../../../assets/images/worker1.jpeg';

const SignUp = () => {
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await apiTs.patch('/signup', data);

      localStorage.removeItem('@TrackMyGuys:ptBrLp');
      localStorage.setItem('@TrackMyGuys:flow', 'new-account');

      setTimeout(() => {
        navigate('/onboarding');
      }, 500);
    } catch (error) {
      if (
        error.response.data &&
        error.response.data.err &&
        error.response.data.err.errors
      ) {
        const errorsKeys = Object.keys(error.response.data.err.errors);
        errorsKeys.forEach((k) => {
          const errorObj = error.response.data.err.errors[k];
          const field = errorObj.path;
          const message = errorObj.message.replace('Path', 'Field');
          setError(field, {
            type: 'manual',
            message,
          });
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative bg-white lg:h-screen lg:overflow-hidden">
        <div className="lg:h-full w-full lg:w-7/12">
          <div className="flex flex-col items-center justify-center w-full h-full p-4 md:p-8">
            <img
              className="mx-auto mb-4 mt-4 lg:mt-0"
              width={320}
              src={logo}
              alt="WorkPodium - Logo"
            />
            <h1 className="text-lg md:text-2xl font-extralight text-center lg:text-4xl">
              Falta pouco!
              <br />
              Finalize agora <span className="font-bold">seu cadastro</span>.
            </h1>

            <form
              className="w-full max-w-xl px-4 md:px-10 mt-6"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full">
                <Input
                  label="Seu nome *"
                  id="name"
                  error={errors.name}
                  errorMessage={errors.name?.message}
                  autoFocus
                  {...register('name', {required: true})}
                />
              </div>

              <div className="mt-2 w-full">
                <Controller
                  rules={{
                    required: true,
                    minLength: 14,
                    pattern: {
                      value: /^[^_]*$/i,
                      message: 'Informe um número válido',
                    },
                  }}
                  name="phone"
                  control={control}
                  render={({field}) => (
                    <MaskedInput
                      {...field}
                      label="Celular *"
                      mask="(999) 999-9999"
                      maskPlaceholder="(999) 999-9999"
                      alwaysShowMask={false}
                      removeMask={false}
                      error={errors.phone}
                      errorMessage={errors.phone ? errors.phone.message : ''}
                    />
                  )}
                />
              </div>

              <div className="mt-6">
                <Button
                  loading={loading}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                  Finalizar cadastro
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden lg:flex w-5/12 lg:absolute lg:inset-y-0 lg:right-0 bg-gradient-to-b from-yellow-500 to-yellow-700">
          <img
            className="h-full w-full object-cover lg:w-full lg:h-full opacity-10"
            src={worker1}
            alt="worker"
          />
        </div>
      </div>

      {loading && <LoadingBox info="Finalizando seu cadastro..." />}
    </>
  );
};

export default SignUp;
