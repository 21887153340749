import {useContextSelector} from 'use-context-selector';
import {AuthContext} from '../context/AuthContext';

export function useAuth() {
  const user = useContextSelector(AuthContext, (auth) => auth.user);
  const isAdmin = useContextSelector(AuthContext, (auth) => auth.isAdmin);
  const payPeriod = useContextSelector(AuthContext, (auth) => auth.payPeriod);
  const canCreateEmployee = useContextSelector(
    AuthContext,
    (auth) => auth.canCreateEmployee,
  );
  const employeesQty = useContextSelector(
    AuthContext,
    (auth) => auth.employeesQty,
  );
  const canCreateBoss = useContextSelector(
    AuthContext,
    (auth) => auth.canCreateBoss,
  );
  const bossQty = useContextSelector(AuthContext, (auth) => auth.bossQty);
  const currentCompany = useContextSelector(
    AuthContext,
    (auth) => auth.currentCompany,
  );
  const currentCompanyName = useContextSelector(
    AuthContext,
    (auth) => auth.currentCompanyName,
  );
  const currentCompanyUuid = useContextSelector(
    AuthContext,
    (auth) => auth.currentCompanyUuid,
  );
  const signUp = useContextSelector(AuthContext, (auth) => auth.signUp);
  const signIn = useContextSelector(AuthContext, (auth) => auth.signIn);
  const signOut = useContextSelector(AuthContext, (auth) => auth.signOut);
  const setCurrentCompany = useContextSelector(
    AuthContext,
    (auth) => auth.setCurrentCompany,
  );
  const timezone = useContextSelector(AuthContext, (auth) => auth.timezone);

  return {
    user,
    isAdmin,
    currentCompany,
    payPeriod,
    canCreateEmployee,
    employeesQty,
    canCreateBoss,
    bossQty,
    signUp,
    signIn,
    signOut,
    setCurrentCompany,
    currentCompanyName,
    currentCompanyUuid,
    timezone,
  };
}
