/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
import {useState, useEffect, forwardRef} from 'react';

import MaskedInput from '../MaskedInput';
import Select from '../Select';

const hour = /[0-2]/;
const digit = /[0-9]/;
const mask = [hour, digit, ':', digit, digit];

const TimePickerSelect = forwardRef(
  (
    {
      label = '',
      onChange = () => {},
      id = 'time-input',
      error = false,
      extraClassName = '',
      value = {value: '', period: 'AM'},
      ...rest
    },
    ref,
  ) => {
    const [touched, setTouched] = useState(false);
    const [inputValue, setInputValue] = useState(value.value);
    const [timePeriod, setTimePeriod] = useState(value.period);

    function handleOnChange(val) {
      setTouched(true);
      let hourValue = parseInt(val.split(':')[0]);
      const minutesValue = val.split(':')[1];

      let time = val;
      if (hourValue > 12) {
        hourValue -= 12;
        hourValue = hourValue < 10 ? `0${hourValue}` : hourValue;
        time = `${hourValue}:${minutesValue}`;
        setTimePeriod('PM');
      } else if (hourValue === 12) {
        setTimePeriod('PM');
      }

      setInputValue(time);
    }

    useEffect(() => {
      if (touched) {
        onChange({value: inputValue, period: timePeriod});
      }
    }, [inputValue, timePeriod]);

    return (
      <div className={`input-w-select w-48 ${extraClassName}`}>
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <div className="mt-1 relative rounded-md shadow-sm">
          <MaskedInput
            removeMask={false}
            mask={mask}
            maskPlaceholder="HH:MM"
            label={null}
            alwaysShowMask={false}
            onChange={(val) => handleOnChange(val)}
            defaultValue={inputValue}
            id={id}
            error={error}
            ref={ref}
            {...rest}
          />
          <div
            className={`absolute top-0 right-0 flex items-center input-w-select--select ${
              error ? 'input-w-select--select-error' : ''
            }`}>
            <Select
              className="w-24"
              value={timePeriod}
              label={null}
              onChange={(val) => setTimePeriod(val)}
              id="time-period-select"
              options={[
                {
                  label: 'AM',
                  value: 'AM',
                },
                {
                  label: 'PM',
                  value: 'PM',
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default TimePickerSelect;
