import {useState, useEffect, useCallback} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';

import {getDateWithoutTimezone} from '../../../../../utils/utils';
import {useJobLocation} from '../../../hooks/useJobLocation';

import PageHeader from '../../../../../components/_layouts/PageHeader';
import SectionWrapper from '../../../../../components/SectionWrapper';

import UpdateForm from './components/Form';
import UpdateLocation from './components/Location';
import BudgetTimeline from './components/BudgetTimeline';

const EditPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {getJob, updateJob, job, syncPunches} = useJobLocation();
  const {formatMessage} = useIntl();
  if (!id) navigate('/job-location');

  const [activeTab, setActiveTab] = useState('edit');
  const [updateLoading, setUpdateLoading] = useState(false);

  const tabItems = [
    {
      value: 'edit',
      label: formatMessage({id: 'edit'}),
    },
    {
      value: 'location',
      label: formatMessage({id: 'location'}),
    },
    {
      value: 'budget',
      label: formatMessage({id: 'budget-timeline'}),
    },
  ];

  const getJobLocation = async () => {
    try {
      await getJob(id);
    } catch (_error) {
      navigate('/job-location');
    }
  };

  const handleSubmitUpdate = async (data) => {
    try {
      setUpdateLoading(true);

      const payload = data;
      if (data.started_at) {
        payload.started_at = new Date(data.started_at).toDateString();
      }

      await updateJob(id, payload);
      syncPunches(id);
      getJobLocation();
      toast.success('Job location updated successfully!');
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleRenderTabs = useCallback(() => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case 'edit':
        return (
          <UpdateForm
            onSubmit={(data) => handleSubmitUpdate(data)}
            initialData={{
              ...job.data,
              budget: job.data.budget?.$numberDecimal,
              started_at: job.data.started_at
                ? getDateWithoutTimezone(job.data.started_at, '/')
                : null,
            }}
            loading={updateLoading}
          />
        );
      case 'location':
        return (
          <UpdateLocation
            location={job.data.location}
            onUpdate={(data) => handleSubmitUpdate(data)}
            loading={updateLoading}
          />
        );
      case 'budget':
        return (
          <BudgetTimeline
            jobLocation={job.data}
            onSuccess={() => getJobLocation()}
          />
        );
      //   default:
    }

    return true;
  }, [job, updateLoading, activeTab]);

  useEffect(() => {
    if (id) getJobLocation();
  }, [id]);

  const isLoading = job.loading && Object.entries(job.data).length < 1;

  if (isLoading) return <h1>loading...</h1>;

  return (
    <>
      <PageHeader
        title={job.data.name}
        breadcrumbs={[
          {
            id: 'company',
            label: formatMessage({id: 'company'}),
          },
          {
            id: 'job-location',
            label: formatMessage({id: 'job-location'}),
          },
        ]}
      />

      <SectionWrapper
        isLoading={isLoading}
        tabItems={tabItems}
        onSelectTab={(val) => setActiveTab(val)}
        activeTab={activeTab}>
        {handleRenderTabs()}
      </SectionWrapper>
    </>
  );
};

export default EditPage;
