import {Fragment, forwardRef} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid';

const Select = forwardRef(
  (
    {
      label = '',
      id,
      error,
      className = 'mb-2',
      onChange = () => {},
      options = [],
      defaultValue = '',
      placeholder = 'Select a option',
      value,
    },
    ref,
  ) => {
    const feedValue = value || defaultValue || '';
    const selectedValue = options.find((opt) => opt.value === feedValue);
    return (
      <div className={`relative z-5 ${className}`} ref={ref}>
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        )}
        <Listbox onChange={onChange} value={feedValue}>
          <div className="relative">
            <Listbox.Button className="relative w-full h-10 py-2 pl-3 pr-10 cursor-pointer focus:outline-none focus:ring-yellow-200 focus:border-yellow-100 sm:text-sm border border-gray-300 rounded-md">
              <span className="block truncate text-left">
                {selectedValue ? selectedValue.label : placeholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    className={({selected}) =>
                      `flex justify-between cursor-pointer select-none relative py-2 px-4 ${
                        selected
                          ? 'text-yellow-900 bg-yellow-100'
                          : 'text-gray-900'
                      } hover:text-yellow-500 hover:bg-yellow-50`
                    }
                    value={item.value}>
                    {({selected, active}) => (
                      <>
                        <span
                          className={`${
                            selected ? 'font-medium' : 'font-normal'
                          } block truncate`}>
                          {item.label}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-amber-600' : 'text-amber-600'
                            } pl-3`}>
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {error && (
          <p className="text-xs text-red-500 mt-2">This field is required</p>
        )}
      </div>
    );
  },
);

export default Select;
