/* eslint-disable no-prototype-builtins */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import {toast} from 'react-toastify';

export const stripeApi = axios.create({
  baseURL: process.env.REACT_APP_STRIPE_API_URL,
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiTs = axios.create({
  baseURL: process.env.REACT_APP_API_TS_URL,
});

export const apiAdonis = axios.create({
  baseURL: process.env.REACT_APP_API_ADONIS_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.data?.hasOwnProperty('reason') &&
      !error.response?.data?.reason === 'inactive company'
    ) {
      toast.warning('Company inactive!');
    } else if (error.response?.data?.hasOwnProperty('msg')) {
      if (error.response?.data?.err?.type === 'StripeCardError') {
        toast.error(
          error.response?.data?.err.message || 'Yor card was declined',
        );
      } else if (error.response.data.msg !== 'Plan not found') {
        toast.error(error.response.data.msg);
      }
    } else if (error.response?.data?.hasOwnProperty('error')) {
      toast.error(error.response.data.error);
    } else if (
      error.response?.data?.hasOwnProperty('auth') &&
      error.response?.data?.hasOwnProperty('action') &&
      error.response?.data?.action === 'logout'
    ) {
      localStorage.removeItem('@TrackMyGuys:token');
      localStorage.removeItem('@TrackMyGuys:cckey');
      localStorage.removeItem('@TrackMyGuys:flow');
      localStorage.removeItem('@TrackMyGuys:cnc');
      window.location.href = '/';
    } else {
      toast.error('Something wrong, please try again!');
    }

    if (error && error.response && error.response.config) {
      Sentry.configureScope((scope) =>
        scope.setTransactionName(
          `${error.response?.config?.method} - ${error.response?.config?.url}`,
        ),
      );
      Sentry.setTag('axios.method', error.response?.config?.method);
      Sentry.setTag('axios.url', error.response?.config?.url);

      Sentry.setContext('request_data', {
        payload: error.response?.config?.data?.replace('password', 'ps'),
        tk: error.response?.config?.headers['x-access-token'],
      });

      Sentry.setContext('response_data', {
        data: error.response?.data,
      });
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

apiAdonis.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.data?.hasOwnProperty('auth') &&
      error.response?.data?.hasOwnProperty('action') &&
      error.response?.data?.action === 'logout'
    ) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response?.data?.hasOwnProperty('message')) {
      toast.error(error.response.data.message);
    } else {
      toast.error('Something wrong, please try again!');
    }

    if (error && error.response && error.response.config) {
      Sentry.configureScope((scope) =>
        scope.setTransactionName(
          `${error.response?.config?.method} - ${error.response?.config?.url}`,
        ),
      );
      Sentry.setTag('axios.method', error.response?.config?.method);
      Sentry.setTag('axios.url', error.response?.config?.url);

      Sentry.setContext('request_data', {
        payload: error.response?.config?.data?.replace('password', 'ps'),
        tk: error.response?.config?.headers['x-access-token'],
      });

      Sentry.setContext('response_data', {
        data: error.response?.data,
      });
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

export default api;
