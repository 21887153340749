import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';

import {InvoiceTradeItemsHTTP} from '../../../../../../providers/trades';

import Modal from '../../../../../../../../components/_modals/Modal';
import Input from '../../../../../../../../components/_form/Input';
import Button from '../../../../../../../../components/Button';
import CurrencyInput from '../../../../../../../../components/_form/MaskedInput/Currency';
import Switch from '../../../../../../../../components/Switch';
import TextArea from '../../../../../../../../components/_form/TextArea';

import {currencyFormat} from '../../../../../../../../utils/currency';

const convertValue = (value) => {
  if (!value) return value;
  if (typeof value === 'string') return value.replace(',', '');

  return value;
};

const ItemForm = ({
  invoiceId = '',
  invoiceTradeId = '',
  invoiceTradeItem = null,
  open = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {formatMessage} = useIntl();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: {errors},
  } = useForm(invoiceTradeItem ? {defaultValues: invoiceTradeItem} : {});

  const [switchs, setSwitchs] = useState({
    labor: invoiceTradeItem ? invoiceTradeItem.laborIncluded : false,
    taxable: invoiceTradeItem ? invoiceTradeItem.taxable : false,
  });

  const unitPriceXQty =
    parseFloat(convertValue(watch('unitPrice'))) *
    parseInt(watch('quantity'), 10);
  const amountWithLabor =
    parseFloat(convertValue(watch('laborPrice')) || 0) + unitPriceXQty;
  const profitAmount =
    amountWithLabor * (parseInt(convertValue(watch('profit')) || 0, 10) / 100);

  const onUpdateItem = async (invoiceTradeItemId, data, isCreateFlow) => {
    try {
      await InvoiceTradeItemsHTTP.updateItem({
        id: invoiceTradeItemId,
        payload: data,
      });
      if (!isCreateFlow)
        toast.success(formatMessage({id: 'toast.updated-sucess'}));
    } catch (error) {
      if (!isCreateFlow)
        toast.error(formatMessage({id: 'toast.something-wrong'}));
    }
  };

  const onCreateItem = async (data) => {
    try {
      const createRes = await InvoiceTradeItemsHTTP.createItem(data);
      return createRes;
    } catch (error) {
      toast.error(formatMessage({id: 'toast.something-wrong'}));
      return null;
    }
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      invoiceTradeId,
      invoiceId,
      price: unitPriceXQty,
      profitAmount,
      itemAmount: amountWithLabor,
      laborIncluded: switchs.labor,
      taxable: switchs.taxable,
      laborPrice: values.laborPrice || 0,
      profit: values.profit || 0,
    };

    let invoiceTradeItemId = invoiceTradeItem ? invoiceTradeItem.id : null;
    let isCreateFlow = false;
    if (!invoiceTradeItem) {
      const createRes = await onCreateItem(data);
      invoiceTradeItemId = createRes.data.id;
      isCreateFlow = true;
      toast.success(formatMessage({id: 'toast.updated-sucess'}));
    }
    await onUpdateItem(invoiceTradeItemId, data, isCreateFlow);
    onSuccess();
  };

  useEffect(() => {
    reset(invoiceTradeItem || {});
  }, [open]);

  return (
    <Modal
      maxWidth="max-w-3xl"
      open={open}
      onClose={onClose}
      title="Trade Item">
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <Input
            label={formatMessage({id: 'bookpodium.estimates.trades.item.name'})}
            name="name"
            error={errors.trade_item_name}
            autoFocus
            {...register('name', {required: true})}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mb-2">
          <Controller
            rules={{
              required: true,
            }}
            name="unitPrice"
            control={control}
            render={({field}) => (
              <CurrencyInput
                {...field}
                label={formatMessage({
                  id: 'bookpodium.estimates.trades.item.unit_price',
                })}
                error={errors.unitPrice}
              />
            )}
          />
          <Input
            label={formatMessage({
              id: 'bookpodium.estimates.trades.item.quantity',
            })}
            name="quantity"
            type="number"
            error={errors.trade_item_name}
            {...register('quantity', {required: true})}
          />
        </div>
        <div className="flex justify-between pb-4 border-b mb-4">
          <p>
            {formatMessage({
              id: 'bookpodium.estimates.trades.item.item-quantity',
            })}
          </p>
          <span>{currencyFormat(unitPriceXQty || 0)}</span>
        </div>

        <div className="mb-4">
          <Switch
            label={formatMessage({
              id: 'bookpodium.estimates.trades.item.labor-install',
            })}
            onChange={(val) => setSwitchs((prev) => ({...prev, labor: val}))}
            checked={switchs.labor}
          />
          {switchs.labor && (
            <div className="grid grid-cols-2 gap-4 my-2">
              <Controller
                rules={{
                  required: true,
                }}
                name="laborPrice"
                control={control}
                render={({field}) => (
                  <CurrencyInput
                    {...field}
                    label={formatMessage({
                      id: 'bookpodium.estimates.trades.item.labor-install-price',
                    })}
                    error={errors.laborPrice}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="flex justify-between pb-4 border-b mb-4">
          <p>Total</p>
          <span>{currencyFormat(amountWithLabor || 0)}</span>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-2">
          <Input
            label={formatMessage({
              id: 'bookpodium.estimates.trades.item.profit',
            })}
            name="profit"
            type="number"
            {...register('profit', {required: false})}
          />
        </div>
        <div className="flex justify-between pb-4 border-b mb-4">
          <p>
            {formatMessage({
              id: 'bookpodium.estimates.trades.item.profit-amount',
            })}
          </p>
          <span>{currencyFormat(profitAmount || 0)}</span>
        </div>

        <div className="mb-4">
          <Switch
            label={formatMessage({
              id: 'taxable',
            })}
            onChange={(val) => setSwitchs((prev) => ({...prev, taxable: val}))}
            checked={switchs.taxable}
          />
        </div>

        <div className="mb-4">
          <TextArea
            label={formatMessage({id: 'description'})}
            type="text"
            error={errors.notes}
            name="notes"
            {...register('notes')}
          />
        </div>

        <div className="flex justify-end w-full py-2">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ItemForm;
