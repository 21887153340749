/* eslint-disable no-unused-vars */
import {useState, useMemo} from 'react';
import {createContext} from 'use-context-selector';
import {
  endOfMonth,
  startOfYear,
  format as formatDate,
  getMonth,
} from 'date-fns';

export const DashboardContext = createContext({});

export function DashboardProvider({children}) {
  const now = new Date();
  const isJanOrFeb = getMonth(now) <= 1;

  const [period, setPeriod] = useState(
    isJanOrFeb
      ? {
          startDate: '01-01-2023',
          endDate: formatDate(endOfMonth(now), 'MM-dd-yyyy'),
        }
      : {
          startDate: formatDate(startOfYear(now), 'MM-dd-yyyy'),
          endDate: formatDate(endOfMonth(now), 'MM-dd-yyyy'),
        },
  );

  const values = useMemo(
    () => ({
      period,
      setPeriod,
    }),
    [period, setPeriod],
  );

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
}
